import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export interface MenuItemProps {
  icon?: any;
  active?: boolean;
  variant?: 'primary'|'secondary';
  link?: string;
  href?: string;
  onClick?: () => void;
  children?: any;
  className?: string;
}

export function MenuItem(props: MenuItemProps) {
  const content = (
    <>
      <div className="MenuItem__Icon">{props.icon}</div>
      {props.children}
    </>
  );
  const className = classNames('MenuItem', {
    'MenuItem--active': props.active,
    'MenuItem--disabled': !(props.link || props.href || props.onClick),
    'MenuItem--emphasize': props.variant === 'secondary',
  }, props.className);
  if(props.link) {
    return (
      <Link className={className} to={props.link}>{content}</Link>
    );
  } else if(props.href) {
    return (
      <a target="_blank" rel="noopener noreferrer" className={className} href={props.href}>{content}</a>
    );
  } else if(props.onClick) {
    return (
      <button className={className} onClick={props.onClick}>{content}</button>
    );
  } else {
    return (
      <div className={className}>{content}</div>
    );
  }
}

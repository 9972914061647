import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import { Link, Prompt, useHistory } from 'react-router-dom';
import { useAuthFetch } from '../AuthService';
import { personFetchOrdering, personUpdateOrdering } from '../BackendService';
import { SaveButton } from '../Components/SaveButton';
import { ContainerLeft } from '../Components/UtilityComponents';
import { PersonOrderingReadItemDto } from '../Generated/BackendTypes';
import { IconArrowLeft, IconMenu } from '../icons';
import { usePolyglot } from '../Localization/Localization';
import { ScheduleId } from '../Types';
import { Path } from '../Utils';

const droppableId = 'personReorder';

export interface PersonReorderProps {
  scheduleId: ScheduleId;
}

export function PersonReorder(props: PersonReorderProps) {
  const pg = usePolyglot();
  const authFetch = useAuthFetch();
  const scheduleId = props.scheduleId;
  const history = useHistory();
  const [initialPersons, setInitialPersons] = useState<PersonOrderingReadItemDto[]>([]);
  const [persons, setPersons] = useState<PersonOrderingReadItemDto[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [dragging, setDragging] = useState(false);

  const dirty = !isEqual(initialPersons.map(s => s.personId), persons.map(s => s.personId));

  useEffect(() => {
    setLoading(true);
    setError(false);
    personFetchOrdering(authFetch, scheduleId)
    .then(response => {
      setInitialPersons(response.persons);
      setPersons(response.persons);
    })
    .catch(error => {
      console.error(error);
      setError(true);
    })
    .finally(() => setLoading(false));
  }, [authFetch, scheduleId]);

  async function handleSave() {
    setLoading(true);
    personUpdateOrdering(authFetch, scheduleId, persons.map(s => s.personId))
    .then(response => {
      setInitialPersons(response.persons);
      setPersons(response.persons);
      history.push(Path.toTeam(scheduleId));
    })
    .catch(error => {
      console.error(error);
      setError(true);
    })
    .finally(() => setLoading(false));
  }

  function handleDragUpdate() {
    setDragging(true);
  }

  function handleDragEnd({ destination, source, reason }: DropResult) {
    setDragging(false);
    if(destination?.droppableId === droppableId && source.droppableId === droppableId) {
      if(destination.index !== source.index) {
        const updated = [...persons];
        updated.splice(source.index, 1);
        updated.splice(destination.index, 0, persons[source.index]);
        setPersons(updated);
      }
    }
  }

  return (
    <ContainerLeft>
      <h1 className="pt-3 mb-4">{pg.t('general.persons')} &ndash; {pg.t('general.changeOrder')}</h1>
      {!dirty &&
        <Button variant="secondary" as={Link} to={Path.toTeam(scheduleId)}>
          <IconArrowLeft /> {pg.t('general.back')}
        </Button>
      }
      {dirty &&
        <>
          <SaveButton dirty={dirty} saving={loading} error={error} onClick={handleSave} />
          <Button variant="secondary" className="ml-3" as={Link} to={Path.toTeam(scheduleId)}>
            {pg.t('general.cancel')}
          </Button>
        </>
      }
      {(dragging || dirty) && <Prompt message={pg.t('general.unsavedChangesPrompt')} />}
      <DragDropContext onDragUpdate={handleDragUpdate} onDragEnd={handleDragEnd}>
        <Droppable droppableId={droppableId}>
          {(provided) =>
            <ListGroup {...provided.droppableProps} ref={provided.innerRef} className="mt-3">
              {persons.map((person, index) => (
                <Draggable key={person.personId} draggableId={person.personId} index={index}>
                  {(provided) =>
                    <ListGroupItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <IconMenu /><span className="pl-3">{person.name}</span>
                    </ListGroupItem>
                  }
                </Draggable>
              ))}
              {provided.placeholder}
            </ListGroup>
          }
        </Droppable>
      </DragDropContext>
    </ContainerLeft>
  );
}

import React, { useState } from 'react';
import { ScheduleProperties, SchedulePropertiesValues } from './ScheduleProperties';
import { ConfirmationModal } from '../Components/ConfirmationModal';
import { Button, Card } from 'react-bootstrap';
import { linkToDataTransferScheduleExport } from '../BackendService';
import { AuthFetchButton } from '../Components/AuthFetchButton';
import { ScheduleId } from '../Types';


export interface ScheduleConfigProps {
  scheduleId: ScheduleId;
  scheduleProperties: SchedulePropertiesValues;
  showDuplicateButton?: boolean;
  showExportButton?: boolean;
  onSaveScheduleProperties: (values: SchedulePropertiesValues) => Promise<void>;
  onDeleteSchedule: () => void;
  onCloneSchedule: () => void;
}

export function ScheduleConfig(props: ScheduleConfigProps) {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCloneModal, setShowCloneModal] = useState<boolean>(false);
  return (
    <>
      <ScheduleProperties
        initial={props.scheduleProperties}
        onSave={props.onSaveScheduleProperties}
      />
      <h1 className="mt-5">Aktionen</h1>
      {props.showDuplicateButton &&
        <Card body border="primary" className="mb-3">
          <Button
            onClick={() => setShowCloneModal(true)}
            className="float-right"
            variant="primary"
          >
            Dienstplan duplizieren
          </Button>
          <Card.Title>Dienstplan duplizieren</Card.Title>
          <Card.Text>
            Erstelle eine identische Kopie dieses Dienstplans.
          </Card.Text>
        </Card>
      }

      {props.showExportButton &&
        <Card body border="primary" className="mb-3">
          <AuthFetchButton
            input={linkToDataTransferScheduleExport(props.scheduleId)}
            filename={`${props.scheduleId}-raw.json`}
            className="float-right"
            variant="primary"
          >
            Raw-Export
          </AuthFetchButton>
          <Card.Title>Developer-Export</Card.Title>
          <Card.Text>
            Dienstplan als JSON exportieren.
          </Card.Text>
        </Card>
      }


      <Card body border="danger" className="mb-3">
        <Button
          onClick={() => setShowDeleteModal(true)}
          variant="danger"
          className="float-right"
        >
          Dienstplan löschen
        </Button>
        <Card.Title>Dienstplan löschen</Card.Title>
        <Card.Text>
          Ein gelöschter Dienstplan kann nicht wiederhergestellt werden.
        </Card.Text>
      </Card>
      <ConfirmationModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onNegative={() => setShowDeleteModal(false)}
        onPositive={() => { props.onDeleteSchedule(); setShowDeleteModal(false); }}>
        Möchtest du diesen Dienstplan wirklich löschen?
      </ConfirmationModal>
      <ConfirmationModal
        show={showCloneModal}
        onHide={() => setShowCloneModal(false)}
        onNegative={() => setShowCloneModal(false)}
        onPositive={() => { props.onCloneSchedule(); setShowCloneModal(false); }}>
        Möchtest du diesen Dienstplan wirklich duplizieren?
      </ConfirmationModal>
    </>
  )
}

import React, { useMemo, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { ContainerLeft } from '../Components/UtilityComponents';
import { usePolyglot } from '../Localization/Localization';
import { Title } from '../TopBar/Title';
import { memberof, Path, StorageKey } from '../Utils';
import { Link } from 'react-router-dom';
import { usageCreateCheckoutSession } from '../BackendService';
import { useAuthFetch } from '../AuthService';
import { InvoiceAddressContainer } from './InvoiceAddressContainer';
import Polyglot from 'node-polyglot';
import { Formik, Form as FormikForm, FormikErrors } from 'formik';
import { FoboField } from '../Components/FoboField';
import { DropdownSingleSelectField } from '../Components/DropdownSingleSelectField';
import { DropdownSingleSelectItem } from '../Components/DropdownSingleSelect';

import imagePaymentProviders from '../images/payment-providers.svg';
import { useSessionStorage } from '../Hooks';
import { IconAlertTriangle, IconArrowLeft, IconCreditCard, IconLoader2 } from '../icons';

interface TopUpData {
  selectedOption: string;
  customAmount: string;
}

const defaultTopUpData: TopUpData = { selectedOption: '20', customAmount: '20' };

function createDropdownItems(pg: Polyglot): DropdownSingleSelectItem<string>[] {
  return [
    { itemKey: '20', label: (<>20 {pg.t('general.rows')} &ndash; CHF 100</>) },
    { itemKey: '50', label: (<>50 {pg.t('general.rows')} &ndash; CHF 250</>) },
    { itemKey: '100', label: (<>100 {pg.t('general.rows')} &ndash; CHF 500</>) },
    { itemKey: '200', label: (<>200 {pg.t('general.rows')} &ndash; CHF 1000</>) },
    { itemKey: '', label: (<>{pg.t('usage.customAmount')}</>) }
  ];
}

const pricePerRow = 5;
const maximumAmount = 200;

function convertCustomAmountToPrice(customAmount: string) {
  const value = parseInt(customAmount, 10);
  return `CHF ${isFinite(value) ? value * pricePerRow : ''}`;
}

export function AccountTopUp() {
  const pg = usePolyglot();
  const authFetch = useAuthFetch();

  const [isEditingInvoiceAddress, setIsEditingInvoiceAddress] = useState(false);
  const dropdownItems = useMemo(() => createDropdownItems(pg), [pg]);
  const [initialTopUpData, setInitialTopUpData] = useSessionStorage<TopUpData>(StorageKey.accountTopUpData, defaultTopUpData);

  const [checkoutLoading, setCheckoutLoading] = useState<'idle' | 'loading' | 'error'>('idle');

  async function handleSubmit(values: TopUpData) {
    // remember selection
    setInitialTopUpData(values);

    let amount: number;
    if(values.selectedOption === '') {
      amount = parseInt(values.customAmount, 10);
    } else {
      amount = parseInt(values.selectedOption, 10);
    }

    setCheckoutLoading('loading');
    try {
      const url = await usageCreateCheckoutSession(authFetch, { amount });
      window.location.href = url;
    } catch {
      setCheckoutLoading('error');
    }
  }

  function handleValidate(values: TopUpData) {
    const errors: FormikErrors<TopUpData> = {};
    if(values.selectedOption === '') {
      const intValue = parseInt(values.customAmount, 10);
      if(intValue < 0) {
        errors.customAmount = pg.t('validation.notNegative');
      } else if(intValue === 0) {
        errors.customAmount = pg.t('validation.notNull')
      } else if(intValue > maximumAmount) {
        errors.customAmount = pg.t('validation.notLargerThan', { number: maximumAmount })
      } else  if(!values.customAmount.trim().match(/^[0-9]*$/)) {
        errors.customAmount = pg.t('validation.onlyDigits');
      }
    }
    return errors;
  }

  return (
    <ContainerLeft className="pt-3">
      <Title>{pg.t('usage.topUpCredit')}</Title>
      <Button variant="secondary" as={Link} to={Path.toAccountUsage}>
        <IconArrowLeft /> {pg.t('general.usage')}
      </Button>
      <Row className="mt-3">
        <Col md={8}>
          <InvoiceAddressContainer
            editing={setIsEditingInvoiceAddress}
          />
        </Col>
      </Row>
      <Formik
        initialValues={{...defaultTopUpData, ...initialTopUpData}}
        onSubmit={handleSubmit}
        validate={handleValidate}
      >
        {({ values, isValid }) => {
          const checkoutDisabled = !isValid || isEditingInvoiceAddress || checkoutLoading === 'loading';
          return (
            <Form as={FormikForm}>
              <Row className="mt-3">
                <Col md={8}>
                  <Card body>
                    <Card.Title>{pg.t('usage.selectTopUpAmount')}</Card.Title>
                    <DropdownSingleSelectField
                      id="formTopUpAmountDropdown"
                      name={memberof<TopUpData>('selectedOption')}
                      items={dropdownItems}
                    />
                    {values.selectedOption === '' &&
                      <Row className="mt-3">
                        <Form.Group controlId="formCustomAmount" className="ml-3">
                          <Form.Label>{pg.t('usage.countOfRows')}</Form.Label>
                          <FoboField name={memberof<TopUpData>('customAmount')} />
                        </Form.Group>
                        <Form.Group controlId="formCustomAmountMonetary" className="ml-3">
                          <Form.Label>{pg.t('usage.invoiceTotal')}</Form.Label>
                          <Form.Control
                            disabled
                            value={convertCustomAmountToPrice(values.customAmount)}
                          />
                        </Form.Group>
                      </Row>
                    }
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <div className="mt-3 text-center">
                    <Button
                      variant="link"
                      type="submit"
                      disabled={checkoutDisabled}
                    >
                      <img
                        src={imagePaymentProviders}
                        alt="Visa, mastercard, AmericanExpress, Apple Pay, Google Pay, ..."
                        className="align-middle"
                      />
                    </Button>
                    und weitere nach Verfügbarkeit
                  </div>
                  <Button
                    block
                    type="submit"
                    size="lg"
                    variant={checkoutDisabled ? "secondary" : "warning"}
                    disabled={checkoutDisabled}
                    className="mt-3"
                  >
                    {checkoutLoading === 'idle' && <IconCreditCard />}
                    {checkoutLoading === 'loading' && <IconLoader2 className="loading-spinner" />}
                    {checkoutLoading === 'error' && <IconAlertTriangle />}
                    &nbsp; Weiter zur Zahlung
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </ContainerLeft>
  );
}

import React, { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';
import { Path, StorageKey } from '../Utils';
import Button from 'react-bootstrap/Button';
import { TeamId } from '../Types';
import { deriveScheduleCaption, getMiddleDate } from '../Domain';
import { fetchScheduleList } from '../BackendService';
import { ContainerLeft, LoadingSpinner } from '../Components/UtilityComponents';
import { Alert, Dropdown } from 'react-bootstrap';
import { useLocalStorage } from '../Hooks';
import { ScheduleListItemDto, ScheduleListTeamDto } from '../Generated/BackendTypes';
import { useAuthFetch } from '../AuthService';
import { usePolyglot } from '../Localization/Localization';
import { Title } from '../TopBar/Title';
import fp from 'lodash/fp';
import { CreateFirstTeam } from '../AccountOrganization/CreateFirstTeam';
import { IconPlus, IconEye, IconChevronRight } from '../icons';

export function ScheduleList() {
  const authFetch = useAuthFetch();
  const pg = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string|undefined>(undefined);
  const [teamId, setTeamId] = useLocalStorage<TeamId|undefined>(StorageKey.lastUsedTeamId, undefined);
  const [schedules, setSchedules] = useState<ScheduleListItemDto[]>([]);
  const [teams, setTeams] = useState<ScheduleListTeamDto[]>([]);
  const [showFullDate, setShowFullDate] = useLocalStorage<boolean>(StorageKey.scheduleListShowFullDate, false);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    setLoading(true);
    fetchScheduleList(authFetch)
    .then(d => {
      setTeams(d.teams);
      setSchedules(d.schedules);
    })
    .catch(e => setError(e.message))
    .finally(() => setLoading(false));
  }, [authFetch, refreshKey]);

  useEffect(() => {
    if(teams.length > 0) {
      if(teams.findIndex(team => team.teamId === teamId) === -1) {
        setTeamId(teams[0].teamId);
      }
    }
  }, [teamId, teams, setTeamId]);

  function handleFirstTeamCreated() {
    setRefreshKey(refreshKey + 1);
  }

  const schedulesOfTeam = schedules.filter(item => item.teamId === teamId);
  const schedulesOfTeamByYear = fp.flow(
    fp.groupBy((s: ScheduleListItemDto) => getMiddleDate(s.startDate, s.endDate).year()),
    fp.toPairs,
    fp.sortBy<[number, ScheduleListItemDto[]]>(([year]) => year),
    fp.reverse,
  )(schedulesOfTeam);

  return (
    <>
      <Title>{pg.t('general.schedules')}</Title>
      {error &&
        <ContainerLeft>
          <Alert variant="danger">{pg.t('general.errorMessageIntro')}: {error}</Alert>
        </ContainerLeft>
      }
      {loading &&
        <ContainerLeft>
          <LoadingSpinner />
        </ContainerLeft>
      }
      {!loading && teams.length === 0 &&
        <CreateFirstTeam onComplete={handleFirstTeamCreated} />
      }
      {teams.length > 0 &&
        <ContainerLeft>
          <div className="mb-3 mt-3 d-flex flex-row">
            <Dropdown>
              <Dropdown.Toggle
                variant="secondary"
                id="select-team-dropdown"
              >
                {teams.find(team => team.teamId === teamId)?.name}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {teams.map(team =>
                  <Dropdown.Item key={team.teamId} onClick={() => setTeamId(team.teamId)}>{team.name}</Dropdown.Item>
                )}
                <Dropdown.Divider />
                <Dropdown.Item as={Link} to={Path.toAccountOrganization}>
                  <IconPlus /> {pg.t('schedule.createTeam')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {teamId &&
              <>
                <Button as={Link} to={Path.toNewSchedule(teamId)} className="ml-3">
                  <IconPlus /> {pg.t('schedule.createSchedule')}
                </Button>
                <Button as={Link} to={Path.toNewScheduleFromExample(teamId)} className="ml-3">
                  <IconPlus /> {pg.t('schedule.createScheduleFromExample')}
                </Button>
              </>
            }
            <Button
              variant="light"
              onClick={() => setShowFullDate(!showFullDate)}
              className="ml-3"
            >
              <IconEye />&nbsp;{showFullDate ? pg.t('schedule.dateModeFullDate') : pg.t('schedule.dateModeMonthOnly')}
            </Button>
          </div>
          {schedulesOfTeamByYear.map(([year, schedulesOfYear]) =>
            <React.Fragment key={year}>
              <h1 className="mt-5">{year}</h1>
              <ListGroup variant="flush" className="mt-3">
                {schedulesOfYear.map(item =>
                  <ListGroup.Item key={item.scheduleId} action as={Link} to={Path.toTeam(item.scheduleId)}>
                    {deriveScheduleCaption(item.startDate, item.endDate, { comment: item.comment, showYear: false, showFullDate })}
                    <IconChevronRight className="float-right" />
                  </ListGroup.Item>
                )}
              </ListGroup>
            </React.Fragment>
          )}
        </ContainerLeft>
      }
    </>
  );
}

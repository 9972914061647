import { Formik, FormikErrors, Form as FormikForm } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import { FoboField } from '../Components/FoboField';
import { SaveButton } from '../Components/SaveButton';
import { usePolyglot } from '../Localization/Localization';

interface TenantSettingsData {
  name: string;
}

export interface TenantSettingsProps {
  name: string;
  onSubmit: (name: string) => Promise<void>;
}

export function TenantSettings(props: TenantSettingsProps) {
  const pg = usePolyglot();
  return (
    <Formik
      initialStatus={undefined}
      initialValues={{ name: props.name }}
      onSubmit={values => props.onSubmit(values.name)}
      validate={values => {
        const errors: FormikErrors<TenantSettingsData> = {};
        if(values.name.length === 0) {
          errors.name = pg.t('validation.notEmpty');
        }
        return errors;
      }}
      enableReinitialize
    >
      {({ isSubmitting, dirty, status }) => (
        <Form as={FormikForm}>
          <h1 className="mt-5">{pg.t('account.additionalSettings')}</h1>
          <Form.Group controlId="formName">
            <Form.Label>{pg.t('account.organizationName')}</Form.Label>
            <FoboField type="text" name="name" />
          </Form.Group>
          <SaveButton type="submit" dirty={dirty} saving={isSubmitting} error={status === false} />
        </Form>
      )}
    </Formik>
  )
}

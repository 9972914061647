import React from 'react';
import { computationFetchResult, solverTaskCreate, computationUpdateAdjustments } from '../BackendService';
import { AuthFetch } from '../AuthService';
import { LoadingSpinner } from '../Components/UtilityComponents';
import { ComputationAdjustmentsChangeDto, ComputationAdjustmentsDto, ComputationTableCellDto, ComputationResultDto, TargetWorkloadMode0 } from '../Generated/BackendTypes';
import { ComputationCellData, ComputationCellViewData, ComputationTable } from './ComputationTable';
import { PersonId, ScheduleId, ShiftId, ShiftLookup } from '../Types';
import Polyglot from 'node-polyglot';
import { Spacetime } from 'spacetime';
import fp, { fromPairs } from 'lodash/fp';
import classNames from 'classnames';
import { ExternalLinks, getDatesBetween, getLocalStorageValue, mapi, minutesToDayHourMinute, Path, setLocalStorageValue, StorageKey } from '../Utils';
import { generateDayOfMonthRow, generateDayOfWeekRow, readOnlyCell } from '../Table/Utils';
import { pensumDescription } from '../Domain';
import ReactDataSheet from 'react-datasheet';
import { Alert, Container } from 'react-bootstrap';
import { History } from 'history';
import { isEmpty, isNumber, isString } from 'lodash';
import { ComputationDrilldown } from './ComputationDrilldown';
import { ComputationToolbar } from './ComputationToolbar';
import { UnsatSubsets } from './UnsatSubsets';
import { toNamesLookup } from '../Condition/Types';
import { computePlanningTimesFromResult, PlanningTimes } from '../Domain/PlanningTimes';
import { ContainerLeft } from '../Components/UtilityComponents';
import { IntroPanel } from '../Components/IntroPanel';
import imageCalculation from '../images/calculation.svg';

export function plannedLoadLabel(planningTimes: PlanningTimes, targetWorkloadMode: TargetWorkloadMode0, precision: 'cell'|'tooltip') {
  if(targetWorkloadMode === 'Pensum') {
    if(precision === 'cell') {
      return `${(planningTimes.plannedDuration / 60).toFixed(0)} h`;
    } else {
      return minutesToDayHourMinute(planningTimes.plannedDuration);
    }
  } else {
    if(precision === 'cell') {
      return `${planningTimes.plannedCount}`;
    } else {
      return minutesToDayHourMinute(planningTimes.plannedDuration);
    }
  }
}

export function targetLoadLabel(planningTimes: PlanningTimes, targetWorkloadMode: TargetWorkloadMode0, precision: 'cell'|'tooltip') {
  if(targetWorkloadMode === 'Pensum') {
    if(planningTimes.targetDurationMin === planningTimes.targetDurationMax) {
      if(precision === 'cell') {
        return `${(planningTimes.targetDurationMax / 60).toFixed(0)} h`;
      } else {
        return minutesToDayHourMinute(planningTimes.targetDurationMax);
      }
    } else {
      if(precision === 'cell') {
        return `${(planningTimes.targetDurationMin / 60).toFixed(0)} - ${(planningTimes.targetDurationMax / 60).toFixed(0)} h`;
      } else {
        return `${minutesToDayHourMinute(planningTimes.targetDurationMin)} - ${minutesToDayHourMinute(planningTimes.targetDurationMax)}`;
      }
    }
  } else {
    if(planningTimes.targetCountMin === planningTimes.targetCountMax) {
      return `${planningTimes.targetCountMin}`;
    } else {
      return `${planningTimes.targetCountMin} - ${planningTimes.targetCountMax}`;
    }
  }
}

export function diffLoadLabel(planningTimes: PlanningTimes, targetWorkloadMode: TargetWorkloadMode0, precision: 'cell'|'tooltip') {
  if(targetWorkloadMode === 'Pensum') {
    if(precision === 'cell') {
      return `${(planningTimes.diffDuration/60).toFixed(0)} h`;
    } else {
      return minutesToDayHourMinute(planningTimes.diffDuration);
    }
  } else {
    return `${planningTimes.diffCount}`;
  }
}


export interface Change {
  personId: PersonId;
  index: number;
  date: Spacetime;
  shiftId?: ShiftId;
}

export interface RecordedChange extends Change {
  changeId: number;
}

interface ComputationTableDataState {
  savedData?: ComputationResultDto;
  changes: RecordedChange[];
  table: ComputationCellViewData[][];
}

const computeCellData = (personId: PersonId, changes: RecordedChange[], dates: Spacetime[], shifts: ShiftLookup[], shiftIdToIdx: Record<ShiftId, number>) =>
  (d: ComputationTableCellDto, index: number): ComputationCellData => {

  const lastChange = fp.flow(
    fp.filter((c: Change) => c.personId === personId && c.index === index),
    fp.last
  )(changes);

  let adjustedShiftIdx: number|undefined;
  if(lastChange) {
    adjustedShiftIdx = isString(lastChange.shiftId) ? shiftIdToIdx[lastChange.shiftId] : undefined;
  } else {
    adjustedShiftIdx = d.a;
  }

  return {
    personId,
    index,
    date: dates[index],
    computedShiftIdx: d.c,
    adjustedShiftIdx,
    shifts
  }
}

const computeCellViewData = (dates: Spacetime[], shifts: ShiftLookup[], forceReadOnly: boolean) => (data: ComputationCellData): ComputationCellViewData => {
  let label: string;
  if(isNumber(data.adjustedShiftIdx)) {
    label = shifts[data.adjustedShiftIdx].label;
  } else {
    label = shifts[data.computedShiftIdx].label;
  }

  const className = classNames({
    'vt-sat': dates[data.index].day() === 6,
    'vt-sun': dates[data.index].day() === 0,
    'vt-hl': isNumber(data.adjustedShiftIdx),
  });

  return {
    label,
    data,
    className,
    readOnly: forceReadOnly
  };
}

function recomputeTableDataState(savedData: ComputationResultDto, changes: RecordedChange[], forceReadOnly: boolean): ComputationTableDataState {
  const dates = getDatesBetween(savedData.scheduleStartDate, savedData.scheduleEndDate);
  const shiftIdToIdx = fromPairs(savedData.shifts.map(({ shiftId }, shiftIdx) => [shiftId, shiftIdx]));

  const titleCell: ComputationCellViewData = {
    label: '',
    readOnly: true,
    className: 'text-left font-weight-bold cell-no-border-right',
  }

  const personsColumnHeader: ComputationCellViewData = {
    label: 'Personen',
    readOnly: true,
    className: 'text-left font-weight-bold cell-no-border-right',
  };

  const pensumColumnHeader: ComputationCellViewData = {
    label: 'Pensum',
    readOnly: true,
    className: 'text-left font-weight-bold'
  };

  const plannedLoadColumnHeader: ComputationCellViewData = {
    label: 'Geplant',
    readOnly: true,
    className: 'text-left font-weight-bold'
  };

  const targetLoadColumnHeader: ComputationCellViewData = {
    label: 'Soll',
    readOnly: true,
    className: 'text-left font-weight-bold'
  };

  const diffLoadColumnHeader: ComputationCellViewData = {
    label: 'Differenz',
    readOnly: true,
    className: 'text-left font-weight-bold'
  };

  const empty1 = readOnlyCell();

  const header = [
    [titleCell, empty1, ...generateDayOfMonthRow(dates), empty1, empty1, empty1],
    [personsColumnHeader, pensumColumnHeader, ...generateDayOfWeekRow(dates), plannedLoadColumnHeader, targetLoadColumnHeader, diffLoadColumnHeader],
  ];

  const planningTimes = computePlanningTimesFromResult(savedData);

  const content = savedData.persons.map((person, personIdx) => {
    const personId = person.personId
    const entries = savedData.entries[personIdx];

    const personCell: ComputationCellViewData = {
      readOnly: true,
      label: person.name,
      className: 'ct-person cell-no-border-right',
    };

    const pensumCell: ComputationCellViewData = {
      readOnly: true,
      label: pensumDescription(person),
      className: 'ct-pensum',
    };

    const personChanges = changes.filter(c => c.personId === personId);

    const cells = fp.flow(
      mapi(computeCellData(personId, personChanges, dates, savedData.shifts, shiftIdToIdx)),
      fp.map(computeCellViewData(dates, savedData.shifts, forceReadOnly))
    )(entries);

    const plannedLoadCell: ComputationCellViewData = {
      readOnly: true,
      label: plannedLoadLabel(planningTimes[personIdx], person.targetWorkload.mode, 'cell'),
      title: plannedLoadLabel(planningTimes[personIdx], person.targetWorkload.mode, 'tooltip'),
      className: 'ct-planned'
    };

    const targetLoadCell: ComputationCellViewData = {
      readOnly: true,
      label: targetLoadLabel(planningTimes[personIdx], person.targetWorkload.mode, 'cell'),
      title: targetLoadLabel(planningTimes[personIdx], person.targetWorkload.mode, 'tooltip'),
      className: 'ct-target'
    };

    const diffLoadCell: ComputationCellViewData = {
      readOnly: true,
      label: diffLoadLabel(planningTimes[personIdx], person.targetWorkload.mode, 'cell'),
      title: diffLoadLabel(planningTimes[personIdx], person.targetWorkload.mode, 'tooltip'),
      className: 'ct-diff'
    };

    return [
      personCell,
      pensumCell,
      ...cells,
      plannedLoadCell,
      targetLoadCell,
      diffLoadCell
    ];
  });

  const table: ComputationCellViewData[][] = [
    ...header,
    ...content
  ];

  return {
    savedData,
    changes,
    table
  }
}

export interface ComputationTableContainerProps {
  scheduleId: ScheduleId;
  authFetch: AuthFetch;
  history: History;
  pg: Polyglot;
}

export interface ComputationTableContainerState extends ComputationTableDataState {
  loading: boolean;
  // separate saving indicator (next to loading) because it's a different spinner
  saving: boolean;
  error?: string;
  nextChangeId: number;
  showDrilldown: boolean;
}

export class ComputationTableContainer extends React.Component<ComputationTableContainerProps, ComputationTableContainerState> {
  _intervalHandle: any;

  constructor(props: ComputationTableContainerProps) {
    super(props);
    this.state = {
      loading: false,
      saving: false,
      changes: [],
      table: [],
      nextChangeId: 0,
      showDrilldown: getLocalStorageValue(StorageKey.computationShowDrilldown, false)
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    computationFetchResult(this.props.authFetch, this.props.scheduleId)
      .then(
        savedData => {
          this.setState({
            error: undefined,
            loading: false,
            ...recomputeTableDataState(savedData, [], false)
          });
        },
        error => {
          this.setState({
            error: error.message,
            loading: false
          });
        }
      );

    // start continuous saving
    this._intervalHandle = setInterval(this.handleSaveChanges, 1000);
  }

  componentWillUnmount() {
    clearInterval(this._intervalHandle);
  }

  // Change management
  addChanges(changes: Change[]) {
    this.setState(state => {
      if(state.savedData) {
        let nextChangeId = state.nextChangeId;
        // assign unique change id to each change
        const recordedChanges = [];
        for(let change of changes) {
          recordedChanges.push({
            ...change,
            changeId: nextChangeId
          });
          nextChangeId ++;
        }

        // update table state and nextChangeId
        return {
          ...recomputeTableDataState(state.savedData, [...state.changes, ...recordedChanges], false),
          nextChangeId
        };
      } else {
        return {};
      }
    })
  }

  async saveChanges() {
    if(!this.state.saving && this.state.changes.length > 0 && this.state.savedData) {
      console.debug(`Detected ${this.state.changes.length} pending changes.`);
      const changes: ComputationAdjustmentsChangeDto[] = this.state.changes.map(change => ({
        personId: change.personId,
        date: change.date.format('iso-short') as string,
        shiftId: change.shiftId
      }));

      const payload: ComputationAdjustmentsDto = {
        scheduleId: this.props.scheduleId,
        documentRevision: this.state.savedData.documentRevision,
        changes
      }

      const lastSavedChangeId = fp.flow(
        fp.map((change: RecordedChange) => change.changeId),
        fp.max
      )(this.state.changes);

      if(lastSavedChangeId !== undefined) {
        console.debug(`Saving ${changes.length} changes up to changeId ${lastSavedChangeId}.`);
        this.setState({ saving: true });
        try {
          const newSavedData = await computationUpdateAdjustments(this.props.authFetch, this.props.scheduleId, payload);
          this.setState(state => {
            const unsavedChanges = state.changes.filter(change => (change.changeId > lastSavedChangeId));
            console.log(`Saved ${changes.length} changes. Unsaved changes appeared in the meantime ${unsavedChanges.length}`);
            return {
              ...recomputeTableDataState(newSavedData, unsavedChanges, false),
              error: undefined,
              saving: false
            };
          })
        } catch(error) {
          console.error(`Error during save.`, error)
          this.setState({ error: error.message, saving: false });
        }
      }
    } else {
      console.debug('No pending changes');
    }
  }

  // Handlers
  handleCellsChanged = (cellChanges: ReactDataSheet.CellsChangedArgs<ComputationCellViewData>) => {
    if(this.state.savedData) {
      const changes = cellChanges.map(({ cell, value }): Change|undefined => {
        if(cell?.data) {
          let shiftId;
          if(isEmpty(value)) {
            shiftId = undefined;
          } else {
            shiftId = this.state.savedData?.shifts.find(shift => shift.label === value)?.shiftId;
            // if value is not empty but we cannot correlate it to a shift. don't accept the change.
            if(shiftId === undefined) {
              return undefined;
            }
          }
          return {
            personId: cell.data.personId,
            date: cell.data.date,
            index: cell.data.index,
            shiftId
          }
        } else {
          return undefined;
        }
      });
      this.addChanges(fp.compact(changes));
    }
  }

  handleSaveChanges = () => {
    this.saveChanges();
  }

  handleStartSolverTask = async (queueTimeout: number, solveTimeout: number) => {
    // save pending changes
    await this.saveChanges();

    this.setState({ loading: true });
    try {
      await solverTaskCreate(this.props.authFetch, this.props.scheduleId, { queueTimeout, solveTimeout });
      this.setState({ error: undefined, loading: false });
      this.props.history.push(Path.toSolverMonitorLive(this.props.scheduleId));
    } catch(error) {
      this.setState({ loading: false, error: error.message });
    }
  }

  handleDrilldownToggle = () => {
    const newValue = !this.state.showDrilldown;
    setLocalStorageValue(StorageKey.computationShowDrilldown, newValue);
    this.setState({
      showDrilldown: newValue
    });
  }

  // Rendering
  render() {
    let content: any;
    if(this.state.savedData) {
      const lookup = toNamesLookup({
        shifts: fromPairs(this.state.savedData.shifts.map(shift => [shift.shiftId, shift])),
        persons: fromPairs(this.state.savedData.persons.map(person => [person.personId, person])),
        personGroups: fromPairs(this.state.savedData.personGroups.map(personGroup => [personGroup.personGroupId, personGroup]))
      });

      if(this.state.savedData.unsatSubsets.length > 0) {
        return (
          <>
            <ComputationToolbar
              scheduleId={this.props.scheduleId}
              saving={this.state.saving}
              dirty={this.state.changes.length > 0}
              error={this.state.error}
              computing={this.state.savedData.isRunning}
              onStartSolverTask={this.handleStartSolverTask}
            />
            <UnsatSubsets
              {...lookup}
              scheduleId={this.props.scheduleId}
              unsatSubsets={this.state.savedData.unsatSubsets}
            />
          </>
        );
      } else {
        content = (
          <>
            <ComputationToolbar
              scheduleId={this.props.scheduleId}
              saving={this.state.saving}
              dirty={this.state.changes.length > 0}
              error={this.state.error}
              computing={this.state.savedData.isRunning}
              drilldown={this.state.showDrilldown}
              onSaveClick={this.handleSaveChanges}
              onStartSolverTask={this.handleStartSolverTask}
              onDrilldownToggle={this.handleDrilldownToggle}
            />
            <ComputationTable
              table={this.state.table}
              onCellsChanged={this.handleCellsChanged}
            />
            {this.state.showDrilldown &&
              <Container className="mt-5">
                <ComputationDrilldown
                  {...lookup}
                  objectiveValues={this.state.savedData.objectiveValues}
                  totalObjectiveValue={this.state.savedData.totalObjectiveValue}
                />
              </Container>
            }
          </>
        );
      }
    }
    return (
      <>
        <ContainerLeft>
          <IntroPanel imageSource={imageCalculation} href={ExternalLinks.toComputationDocs}>
            {this.props.pg.t('introtext.computation')}
          </IntroPanel>
        </ContainerLeft>
        <h1 className="ml-3 mt-3 mb-5">{this.props.pg.t('general.computation')}</h1>
        {this.state.error &&
          <Alert variant='danger'>{this.state.error}</Alert>
        }
        {this.state.loading &&
          <LoadingSpinner />
        }
        {content}
      </>
    );
  }
}

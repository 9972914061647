import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useAuthFetch } from "./AuthService";
import { userFetch } from "./BackendService";
import { TenantId, UserId } from "./Types";

export interface UserProfile {
  userId: UserId;
  userName: string;
  emailAddress: string;
  tenantId: TenantId;
  tenantName: string;
  isAdmin: boolean;
  isRoot: boolean;
  refresh(): void;
}

const emptyUserProfile: UserProfile = {
  userId: '',
  userName: '',
  emailAddress: '',
  tenantId: '',
  tenantName: '',
  isAdmin: false,
  isRoot: false,
  refresh: () => {}
}

export const UserProfileContext = createContext<UserProfile>(emptyUserProfile);

export function useUserProfile() {
  return useContext(UserProfileContext);
}

export function UserProfileProvider(props: React.PropsWithChildren<{}>) {
  const authFetch = useAuthFetch();
  const [userProfile, setUserProfile] = useState(emptyUserProfile);

  const refresh = useCallback(() => {
    userFetch(authFetch).then(d =>
      setUserProfile({
        userId: d.userId,
        userName: d.userName,
        emailAddress: d.emailAddress,
        tenantId: d.tenantId,
        tenantName: d.tenantName,
        isAdmin: d.a || d.r,
        isRoot: d.r,
        refresh
      })
    );
  }, [authFetch]);

  useEffect(refresh, [refresh]);

  return (
    <UserProfileContext.Provider value={userProfile}>
      {props.children}
    </UserProfileContext.Provider>
  );
}

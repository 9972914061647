import React, { useCallback, useRef } from 'react';
import { PersonId, ScheduleId, ShiftId, ShiftLookup } from '../Types';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { Path } from '../Utils';
import { Link, Prompt } from 'react-router-dom';
import ReactDataSheet from 'react-datasheet';
import { SaveButton } from '../Components/SaveButton';
import { usePolyglot } from '../Localization/Localization';
import { CellViewData, parsePasteMultipleCells } from '../Table/Utils';
import { AvailabilityCellEditor } from './AvailabilityCellEditor';

export interface AvailabilityCellData {
  personId: PersonId;
  index: number;
  options: ShiftId[];
  selection: ShiftId[];
  shifts: ShiftLookup[];
  defaultShiftId: ShiftId;
}

export interface AvailabilityCellViewData extends CellViewData<AvailabilityCellData> {
  dataLabel?: string;
}

class AvailabilityDataSheet extends ReactDataSheet<AvailabilityCellViewData> { }

function valueRenderer(cell: AvailabilityCellViewData) {
  return cell.label;
}

function dataRenderer(cell: AvailabilityCellViewData) {
  return cell.dataLabel;
}

export interface AvailabilityTableProps {
  scheduleId: ScheduleId;
  table: AvailabilityCellViewData[][];
  onCellsChanged?: ReactDataSheet.CellsChangedHandler<AvailabilityCellViewData>;
  loading: boolean;
  dirty: boolean;
  error?: string;
  onSaveClick: () => void;
}

export function AvailabilityTable(props: AvailabilityTableProps) {
  const pg = usePolyglot();

  // remember selection for multi-row/multi-column paste
  const selections = useRef<ReactDataSheet.Selection>();

  function handleSelect(s: ReactDataSheet.Selection) {
    selections.current = s;
  }
  const parsePaste = useCallback(
    (value: string) => parsePasteMultipleCells(value, selections.current),
    [selections]
  );

  return (
    <>
      <div className="d-flex flex-row m-3 mt-5">
        <SaveButton
          className="mr-3"
          dirty={props.dirty}
          saving={props.loading}
          error={props.error !== undefined}
          onClick={props.onSaveClick}
        />
        <Button
          as={Link}
          to={Path.toScheduleImport(props.scheduleId)}
        >
          Eingabedaten-Import
        </Button>
      </div>
      <div>
        {props.error &&
          <Alert variant="danger">{props.error}</Alert>
        }
      </div>
      <AvailabilityDataSheet
        className="AvailabilityTable ValueTable"
        data={props.table}
        valueRenderer={valueRenderer}
        onCellsChanged={props.onCellsChanged}
        onSelect={handleSelect}
        parsePaste={parsePaste}
        dataRenderer={dataRenderer}
        dataEditor={AvailabilityCellEditor}
      />
      {props.dirty && <Prompt message={pg.t('general.unsavedChangesPrompt')} />}
    </>
  );
}

import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { PersonGroupLookupDto, PersonListItemDto } from '../Generated/BackendTypes';

export interface AffectedPersonsCaptionProps {
  personGroups: PersonGroupLookupDto[];
  persons: PersonListItemDto[];
  affectedPersons: PersonListItemDto[];
}

export function AffectedPersonsCaption(props: AffectedPersonsCaptionProps) {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title>Personen</Popover.Title>
      <Popover.Content>
        {props.affectedPersons.map((p, i) => (<span key={i}>{p.name}<br /></span>))}
      </Popover.Content>
    </Popover>
  );
  let caption;
  if(props.personGroups.length === 0) {
    if(props.persons.length === 0) {
      caption = 'Alle';
    } else if(props.persons.length === 1) {
      caption = props.persons[0].name;
    } else {
      caption = <i>{props.persons.length}&nbsp;Personen</i>;
    }
  } else if(props.persons.length === 0) {
    if(props.personGroups.length === 1) {
      caption = props.personGroups[0].name;
    } else {
      caption = <i>{props.personGroups.length}&nbsp;Gruppen</i>;
    }
  } else {
    if(props.personGroups.length === 1) {
      caption = <span>{props.personGroups[0].name}&nbsp;<i>+{props.persons.length}</i></span>;
    } else {
      caption = <i>{props.affectedPersons.length}&nbsp;Personen</i>;
    }
  }
  return (
    <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popover}>
      <div>{caption}</div>
    </OverlayTrigger>
  )
}

import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useAuthFetch } from '../AuthService';
import { tenantFetch, tenantUpdate } from '../BackendService';
import { LoadingSpinner } from '../Components/UtilityComponents';
import { TenantDto } from '../Generated/BackendTypes';
import { usePolyglot } from '../Localization/Localization';
import { useUserProfile } from '../UserProfileContext';
import { TenantSettings } from './TenantSettings';

export function TenantSettingsContainer() {
  const authFetch = useAuthFetch();
  const pg = usePolyglot();
  const userProfile = useUserProfile();
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState<TenantDto|undefined>(undefined);
  const [error, setError] = useState<string|undefined>(undefined);

  useEffect(() => {
    setLoading(true);
    tenantFetch(authFetch)
    .then(d => setSettings(d))
    .catch(e => setError(e.message))
    .finally(() => setLoading(false));
  }, [authFetch]);

  async function handleUpdate(name: string) {
    setLoading(true);
    try {
      setSettings(await tenantUpdate(authFetch, { name }));
      userProfile.refresh();
    } catch(error) {
      setError(error.message);
    }
    setLoading(false);
  }

  if(settings !== undefined) {
    return (
      <TenantSettings
        name={settings.name}
        onSubmit={handleUpdate}
      />
    )
  } else {
    return (
      <>
        {loading &&
          <LoadingSpinner />
        }
        {error &&
          <Alert variant="danger">{pg.t('general.errorMessageIntro')}: {error}</Alert>
        }
      </>
    )
  }
}

import React, { useMemo } from 'react';
import { Spacetime } from 'spacetime';
import { getDatesBetween } from '../Utils';
import { ComputationShiftDto, PersonListItemDto } from '../Generated/BackendTypes';
import { pensumDescription } from '../Domain';
import { generateDayOfMonthRow, generateDayOfWeekRow } from '../Table/Utils';
import classNames from 'classnames';
import { PlanningTimes } from '../Domain/PlanningTimes';
import { diffLoadLabel, plannedLoadLabel, targetLoadLabel } from './ComputationTableContainer';

interface SolverResultTableCellProps {
  label: string;
  className: string
}

function SolverResultTableCell(props: SolverResultTableCellProps) {
  return (
    <td className={props.className}>{props.label}</td>
  );
}

interface SolverResultTableRowProps {
  cells: SolverResultTableCellProps[];
  name: string;
  pensum: string;
  planned: any;
  target: any;
  diff: any;
}

function SolverResultTableRow(props: SolverResultTableRowProps) {
  return (
    <tr>
      <td className="cell ct-person">{props.name}</td>
      <td className="cell ct-pensum">{props.pensum}</td>
      {props.cells.map((cell, index) => <SolverResultTableCell key={`${index}-${cell.label}`} {...cell} />)}
      <td className="cell ct-planned">{props.planned}</td>
      <td className="cell ct-target">{props.target}</td>
      <td className="cell ct-diff">{props.diff}</td>
    </tr>
  )
}

export interface SolverMonitorSolutionTableProps {
  startDate: string|Spacetime;
  endDate:  string|Spacetime;
  persons: PersonListItemDto[];
  shifts: ComputationShiftDto[];
  entries: number[][];
  planningTimes: PlanningTimes[];
}

export function SolverMonitorSolutionTable(props: SolverMonitorSolutionTableProps) {
  const dates = useMemo(() =>
    getDatesBetween(props.startDate, props.endDate),
    [props.startDate, props.endDate]
  );
  const dayOfMonthRow = useMemo(() => generateDayOfMonthRow(dates, { className: 'cell' }), [dates]);
  const dayOfWeekRow = useMemo(() => generateDayOfWeekRow(dates, { className: 'cell' }), [dates]);
  const isSaturday = useMemo(() => dates.map(d => d.day() === 6), [dates]);
  const isSunday = useMemo(() => dates.map(d => d.day() === 0), [dates]);

  return (
    <table className="ComputationTable ValueTable">
      <thead>
        <tr>
          <th></th>
          <th></th>
          {dayOfMonthRow.map((d, i) => <th key={i} className={d.className}>{d.label}</th>)}
        </tr>
        <tr>
          <th className="cell ct-person">Personen</th>
          <th className="cell ct-pensum">Pensum</th>
          {dayOfWeekRow.map((d, i) => <th key={i} className={d.className}>{d.label}</th>)}
          <th className="cell">Geplant</th>
          <th className="cell">Soll</th>
          <th className="cell">Differenz</th>
        </tr>
      </thead>
      <tbody>
        {props.entries.map((row, personIdx) => {
          const person = props.persons[personIdx];
          if(person) {
            const cells = row.map((cell, slot) => ({
              label: props.shifts[cell].label,
              className: classNames('cell', { 'vt-sat': isSaturday[slot], 'vt-sun': isSunday[slot] })
            }));
            return (
              <SolverResultTableRow
                key={person.personId}
                cells={cells}
                name={person.name}
                pensum={pensumDescription(person)}
                planned={plannedLoadLabel(props.planningTimes[personIdx], person.targetWorkload.mode, 'cell')}
                target={targetLoadLabel(props.planningTimes[personIdx], person.targetWorkload.mode, 'cell')}
                diff={diffLoadLabel(props.planningTimes[personIdx], person.targetWorkload.mode, 'cell')}
              />
            );
          } else {
            return <></>;
          }
        })}
      </tbody>
    </table>
  )
}

import React from "react";
import { ErrorMessage, useField } from "formik";
import FormControl, { FormControlProps } from "react-bootstrap/FormControl";

interface FoboFieldProps extends FormControlProps {
  name: string;
  feedbackAsTooltip?: boolean;
}

/**
 * Wire Formik's touched and errors to bootstrap's Form.Control isValid / isInvalid props and also add a FormControl.Feedback.
 *
 * Note that if feedbackAsTooltip is specified, be sure to have a parent with position: relative on it for tooltip positioning.
 * (from v4 docs)
 */
export function FoboField<T>({feedbackAsTooltip, ...props}: T & FoboFieldProps) {
  const [field, meta] = useField(props.name);
  return (
    <>
      <FormControl
        {...field}
        {...props}
        isValid={meta.touched && meta.error === undefined}
        isInvalid={meta.touched && meta.error !== undefined}
      />
      <ErrorMessage name={props.name}>
        {message => <FormControl.Feedback type='invalid' tooltip={feedbackAsTooltip}>{message}</FormControl.Feedback>}
      </ErrorMessage>
    </>
  )
}

import React from 'react';
import { ChipSelectItem } from '../Components/ChipSelect';
import { ChipSelectField } from '../Components/ChipSelectField';
import { DropdownMultiSelectItem } from '../Components/DropdownMultiSelect';
import { PersonGroupId, PersonId } from '../Types';

export type AffectedPersonsSelectorFormData = Array<PersonId | PersonGroupId>;

export interface AffectedPersonsSelectorProps {
  name: string;
  personGroups: DropdownMultiSelectItem<PersonGroupId>[];
  persons: DropdownMultiSelectItem<PersonId>[];
}

export function AffectedPersonsSelector(props: AffectedPersonsSelectorProps) {
  const items: ChipSelectItem<PersonId | PersonGroupId>[] = [
    {
      label: 'Gruppen',
      items: props.personGroups.map(item => ({ ...item, variant: 'composite' }))
    },
    {
      label: 'Personen',
      items: props.persons
    }
  ];

  return (
    <ChipSelectField
      id="personSelectorChipSelect"
      items={items}
      name={props.name}
      placeholder="Alle Personen"
    />
  );
}

export function toAffectedPersonsSelectorFormData(personIds: PersonId[], personGroupIds: PersonGroupId[]): AffectedPersonsSelectorFormData {
  return [...personIds, ...personGroupIds]
}

export function fromAffectedPersonsSelectorFormData(
  personIds: PersonId[],
  personGroupIds: PersonGroupId[],
  d: AffectedPersonsSelectorFormData) {
  return {
    personIds: d.filter(id => personIds.includes(id)),
    personGroupIds: d.filter(id => personGroupIds.includes(id))
  };
}

import React from 'react';

export interface LogoProps {
  expanded: boolean;
}

export function Logo({ expanded }: LogoProps) {
  const names = expanded ? 'Logo' : 'Logo Logo--Shrinked';
  return (
    <svg width="100" height="40" version="1.1" viewBox="0 0 26.4584 10.5834" xmlns="http://www.w3.org/2000/svg">
      <g strokeWidth=".5">
        <path d="m1.32292 1.32292v1.05833l6.35001-1.9e-6v-1.05833zm2.64584 1.85208-1.3e-6 6.08542h1.05834l0.02192-6.08542z" />
        <path className={names} d="m9.7744 3.13114c-1.41312 0-2.20338 0.531705-2.37092 1.59519l1.02716 0.092835c0.040065-0.327785 0.160013-0.56629 0.360326-0.715615 0.200314-0.14933 0.52102-0.22407 0.96171-0.22407 0.437048 0 0.75744 0.103635 0.961395 0.311235s0.305884 0.551765 0.305884 1.03252v0.32256l0.0321 2.47477h6e-4c0.02185 0.40791 0.114928 0.708255 0.278821 0.90129 0.167535 0.18938 0.433232 0.28417 0.79744 0.28417 0.258586 0 0.5117-0.02925 0.75936-0.087485v-0.61177c-0.120188 0.0255-0.227555 0.03805-0.322249 0.03805-0.214881 0-0.360629-0.072715-0.437112-0.2184-0.07648-0.14932-0.114549-0.36981-0.114549-0.661175v-2.54558c0-0.644645-0.187544-1.13658-0.562675-1.47529-0.375112-0.342355-0.934325-0.51327-1.67731-0.51327zm0.421378 2.42819-0.5032 0.00815c-0.895945 0.02185-1.54622 0.196815-1.95048 0.5246-0.400626 0.327785-0.600755 0.79765-0.600755 1.40952 0 0.54631 0.149162 0.975775 0.447812 1.289 0.298648 0.31322 0.744745 0.46984 1.3384 0.46984 0.47711 0 0.88141-0.092755 1.21284-0.278505 0.150676-0.08516 0.292676-0.198215 0.426412-0.33641-0.08079-0.19726-0.144486-0.40625-0.162697-0.58439-0.08927 0.079715-0.184128 0.15387-0.290779 0.2162-0.280439 0.16389-0.601145 0.245775-0.96171 0.245775-0.320502 0-0.56818-0.08732-0.742995-0.26214-0.171178-0.174815-0.256792-0.4225-0.256792-0.742995 0-0.29501 0.063862-0.53383 0.191336-0.715935 0.127472-0.182105 0.309578-0.31666 0.54631-0.40407 0.240376-0.08741 0.59169-0.134955 1.05423-0.142245l0.30903-0.00625z" />
        <path className={names} d="m18.0838 7.51764q0 0.835855-0.63372 1.28929-0.628255 0.453435-1.76458 0.453435-1.10354 0-1.70448-0.36056-0.595475-0.36603-0.77576-1.13632l0.86863-0.169355q0.125651 0.47529 0.518995 0.699275 0.393343 0.218525 1.09262 0.218525 0.748445 0 1.09262-0.229455 0.349638-0.22945 0.349638-0.688345 0-0.34964-0.240376-0.56816-0.240376-0.218525-0.77576-0.36057l-0.70474-0.18574q-0.84678-0.218525-1.20734-0.426125-0.355101-0.21306-0.557235-0.51353-0.202135-0.30047-0.202135-0.73752 0-0.808535 0.573625-1.2292 0.57909-0.426115 1.68263-0.426115 0.977895 0 1.55152 0.344175 0.57909 0.34417 0.732055 1.10354l-0.88502 0.10926q-0.08195-0.39334-0.44251-0.60094-0.355101-0.21306-0.95604-0.21306-0.666495 0-0.983355 0.202135-0.316859 0.20213-0.316859 0.611865 0 0.2513 0.131114 0.41519 0.131114 0.163895 0.387879 0.27862 0.256766 0.114725 1.08169 0.31686 0.78122 0.196675 1.1254 0.36603 0.344176 0.16389 0.540845 0.366025 0.202135 0.202135 0.311396 0.469825 0.109262 0.262225 0.109262 0.60094z" />
        <path className={names} d="m24.2407 6.19014q0 1.55152-0.682885 2.31088-0.682885 0.75937-1.9831 0.75937-1.29475 0-1.95578-0.786685-0.661035-0.792145-0.661035-2.28357 0-3.05934 2.6496-3.05934 1.35484 0 1.99403 0.74845 0.63918 0.742975 0.63918 2.31088zm-1.03252 0q0-1.22374-0.366027-1.77551-0.360564-0.557235-1.21827-0.557235-0.86317 0-1.25105 0.56817-0.382416 0.562695-0.382416 1.76458 0 1.1691 0.376953 1.75911 0.382416 0.584555 1.19642 0.584555 0.88502 0 1.26198-0.56816 0.382416-0.568165 0.382416-1.7755z" />
      </g>
    </svg>
  );
}



import { isNil } from 'lodash';
import Polyglot from 'node-polyglot';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ErrorDto } from '../Generated/BackendTypes';
import { IconAlertCircle } from '../icons';
import { usePolyglot } from '../Localization/Localization';
import { commaSeparated } from '../Utils';

interface ErrorContent {
  title: React.ReactNode;
  message: React.ReactNode;
}

function processError(error: ErrorDto, pg: Polyglot): ErrorContent {
  switch(error._type) {
    case 'Other':
      return {
        title: pg.t('error.generalError'),
        message: error.message
      };
    case 'CannotDeleteShiftIsDefaultShift':
      return {
        title: pg.t('error.CannotDeleteShiftIsDefaultShift.title'),
        message: pg.t('error.CannotDeleteShiftIsDefaultShift.message', { shiftName: error.shiftName})
      };
    case 'CannotDeleteShiftStillReferenced':
      return {
        title: pg.t('error.CannotDeleteShiftStillReferenced.title'),
        message: pg.t('error.CannotDeleteShiftStillReferenced.message', {
          shiftName: error.shiftName,
          staffDemands: commaSeparated(error.staffDemandIdxs.map(idx => `P${idx+1}`))
        })
      };
  }
}

interface ErrorPopupProps {
  error?: ErrorDto;
}

export function ErrorPopup({ error }: ErrorPopupProps) {
  const pg = usePolyglot();
  const [show, setShow] = useState(false);
  const [content, setContent] = useState<ErrorContent|undefined>(undefined);

  useEffect(() => {
    if(isNil(error)) {
      setShow(false);
    } else {
      setContent(processError(error, pg))
      setShow(true);
    }
  }, [pg, error]);

  function hide() {
    setShow(false);
  }

  return (
    <Modal show={show} onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title><IconAlertCircle className="text-danger" /> {content?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {content?.message}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={hide}>{pg.t('general.close')}</Button>
      </Modal.Footer>
    </Modal>
  );
}

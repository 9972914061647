import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { usePolyglot } from '../Localization/Localization';
import { spaceSeparated } from '../Utils';
import { InvoiceAddressData } from './InvoiceAddressContainer';
import { IconEdit2 } from '../icons';

export interface ShowInvoiceAddressProps {
  address: InvoiceAddressData;
  onEdit: () => void;
}

export function ShowInvoiceAddress(props: ShowInvoiceAddressProps) {
  const pg = usePolyglot();
  const d = props.address;

  let lines = [];
  if(d.mode === 'private') {
    lines.push(spaceSeparated([d.firstName, d.lastName]));
  } else if(d.mode === 'company') {
    lines.push(d.companyName);
    lines.push(d.companyAddition);
  }
  lines.push(...d.address.split('\n'));
  lines.push(spaceSeparated([d.postalCode, d.city]));
  lines.push(d.country);
  lines.push(d.emailAddress);

  lines = lines.map(l => l.trim()).filter(l => l.length !== 0);

  return (
    <Card>
      <Card.Body>
        <Card.Title>{pg.t('usage.invoiceAddress')}</Card.Title>
        <Card.Text className="my-4">
          {lines.map((line, idx) =>
            <span key={idx}>{line}<br /></span>
          )}
        </Card.Text>
        <Button onClick={props.onEdit} variant="primary">
          <IconEdit2 size={20} />&nbsp;{pg.t('general.edit')}
        </Button>
      </Card.Body>
    </Card>
  )
}

import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { memberof, Path } from '../Utils';
import { usePolyglot } from '../Localization/Localization';
import { ScheduleId, PersonGroupId, PersonId } from '../Types';
import { Formik, Form as FormikForm, FormikErrors } from 'formik';
import { FoboField } from '../Components/FoboField';
import { Badge, Dropdown } from 'react-bootstrap';
import { ConfirmationModalButton } from '../Components/ConfirmationModalButton';
import { Link, Prompt } from 'react-router-dom';
import { FormTable, FormTableAutoshow, FormTableCell, FormTableHeaderCell, FormTableHeaderRow, FormTableRow } from '../Components/FormTable';
import { fromPairs } from 'lodash';
import { ChipSelectField } from '../Components/ChipSelectField';
import { useEventListener } from '../Hooks';
import { IconAlertTriangle, IconSave, IconUndo, IconEdit, IconMoreVertical, IconRepeat, IconPlus } from '../icons';

export interface PersonGroupEntry {
  personGroupId?: PersonGroupId;
  name: string;
  personIds: PersonId[];
}

export interface PersonLookup {
  personId: PersonId;
  name: string;
}

const defaultEntry: PersonGroupEntry = {
  name: '',
  personIds: []
};

function PersonGroupTableHeader() {
  const pg = usePolyglot();
  return (
    <FormTableHeaderRow>
      <FormTableHeaderCell style={{width: '20%'}}>{pg.t('general.name')}</FormTableHeaderCell>
      <FormTableHeaderCell>{pg.t('general.persons')}</FormTableHeaderCell>
      <FormTableHeaderCell style={{width: 0}}></FormTableHeaderCell>
    </FormTableHeaderRow>
  );
}

interface PersonGroupRowEditProps {
  personGroup: PersonGroupEntry;
  persons: PersonLookup[];
  error: boolean;
  validate: (d: PersonGroupEntry) => FormikErrors<PersonGroupEntry>;
  onSave: (d: PersonGroupEntry) => Promise<void>;
  onCancel: () => void;
}

function PersonGroupRowEditor(props: PersonGroupRowEditProps) {
  const pg = usePolyglot();
  useEventListener("keydown", e => {
    const event = e as KeyboardEvent;
    if(event.code === 'Escape') {
      props.onCancel();
    }
  });
  const items = props.persons.map(p => ({ itemKey: p.personId, label: p.name }));
  return (
    <Formik
      initialValues={props.personGroup}
      validate={props.validate}
      onSubmit={props.onSave}
    >
      {({ isSubmitting, dirty, values }) => (
        <FormTableRow as={FormikForm}>
          {(props.personGroup.personGroupId === undefined || dirty) && <Prompt message={pg.t('general.unsavedChangesPrompt')} />}
          <FormTableCell editing relative>
            <FoboField name={memberof<PersonGroupEntry>('name')} feedbackAsTooltip placeholder={pg.t('general.name')} />
          </FormTableCell>
          <FormTableCell editing relative>
            <ChipSelectField
              id="PersonGroupRowEditor__personChipSelectField"
              name={memberof<PersonGroupEntry>('personIds')}
              placeholder={pg.t('general.persons')}
              items={items}
            />
          </FormTableCell>
          <FormTableCell editing relative>
            <div className="d-flex flex-row">
              <Button type="submit" variant="primary" disabled={isSubmitting}>
                {props.error ? <IconAlertTriangle /> : <IconSave />}
              </Button>
              <Button variant="outline-secondary" className="ml-3" onClick={props.onCancel} disabled={isSubmitting}>
                <IconUndo />
              </Button>
            </div>
          </FormTableCell>
        </FormTableRow>
      )}
    </Formik>
  );
}

interface PersonGroupRowProps {
  personGroup: PersonGroupEntry;
  lookup: Record<PersonId, string>;
  editingAnother?: boolean;
  highlighted: boolean;
  onEdit: () => void;
  onMouseDown: () => void;
  onDelete: () => void;
}

function PersonGroupRow(props: PersonGroupRowProps) {
  const pg = usePolyglot();
  const cellProps = {
    editingAnother: props.editingAnother,
    highlighted: props.highlighted
  };
  return (
    <FormTableRow
      onMouseDown={props.onMouseDown}
      onDoubleClick={props.onEdit}
    >
      <FormTableCell {...cellProps}>{props.personGroup.name}</FormTableCell>
      <FormTableCell {...cellProps}>
        {props.personGroup.personIds.map(personId => <Badge key={personId}>{props.lookup[personId] || personId}</Badge>)}
      </FormTableCell>
      <FormTableCell {...cellProps}>
        <FormTableAutoshow highlighted={props.highlighted} editingAnother={props.editingAnother} className="d-flex flex-row">
          <Button variant="link" onClick={props.onEdit} className="ml-3" disabled={props.editingAnother}>
            <IconEdit />
          </Button>
          {props.personGroup.personGroupId !== undefined &&
            <Dropdown>
              <Dropdown.Toggle variant="link" className="hide-dropdown-arrow" disabled={props.editingAnother}>
                <IconMoreVertical />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  as={ConfirmationModalButton}
                  variant="link"
                  className="text-danger"
                  onPositive={props.onDelete}
                  text={pg.t('team.confirmDeleteGroup', { name: props.personGroup.name })}
                >
                  {pg.t('general.remove')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          }
        </FormTableAutoshow>
      </FormTableCell>
    </FormTableRow>
  );
}

export interface PersonGroupTableProps {
  scheduleId: ScheduleId;
  persons: PersonLookup[];
  personGroups: PersonGroupEntry[];
  saving: boolean;
  error: boolean;
  validate: (d: PersonGroupEntry) => FormikErrors<PersonGroupEntry>;
  onSave: (d: PersonGroupEntry) => Promise<void>;
  onDelete: (personGroupId: PersonGroupId) => void;
}

export function PersonGroupTable(props: PersonGroupTableProps) {
  const pg = usePolyglot();
  const [editedPos, setEditedPos] = useState<number|undefined>(undefined);
  const [highlightedPos, setHighlightedPos] = useState<number|undefined>(undefined);
  const editing = editedPos !== undefined;
  const lookup = fromPairs(props.persons.map(p => [p.personId, p.name]));

  async function handleSave(d: PersonGroupEntry) {
    await props.onSave(d);
    setEditedPos(undefined);
    setHighlightedPos(undefined);
  }
  function handleCancel() {
    setEditedPos(undefined);
    setHighlightedPos(undefined);
  }
  function handleEdit(pos: number) {
    if(editedPos === undefined) {
      setEditedPos(pos);
      setHighlightedPos(undefined);
    }
  }
  function handleSelect(pos: number) {
    if(editedPos === undefined) {
      setHighlightedPos(pos);
    }
  }
  async function handleDelete(pos: number) {
    const personGroupId = props.personGroups[pos].personGroupId;
    if(personGroupId) {
      props.onDelete(personGroupId);
      setEditedPos(undefined);
      setHighlightedPos(undefined);
    }
  }

  return (
    <div>
      <Button
        variant="secondary"
        disabled={editing || props.saving}
        as={Link}
        to={Path.toPersonGroupReorder(props.scheduleId)}
      >
        <IconRepeat transform="rotate(90)" /> {pg.t('general.changeOrder')}
      </Button>
      <FormTable className="mt-3">
        <PersonGroupTableHeader />
        {props.personGroups.map((personGroup, pos) => {
          if(pos === editedPos) {
            return (
              <PersonGroupRowEditor
                key={personGroup.personGroupId || pos}
                personGroup={personGroup}
                persons={props.persons}
                error={props.error}
                validate={props.validate}
                onSave={handleSave}
                onCancel={handleCancel}
              />
            );
          } else {
            return (
              <PersonGroupRow
                key={personGroup.personGroupId || pos}
                personGroup={personGroup}
                lookup={lookup}
                onEdit={() => handleEdit(pos)}
                onMouseDown={() => handleSelect(pos)}
                onDelete={() => handleDelete(pos)}
                editingAnother={editing}
                highlighted={highlightedPos === pos}
              />
            );
          }
        })}
        {editedPos === -1 &&
          <PersonGroupRowEditor
            key={-1}
            error={props.error}
            personGroup={defaultEntry}
            persons={props.persons}
            validate={props.validate}
            onSave={handleSave}
            onCancel={handleCancel}
          />
        }
      </FormTable>
      <Button className="ml-1 mt-3" disabled={editing || props.saving} onClick={() => handleEdit(-1)}>
        <IconPlus /> {pg.t('team.newPersonGroup')}
      </Button>
    </div>
  )
}

import { flow, take } from "lodash";
import { map } from "lodash/fp";
import { commaSeparated, isNotNil, lookup } from "../Utils";
import { italic } from "../SimpleMarkup";
import { NamesLookup } from "./Types";
import { ConditionDto, ConditionDto_MaxShiftsInARowConstraintDto, ConditionDto_MaxShiftsPerWeekObjectiveDto, ConditionDto_RestrictChangeFromShiftConditionDto, ConditionDto_RestrictChangeToShiftConditionDto, PersonSelectorDto } from '../Generated/BackendTypes';
import { ShiftSelector } from "../Domain";

function joinCommaAnd(s: string[]): string {
  if(s.length === 0) {
    return '<leer>';
  } else if(s.length === 1) {
    return s[0];
  } else {
    return commaSeparated(take(s, s.length-1)) + " und " + s[s.length-1];
  }
}

function joinCommaOr(s: string[]): string {
  if(s.length === 0) {
    return '<leer>';
  } else if(s.length === 1) {
    return s[0];
  } else {
    return commaSeparated(take(s, s.length-1)) + " oder " + s[s.length-1];
  }
}

function expressCount(n: number, gender: 'male'|'female' = 'male'): string {
  switch(n) {
    case 0: return gender === 'male' ? 'kein' : 'keine';
    case 1: return gender === 'male' ? 'ein' : 'eine';
    case 2: return 'zwei';
    case 3: return 'drei';
    case 4: return 'vier';
    case 5: return 'fünf';
    case 6: return 'sechs';
    case 7: return 'sieben';
    case 8: return 'acht';
    case 9: return 'neun';
    case 10: return 'zehn';
    default: return n.toString();
  }
}

function expressPersonSelector(p: PersonSelectorDto, { persons, personGroups }: NamesLookup) {
  const r = [];
  r.push('Betrifft')
  if(p._type === 'PersonSelector1') {
    let hasGroups = p.groupIds.length > 0;
    let hasPersons = p.personIds.length > 0;

    let personGroupNames: string = flow(
      map(lookup(personGroups)),
      map(italic),
      joinCommaAnd,
    )(p.groupIds);

    let personNames: string = flow(
      map(lookup(persons)),
      map(italic),
      joinCommaAnd,
    )(p.personIds);

    if(hasGroups) {
      if(p.groupIds.length === 1) {
        r.push('die Gruppe');
      } else {
        r.push('die Gruppen');
      }
      r.push(personGroupNames);
    }

    if(hasGroups && hasPersons) {
      r.push('sowie');
    }

    if(hasPersons) {
      if(p.personIds.length === 1) {
        r.push('die Person');
      } else {
        r.push('die Personen');
      }
      r.push(personNames);
    }

    if(!hasGroups && !hasPersons) {
      r.push('alle');
    }
  }
  r[r.length-1] += '.';
  return r;
}

function explainMaxShiftsInARowConstraint(c: ConditionDto_MaxShiftsInARowConstraintDto, nl: NamesLookup) {
  const r = [];
  if(c.shiftSelector._type === 'ShiftSelector1') {
    if(ShiftSelector.matchAll(c.shiftSelector)) {
      r.push('Egal welche Schicht darf');
    } else {
      const hasShiftIds = c.shiftSelector.shiftIds.length > 0;
      const isSingular = c.shiftSelector.shiftIds.length === 1;
      if(hasShiftIds) {
        let shiftNames: string = flow(
          map(lookup(nl.shifts)),
          map(italic),
          joinCommaOr,
        )(c.shiftSelector.shiftIds);
        if(isSingular) {
          r.push(`Die Schicht ${shiftNames}`);
        } else {
          r.push(`Die Schichten ${shiftNames}`);
        }
      }

      if(c.shiftSelector.shiftKind === 'Rest') {
        if(hasShiftIds) {
          r.push('oder');
        }
        r.push('Ruheschichten');
      } else if(c.shiftSelector.shiftKind === 'Work') {
        if(hasShiftIds) {
          r.push('oder');
        }
        r.push('Arbeitsschichten');
      }

      if(c.shiftSelector.shiftKind || !isSingular) {
        r.push('dürfen zusammengezählt')
      } else {
        r.push('darf')
      }
    }
  }

  r.push(`maximal ${expressCount(c.maxCount)} Mal in Folge vorkommen.`);

  r.push(...expressPersonSelector(c.personSelector, nl));
  return r.join(' ');
}

function explainMaxShiftsPerWeekObjective0(c: ConditionDto_MaxShiftsPerWeekObjectiveDto, nl: NamesLookup) {
  let r = [];
  r.push(`Pro Woche `);

  if(c.maxCount === 1) {
    r.push(`soll höchstens ${expressCount(c.maxCount, 'male')} Einsatz`)
  } else if(c.maxCount > 1) {
    r.push(`sollen höchstens ${expressCount(c.maxCount, 'male')} Einsätze`)
  } else {
    r.push(`sollen keine Einsätze `);
  }

  if(c.shiftSelector._type === 'ShiftSelector1') {
    if(ShiftSelector.matchAll(c.shiftSelector)) {
      r.push('irgendeiner Schicht');
    } else {
      const hasShiftIds = c.shiftSelector.shiftIds.length > 0;
      const isSingular = c.shiftSelector.shiftIds.length === 1;
      if(hasShiftIds) {
        let shiftNames: string = flow(
          map(lookup(nl.shifts)),
          map(italic),
          joinCommaOr,
        )(c.shiftSelector.shiftIds);
        if(isSingular) {
          r.push(`der Schicht ${shiftNames}`);
        } else {
          r.push(`der Schichten ${shiftNames}`);
        }
      }

      if(c.shiftSelector.shiftKind === 'Rest') {
        if(hasShiftIds) {
          r.push('oder');
        }
        r.push('von Ruheschichten');
      } else if(c.shiftSelector.shiftKind === 'Work') {
        if(hasShiftIds) {
          r.push('oder');
        }
        r.push('von Arbeitsschichten');
      }
    }
  }
  r.push(` eingeplant werden.`);
  r.push(...expressPersonSelector(c.personSelector, nl));
  return r.join(' ');
}

function explainRestrictChangeFromShiftCondition0(c: ConditionDto_RestrictChangeFromShiftConditionDto, nl: NamesLookup) {
  const r = [];
  r.push('Beim Wechsel von')

  {
    const hasShiftKind = isNotNil(c.fromShiftSelector.shiftKind);
    const hasShiftIds = c.fromShiftSelector.shiftIds.length > 0;
    if(hasShiftKind) {
      if(c.fromShiftSelector.shiftKind === 'Rest') {
        r.push('Ruheschichten');
      }
      if(c.fromShiftSelector.shiftKind === 'Work') {
        r.push('Arbeitsschichten');
      }
    }

    if(hasShiftKind && hasShiftIds) {
      r.push('oder')
    }

    if(hasShiftIds) {
      r.push('Schicht');

      let shiftNames: string = flow(
        map(lookup(nl.shifts)),
        map(italic),
        joinCommaOr,
      )(c.fromShiftSelector.shiftIds);
      r.push(shiftNames);
    }
  }

  if(c.restriction === 'Confine') {
    if(c.length === 1) {
      r.push('muss mindestens');
    } else {
      r.push('müssen mindestens')
    }
  }
  if(c.restriction === 'Prevent') {
    if(c.length === 1) {
      r.push('darf nicht');
    } else {
      r.push('dürfen nicht');
    }
  }

  r.push(italic(expressCount(c.length, 'female')));

  {
    const hasShiftKind = isNotNil(c.toShiftSelector.shiftKind);
    const hasShiftIds = c.toShiftSelector.shiftIds.length > 0;
    if(hasShiftKind) {
      if(c.toShiftSelector.shiftKind === 'Rest') {
        if(c.length === 1) {
          r.push('Ruheschicht');
        } else {
          r.push('Ruheschichten');
        }
      }
      if(c.toShiftSelector.shiftKind === 'Work') {
        if(c.length === 1) {
          r.push('Arbeitsschicht');
        } else {
          r.push('Arbeitsschichten');
        }
      }
    }

    if(hasShiftKind && hasShiftIds) {
      r.push('oder')
    }

    if(hasShiftIds) {
      if(!hasShiftKind) {
        if(c.length === 1) {
          r.push('Schicht');
        } else if(c.length > 1) {
          r.push('Schichten');
        }
      }
      let shiftNames: string = flow(
        map(lookup(nl.shifts)),
        map(italic),
        joinCommaOr,
      )(c.toShiftSelector.shiftIds);
      r.push(shiftNames);
    }
  }

  r.push('folgen.');
  r.push(...expressPersonSelector(c.personSelector, nl));
  return r.join(' ').replace(' , ', ', ');
}

function explainRestrictChangeToShiftCondition0(c: ConditionDto_RestrictChangeToShiftConditionDto, nl: NamesLookup) {
  const r = [];
  r.push('Beim Wechsel auf')

  {
    const hasShiftKind = isNotNil(c.toShiftSelector.shiftKind);
    const hasShiftIds = c.toShiftSelector.shiftIds.length > 0;
    if(hasShiftKind) {
      if(c.toShiftSelector.shiftKind === 'Rest') {
        r.push('Ruheschichten');
      }
      if(c.toShiftSelector.shiftKind === 'Work') {
        r.push('Arbeitsschichten');
      }
    }

    if(hasShiftKind && hasShiftIds) {
      r.push('oder')
    }

    if(hasShiftIds) {
      r.push('Schicht');

      let shiftNames: string = flow(
        map(lookup(nl.shifts)),
        map(italic),
        joinCommaOr,
      )(c.toShiftSelector.shiftIds);
      r.push(shiftNames);
    }
  }

  if(c.restriction === 'Confine') {
    if(c.length === 1) {
      r.push('muss mindestens');
    } else {
      r.push('müssen mindestens')
    }
  }
  if(c.restriction === 'Prevent') {
    if(c.length === 1) {
      r.push('darf nicht');
    } else {
      r.push('dürfen nicht');
    }
  }

  r.push(italic(expressCount(c.length, 'female')));

  {
    const hasShiftKind = isNotNil(c.fromShiftSelector.shiftKind);
    const hasShiftIds = c.fromShiftSelector.shiftIds.length > 0;
    if(hasShiftKind) {
      if(c.fromShiftSelector.shiftKind === 'Rest') {
        if(c.length === 1) {
          r.push('Ruheschicht');
        } else {
          r.push('Ruheschichten');
        }
      }
      if(c.fromShiftSelector.shiftKind === 'Work') {
        if(c.length === 1) {
          r.push('Arbeitsschicht');
        } else {
          r.push('Arbeitsschichten');
        }
      }
    }

    if(hasShiftKind && hasShiftIds) {
      r.push('oder')
    }

    if(hasShiftIds) {
      if(c.length === 1) {
        r.push('Schicht');
      } else if(c.length > 1) {
        r.push('Schichten');
      }
      let shiftNames: string = flow(
        map(lookup(nl.shifts)),
        map(italic),
        joinCommaOr,
      )(c.fromShiftSelector.shiftIds);
      r.push(shiftNames);
    }
  }

  r.push('vorausgehen.');

  return r.join(' ').replace(' , ', ', ');
}

export function explainDE(c: ConditionDto, n: NamesLookup): string {
  switch(c._type) {
    case "MaxShiftsInARowConstraintDto":
      return explainMaxShiftsInARowConstraint(c, n);
    case "MinCountWeekendsHavingShiftObjectiveDto":
      return "";
    case "EvenlyDistributeOverWeeksObjectiveDto":
      return "Verteile Arbeitslast gleichmässig über die Wochen";
    case "TargetMinutesObjectiveDto":
      return "Erziele gleichmässige Auslastung";
    case "WeekendEitherFreeOrWorkObjectiveDto":
      return "";
    case "AvoidSingleWorkdaysObjectiveDto":
      return "Vermeide einzelne Arbeitstage";
    case "AvoidSingleFreedaysObjectiveDto":
      return "Vermeide einzelne Ruhetage";
    case "PreferMaxFiveWorkdaysInARowObjectiveDto":
      return "Strebe maximal fünf aufeinanderfolgende Arbeitstage an.";
    case "DistributeWeekendLoadObjectiveDto":
      return "Verteile Wochenendarbeit gleichmässig";
    case "MaxShiftsPerWeekObjectiveDto":
      return explainMaxShiftsPerWeekObjective0(c, n);
    case "ShiftSelectionConditionDto":
      return "tbd"
    case "RestrictChangeFromShiftConditionDto":
      return explainRestrictChangeFromShiftCondition0(c, n);
    case "RestrictChangeToShiftConditionDto":
      return explainRestrictChangeToShiftCondition0(c, n);
  }
}

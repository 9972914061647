import React, { useMemo } from 'react';
import spacetime from 'spacetime';
import { tz_utc, Path, memberof } from '../Utils';
import { Formik, FormikErrors, Form as FormikForm, Field, FieldProps, ErrorMessage, isString } from 'formik';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
// @ts-ignore
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { DropdownSingleSelectField } from '../Components/DropdownSingleSelectField';
import { DropdownSingleSelectItem } from '../Components/DropdownSingleSelect';
import { ContainerLeft } from '../Components/UtilityComponents';
import { ScheduleListItemDto } from '../Generated/BackendTypes';
import { CreateButton } from '../Components/CreateButton';
import { ListGroup } from 'react-bootstrap';
import { usePolyglot } from '../Localization/Localization';
import { IconArrowLeft } from '../icons';

export enum DateMode {
  Month = 'Month',
  DateRange = 'DateRange'
}

export interface FormValues {
  dateMode: DateMode;
  dateRange?: [Date, Date];
  year: string;
  month: string;
}

const monthItems: DropdownSingleSelectItem<string>[] = [
  { itemKey: "0", label: 'Januar' },
  { itemKey: "1", label: 'Februar' },
  { itemKey: "2", label: 'März' },
  { itemKey: "3", label: 'April' },
  { itemKey: "4", label: 'Mai' },
  { itemKey: "5", label: 'Juni' },
  { itemKey: "6", label: 'Juli' },
  { itemKey: "7", label: 'August' },
  { itemKey: "8", label: 'September' },
  { itemKey: "9", label: 'Oktober' },
  { itemKey: "10", label: 'November' },
  { itemKey: "11", label: 'Dezember' },
];

const currentYear = spacetime.now(tz_utc).year();
const yearItems: DropdownSingleSelectItem<string>[] = [0, 1, 2, 3, 4].map(ofs =>
  ({ itemKey: `${currentYear+ofs}`, label: `${currentYear+ofs}` })
);

export interface CreateScheduleProps {
  onSubmit: (values: FormValues) => void | Promise<void>;
  validate: (values: FormValues) => void | FormikErrors<FormValues> | Promise<FormikErrors<FormValues>>;
  error?: string;
  overlaps: ScheduleListItemDto[];
}

export function CreateSchedule(props: CreateScheduleProps) {
  const pg = usePolyglot();
  const initial: FormValues = useMemo(() => {
    const startDate = spacetime.now(tz_utc).startOf('month').add(1, 'month');
    const endDate = startDate.endOf('month');
    return {
      dateMode: DateMode.Month,
      year: startDate.year().toString(),
      month: startDate.month().toString(),
      dateRange: [
        // cannot use toLocaleDate() because it will convert into local time zone
        // cannot use spacetime.now() without tz_utc because some systems (e.g. firefox on my android 9)
        // have a time zone that spacetime doesn't understand.
        new Date(startDate.year(), startDate.month(), startDate.date()),
        new Date(endDate.year(), endDate.month(), endDate.date())
      ],
    };
  }, []);

  return (
    <ContainerLeft>
      <h1 className="mb-3 mt-3">Neuer Dienstplan erstellen</h1>
      <Button variant="secondary" className="mb-3" as={Link} to={Path.toScheduleChooser}>
        <IconArrowLeft />Zurück
      </Button>
      <Formik
        initialValues={initial}
        validate={props.validate}
        onSubmit={props.onSubmit}
      >
        {({ isSubmitting, values }) => (
        <Form as={FormikForm}>
          {props.error &&
            <Alert variant='danger'>
              {props.error}
              {props.overlaps.length > 0 &&
                <ListGroup className="mt-3">
                  {props.overlaps.map(overlap =>
                    <ListGroup.Item key={overlap.scheduleId} variant="danger" action as={Link} to={Path.toUnspecificStaffDemand(overlap.scheduleId)}>
                      {pg.t('general.schedule')} {overlap.startDate} {overlap.endDate} {overlap.comment}
                    </ListGroup.Item>
                  )}
                </ListGroup>
              }
            </Alert>}
          <Form.Group>
            <ButtonGroup toggle>
              <Field name={memberof<FormValues>('dateMode')}>
                {({ field }: FieldProps<DateMode>) =>
                  <ToggleButton
                    type="radio"
                    name={field.name}
                    variant="primary"
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    checked={field.value === DateMode.Month}
                    value={DateMode.Month}
                  >
                    Monat
                  </ToggleButton>
                }
              </Field>
              <Field name={memberof<FormValues>('dateMode')}>
                {({ field }: FieldProps<DateMode>) =>
                  <ToggleButton
                  type="radio"
                  name={field.name}
                  variant="primary"
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  checked={field.value === DateMode.DateRange}
                  value={DateMode.DateRange}
                  >
                    Datumsbereich
                  </ToggleButton>
                }
              </Field>
            </ButtonGroup>
          </Form.Group>
          {values.dateMode === DateMode.DateRange &&
            <Form.Group controlId="formDateRange">
              <Form.Label>Anfangs- und Enddatum des Dienstplans wählen</Form.Label>
              <div>
                <Field name={memberof<FormValues>('dateRange')}>
                {({
                    field,
                    form,
                    meta,
                }: FieldProps<any, FormValues>) => (
                  <>
                    <DateRangePicker
                      name={field.name}
                      value={field.value}
                      onChange={(dateRange: [Date, Date]) => form.setFieldValue(field.name, dateRange)}
                      onBlur={() => form.setFieldTouched(field.name, true)}
                    />
                    <ErrorMessage name={field.name}>
                      {message => <div className="text-danger">{message}</div>}
                    </ErrorMessage>
                  </>
                )}
                </Field>
              </div>
            </Form.Group>
          }
          {values.dateMode === DateMode.Month &&
            <Form.Group>
              <Form.Label>Jahr und Monat des Dienstplans wählen</Form.Label>
              <div className="d-flex flex-row">
                <DropdownSingleSelectField
                  id="formYearSelect"
                  items={yearItems}
                  name={memberof<FormValues>('year')}
                  className="pr-1"
                />
                <DropdownSingleSelectField
                  id="formMonthSelect"
                  items={monthItems}
                  name={memberof<FormValues>('month')}
                />
              </div>
            </Form.Group>
          }
          <CreateButton type="submit" creating={isSubmitting} error={isString(props.error) || props.overlaps.length > 0}/>
        </Form>
        )}
      </Formik>
    </ContainerLeft>
  );
}

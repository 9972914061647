import classNames from 'classnames';
import React from 'react';

export function FormTable({ className, children, ...props }: React.HTMLAttributes<HTMLElement>) {
  return (
    <div className={classNames("FormTable", className)} {...props}>
      {children}
    </div>
  );
}

interface FormTableRowProps extends React.HTMLAttributes<HTMLElement> {
  as?: React.ElementType;
}

export function FormTableRow({ as, className, children, ...props }: FormTableRowProps) {
  const ComponentType = as || "div";
  return (
    <ComponentType className={classNames("FormTable__Row", className)} {...props}>
      {children}
    </ComponentType>
  );
}

interface FormTableHeaderRowProps extends React.HTMLAttributes<HTMLElement> {
  as?: React.ElementType;
}

export function FormTableHeaderRow({ as, className, children, ...props }: FormTableHeaderRowProps) {
  const ComponentType = as || "div";
  return (
    <ComponentType className={classNames("FormTable__HeaderRow", className)} {...props}>
      {children}
    </ComponentType>
  );
}


interface FormTableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing?: boolean;
  editingAnother?: boolean;
  highlighted?: boolean;
  relative?: boolean;
  center?: boolean;
}

export function FormTableCell({ editing, editingAnother, highlighted, relative, className, children,
  center, ...props }: FormTableCellProps) {
  const c = classNames(
    'FormTable__Cell',
    {
      'FormTable__Cell--Editing': editing,
      'FormTable__Cell--EditingAnother': editingAnother,
      'FormTable__Cell--Highlighted': highlighted,
      'text-center': center,
    },
    className
  )
  return (
    <div className={c} {...props}>
      <div className={classNames({'position-relative': relative})}>
        {children}
      </div>
    </div>
  );
}

interface FormTableHeaderCellProps extends React.HTMLAttributes<HTMLElement> {
  center?: boolean;
}

export function FormTableHeaderCell({ className, children, center, ...props }: FormTableHeaderCellProps) {
  const c = classNames(
    "FormTable__HeaderCell",
    className,
    {
      'text-center': center
    }
  )
  return (
    <div className={c} {...props}>
      {children}
    </div>
  )
}

/// Show children on row hover or highlighted but not when editing another row
export function FormTableAutoshow({ className, children, ...props }: FormTableCellProps) {
  const c = classNames(
    'FormTable__Autoshow',
    {
      'FormTable__Autoshow--Highlighted': props.highlighted,
      'FormTable__Autoshow--EditingAnother': props.editingAnother,
    },
    className
  );
  return (
    <div className={c}>
      {children}
    </div>
  )
}

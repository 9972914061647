import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import Button, { ButtonProps } from 'react-bootstrap/Button';
import { useAuthFetch } from '../AuthService';

export interface AuthFetchButtonProps {
  input: RequestInfo;
  init?: RequestInit;
  disabled?: boolean;
  filename: string;
  children: any;
  className?: string;
}

export function AuthFetchButton(props: AuthFetchButtonProps & ButtonProps) {
  const { input, init, disabled, filename, children } = props;

  const authFetch = useAuthFetch();
  const [loading, setLoading] = useState<boolean>(false);

  function handleClick() {
    setLoading(true);

    authFetch(input, init)
    .then(response => {
      if(!response.ok) {
        throw new Error(`${response.status}: '${response.statusText}`);
      }
      return response.blob();
    })
    .then(
      blob => {
        setLoading(false);
        saveAs(blob, filename);
      },
      error => { setLoading(false); alert("Datei konnte nicht abgerufen werden: " + error.message); }
    )
  }

  return (
    <Button {...props} disabled={disabled || loading} onClick={handleClick}>
      {children}
    </Button>
  );
}

import React, { useState } from 'react';
import { Button, Alert } from 'react-bootstrap';
import { TenantInviteListItemDto, TenantMemberListItemDto } from '../Generated/BackendTypes';
import { UserId } from '../Types';
import { usePolyglot } from '../Localization/Localization';
import { CreateInviteModal } from './CreateInviteModal';
import { MemberList } from './MemberList';
import { InviteList } from './InviteList';
import { useUserProfile } from '../UserProfileContext';
import { LoadingSpinner } from '../Components/UtilityComponents';

export interface MemberManagementProps {
  members: TenantMemberListItemDto[];
  invites: TenantInviteListItemDto[];
  error?: string;
  loading: boolean;
  onRemoveMember: (userId: UserId) => void;
  onUpdateMember: (userId: UserId, adminStatus: boolean) => void;
  onCreateInvite: (name: string, emailAddress: string) => Promise<void>;
  onDeleteInvite: (emailAddress: string) => void;
  inviteAlreadyExist: (emailAddress: string) => boolean;
  memberAlreadyAdded: (emailAddress: string) => boolean;
}

export function MemberManagement(props: MemberManagementProps) {
  const pg = usePolyglot();
  const userProfile = useUserProfile();
  const [showCreateInvite, setShowCreateInvite] = useState(false);

  async function handleCreateInvite(name: string, emailAddress: string) {
    await props.onCreateInvite(name, emailAddress);
    setShowCreateInvite(false);
  }

  return (
    <>
      <h1 className="mt-5">{pg.t('account.users')}</h1>
      {userProfile.isAdmin &&
        <Button onClick={() => setShowCreateInvite(true)} className="my-3">{pg.t('general.add')}</Button>
      }
      <CreateInviteModal
        show={showCreateInvite}
        onCancel={() => setShowCreateInvite(false)}
        onSubmit={handleCreateInvite}
        inviteAlreadyExist={props.inviteAlreadyExist}
        memberAlreadyAdded={props.memberAlreadyAdded}
      />

      {props.error &&
        <Alert variant='danger'>
          {pg.t('general.errorMessageIntro')}: {props.error}
        </Alert>
      }
      {props.loading &&
        <LoadingSpinner />
      }
      <MemberList
        loading={props.loading}
        members={props.members}
        onRemoveMember={props.onRemoveMember}
        onUpdateMember={props.onUpdateMember}
      />
      {props.invites.length > 0 &&
        <>
          <h5>{pg.t('account.inviteHeading')}</h5>
          <InviteList
            loading={props.loading}
            invites={props.invites}
            onDeleteInvite={props.onDeleteInvite}
          />
        </>
      }
    </>
  );
}

import React from 'react';
import Form from 'react-bootstrap/Form';
import { Formik, Form as FormikForm } from 'formik';
import { memberof } from '../../Utils';
import { FoboField } from '../../Components/FoboField';
import { renderMarkup } from '../../SimpleMarkup';
import { explainDE } from '../ExplainDE';
import { ConditionEditorCardSubform } from '../ConditionEditorCard';
import { ConditionEditorProps } from '../ConditionEditorContainer';
import { ConditionDto_MinCountWeekendsHavingShiftObjectiveDto } from '../../Generated/BackendTypes';
import { fromPersonSelectorFormData, PersonSelectorFormData, PersonSelectorSubform, toPersonSelectorFormData } from '../PersonSelector';
import { fromShiftSelectorFormData, ShiftSelectorFormData, ShiftSelectorSubform, toShiftSelectorFormData } from '../ShiftSelector';

type C = ConditionDto_MinCountWeekendsHavingShiftObjectiveDto;

interface FormValues {
  enabled: boolean;
  personSelector: PersonSelectorFormData;
  shiftSelector: ShiftSelectorFormData;
  minCount: number;
  penalty: number;
}

function toFormValues(condition: C): FormValues {
  return {
    enabled: condition.enabled,
    personSelector: toPersonSelectorFormData(condition.personSelector),
    shiftSelector: toShiftSelectorFormData(condition.shiftSelector),
    minCount: condition.minCount,
    penalty: condition.penalty,
  };
}

function fromFormValues(props: ConditionEditorProps<C>, values: FormValues): C {
  return {
    _type: props.condition._type,
    enabled: values.enabled,
    personSelector: fromPersonSelectorFormData(props.identifiers.personIds, props.identifiers.personGroupIds, values.personSelector),
    shiftSelector: fromShiftSelectorFormData(props.identifiers.shiftIds, values.shiftSelector),
    minCount: values.minCount,
    penalty: values.penalty,
  };
}

export function MinCountWeekendsHavingShiftObjectiveEditor(props: ConditionEditorProps<C>) {
  return (
    <Formik
      initialValues={toFormValues(props.condition)}
      onSubmit={values => props.onSave && props.onSave(fromFormValues(props, values))}
    >
      {({ values }) => (
        <Form as={FormikForm}>
          <ConditionEditorCardSubform
            {...props}
            name={memberof<FormValues>('enabled')}
            title={renderMarkup(explainDE(fromFormValues(props, values), props.lookup))}
          >
            <PersonSelectorSubform
              name={memberof<FormValues>('personSelector')}
              persons={props.dropdown.persons}
              personGroups={props.dropdown.personGroups}
            />

            <ShiftSelectorSubform
              name={memberof<FormValues>('shiftSelector')}
              shifts={props.dropdown.shifts}
            />

            <Form.Group controlId="formStandardDurationPerDay">
              <Form.Label>Anzahl Tage</Form.Label>
              <FoboField
                name={memberof<FormValues>('minCount')}
                type="number"
                required
                min="1"
                max="30"
              />
            </Form.Group>

            <Form.Group controlId="formPenalty">
              <Form.Label>Penalty</Form.Label>
              <FoboField
                name={memberof<FormValues>('penalty')}
                type="number"
                required
                min="1"
              />
            </Form.Group>
          </ConditionEditorCardSubform>
        </Form>
      )}
    </Formik>
  );
}

import { keys } from 'lodash';
import { isNumber, values } from 'lodash/fp';
import React, { useState } from 'react';
import spacetime from 'spacetime';
import { useAuthFetch } from '../AuthService';
import { staffDemandCreate, staffDemandDelete, staffDemandRead, staffDemandReadTemplate, staffDemandUpdate } from '../BackendService';
import { fromDateSelectorFormData, toDateSelectorFormData } from '../Condition/DateSelector';
import { FetchedResource } from '../FetchedResource';
import { StaffDemandEditDto, StaffDemandRangeDto, StaffDemandReadDto } from '../Generated/BackendTypes';
import { ScheduleId } from '../Types';
import { tz_utc } from '../Utils';
import { fromAffectedPersonsSelectorFormData, toAffectedPersonsSelectorFormData } from './AffectedPersonsSelector';
import { parseMinMaxCount, stringifyMinMaxCount } from '../Utils/range';
import { defaultStaffDemandEditorValues, StaffDemandEditor, StaffDemandEditorValues } from './StaffDemandEditor';

export interface StaffDemandEditorContainerProps {
  scheduleId: ScheduleId;
  staffDemandIdx?: number;
  onComplete: () => void;
}

function toEditorValues(d: StaffDemandReadDto): StaffDemandEditorValues {
  function stringifyRange(d: StaffDemandRangeDto|undefined) {
    if(d) {
      return stringifyMinMaxCount(d.minCount, d.maxCount);
    } else {
      return '1';
    }
  }

  return {
    ...defaultStaffDemandEditorValues,
    enabled: d.enabled,
    shiftId: d.shiftId,
    affectedPersonsSelector: toAffectedPersonsSelectorFormData(d.personIds, d.personGroupIds),
    startDateSelector: toDateSelectorFormData(d.startDateSelector),
    endDateSelector: toDateSelectorFormData(d.endDateSelector),
    patternMode: d.pattern.mode,
    daily: stringifyRange(d.pattern.staffDemandRanges[0]),
    mon: stringifyRange(d.pattern.staffDemandRanges[0]),
    tue: stringifyRange(d.pattern.staffDemandRanges[1]),
    wed: stringifyRange(d.pattern.staffDemandRanges[2]),
    thu: stringifyRange(d.pattern.staffDemandRanges[3]),
    fri: stringifyRange(d.pattern.staffDemandRanges[4]),
    sat: stringifyRange(d.pattern.staffDemandRanges[5]),
    sun: stringifyRange(d.pattern.staffDemandRanges[6]),
  };
}

function fromEditorValues(v: StaffDemandEditorValues, r: StaffDemandReadDto): StaffDemandEditDto {
  function parseOrFail(v: string) {
    const parsed = parseMinMaxCount(v);
    if(parsed) {
      return parsed;
    } else {
      throw Error("Couldn't parse MinMaxCount. Form validation didn't catch it?");
    }
  }
  let ranges: StaffDemandRangeDto[] = [];
  if(v.patternMode === 'Daily') {
    ranges = [
      parseOrFail(v.daily)
    ];
  } else if(v.patternMode === 'WeekDays') {
    ranges = [
      parseOrFail(v.mon),
      parseOrFail(v.tue),
      parseOrFail(v.wed),
      parseOrFail(v.thu),
      parseOrFail(v.fri),
      parseOrFail(v.sat),
      parseOrFail(v.sun),
    ];
  }
  return {
    scheduleId: r.scheduleId,
    documentRevision: r.documentRevision,
    staffDemandIdx: r.staffDemandIdx,
    enabled: v.enabled,
    shiftId: v.shiftId,
    ...fromAffectedPersonsSelectorFormData(keys(r.persons), keys(r.personGroups), v.affectedPersonsSelector),
    startDateSelector: fromDateSelectorFormData(v.startDateSelector),
    endDateSelector: fromDateSelectorFormData(v.endDateSelector),
    pattern: {
      mode: v.patternMode,
      staffDemandRanges: ranges
    }
  }
}

export function StaffDemandEditorContainer({ scheduleId, staffDemandIdx, onComplete }: StaffDemandEditorContainerProps) {
  const authFetch = useAuthFetch();
  const [error, setError] = useState<string|undefined>();
  return (
    <FetchedResource
      fetch={() =>
        isNumber(staffDemandIdx)
        ? staffDemandRead(authFetch, scheduleId, staffDemandIdx)
        : staffDemandReadTemplate(authFetch, scheduleId)
      }
    >
      {(staffDemandReadDto: StaffDemandReadDto) => {
        const shifts = values(staffDemandReadDto.shifts).map(s => ({ itemKey: s.shiftId, label: s.name}));
        const personGroups = values(staffDemandReadDto.personGroups).map(r => ({ itemKey: r.personGroupId, label: r.name}));
        const persons = values(staffDemandReadDto.persons).map(p => ({ itemKey: p.personId, label: p.name}));

        function handleSave(values: StaffDemandEditorValues) {
          const payload = fromEditorValues(values, staffDemandReadDto);
          let promise;
          if(isNumber(staffDemandIdx)) {
            promise = staffDemandUpdate(authFetch, scheduleId, staffDemandIdx, payload);
          } else {
            promise = staffDemandCreate(authFetch, scheduleId, payload);
          }
          return promise.then(
            () => onComplete(),
            error => setError(error.message || error)
          )
        }

        function handleDelete() {
          if(isNumber(staffDemandIdx)) {
            staffDemandDelete(authFetch, scheduleId, staffDemandIdx).then(
              () => onComplete(),
              error => setError(error.message || error)
            )
          }
        }

        return (
          <StaffDemandEditor
            startDate={spacetime(staffDemandReadDto.scheduleStartDate, tz_utc)}
            endDate={spacetime(staffDemandReadDto.scheduleEndDate, tz_utc)}
            shifts={shifts}
            personGroups={personGroups}
            persons={persons}
            initialValues={toEditorValues(staffDemandReadDto)}
            error={error}
            onSave={handleSave}
            onDelete={isNumber(staffDemandIdx) ? handleDelete : undefined}
            onCancel={onComplete}
          />
        );
      }}
    </FetchedResource>
  )
}

import React, { useEffect, useState } from 'react';
import { useAuthFetch } from '../AuthService';
import { teamCreate, teamDelete, teamFetchList, teamUpdate } from '../BackendService';
import { TeamDto } from '../Generated/BackendTypes';
import { TeamId } from '../Types';
import { TeamManagement } from './TeamManagement';

export interface TeamManagementContainerProps {
}

export function TeamManagementContainer(props: TeamManagementContainerProps) {
  const authFetch = useAuthFetch();
  const [teams, setTeams] = useState<TeamDto[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string|undefined>(undefined);

  useEffect(() => {
    setLoading(true);
    teamFetchList(authFetch)
    .then(d => setTeams(d.teams))
    .catch(e => setError(e.message))
    .finally(() => setLoading(false));
  }, [authFetch]);

  async function handleCreate(name: string) {
    setLoading(true);
    try {
      const d = await teamCreate(authFetch, { name });
      setTeams(d.teams);
    } catch(e) {
      setError(e.message);
    }
    setLoading(false);
  }

  async function handleUpdate(teamId: TeamId, name: string) {
    setLoading(true);
    try {
      const d = await teamUpdate(authFetch, teamId, { name });
      setTeams(d.teams);
    } catch(e) {
      setError(e.message);
    }
    setLoading(false);
  }

  async function handleDelete(teamId: TeamId) {
    setLoading(true);
    try {
      const d = await teamDelete(authFetch, teamId);
      setTeams(d.teams);
    } catch(e) {
      setError(e.message);
    }
    setLoading(false);
  }

  return (
    <TeamManagement
      teams={teams}
      error={error}
      loading={loading}
      onCreateTeam={handleCreate}
      onUpdateTeam={handleUpdate}
      onDeleteTeam={handleDelete}
    />
  )
}

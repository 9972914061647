import React from 'react';
import Form from 'react-bootstrap/Form';
import { Formik, Form as FormikForm } from 'formik';
import { memberof } from '../../Utils';
import { FoboField } from '../../Components/FoboField';
import { renderMarkup } from '../../SimpleMarkup';
import { explainDE } from '../ExplainDE';
import { ConditionEditorCardSubform } from '../ConditionEditorCard';
import { ConditionEditorProps } from '../ConditionEditorContainer';
import { ConditionDto_AvoidSingleFreedaysObjectiveDto } from '../../Generated/BackendTypes';

type C = ConditionDto_AvoidSingleFreedaysObjectiveDto;

interface FormValues {
  enabled: boolean;
  penaltyPerSingleDay: number;
}

function toFormValues(condition: C): FormValues {
  return {
    enabled: condition.enabled,
    penaltyPerSingleDay: condition.penaltyPerSingleDay,
  };
}

function fromFormValues(props: ConditionEditorProps<C>, values: FormValues): C {
  return {
    _type: props.condition._type,
    enabled: values.enabled,
    penaltyPerSingleDay: values.penaltyPerSingleDay,
  };
}

export function AvoidSingleFreedaysObjectiveEditor(props: ConditionEditorProps<C>) {
  return (
    <Formik
      initialValues={toFormValues(props.condition)}
      onSubmit={values => props.onSave && props.onSave(fromFormValues(props, values))}
    >
      {({ values }) => (
        <Form as={FormikForm}>
          <ConditionEditorCardSubform
            {...props}
            name={memberof<FormValues>('enabled')}
            title={renderMarkup(explainDE(fromFormValues(props, values), props.lookup))}
          >
            <Form.Group controlId="formPenaltyPerSingleDay">
              <Form.Label>Penalty pro Einzeltag</Form.Label>
              <FoboField
                name={memberof<FormValues>('penaltyPerSingleDay')}
                type="number"
                required
                min="1"
              />
            </Form.Group>
          </ConditionEditorCardSubform>
        </Form>
      )}
    </Formik>
  );
}

import React, { useState, useRef, ChangeEvent } from 'react';
import { AuthFetchButton } from '../Components/AuthFetchButton';
import { linkToScheduleExcel, importSchedule } from '../BackendService';
import { Link, Redirect } from 'react-router-dom';
import { Path } from '../Utils';
import Button from 'react-bootstrap/Button';
import { useAuthFetch } from '../AuthService';
import Alert from 'react-bootstrap/Alert';
import { ScheduleImportResponseDto } from '../Generated/BackendTypes';

export interface ImportScheduleProps {
  scheduleId: string;
}

export function ImportSchedule({ scheduleId }: ImportScheduleProps) {
  const authFetch = useAuthFetch();
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);

  const [importDryRunResult, setImportDryRunResult] = useState<ScheduleImportResponseDto|undefined>(undefined);
  const [error, setError] = useState<string|undefined>(undefined);

  const fileInputRef = useRef<HTMLInputElement>(null);
  function handleChooseFile() {
    if(fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  function performImport(blob: Blob, dryRun: boolean) {
    importSchedule(authFetch, scheduleId, blob, dryRun).then(
      result => {
        setLoading(false);
        setImportDryRunResult(result);

        if(dryRun === false) {
          setComplete(true);
        }
      },
      error => {
        setLoading(false);
        setError(error.message);
      }
    )
  }

  function handleFileSelected(evt: ChangeEvent<HTMLInputElement>) {
    if(evt.target.files && evt.target.files.length > 0) {
      performImport(evt.target.files[0], true);
    }
  }

  function handlePerformImport() {
    if(fileInputRef.current && fileInputRef.current.files && fileInputRef.current.files.length > 0) {
      performImport(fileInputRef.current.files[0], false);
    }
  }

  return (
    <div className="m-3">
      {complete && <Redirect to={Path.toAvailabilityTable(scheduleId)} />}
      <h1>Dienstplan importieren</h1>
      <p>
        Importiere Eingabedaten für jede Person und jeden Tag über eine Excel-Datei.
        Am einfachsten gehts wie folgt:
      </p>
      <ol>
        <li>Exportiere den Dienstplan als Excel-Datei: &nbsp;
          <AuthFetchButton input={linkToScheduleExcel(scheduleId, 'input')} filename={`${scheduleId}.xlsx`} disabled={loading}>
            Eingabedaten-Export
          </AuthFetchButton>
        </li>
        <li>Verändere die Einträge.</li>
        <li>Importiere die Excel-Datei in Taso:&nbsp;
          <input
            ref={fileInputRef}
            type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            style={{display: 'none'}}
            onChange={handleFileSelected}
          />
          <Button disabled={loading} onClick={handleChooseFile}>Eingabedaten-Import</Button>
        </li>
      </ol>
      <p>
        Wichtig: Über den Import können keine Personen dem Dienstplan hinzugefügt werden.
      </p>
      {error &&
        <Alert variant='danger'>{error}</Alert>
      }
      {importDryRunResult &&
        <>
          {importDryRunResult.warnings.length > 0 &&
            <p>
              <h1>Warnungen</h1>
              <ul>
                {importDryRunResult.warnings.map(warning => (
                  <li>
                    {warning.address &&
                      <span>Zelle {warning.address.columnLetter}{warning.address.rowNumber}&nbsp;</span>
                    }
                    <b>{warning.message}</b>
                  </li>
                ))}
              </ul>
            </p>
          }
          {importDryRunResult.warnings.length === 0 &&
            <p>Keine Warnungen</p>
          }
          <Button disabled={loading} onClick={handlePerformImport}>Import durchführen</Button>
        </>
      }
      <Button as={Link} variant='secondary' to={Path.toAvailabilityTable(scheduleId)}>Abbrechen</Button>
    </div>
  );
}

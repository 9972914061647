import { sortBy } from 'lodash';
import fp from 'lodash/fp';
import React from 'react';
import { Container, Alert, Card, Table, Col, Row } from 'react-bootstrap';
import spacetime from 'spacetime';
import { NamesLookup } from '../Condition/Types';
import { UnsatLocator0 } from '../Generated/BackendTypes';
import { commaSeparated, isNotEmpty, Path, tz_utc } from '../Utils';
import { Link } from 'react-router-dom';
import { ScheduleId } from '../Types';
import { IconAlertCircle } from '../icons';

export interface UnsatSubsetProps {
  scheduleId: ScheduleId;
  index: number;
  subset: UnsatLocator0[];
  round?: number;
  lookup: NamesLookup
}

export function UnsatSubset(props: UnsatSubsetProps) {
  const dates: string =
    fp.flow(
      fp.flatMap((locator: UnsatLocator0) => locator.dates),
      fp.uniq,
      fp.map(date => spacetime(date, tz_utc).format('{date-pad}.{iso-month}.')),
      commaSeparated
    )(props.subset);
  const staffDemandRelated: UnsatLocator0[] = [];
  const availabilityRelated: UnsatLocator0[] = [];
  const conditionRelated: UnsatLocator0[] = [];
  const otherRelation: UnsatLocator0[] = []
  for(const locator of props.subset) {
    if(locator.availability) {
      availabilityRelated.push(locator)
    } else if(isNotEmpty(locator.staffDemandIdxs)) {
      staffDemandRelated.push(locator);
    } else if(isNotEmpty(locator.conditionPos)) {
      conditionRelated.push(locator);
    } else {
      otherRelation.push(locator);
    }
  }

  return (
    <Card border="danger" className="h-100">
      <Card.Body>
        <Card.Title className="d-flex justify-content-between">
          <span>Problembereich {props.index+1}&nbsp;</span>
          <span>{dates.length > 0 && <>am</>} {dates}</span>
        </Card.Title>
        <Table size="sm" >
          {staffDemandRelated.length > 0 &&
            <>
              <thead>
                <tr>
                  <th colSpan={2}>Personalbedarf</th>
                </tr>
              </thead>
              <tbody>
                {staffDemandRelated.map((locator, index) =>
                  <tr key={index}>
                    <td colSpan={2}>
                      Personalbedarf&nbsp;
                      {locator.staffDemandIdxs.map(staffDemandIdx =>
                        <><Link to={Path.toSpecificStaffDemand(props.scheduleId, staffDemandIdx)}>P{staffDemandIdx+1}</Link>&nbsp;</>
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </>
          }
          {availabilityRelated.length > 0 &&
            <>
              <thead>
                <tr>
                  <th className="pt-3">Verfügbarkeit</th>
                  <th className="pt-3"></th>
                </tr>
              </thead>
              <tbody>
                {availabilityRelated.map((locator, index) =>
                  <tr>
                    <td>{commaSeparated(locator.personIds.map(personId => props.lookup.persons[personId]))}</td>
                    <td className="text-center"></td>
                  </tr>
                )}
              </tbody>
            </>
          }
          {conditionRelated.length > 0 &&
            <>
              <thead>
                <tr>
                  <th className="pt-3">Bedingung</th>
                  <th className="pt-3"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {conditionRelated.map((locator, index) =>
                    <tr key={index}>
                      <td colSpan={2}>
                        Bedingung&nbsp;
                        {locator.conditionPos.map(conditionPos =>
                          <><Link to={Path.toSpecificCondition(props.scheduleId, conditionPos)}>C{conditionPos+1}</Link>&nbsp;</>
                        )}
                      </td>
                    </tr>
                  )}
                </tr>
              </tbody>
            </>
          }
          {otherRelation.length > 0 &&
            <>
            {otherRelation.map(locator => JSON.stringify(locator))}
            </>
          }
        </Table>
        <Card.Text></Card.Text>
      </Card.Body>
    </Card>
  )
}

function getEarliestDate(subset: UnsatLocator0[]): string|undefined {
  return fp.flow(
    fp.flatMap((locator: UnsatLocator0) => locator.dates),
    fp.min
  )(subset);
}

export interface UnsatSubsetsProps extends NamesLookup {
  scheduleId: ScheduleId;
  unsatSubsets: UnsatLocator0[][];
}

export function UnsatSubsets(props: UnsatSubsetsProps) {
  return (
    <Container>
      <Alert variant="danger">
        <IconAlertCircle className="mr-1" /> Bei der letzten Berechnung des Dienstplans konnte keine Lösung gefunden werden, da
        Konflikte bestehen. Bitte löse die Konflikte und starte die Berechnung erneut.
      </Alert>

      <br />
      <Row xs={1} md={3}>
        {sortBy(props.unsatSubsets, getEarliestDate).map((subset, subsetIndex) =>
          <Col className="mb-5">
            <UnsatSubset scheduleId={props.scheduleId} key={subsetIndex} index={subsetIndex} subset={subset} lookup={props} />
          </Col>
        )}
      </Row>
    </Container>
  )
}

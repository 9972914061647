import React from 'react';
import { PersonId, ScheduleId, ShiftId, ShiftLookup } from '../Types';
import { fetchSchedule, updateScheduleInputEntries } from '../BackendService';
import { AvailabilityTable, AvailabilityCellData, AvailabilityCellViewData } from './AvailabilityTable';
import { AuthFetch } from '../AuthService';
import Alert from 'react-bootstrap/Alert';
import { History } from 'history';
import { ExternalLinks, mapi, tz_utc } from '../Utils';
import { ContainerLeft, LoadingSpinner } from '../Components/UtilityComponents';
import { flatMap, fromPairs, groupBy } from 'lodash';
import spacetime, { Spacetime } from 'spacetime';
import { ScheduleInputDto, ScheduleInputInputEntryDto, ScheduleInputShiftDto, ScheduleInputUpdateDto, ScheduleInputUpdateEntryDto, UnsatLocator0 } from '../Generated/BackendTypes';
import ReactDataSheet from 'react-datasheet';
import classNames from 'classnames';
import fp from 'lodash/fp';
import { parseInputValue, ParseResultPart } from './AvailabilityCellEditor';
import { generateDayOfMonthRow, generateDayOfWeekRow, readOnlyCell } from '../Table/Utils';
import Polyglot from 'node-polyglot';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { pensumDescription } from '../Domain';
import { UnsatSubset } from '../Computation/UnsatSubsets';
import { toNamesLookup } from '../Condition/Types';
import { IntroPanel } from '../Components/IntroPanel';
import imageAvailability from '../images/availability.svg';
import { IconAlertOctagon, IconAlertTriangle } from '../icons';

export interface Change {
  personId: PersonId;
  index: number;
  selection: ShiftId[];
}

export interface RecordedChange extends Change {
  changeId: number;
}

export interface AvailabilityTableDataState {
  schedule?: ScheduleInputDto;
  changes: RecordedChange[];
  table: AvailabilityCellViewData[][];
}

const computeCellData = (personId: PersonId, changes: Change[], shifts: ShiftLookup[], defaultShiftId: ShiftId) => (entry: ScheduleInputInputEntryDto, index: number): AvailabilityCellData => {
  let selection = fp.flow(
    fp.filter((c: Change) => c.personId === personId && c.index === index),
    fp.map(c => c.selection),
    fp.last
  )(changes);

  if(!selection) {
    selection = entry.s.map(pos => shifts[pos].shiftId);
  }

  return {
    personId,
    index,
    options: entry.o.map(pos => shifts[pos].shiftId),
    selection,
    shifts,
    defaultShiftId
  }
}

const computeCellViewData = (defaultShiftId: ShiftId, shifts: Record<ShiftId, ScheduleInputShiftDto>, dates: Spacetime[]) => (data: AvailabilityCellData): AvailabilityCellViewData => {
  const isAssignment = (data.selection.length === 1);

  let label: string;
  let dataLabel: string;
  if(isAssignment) {
    label = shifts[data.selection[0]].label;
    dataLabel = data.selection[0] === defaultShiftId ? label : `!${label}`;
  } else {
    // entry is a selection, hide default shift
    label = fp.flow(
      fp.without([defaultShiftId]),
      fp.map(shiftId => shifts[shiftId].label),
      fp.join(' ')
    )(data.selection);
    dataLabel = label;
  }

  const className = classNames({
    'vt-sat': dates[data.index].day() === 6,
    'vt-sun': dates[data.index].day() === 0,
    'vt-hl': isAssignment,
  });

  return {
    label,
    dataLabel,
    className,
    data
  };
}

function affectedDates(unsatSubset: UnsatLocator0[]): string[] {
  return fp.flow(
    fp.flatMap((locator: UnsatLocator0) => locator.dates),
    fp.uniq,
    fp.map(date => spacetime(date, tz_utc).format('iso-short') as string),
  )(unsatSubset);
}

function recomputeTableDataState(schedule: ScheduleInputDto, changes: RecordedChange[]): AvailabilityTableDataState {
  const dates = schedule.inputEntries.cols.map(ds => spacetime(ds, tz_utc));
  const persons = fromPairs(schedule.persons.map(person => [person.personId, person]));
  const shifts = fromPairs(schedule.shifts.map(shift => [shift.shiftId, shift]));
  const personGroups = fromPairs(schedule.personGroups.map(personGroup => [personGroup.personGroupId, personGroup]));

  const titleCell: AvailabilityCellViewData = {
    label: '',
    readOnly: true,
    className: 'text-left font-weight-bold cell-no-border-right',
  }

  const personsColumnHeader = {
    label: 'Personen',
    readOnly: true,
    className: 'text-left font-weight-bold cell-no-border-right',
  };

  const pensumColumnHeader = {
    label: 'Pensum',
    readOnly: true,
    className: 'text-left font-weight-bold'
  };

  const empty1 = readOnlyCell();
  const empty2 = readOnlyCell({ className: 'cell-no-border-right'});
  const emptyTransparent = readOnlyCell({ className: 'vt-transparent' });
  const warningCell = readOnlyCell({
    disableEvents: true,
    className: 'vt-warning'
  });
  const errorCell = readOnlyCell({
    disableEvents: true,
    className: 'vt-error'
  });

  const warningsByDate = groupBy(schedule.staffDemandWarnings, d => d.date);

  const warningRow = dates.map(date => {
    const isodate = date.format('iso-short') as string;
    const warnings = warningsByDate[isodate] || [];
    if(warnings.length > 0) {
      const warningPopover = (
        <Popover id="popover-basic">
          <Popover.Title as="h3">Warnung</Popover.Title>
          {warnings.map((warning, index) =>
            <Popover.Content key={index}>
              Personalbedarf P{warning.staffDemandIdx+1} für Schicht {shifts[warning.shiftId].label} {shifts[warning.shiftId].name} kann nicht erfüllt werden.<br />
              {warning.maxCount !== undefined && warning.actualLowerBound > warning.maxCount &&
                <ul>
                  <li>Höchstens erlaubt: {warning.maxCount} Personen</li>
                  <li>Manuell zugewiesen: {warning.actualLowerBound} Personen</li>
                </ul>
              }
              {warning.minCount !== undefined && warning.actualUpperBound < warning.minCount &&
                <ul>
                  <li>Erforderlich mindestens: {warning.minCount} Personen</li>
                  <li>Verfügbar: {warning.actualUpperBound} Personen</li>
                </ul>
              }
            </Popover.Content>
          )}
        </Popover>
      );

      return {
        ...warningCell,
        forceComponent: true,
        component: (
          <OverlayTrigger trigger={['hover', 'focus']} overlay={warningPopover}>
            <IconAlertTriangle />
          </OverlayTrigger>
        )
      };
    } else {
      return emptyTransparent;
    }
  });

  const errorsFlattened: { date: string, unsatSubset: UnsatLocator0[], index: number }[] =
    flatMap(schedule.unsatSubsets, (unsatSubset: UnsatLocator0[], index: number) => affectedDates(unsatSubset).map(date => ({ date, unsatSubset, index })));
  const errorsByDate = groupBy(errorsFlattened, ({ date }) => date);

  console.dir(errorsFlattened);

  const errorRow = dates.map(date => {
    const isodate = date.format('iso-short') as string;
    const unsatSubsets = errorsByDate[isodate] || [];
    if(unsatSubsets.length > 0) {
      const errorPopover = (
        <Popover id="popover-basic">
          <UnsatSubset
            scheduleId={schedule.scheduleId}
            subset={unsatSubsets[0].unsatSubset}
            index={unsatSubsets[0].index}
            lookup={toNamesLookup({ shifts, persons, personGroups })}
          />
        </Popover>
      );
      return {
        ...errorCell,
        forceComponent: true,
        component: (
          <OverlayTrigger trigger={['hover', 'focus']} overlay={errorPopover}>
            <IconAlertOctagon />
          </OverlayTrigger>
        )
      };
    } else {
      return emptyTransparent;
    }
  })

  const header = [
    [titleCell, empty1, ...generateDayOfMonthRow(dates)],
    [personsColumnHeader, pensumColumnHeader, ...generateDayOfWeekRow(dates)],
  ];

  const footer = [
    [emptyTransparent, emptyTransparent, ...errorRow],
    [emptyTransparent, emptyTransparent, ...warningRow]
  ];

  const content = schedule.inputEntries.entries.map((personBaseData, rowIndex) => {
    const personId = schedule.inputEntries.rows[rowIndex];
    const person = persons[personId];

    const personCell: AvailabilityCellViewData = {
      readOnly: true,
      label: person.name,
      className: 'at-person cell-no-border-right',
    };

    const pensumCell: AvailabilityCellViewData = {
      readOnly: true,
      label: pensumDescription(person),
      className: 'at-pensum text-right text-nowrap',
    };

    const personChanges = changes.filter(c => c.personId === personId);

    const cells = fp.flow(
      mapi(computeCellData(personId, personChanges, schedule.shifts, schedule.defaultShiftId)),
      fp.map(computeCellViewData(schedule.defaultShiftId, shifts, dates))
    )(personBaseData);

    return [
      personCell,
      pensumCell,
      ...cells
    ];
  });

  const table: AvailabilityCellViewData[][] = [
    ...header,
    ...content,
    ...footer
  ];

  return {
    schedule,
    changes,
    table
  }
}

const parseShiftSelection = (shifts: ShiftLookup[], defaultShiftId: string) => (input: string): ShiftId[] => {
  const { mode, parts } = parseInputValue(shifts, defaultShiftId, input);

  const shiftIds = fp.flow(
    fp.map((p: ParseResultPart) => p.shiftId),
    fp.compact,
    fp.uniq
  )(parts);

  if(shiftIds.length === 0) {
    // no shift has been recognized, fall back to all shift options
    return [];
  } else if(mode === 'assign') {
    // if user activated assignment, only take first shift
    return [shiftIds[0]];
  } else {
    // user entered a selection, make sure default shift is included
    return fp.uniq([...shiftIds, defaultShiftId]);
  }
}

export interface AvailabilityTableContainerProps {
  scheduleId: ScheduleId;
  authFetch: AuthFetch;
  history: History;
  pg: Polyglot
}

export interface AvailabilityTableContainerState extends AvailabilityTableDataState {
  loading: boolean;
  error?: string;
  nextChangeId: number;
}

export class AvailabilityTableContainer extends React.Component<AvailabilityTableContainerProps, AvailabilityTableContainerState> {
  _intervalHandle: any;

  constructor(props: AvailabilityTableContainerProps) {
    super(props);
    this.state = {
      loading: false,
      changes: [],
      table: [],
      nextChangeId: 0
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    fetchSchedule(this.props.authFetch, this.props.scheduleId)
      .then(
        schedule => {
          this.setState({
            error: undefined,
            loading: false,
            ...recomputeTableDataState(schedule, [])
          });
        },
        error => {
          this.setState({
            error: error.message,
            loading: false
          });
        }
      );

    // start continuous saving
    this._intervalHandle = setInterval(this.handleSaveChanges, 1000);
  }

  componentWillUnmount() {
    clearInterval(this._intervalHandle);
  }

  // Change management
  addChanges(changes: Change[]) {
    this.setState(state => {
      if(state.schedule) {
        let nextChangeId = state.nextChangeId;
        // assign unique change id to each change
        const recordedChanges = [];
        for(let change of changes) {
          recordedChanges.push({
            ...change,
            changeId: nextChangeId
          });
          nextChangeId ++;
        }

        // update table state and nextChangeId
        return {
          ...recomputeTableDataState(state.schedule, [...state.changes, ...recordedChanges]),
          nextChangeId
        };
      } else {
        return {};
      }
    })
  }

  async saveChanges() {
    if(!this.state.loading && this.state.changes.length > 0) {
      console.debug(`Detected ${this.state.changes.length} pending changes.`);
      const entries: ScheduleInputUpdateEntryDto[] = this.state.changes.map(change => ({
        personId: change.personId,
        index: change.index,
        selection: change.selection
      }));

      const payload: ScheduleInputUpdateDto = {
        scheduleId: this.props.scheduleId,
        entries
      }

      const lastSavedChangeId = fp.flow(
        fp.map((change: RecordedChange) => change.changeId),
        fp.max
      )(this.state.changes);

      if(lastSavedChangeId !== undefined) {
        console.debug(`Saving ${entries.length} changes up to changeId ${lastSavedChangeId}.`);
        this.setState({ loading: true });
        try {
          const newSchedule = await updateScheduleInputEntries(this.props.authFetch, this.props.scheduleId, payload);
          this.setState(state => {
            const unsavedChanges = state.changes.filter(change => (change.changeId > lastSavedChangeId));
            console.log(`Saved ${entries.length} changes. Unsaved changes appeared in the meantime ${unsavedChanges.length}`);
            return {
              ...recomputeTableDataState(newSchedule, unsavedChanges),
              error: undefined,
              loading: false
            };
          })
        } catch(error) {
          console.error(`Error during save.`, error)
          this.setState({ error: error.message, loading: false });
        }
      }
    } else {
      console.debug('No pending changes');
    }
  }

  // Handlers
  handleCellsChanged = (cellChanges: ReactDataSheet.CellsChangedArgs<AvailabilityCellViewData>) => {
    if(this.state.schedule) {
      const parse = parseShiftSelection(this.state.schedule?.shifts, this.state.schedule?.defaultShiftId)
      const changes = cellChanges.map(({ cell, value }): Change|undefined => {
        if(cell?.data) {
          return {
            personId: cell.data.personId,
            index: cell.data.index,
            selection: parse(fp.toString(value))
          }
        } else {
          return undefined;
        }
      });
      this.addChanges(fp.compact(changes));
    }
  }

  handleSaveChanges = () => {
    this.saveChanges();
  }

  // Rendering
  render() {
    return (
      <>
        <ContainerLeft>
          <IntroPanel imageSource={imageAvailability} href={ExternalLinks.toAvailabilityDocs}>
            {this.props.pg.t('introtext.availability')}
          </IntroPanel>
        </ContainerLeft>
        {this.state.error &&
          <Alert variant='danger'>{this.state.error}</Alert>
        }
        {this.state.loading &&
          <LoadingSpinner />
        }
        <h1 className="ml-3 mt-3 mb-5">{this.props.pg.t('general.availabilities')}</h1>
        {this.state.schedule &&
          <AvailabilityTable
            scheduleId={this.props.scheduleId}
            table={this.state.table}
            onCellsChanged={this.handleCellsChanged}
            loading={this.state.loading}
            dirty={this.state.changes.length > 0}
            error={this.state.error}
            onSaveClick={this.handleSaveChanges}
          />
        }
      </>
    );
  }
}

import { Formik, FormikErrors, Form as FormikForm } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import { FoboField } from '../Components/FoboField';
import { SaveButton } from '../Components/SaveButton';
import { usePolyglot } from '../Localization/Localization';

interface UserProfileData {
  name: string;
}

export interface UserProfileProps {
  name: string;
  emailAddress: string;
  onSubmit: (name: string) => Promise<void>;
}

export function UserProfile(props: UserProfileProps) {
  const pg = usePolyglot();
  return (
    <Formik
      initialStatus={undefined}
      initialValues={{ name: props.name }}
      onSubmit={values => props.onSubmit(values.name)}
      validate={values => {
        const errors: FormikErrors<UserProfileData> = {};
        if(values.name.length === 0) {
          errors.name = pg.t('validation.notEmpty');
        }
        return errors;
      }}
      enableReinitialize
    >
      {({ isSubmitting, isValid, dirty, status }) => (
        <Form as={FormikForm}>
          <h1 className="mt-5">Bearbeiten</h1>
          <Form.Group controlId="formName">
            <Form.Label>{pg.t('general.name')}</Form.Label>
            <FoboField type="text" name="name" />
          </Form.Group>
          <Form.Group controlId="formEmailAddress">
            <Form.Label>{pg.t('general.emailAddress')}</Form.Label>
            <Form.Control
              disabled
              value={props.emailAddress}
            />
          </Form.Group>
          <SaveButton type="submit" dirty={dirty} saving={isSubmitting} error={status === false} />
        </Form>
      )}
    </Formik>
  )
}

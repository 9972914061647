import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { usePolyglot } from '../Localization/Localization';
import { useAuthFetch } from '../AuthService';
import imageTeam from '../images/team.svg';
import { teamCreate } from '../BackendService';
import { Formik, Form as FormikForm, FormikErrors } from 'formik';
import { memberof } from '../Utils';
import { trim } from 'lodash';
import { FoboField } from '../Components/FoboField';
import { CreateButton } from '../Components/CreateButton';

interface CreateFirstTeamData {
  name: string;
}

export interface CreateFirstTeamProps {
  onComplete?: () => void;
}

export function CreateFirstTeam(props: CreateFirstTeamProps) {
  const pg = usePolyglot();
  const authFetch = useAuthFetch();
  const [error, setError] = useState(false);

  async function handleCreate(name: string) {
    setError(false);
    try {
      await teamCreate(authFetch, { name });
      if(props.onComplete) {
        props.onComplete();
      }
    } catch(e) {
      setError(true);
    }
  }

  return (
    <Container className="text-center pt-5">
      <img src={imageTeam} alt="" />
      <h3 className="mt-5">{pg.t('account.firstTeamIntro')}</h3>
      <Formik
        initialValues={{ name: '' }}
        validate={values => {
          let errors: FormikErrors<CreateFirstTeamData> = {}
          if(trim(values.name) === '') {
            errors.name = pg.t('validation.notEmpty');
          }

          return errors;
        }}
        onSubmit={values => handleCreate(values.name)}
      >
        {({ isSubmitting }) => (
          <Row className="justify-content-center">
            <Col xs={12} md={8} lg={4}>
              <Form as={FormikForm}>
                <Form.Row className="mt-5">
                  <Col xs="auto">
                    <FoboField
                      type="text"
                      name={memberof<CreateFirstTeamData>('name')}
                      placeholder={pg.t('account.teamName')}
                    />
                  </Col>
                  <Col xs>
                    <CreateButton type="submit" creating={isSubmitting} error={error}>
                      {pg.t('general.create')}
                    </CreateButton>
                  </Col>
                </Form.Row>
              </Form>
            </Col>
          </Row>
        )}
      </Formik>
    </Container>
  );
}

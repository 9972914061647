import React from "react";
import { useGlobalState } from "../GlobalStateContext";
import { AccountMenuButton } from "./AccountMenu";


export function TopBar() {
  const globalState = useGlobalState();
  return (
    <div className="TopBar">
      <h1>
        {globalState.title}
      </h1>
      <AccountMenuButton />
    </div>
  );
}

/*
A regex pattern with a capturing group for each count.
The non-capturing group (?:) is just for convenience so that the matches don't contain the minus sign.
Also \s* is sprinkled inbetween so user can add whitespace between numbers and dash

Accepted:
1
1-2
-2
1-
-

Not accepted:
-1-2
a-3
3-2-a
1-3a
a12b3
a
*/
export const minMaxPattern = /^\s*([0-9]*)\s*(?:-\s*([0-9]*)){0,1}\s*$/;

export function parseMinMaxCount(value: string): { minCount: number, maxCount: number }|undefined {
  const matches = value.match(minMaxPattern);

  if(matches && matches.length === 3) {
    const p1 = matches[1];
    const p2 = matches[2];

    // the mechanics work as follows:
    // "", undefined -> undefined
    // "", "" -> undefined
    // "", something -> undefined
    // something, undefined -> { minCount, maxCount: minCount }
    // something, "" -> { minCount, maxCount: minCount }
    // something, something -> { minCount, maxCount }

    // also see unit test
    if(p1 === '') {
      return undefined;
    } else {
      const minCount = parseInt(p1, 10);
      if(p2 === undefined) {
        return { minCount, maxCount: minCount };
      } else if(p2 === '') {
        return undefined;
      } else {
        const maxCount = parseInt(p2, 10);
        if(minCount > maxCount) {
          return undefined;
        } else {
          return { minCount, maxCount };
        }
      }
    }
  }
  return undefined;
}

export function stringifyMinMaxCount(minCount: number, maxCount: number): string {
  if(maxCount === minCount) {
    return `${maxCount}`;
  } else {
    return `${minCount}-${maxCount}`;
  }
}

import React, { useMemo } from 'react';
import { DropdownSingleSelect, DropdownSingleSelectItem } from '../Components/DropdownSingleSelect';
import { useField } from 'formik';
import spacetime, { Spacetime } from 'spacetime';
import { getDatesBetween, tz_utc, memberof } from '../Utils';
import { DateSelectorDto, DateSelectorMode0 } from '../Generated/BackendTypes';

export interface DateSelectorFormData {
  mode: DateSelectorMode0;
  date?: Spacetime;
}

export function createEmptyDateSelectorFormData(mode?: DateSelectorMode0): DateSelectorFormData {
  return { mode: mode !== undefined ? mode : 'StartOfPeriod' };
}

export function toDateSelectorFormData(d: DateSelectorDto): DateSelectorFormData {
  return {
    mode: d.mode,
    date: d.date ? spacetime(d.date) : undefined
  }
}

export function fromDateSelectorFormData(d: DateSelectorFormData): DateSelectorDto {
  return {
    _type: 'DateSelector0',
    mode: d.mode,
    date: (d.mode === 'Date' && d.date) ? d.date.format('iso-short') as string : undefined
  };
}

const modeItems: DropdownSingleSelectItem<DateSelectorMode0>[] = [
  { itemKey: "StartOfPeriod", label: "vom ersten Tag" },
  { itemKey: "EndOfPeriod", label: "zum letzten Tag" },
  { itemKey: "Date", label: "Datum" },
];

interface ModeDropdownProps {
  name: string;
  disableStartOfPeriod?: boolean;
  disableEndOfPeriod?: boolean;
}
function ModeDropdown({ name, disableStartOfPeriod, disableEndOfPeriod }: ModeDropdownProps) {
  const [field,, helpers] = useField<DateSelectorMode0>(name);

  let items = modeItems;
  if(disableStartOfPeriod) {
    items = items.filter(item => item.itemKey !== 'StartOfPeriod');
  }
  if(disableEndOfPeriod) {
    items = items.filter(item => item.itemKey !== 'EndOfPeriod');
  }

  return (
    <DropdownSingleSelect
      id="dateSelectorModeDropdown"
      variant="link"
      items={items}
      selected={field.value}
      onSelect={helpers.setValue}
    />
  );
}

interface DateDropdownProps {
  name: string;
  startDate: Spacetime;
  endDate: Spacetime;
}
function DateDropdown({ name, startDate, endDate }: DateDropdownProps) {
  const [field,, helpers] = useField<Spacetime|undefined>(name);

  const dates: DropdownSingleSelectItem<string>[] = useMemo(() =>
    getDatesBetween(startDate, endDate).map(date => (
      { itemKey: date.format('iso-short') as string, label: date.format('{date-pad}.{iso-month}.{year}') as string })
    ),
    [ startDate, endDate ]
  );

  let value: string;
  if(field.value) {
    value = field.value.format('iso-short') as string;
  } else {
    value = startDate.format('iso-short') as string;
  }

  return (
    <DropdownSingleSelect
      id="dateSelectorDateDropdown"
      variant="link"
      items={dates}
      selected={value}
      onSelect={s => helpers.setValue(spacetime(s, tz_utc))}
    />
  );
}

export interface DateSelectorProps {
  name: string;
  startDate: Spacetime;
  endDate: Spacetime;
  disableStartOfPeriod?: boolean;
  disableEndOfPeriod?: boolean;
}

export function DateSelector({ name: parentName, startDate, endDate, disableStartOfPeriod, disableEndOfPeriod }: DateSelectorProps) {
  const modeName = `${parentName}.${memberof<DateSelectorFormData>('mode')}`;
  const [{ value: mode }] = useField<DateSelectorMode0>(modeName);

  return (
    <div className="d-flex flex-row">
      <ModeDropdown
        name={modeName}
        disableStartOfPeriod={disableStartOfPeriod}
        disableEndOfPeriod={disableEndOfPeriod} />
      {mode === "Date" &&
        <DateDropdown
          name={`${parentName}.${memberof<DateSelectorFormData>('date')}`}
          startDate={startDate}
          endDate={endDate} />
      }
    </div>
  );
}



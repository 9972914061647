import React from 'react';
import { oneof } from '../Utils';
import { ShiftKind0, ShiftSelectorDto } from '../Generated/BackendTypes';
import { ShiftId } from '../Types';
import { Form } from 'react-bootstrap';
import { ChipSelectItem, ChipSelectOption } from '../Components/ChipSelect';
import { usePolyglot } from '../Localization/Localization';
import { ChipSelectField } from '../Components/ChipSelectField';

export type ShiftSelectorFormData = Array<ShiftId|ShiftKind0>;

export function toShiftSelectorFormData(d: ShiftSelectorDto): ShiftSelectorFormData {
  if(d._type !== 'ShiftSelector1') {
    throw Error("Only works with ShiftSelector1");
  }
  let s = [...d.shiftIds];
  if(d.shiftKind) {
    s.push(d.shiftKind)
  }
  return s;
}

export function fromShiftSelectorFormData(shiftIds: ShiftId[], d: ShiftSelectorFormData): ShiftSelectorDto {
  const hasWork = d.includes(oneof<ShiftKind0>("Work"));
  const hasRest = d.includes(oneof<ShiftKind0>("Rest"));
  const selectedShiftIds = d.filter(id => shiftIds.includes(id))
  if(selectedShiftIds.length === 0 && ((hasWork && hasRest) || (!hasWork && !hasRest))) {
    // matches everything
    return {
      _type: 'ShiftSelector1',
      shiftIds: [],
      shiftKind: undefined
    };
  } else {
    let shiftKind: ShiftKind0|undefined;
    if(hasWork && hasRest) {
      shiftKind = undefined;
    } else if(hasWork) {
      shiftKind = 'Work';
    } else if(hasRest) {
      shiftKind = 'Rest';
    } else {
      shiftKind = undefined;
    }
    return {
      _type: 'ShiftSelector1',
      shiftIds: selectedShiftIds,
      shiftKind
    };
  }
}

export interface ShiftSelectorProps {
  name: string;
  shifts: ChipSelectOption<ShiftId>[];
}

// TODO: rename to ShiftSelectorEditor because i want to have some domain functions in a ShiftSelector "module"
export function ShiftSelector(props: ShiftSelectorProps) {
  const pg = usePolyglot();
  const items: ChipSelectItem<ShiftId|ShiftKind0>[] = [
    {
      label: pg.t('condition.shiftKind'),
      items: [
        {
          itemKey: oneof<ShiftKind0>('Rest'),
          label: pg.t('condition.shiftKindRest'),
          variant: 'composite'
        },
        {
          itemKey: oneof<ShiftKind0>('Work'),
          label: pg.t('condition.shiftKindWork'),
          variant: 'composite'
        }
      ]
    },
    {
      label: pg.t('general.shifts'),
      items: props.shifts
    }
  ];

  return (
    <ChipSelectField
      id={`shiftSelectorChipSelect_{props.name}`}
      items={items}
      name={props.name}
      placeholder="Alle Schichten"
    />
  );
}

export function ShiftSelectorSubform(props: ShiftSelectorProps) {
  return (
    <Form.Group controlId="formShiftSelector">
      <Form.Label>Schichtauswahl</Form.Label>
      <ShiftSelector {...props} />
    </Form.Group>
  )
}


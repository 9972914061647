import { AuthFetch } from "./AuthService";
import { ErrorDto } from "./Generated/BackendTypes";

async function handleResponse<T>(response: Response) {
  if(!response.ok) {
    const raw = await response.text();
    let parsed: ErrorDto;
    try {
      // try parsing as json
      parsed = JSON.parse(raw);
    } catch {
      // cannot parse json, it must be text
      parsed = {
        _type: 'Other',
        message: raw
      };
    }
    // throw parsed error dto
    throw parsed;
  }
  const data = await response.json();
  return data as T;
}


export async function performCreate<TResponse, TRequest=TResponse>(authFetch: AuthFetch, endpoint: string, obj: TRequest): Promise<TResponse> {
  const params: RequestInit = {
    method: 'POST',
    body: JSON.stringify(obj),
    headers: {
      'Content-Type': 'text/json'
    },
    cache: 'no-cache'
  };

  const response = await authFetch(endpoint, params);
  return await handleResponse(response);
}

export async function performCreateNoContent<TRequest>(authFetch: AuthFetch, endpoint: string, obj: TRequest): Promise<void> {
  const params: RequestInit = {
    method: 'POST',
    body: JSON.stringify(obj),
    headers: {
      'Content-Type': 'text/json'
    },
    cache: 'no-cache'
  };

  const response = await authFetch(endpoint, params);
  if(!response.ok) {
    const text = await response.text();
    throw new Error(`Fehler ${response.status} (${response.statusText}): ${text}`);
  }
}

export async function performFetch<T>(authFetch: AuthFetch, endpoint: string): Promise<T> {
  const response = await authFetch(endpoint);
  return await handleResponse(response);
}

export async function performUpdate<TResponse, TRequest=TResponse>(authFetch: AuthFetch, endpoint: string, obj: TRequest): Promise<TResponse> {
  const params: RequestInit = {
    method: 'PUT',
    body: JSON.stringify(obj),
    headers: {
      'Content-Type': 'text/json'
    },
    cache: 'no-cache'
  };

  const response = await authFetch(endpoint, params);
  return await handleResponse(response);
}

export async function performDelete(authFetch: AuthFetch, endpoint: string): Promise<void> {
  const response = await authFetch(endpoint, { method: 'DELETE' });

  if(!response.ok) {
    const text = await response.text();
    throw new Error(`Fehler ${response.status} (${response.statusText}) beim Löschen: ${text}`);
  }
}

export async function performDeleteWithContent<T>(authFetch: AuthFetch, endpoint: string): Promise<T> {
  const response = await authFetch(endpoint, { method: 'DELETE' });
  return await handleResponse(response);
}

export function createFetch<T>(endpoint: string) {
  return async (authFetch: AuthFetch, id: string): Promise<T> => {
    const response = await authFetch(endpoint + '/' + id);
    return await handleResponse(response);
  };
}

export function createFetchWithoutId<T>(endpoint: string) {
  return async (authFetch: AuthFetch): Promise<T> => {
    const response = await authFetch(endpoint);
    return await handleResponse(response);
  };
}

export function createUpdate<T>(endpoint: string, getId: (obj:T) => string) {
  return async (authFetch: AuthFetch, obj: T) => {
    const params: RequestInit = {
      method: 'PUT',
      body: JSON.stringify(obj),
      headers: {
        'Content-Type': 'text/json'
      },
      cache: 'no-cache'
    };

    const response = await authFetch(endpoint + '/' + getId(obj), params);
    return await handleResponse(response);
  };
}

export function createUpdateWithoutId<T>(endpoint: string) {
  return async (authFetch: AuthFetch, obj: T) => {
    const params: RequestInit = {
      method: 'PUT',
      body: JSON.stringify(obj),
      headers: {
        'Content-Type': 'text/json'
      },
      cache: 'no-cache'
    };

    const response = await authFetch(endpoint, params);
    return await handleResponse(response);
  };
}

export function createCreate<T, A=T>(endpoint: string) {
  return async (authFetch: AuthFetch, obj: T) => {
    const params: RequestInit = {
      method: 'POST',
      body: JSON.stringify(obj),
      headers: {
        'Content-Type': 'text/json'
      },
      cache: 'no-cache'
    };

    const response = await authFetch(endpoint, params);
    return await handleResponse<A>(response);
  };
}

export function createDelete(endpoint: string) {
  return async (authFetch: AuthFetch, id: string): Promise<void> => {
    const response = await authFetch(endpoint + '/' + id, { method: 'DELETE' });

    if(!response.ok) {
      const text = await response.text();
      throw new Error(`Fehler ${response.status} (${response.statusText}) beim Löschen: ${text}`);
    }
  };
}

export function createFetchAll<T>(endpoint: string) {
  return async (authFetch: AuthFetch): Promise<T[]> => {
    const response = await authFetch(endpoint);
    return await handleResponse(response);
  };
}

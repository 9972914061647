import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, useParams, Redirect, useHistory, useRouteMatch, Link } from "react-router-dom";
import { isProd } from './config';
import { AuthTokenService, AuthContext, createAuthFetch, AuthTokenServiceContext, useAuthFetch } from './AuthService';
import { AvailabilityTableContainer } from './Availability/AvailabilityTableContainer';
import spacetime from 'spacetime';
import { fetchAppConf, fetchScheduleMetadata } from './BackendService';
import { mapNilUndefined, Path, StorageKey } from './Utils';
import { ConditionEditorContainer } from './Condition/ConditionEditorContainer';
import { ImportSchedule } from './Availability/ImportSchedule';
import { MainMenu } from './Menu/MainMenu';
import { ComputationTableContainer } from './Computation/ComputationTableContainer';
import { SolverMonitorContainer } from './Computation/SolverMonitorContainer';
import { TenantSwitcher } from './SiteAdmin/TenantSwitcher';
import { ScheduleList } from './Schedule/ScheduleList';
import { ScheduleId, TeamId } from './Types';
import { DataTransferScheduleImport } from './Schedule/DataTransferScheduleImport';
import { useLocalStorage, useQuery } from './Hooks';
import { ScheduleConfigContainer } from './ScheduleConfig/ScheduleConfigContainer';
import { StaffDemandEditorContainer } from './StaffDemand/StaffDemandEditorContainer';
import { ConditionList } from "./Condition/ConditionList";
import { Team } from "./Team/Team";
import { Logo } from "./Components/Logo";
import classNames from "classnames";
import { StaffDemandTableContainer } from "./StaffDemand/StaffDemandTableContainer";
import { usePolyglot } from "./Localization/Localization";
import { TopBar } from "./TopBar/TopBar";
import { AccountOrganization } from "./AccountOrganization/AccountOrganization";
import { UserProfileContainer } from "./UserProfile/UserProfileContainer";
import { ConditionDto_CaseNames, ConditionDto_Cases } from "./Generated/BackendTypes";
import { UserProfileProvider } from "./UserProfileContext";
import { GlobalStateProvider } from "./GlobalStateContext";
import { Title } from "./TopBar/Title";
import { CreateScheduleContainer } from "./Schedule/CreateScheduleContainer";
import { deriveScheduleCaption } from "./Domain";
import { AccountUsage } from "./AccountUsage/AccountUsage";
import { InvoiceAddressContainer } from "./AccountUsage/InvoiceAddressContainer";
import { AccountTopUp } from "./AccountUsage/AccountTopUp";
import { AccountTopUpSuccess } from "./AccountUsage/AccountTopUpSuccess";
import { TestComputationCheckout } from "./Computation/ComputationCheckout";
import { ShiftTableContainer } from "./Shift/ShiftTableContainer";
import { ShiftReorder } from "./Shift/ShiftReorder";
import { PersonReorder } from "./Team/PersonReorder";
import { PersonGroupReorder } from "./Team/PersonGroupReorder";
import { CreateScheduleFromExample } from "./Schedule/CreateScheduleFromExample";
import { DemoConditionContainer } from "./Condition/DemoConditionsContainer";

const appConfPromise = fetchAppConf();

const authTokenService = new AuthTokenService(appConfPromise, (reason, loginUrl) => {
  if(reason === 'backendError' || reason === 'dummyAuthFailed' || reason === 'backendUnreachable') {
    return ;
  }
  //window.location.replace(`${loginUrl}?returnUrl=${encodeURIComponent(window.location.href)}`);
});

const authFetch = createAuthFetch(authTokenService);

if(!isProd) {
  // @ts-ignore: property does not exist on window
  window.tbe = require('./BackendService');
  // @ts-ignore: property does not exist on window
  window.authFetch = authFetch;
  // @ts-ignore: property does not exist on window
  window.spacetime = spacetime;
}

function DemoConditionsPage() {
  const query = useQuery();
  return (
    <DemoConditionContainer
      demoConditionId={mapNilUndefined(query.get('demoConditionId'))}
    />
  )
}

function TenantSwitcherPage() {
  return <TenantSwitcher />
}

function ScheduleChooserPage() {
  return (
    <ScheduleList />
  );
}

function DataTransferScheduleImportPage() {
  return <DataTransferScheduleImport />;
}

function AvailabilityTablePage() {
  const { scheduleId } = useParams<{ scheduleId: ScheduleId }>();
  const authFetch = useAuthFetch();
  const history = useHistory();
  const pg = usePolyglot();

  if (scheduleId) {
    return (
      <AvailabilityTableContainer
        scheduleId={scheduleId}
        authFetch={authFetch}
        history={history}
        pg={pg}
      />
    );
  } else {
    return <Redirect to={Path.default} />;
  }
}

function CreateSchedulePage() {
  const { teamId } = useParams<{ teamId: TeamId }>();
  return (
    <CreateScheduleContainer teamId={teamId} />
  );
}

function CreateScheduleFromExamplePage() {
  const { teamId } = useParams<{ teamId: TeamId }>();
  return (
    <CreateScheduleFromExample teamId={teamId} />
  );
}

function ImportSchedulePage() {
  const { scheduleId } = useParams<{ scheduleId: ScheduleId }>();

  if(scheduleId) {
    return (
      <ImportSchedule scheduleId={scheduleId} />
    );
  } else {
    return <Redirect to={Path.default} />;
  }
}

function MatchScheduleConfigPage() {
  const { scheduleId } = useParams<{ scheduleId: ScheduleId }>();

  if(scheduleId) {
    return (
      <ScheduleConfigContainer scheduleId={scheduleId} />
    );
  } else {
    return <Redirect to={Path.default} />;
  }
}

function ComputationPage() {
  const { scheduleId } = useParams<{ scheduleId: ScheduleId }>();
  const authFetch = useAuthFetch();
  const history = useHistory();
  const pg = usePolyglot();

  if(scheduleId) {
    return (
      <ComputationTableContainer
        scheduleId={scheduleId}
        authFetch={authFetch}
        history={history}
        pg={pg}
      />
    );
  } else {
    return <Redirect to={Path.default} />;
  }
}

function SolverMonitorPage() {
  const { scheduleId, action } = useParams<{ scheduleId: ScheduleId, action: string }>();

  if(scheduleId) {
    return (
      <SolverMonitorContainer scheduleId={scheduleId} redirectToComputationOnFinish={action === 'live'}/>
    );
  } else {
    return <Redirect to={Path.default} />;
  }
}

function TeamPage() {
  const { scheduleId } = useParams<{ scheduleId: ScheduleId }>();
  if(scheduleId) {
    return <Team scheduleId={scheduleId} />;
  } else {
    return <Redirect to={Path.default} />;
  }
}

function PersonReorderPage() {
  const { scheduleId } = useParams<{ scheduleId: ScheduleId }>();
  if(scheduleId) {
    return <PersonReorder scheduleId={scheduleId} />;
  } else {
    return <Redirect to={Path.default} />;
  }
}

function PersonGroupReorderPage() {
  const { scheduleId } = useParams<{ scheduleId: ScheduleId }>();
  if(scheduleId) {
    return <PersonGroupReorder scheduleId={scheduleId} />;
  } else {
    return <Redirect to={Path.default} />;
  }
}

function ShiftReorderPage() {
  const { scheduleId } = useParams<{ scheduleId: ScheduleId }>();
  if(scheduleId) {
    return <ShiftReorder scheduleId={scheduleId} />;
  } else {
    return <Redirect to={Path.default} />;
  }
}

function ShiftListPage() {
  const { scheduleId } = useParams<{ scheduleId: ScheduleId }>();
  if(scheduleId) {
    return <ShiftTableContainer scheduleId={scheduleId} />;
  } else {
    return <Redirect to={Path.default} />;
  }
}

function CreateConditionPage() {
  const { scheduleId } = useParams<{ scheduleId: ScheduleId }>();
  const query = useQuery();
  const history = useHistory();
  const conditionType = query.get('type');
  const useRawEditor = query.has('raw');
  if(scheduleId && conditionType && ConditionDto_CaseNames.includes(conditionType)) {
    return (
      <ConditionEditorContainer
        scheduleId={scheduleId}
        onComplete={() => history.push(Path.toUnspecificCondition(scheduleId))}
        conditionType={conditionType as ConditionDto_Cases}
        useRawEditor={useRawEditor}
      />
    );
  } else {
    return <Redirect to={Path.default} />
  }
}

function ListConditionPage() {
  const { scheduleId } = useParams<{ scheduleId: ScheduleId }>();
  if(scheduleId) {
    return <ConditionList scheduleId={scheduleId} />;
  } else {
    return <Redirect to={Path.default} />;
  }
}

function EditConditionPage() {
  const { scheduleId, conditionPos } = useParams<{ scheduleId: ScheduleId, conditionPos: string }>();
  const history = useHistory();
  const query = useQuery();
  const useRawEditor = query.has('raw');
  if(scheduleId && conditionPos) {
    return (
      <ConditionEditorContainer
        scheduleId={scheduleId}
        conditionPos={parseInt(conditionPos, 10)}
        onComplete={() => history.push(Path.toUnspecificCondition(scheduleId))}
        useRawEditor={useRawEditor}
      />
    );
  } else {
    return <Redirect to={Path.default} />;
  }
}

function CreateStaffDemandPage() {
  const { scheduleId } = useParams<{ scheduleId: ScheduleId }>();
  const history = useHistory();
  if(scheduleId) {
    return (
      <StaffDemandEditorContainer
        scheduleId={scheduleId}
        onComplete={() => history.push(Path.toUnspecificStaffDemand(scheduleId))}
      />
    );
  } else {
    return <Redirect to={Path.default} />;
  }
}

function ListStaffDemandPage() {
  const authFetch = useAuthFetch();
  const history = useHistory();
  const { scheduleId } = useParams<{ scheduleId: ScheduleId }>();
  const pg = usePolyglot();
  if(scheduleId) {
    return <StaffDemandTableContainer scheduleId={scheduleId} authFetch={authFetch} history={history} pg={pg} />;
  } else {
    return <Redirect to={Path.default} />;
  }
}

function EditStaffDemandPage() {
  const { scheduleId, staffDemandIdx } = useParams<{ scheduleId: ScheduleId, staffDemandIdx: string }>();
  const history = useHistory();
  if(scheduleId && staffDemandIdx) {
    return (
      <StaffDemandEditorContainer
        scheduleId={scheduleId}
        staffDemandIdx={parseInt(staffDemandIdx, 10)}
        onComplete={() => history.push(Path.toUnspecificStaffDemand(scheduleId))}
      />
    );
  } else {
    return <Redirect to={Path.default} />;
  }
}

function AccountOrganizationPage() {
  return <AccountOrganization />
}

function AccountProfilePage() {
  return <UserProfileContainer />
}

function AccountUsagePage() {
  return <AccountUsage />
}

function AccountEditInvoiceAddressPage() {
  return <InvoiceAddressContainer />
}

function AccountTopUpPage() {
  return <AccountTopUp />
}

function AccountTopUpSuccessPage() {
  return <AccountTopUpSuccess />;
}

function TestComputationCheckoutPage() {
  const { testCase } = useParams<{ testCase: string }>();
  return <TestComputationCheckout testCase={testCase} />
}

/**
 * Provide main layout container and MainMenu. Remembers last opened schedule using SessionStorage.
 */
function Skeleton({ children }: React.PropsWithChildren<{}>) {
  const [expanded, setExpanded] = useLocalStorage(StorageKey.mainMenuExpanded, true);

  const scheduleId = useRouteMatch<{ scheduleId: ScheduleId }>(Path.matchSchedule)?.params.scheduleId;

  const logoActive = useRouteMatch({ path: Path.matchScheduleChooser, exact: true }) !== null;
  return (
    <div className={classNames('Skeleton', { 'Skeleton--Expanded': expanded })}>
    <Link to={Path.toScheduleChooser}>
      <div className={classNames('Skeleton__Logo', { 'Skeleton__Logo--Active': logoActive })}>
          <Logo expanded={expanded} />
      </div>
        </Link>
      <div className="Skeleton__TopBar">
        <TopBar />
      </div>
      <div className="Skeleton__Menu">
        <MainMenu scheduleId={scheduleId} expanded={expanded} onExpand={setExpanded} />
      </div>
      <div className="Skeleton__Content">
        {children}
      </div>
    </div>
  );
}


interface ScheduleNameProps {
  scheduleId?: ScheduleId;
}

function SetTitleToScheduleName(props: ScheduleNameProps) {
  const authFetch = useAuthFetch();
  const pg = usePolyglot();
  const [comment, setComment] = useState<string|undefined>(undefined);
  useEffect(() => {
    if(props.scheduleId) {
      fetchScheduleMetadata(authFetch, props.scheduleId)
      .then(metadata => setComment(deriveScheduleCaption(metadata.startDate, metadata.endDate, { comment: metadata.comment })))
      .catch(() => {} /* ignore */)
    }
  }, [props.scheduleId, authFetch, pg]);
  return <Title>{comment}</Title>;
}

export function App() {
  return (
    <AuthContext.Provider value={authFetch}>
    <AuthTokenServiceContext.Provider value={authTokenService}>
    <UserProfileProvider>
    <GlobalStateProvider>
    <Router basename="/">
    <Switch>
      <Route path={Path.matchDemoConditions} exact><DemoConditionsPage /></Route>
      <Route>
        <Skeleton>
          {/* Set title when in schedule */}
          <Route path={Path.matchSchedule} render={
            props => <SetTitleToScheduleName scheduleId={props.match.params.scheduleId} /> }
          />
          <Switch>
            {/* Site Administration */}
            <Route path={Path.matchTenantSwitcher} exact><TenantSwitcherPage /></Route>

            {/* Data Transfer */}
            <Route path={Path.matchDataTransferScheduleImport}><DataTransferScheduleImportPage /></Route>

            {/* Schedule */}
            <Route path={Path.matchScheduleChooser} exact><ScheduleChooserPage /></Route>
            <Route path={Path.matchNewSchedule} exact><CreateSchedulePage /></Route>
            <Route path={Path.matchNewScheduleFromExample} exact><CreateScheduleFromExamplePage /></Route>

            <Route path={Path.matchTeam} exact><TeamPage /></Route>
            <Route path={Path.matchPersonReorder} exact><PersonReorderPage /></Route>
            <Route path={Path.matchPersonGroupReorder} exact><PersonGroupReorderPage /></Route>

            <Route path={Path.matchShiftReorder}><ShiftReorderPage /></Route>
            <Route path={Path.matchUnspecificShift}><ShiftListPage /></Route>

            <Route path={Path.matchNewCondition}><CreateConditionPage /></Route>
            <Route path={Path.matchSpecificCondition}><EditConditionPage /></Route>
            <Route path={Path.matchUnspecificCondition}><ListConditionPage /></Route>

            <Route path={Path.matchNewStaffDemand}><CreateStaffDemandPage /></Route>
            <Route path={Path.matchSpecificStaffDemand}><EditStaffDemandPage /></Route>
            <Route path={Path.matchUnspecificStaffDemand}><ListStaffDemandPage /></Route>

            <Route path={Path.matchScheduleImport}><ImportSchedulePage /></Route>
            <Route path={Path.matchSolverMonitor}><SolverMonitorPage /></Route>
            <Route path={Path.matchComputation}><ComputationPage /></Route>
            <Route path={Path.matchScheduleConfig}><MatchScheduleConfigPage /></Route>
            <Route path={Path.matchAvailabilityTable}><AvailabilityTablePage /></Route>

            <Route path={Path.matchSchedule} render={props => <Redirect to={Path.toAvailabilityTable(props.match.params.scheduleId!)} />} />

            {/* Account */}
            <Route exact path={Path.matchAccountOrganization}><AccountOrganizationPage /></Route>
            <Route exact path={Path.matchAccountProfile}><AccountProfilePage /></Route>
            <Route exact path={Path.matchAccountUsage}><AccountUsagePage /></Route>
            <Route exact path={Path.matchAccountEditInvoiceAddress}><AccountEditInvoiceAddressPage /></Route>
            <Route exact path={Path.matchAccountTopUp}><AccountTopUpPage /></Route>
            <Route exact path={Path.matchAccountTopUpSuccess}><AccountTopUpSuccessPage /></Route>

            {/* Test */}
            <Route path={Path.toTestComputationCheckout}><TestComputationCheckoutPage /></Route>

            {/* Entry */}
            <Route path={Path.default}><ScheduleChooserPage /></Route>
          </Switch>
        </Skeleton>
      </Route>
    </Switch>
    </Router>
    </GlobalStateProvider>
    </UserProfileProvider>
    </AuthTokenServiceContext.Provider>
    </AuthContext.Provider>
  );
}

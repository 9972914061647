import React, { useEffect, useState } from 'react';
import { UserDto } from '../Generated/BackendTypes';
import { useAuthFetch } from '../AuthService';
import { userFetch, userUpdate } from '../BackendService';
import { usePolyglot } from '../Localization/Localization';
import { ContainerLeft, LoadingSpinner } from '../Components/UtilityComponents';
import { Alert } from 'react-bootstrap';
import { UserProfile } from './UserProfile';
import { useUserProfile } from '../UserProfileContext';
import { Title } from '../TopBar/Title';

export function UserProfileContainer() {
  const authFetch = useAuthFetch();
  const pg = usePolyglot();
  const userProfile = useUserProfile();
  const [user, setUser] = useState<UserDto|undefined>(undefined);
  const [error, setError] = useState<string|undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    userFetch(authFetch)
    .then(user => setUser(user))
    .catch(error => setError(error.message))
    .finally(() => setLoading(false));
  }, [authFetch]);

  async function handleUpdate(name: string) {
    setLoading(true);
    try {
      setUser(await userUpdate(authFetch, { name }));
      userProfile.refresh();
    } catch(error) {
      setError(error.message);
    }
    setLoading(false);
  }

  if(user === undefined) {
    if(loading) {
      return (
        <LoadingSpinner />
      );
    } else {
      return (
        <Alert variant="danger">{pg.t('general.errorMessageIntro')}: {error}</Alert>
      );
    }
  } else {
    return (
      <ContainerLeft>
        <Title>{pg.t('account.yourProfile')}</Title>
        <UserProfile
          name={user.userName}
          emailAddress={user.emailAddress}
          onSubmit={handleUpdate}
        />
      </ContainerLeft>
    );
  }
}

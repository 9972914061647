import React, { useState } from 'react';
import { Alert, Button, FormCheck } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { linkToScheduleExcel } from '../BackendService';
import { AuthFetchButton } from '../Components/AuthFetchButton';
import { SaveButton } from '../Components/SaveButton';
import { Path } from '../Utils';
import { ComputationCheckoutContainer } from './ComputationCheckout';
import { ScheduleId } from '../Types';
import { usePolyglot } from '../Localization/Localization';
import { IconAlertCircle } from '../icons';

export interface ComputationToolbarProps {
  scheduleId: ScheduleId;
  obsolete?: boolean;
  computing?: boolean;
  saving: boolean;
  dirty: boolean;
  error?: string;
  drilldown?: boolean;
  onSaveClick?: () => void;
  onStartSolverTask: (queueTimeout: number, solveTimeout: number) => void;
  onDrilldownToggle?: () => void;
}

export function ComputationToolbar(props: ComputationToolbarProps) {
  const pg = usePolyglot();
  const [showStartModal, setShowStartModal] = useState<boolean>(false);
  return (
    <>
      <ComputationCheckoutContainer
        scheduleId={props.scheduleId}
        show={showStartModal}
        onCancel={() => setShowStartModal(false)}
        onStart={() => {
          props.onStartSolverTask(60, 2*60);
          setShowStartModal(false);
        }}
      />
      <div className="m-3">
        {props.computing &&
          <Alert variant="info">
            <IconAlertCircle /> Dieser Dienstplan wird gerade neu berechnet.
            <Button
              variant="info"
              as={Link}
              to={Path.toSolverMonitor(props.scheduleId)}
              className="ml-3"
            >
              {pg.t('general.computationProcess')}
            </Button>
          </Alert>
        }
        {props.obsolete &&
          <Alert variant="info">
            <IconAlertCircle /> Dieses Resultat beruht auf einem veralteten Stand der Eingabedaten.
            Bitte neue Berechnung starten.
          </Alert>
        }
      </div>
      <div className="d-flex flex-row m-3 mt-5">
        {props.onSaveClick &&
          <SaveButton
            className="mr-3"
            dirty={props.dirty}
            saving={props.saving}
            error={props.error !== undefined}
            onClick={props.onSaveClick}
          />
        }
        <Button
          variant="warning"
          className="mr-3"
          disabled={props.saving || props.computing}
          onClick={() => setShowStartModal(true)}
        >
          Neu Berechnen
        </Button>
        <AuthFetchButton
            input={linkToScheduleExcel(props.scheduleId)}
            filename={`${props.scheduleId}.xlsx`}
            className="mr-3"
          >
          Resultat-Export
        </AuthFetchButton>
        <Button
          as={Link}
          to={Path.toSolverMonitor(props.scheduleId)}
          className="mr-3"
        >
          Berechnungsverlauf
        </Button>
        {props.onDrilldownToggle &&
          <FormCheck
            type="switch"
            id="showDrilldownSwitch"
            label="Drilldown anzeigen"
            checked={props.drilldown}
            onChange={props.onDrilldownToggle}
          />
        }
      </div>
    </>
  );
}

import spacetime, { Spacetime } from "spacetime";
import { spaceSeparated, tz_utc } from "./Utils";
import { isBoolean, isNil, isString } from "lodash";
import { PersonListItemDto, ScheduleInputDto, ScheduleInputPersonDto, ShiftSelector1Dto } from "./Generated/BackendTypes";

const months = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember'
];

function parseDate(s: string) {
  return spacetime(s, tz_utc);
}

export function datesInInterval(startDate: Spacetime|string, endDate: Spacetime|string) {
  if(typeof startDate === 'string') {
    startDate = parseDate(startDate);
  }
  if(typeof endDate === 'string') {
    endDate = parseDate(endDate);
  }
  const between = startDate.every('day', endDate);
  return [startDate, ...between, endDate];
}

export interface DeriveScheduleCaptionParams {
  comment?: string;
  showYear?: boolean;
  showMonth?: boolean;
  showFullDate?: boolean;
}

export function getMiddleDate(startDate: Spacetime|string, endDate: Spacetime|string): Spacetime {
  if(typeof startDate === 'string') {
    startDate = parseDate(startDate);
  }
  if(typeof endDate === 'string') {
    endDate = parseDate(endDate);
  }
  return startDate.add(startDate.diff(endDate, 'day') / 2, 'day');
}

export function deriveScheduleCaption(startDate: Spacetime|string, endDate: Spacetime|string, opts?: DeriveScheduleCaptionParams) {
  if(typeof startDate === 'string') {
    startDate = parseDate(startDate);
  }
  if(typeof endDate === 'string') {
    endDate = parseDate(endDate);
  }
  const comment = isString(opts?.comment) && opts?.comment !== '' ? `- ${opts?.comment}` : undefined;
  if(opts?.showFullDate) {
    return spaceSeparated([
      startDate.format('{date-pad}.{iso-month}.') as string,
      '-',
      endDate.format('{date-pad}.{iso-month}.') as string,
      comment
    ])
  } else {
    const d = startDate.add(startDate.diff(endDate, 'day') / 2, 'day');
    const month = months[d.month()];
    const year = d.year().toString();

    const showMonth = isBoolean(opts?.showMonth) ? opts?.showMonth : true;
    const showYear = isBoolean(opts?.showYear) ? opts?.showYear : true;

    return spaceSeparated([
      showMonth ? month : undefined,
      showYear ? year : undefined,
      comment
    ]);
  }
}

export class Schedule {
  static getStartDate(schedule: ScheduleInputDto) {
    return parseDate(schedule.startDate);
  }

  static getEndDate(schedule: ScheduleInputDto) {
    return parseDate(schedule.endDate);
  }

  static getDates(schedule: ScheduleInputDto) {
    const start = this.getStartDate(schedule);
    const end = this.getEndDate(schedule);

    const between = start.every('day', end);
    return [start, ...between, end];
  }
}

export function pensumDescription(person: ScheduleInputPersonDto|PersonListItemDto) {
  switch(person.targetWorkload.mode) {
    case 'Pensum': return `${person.targetWorkload.pensum}%`;
    case 'ShiftCountRange':
      const shiftCountMin = person.targetWorkload.shiftCountMin;
      const shiftCountMax = person.targetWorkload.shiftCountMax;
      if(isNil(shiftCountMin) || shiftCountMin === 0) {
        if(isNil(shiftCountMax) || shiftCountMax === 0) {
          return `0`;
        } else {
          return `0 - ${shiftCountMax}`;
        }
      } else {
        if(isNil(shiftCountMax)) {
          return `${shiftCountMin} - `;
        } else {
          if(shiftCountMin === shiftCountMax) {
            return `${shiftCountMax}`;
          } else {
            return `${shiftCountMin} - ${shiftCountMax}`;
          }
        }
      }
  }
}

export class ShiftSelector {
  static matchAll(d: ShiftSelector1Dto) {
    return d.shiftIds.length === 0 && isNil(d.shiftKind);
  }
}

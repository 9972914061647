import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import { IconLoader2, IconAlertTriangle, IconCheck, IconSave } from '../icons';
import { usePolyglot } from '../Localization/Localization';

export interface SaveButtonProps extends ButtonProps {
  dirty: boolean;
  saving: boolean;
  error: boolean;
}

export function SaveButton({ dirty, saving, error, ...rest}: SaveButtonProps) {
  const pg = usePolyglot();
  if(saving) {
    return (
      <Button {...rest} disabled>
        <IconLoader2 className="loading-spinner" />&nbsp;{pg.t('general.save')}
      </Button>
    );
  } else if(error) {
    return (
      <Button {...rest}>
        <IconAlertTriangle />&nbsp;{pg.t('general.save')}
      </Button>
    );
  } else if(dirty) {
    return (
      <Button {...rest}>
        <IconSave />&nbsp;{pg.t('general.save')}
      </Button>
    );
  } else {
    return (
      <Button {...rest} disabled>
        <IconCheck />&nbsp;{pg.t('general.save')}
      </Button>
    );
  }
}

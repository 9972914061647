import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { memberof, oneof, Path } from '../Utils';
import { usePolyglot } from '../Localization/Localization';
import { ScheduleId, PersonId } from '../Types';
import { Formik, Form as FormikForm, FormikErrors } from 'formik';
import { FoboField } from '../Components/FoboField';
import { Dropdown, InputGroup } from 'react-bootstrap';
import { ConfirmationModalButton } from '../Components/ConfirmationModalButton';
import { Link, Prompt } from 'react-router-dom';
import { FormTable, FormTableAutoshow, FormTableCell, FormTableHeaderCell, FormTableHeaderRow, FormTableRow } from '../Components/FormTable';
import { TargetWorkloadMode0 } from '../Generated/BackendTypes';
import { useEventListener } from '../Hooks';
import { IconAlertTriangle, IconSave, IconUndo, IconEdit, IconMoreVertical, IconRepeat, IconPlus } from '../icons';

export interface PersonEntry {
  personId?: PersonId;
  name: string;
  targetWorkloadMode: TargetWorkloadMode0;
  pensum: string;
  shiftCountRange: string;
}

const defaultEntry: PersonEntry = {
  name: '',
  targetWorkloadMode: 'Pensum',
  pensum: '100',
  shiftCountRange: '15-20'
};

function PersonTableHeader() {
  const pg = usePolyglot();
  return (
    <FormTableHeaderRow>
      <FormTableHeaderCell>{pg.t('general.name')}</FormTableHeaderCell>
      <FormTableHeaderCell style={{width: '20%'}}>{pg.t('team.workloadMode')}</FormTableHeaderCell>
      <FormTableHeaderCell style={{width: '20%'}}></FormTableHeaderCell>
      <FormTableHeaderCell style={{width: 0}}></FormTableHeaderCell>
    </FormTableHeaderRow>
  );
}

interface PersonRowEditProps {
  person: PersonEntry;
  error: boolean;
  validate: (d: PersonEntry) => FormikErrors<PersonEntry>;
  onSave: (d: PersonEntry) => Promise<void>;
  onCancel: () => void;
}

function PersonRowEditor(props: PersonRowEditProps) {
  const pg = usePolyglot();
  useEventListener("keydown", e => {
    const event = e as KeyboardEvent;
    if(event.code === 'Escape') {
      props.onCancel();
    }
  });
  return (
    <Formik
      initialValues={props.person}
      validate={props.validate}
      onSubmit={props.onSave}
    >
      {({ isSubmitting, dirty, values }) => (
        <FormTableRow as={FormikForm}>
          {(props.person.personId === undefined || dirty) && <Prompt message={pg.t('general.unsavedChangesPrompt')} />}
          <FormTableCell editing relative>
            <FoboField name={memberof<PersonEntry>('name')} feedbackAsTooltip placeholder={pg.t('general.name')} />
          </FormTableCell>
          <FormTableCell editing relative>
            <FoboField as="select" custom name={memberof<PersonEntry>('targetWorkloadMode')}>
              <option value={oneof<TargetWorkloadMode0>('Pensum')}>{pg.t('team.workloadModePensum')}</option>
              <option value={oneof<TargetWorkloadMode0>('ShiftCountRange')}>{pg.t('team.workloadModeShiftCountRange')}</option>
            </FoboField>
          </FormTableCell>
          <FormTableCell editing relative>
            {values.targetWorkloadMode === 'Pensum' &&
              <InputGroup>
                <FoboField name={memberof<PersonEntry>('pensum')} feedbackAsTooltip />
                <InputGroup.Append>
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            }
            {values.targetWorkloadMode === 'ShiftCountRange' &&
              <FoboField name={memberof<PersonEntry>('shiftCountRange')} feedbackAsTooltip />
            }
          </FormTableCell>
          <FormTableCell editing relative>
            <div className="d-flex flex-row">
              <Button type="submit" variant="primary" disabled={isSubmitting}>
                {props.error ? <IconAlertTriangle /> : <IconSave />}
              </Button>
              <Button variant="outline-secondary" className="ml-3" onClick={props.onCancel} disabled={isSubmitting}>
                <IconUndo />
              </Button>
            </div>
          </FormTableCell>
        </FormTableRow>
      )}
    </Formik>
  );
}

interface PersonRowProps {
  person: PersonEntry;
  editingAnother?: boolean;
  highlighted: boolean;
  onEdit: () => void;
  onMouseDown: () => void;
  onDelete: () => void;
}

function PersonRow(props: PersonRowProps) {
  const pg = usePolyglot();
  const cellProps = {
    editingAnother: props.editingAnother,
    highlighted: props.highlighted
  };
  return (
    <FormTableRow
      onMouseDown={props.onMouseDown}
      onDoubleClick={props.onEdit}
    >
      <FormTableCell {...cellProps}>{props.person.name}</FormTableCell>
      <FormTableCell {...cellProps}>
        {props.person.targetWorkloadMode === 'Pensum' && pg.t('team.workloadModePensum')}
        {props.person.targetWorkloadMode === 'ShiftCountRange' && pg.t('team.workloadModeShiftCountRange')}
      </FormTableCell>
      <FormTableCell {...cellProps}>
        {props.person.targetWorkloadMode === 'Pensum' && `${props.person.pensum}%`}
        {props.person.targetWorkloadMode === 'ShiftCountRange' && props.person.shiftCountRange}
      </FormTableCell>
      <FormTableCell {...cellProps}>
        <FormTableAutoshow highlighted={props.highlighted} editingAnother={props.editingAnother} className="d-flex flex-row">
          <Button variant="link" onClick={props.onEdit} className="ml-3" disabled={props.editingAnother}>
            <IconEdit />
          </Button>
          {props.person.personId !== undefined &&
            <Dropdown>
              <Dropdown.Toggle variant="link" className="hide-dropdown-arrow" disabled={props.editingAnother}>
                <IconMoreVertical />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  as={ConfirmationModalButton}
                  variant="link"
                  className="text-danger"
                  onPositive={props.onDelete}
                  text={pg.t('team.confirmDeletePerson', { name: props.person.name })}
                >
                  {pg.t('general.remove')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          }
        </FormTableAutoshow>
      </FormTableCell>
    </FormTableRow>
  );
}

export interface PersonTableProps {
  scheduleId: ScheduleId;
  persons: PersonEntry[];
  saving: boolean;
  error: boolean;
  validate: (d: PersonEntry) => FormikErrors<PersonEntry>;
  onSave: (d: PersonEntry) => Promise<void>;
  onDelete: (personId: PersonId) => void;
}

export function PersonTable(props: PersonTableProps) {
  const pg = usePolyglot();
  const [editedPos, setEditedPos] = useState<number|undefined>(undefined);
  const [highlightedPos, setHighlightedPos] = useState<number|undefined>(undefined);
  const editing = editedPos !== undefined;

  async function handleSave(d: PersonEntry) {
    await props.onSave(d);
    setEditedPos(undefined);
    setHighlightedPos(undefined);
  }
  function handleCancel() {
    setEditedPos(undefined);
    setHighlightedPos(undefined);
  }
  function handleEdit(pos: number) {
    if(editedPos === undefined) {
      setEditedPos(pos);
      setHighlightedPos(undefined);
    }
  }
  function handleSelect(pos: number) {
    if(editedPos === undefined) {
      setHighlightedPos(pos);
    }
  }
  async function handleDelete(pos: number) {
    const personId = props.persons[pos].personId;
    if(personId) {
      props.onDelete(personId);
      setEditedPos(undefined);
      setHighlightedPos(undefined);
    }
  }

  return (
    <div>
      <Button
        variant="secondary"
        disabled={editing || props.saving}
        as={Link}
        to={Path.toPersonReorder(props.scheduleId)}
      >
        <IconRepeat transform="rotate(90)" /> {pg.t('general.changeOrder')}
      </Button>
      <FormTable className="mt-3">
        <PersonTableHeader />
        {props.persons.map((person, pos) => {
          if(pos === editedPos) {
            return (
              <PersonRowEditor
                key={person.personId || pos}
                person={person}
                error={props.error}
                validate={props.validate}
                onSave={handleSave}
                onCancel={handleCancel}
              />
            );
          } else {
            return (
              <PersonRow
                key={person.personId || pos}
                person={person}
                onEdit={() => handleEdit(pos)}
                onMouseDown={() => handleSelect(pos)}
                onDelete={() => handleDelete(pos)}
                editingAnother={editing}
                highlighted={highlightedPos === pos}
              />
            );
          }
        })}
        {editedPos === -1 &&
          <PersonRowEditor
            key={-1}
            error={props.error}
            person={defaultEntry}
            validate={props.validate}
            onSave={handleSave}
            onCancel={handleCancel}
          />
        }
      </FormTable>
      <Button className="ml-1 mt-3" disabled={editing || props.saving} onClick={() => handleEdit(-1)}>
        <IconPlus /> {pg.t('team.newPerson')}
      </Button>
    </div>
  )
}

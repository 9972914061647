import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import { Link, Prompt, useHistory } from 'react-router-dom';
import { useAuthFetch } from '../AuthService';
import { shiftFetchOrdering, shiftUpdateOrdering } from '../BackendService';
import { SaveButton } from '../Components/SaveButton';
import { ContainerLeft } from '../Components/UtilityComponents';
import { ShiftOrderingReadItemDto } from '../Generated/BackendTypes';
import { IconArrowLeft, IconMenu } from '../icons';
import { usePolyglot } from '../Localization/Localization';
import { ScheduleId } from '../Types';
import { Path } from '../Utils';

const droppableId = 'shiftReorder';

export interface ShiftReorderProps {
  scheduleId: ScheduleId;
}

export function ShiftReorder(props: ShiftReorderProps) {
  const pg = usePolyglot();
  const authFetch = useAuthFetch();
  const scheduleId = props.scheduleId;
  const history = useHistory();
  const [initialShifts, setInitialShifts] = useState<ShiftOrderingReadItemDto[]>([]);
  const [shifts, setShifts] = useState<ShiftOrderingReadItemDto[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [dragging, setDragging] = useState(false);

  const dirty = !isEqual(initialShifts.map(s => s.shiftId), shifts.map(s => s.shiftId));

  useEffect(() => {
    setLoading(true);
    setError(false);
    shiftFetchOrdering(authFetch, scheduleId)
    .then(response => {
      setInitialShifts(response.shifts);
      setShifts(response.shifts);
    })
    .catch(error => {
      console.error(error);
      setError(true);
    })
    .finally(() => setLoading(false));
  }, [authFetch, scheduleId]);

  async function handleSave() {
    setLoading(true);
    shiftUpdateOrdering(authFetch, scheduleId, shifts.map(s => s.shiftId))
    .then(response => {
      setInitialShifts(response.shifts);
      setShifts(response.shifts);
      history.push(Path.toUnspecificShift(scheduleId));
    })
    .catch(error => {
      console.error(error);
      setError(true);
    })
    .finally(() => setLoading(false));
  }

  function handleDragUpdate() {
    setDragging(true);
  }

  function handleDragEnd({ destination, source, reason }: DropResult) {
    setDragging(false);
    if(destination?.droppableId === droppableId && source.droppableId === droppableId) {
      if(destination.index !== source.index) {
        const updated = [...shifts];
        updated.splice(source.index, 1);
        updated.splice(destination.index, 0, shifts[source.index]);
        setShifts(updated);
      }
    }
  }

  return (
    <ContainerLeft>
      <h1 className="pt-3 mb-4">{pg.t('general.shifts')} &ndash; {pg.t('general.changeOrder')}</h1>
      {!dirty &&
        <Button variant="secondary" as={Link} to={Path.toUnspecificShift(scheduleId)}>
          <IconArrowLeft /> {pg.t('general.back')}
        </Button>
      }
      {dirty &&
        <>
          <SaveButton dirty={dirty} saving={loading} error={error} onClick={handleSave} />
          <Button variant="secondary" className="ml-3" as={Link} to={Path.toUnspecificShift(scheduleId)}>
            {pg.t('general.cancel')}
          </Button>
        </>
      }
      {(dragging || dirty) && <Prompt message={pg.t('general.unsavedChangesPrompt')} />}
      <DragDropContext onDragUpdate={handleDragUpdate} onDragEnd={handleDragEnd}>
        <Droppable droppableId={droppableId}>
          {(provided) =>
            <ListGroup {...provided.droppableProps} ref={provided.innerRef} className="mt-3">
              {shifts.map((shift, index) => (
                <Draggable key={shift.shiftId} draggableId={shift.shiftId} index={index}>
                  {(provided) =>
                    <ListGroupItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <IconMenu /><span className="pl-3">{shift.name} {shift.label}</span>
                    </ListGroupItem>
                  }
                </Draggable>
              ))}
              {provided.placeholder}
            </ListGroup>
          }
        </Droppable>
      </DragDropContext>
    </ContainerLeft>
  );
}

import { Spacetime } from "spacetime/types/types";
import spacetime from "spacetime";
import { isNil, cloneDeep, PropertyPath, setWith, map, isString, isEmpty, sumBy, compact } from "lodash";
import { TeamId } from "./Types";

export const memberof = <T>(name: keyof T) => name;
export const oneof = <T>(value: T) => value;

export const ensureOneOf = <T>(allowed: readonly any[], value: any, defaultValue: T) => allowed.includes(value) ? value as T : defaultValue;

export const mapNilUndefined = <T>(x: T|null|undefined) => isNil(x) ? undefined : x;

//export const formatMinutes = (minutes: number) => `${Math.floor(minutes/60)}h${(minutes%60)}m`;
export const formatMinutes = (minutes: number) => (minutes/60.0).toFixed(1);
export function minutesToDayHourMinute(totalMinutes: number) {
  const minutes = totalMinutes % 60;
  let hours = Math.floor(totalMinutes / 60);
  const days = Math.floor(hours/24);

  if(days > 0) {
    hours = hours % 24;
    return `${days}d ${hours}h ${minutes}m`;
  } else if(hours > 0) {
    return `${hours}h ${minutes}m`;
  } else {
    return `${minutes}m`;
  }
}

export const tz_utc = 'UTC';
export function spacetimeFromDateOnly(d: Date): Spacetime {
  return spacetime([d.getFullYear(), d.getMonth(), d.getDate()]);
}

export function getDatesBetween(startDate: Spacetime|string, endDate: Spacetime|string): Spacetime[] {
  startDate = isString(startDate) ? spacetime(startDate, tz_utc) : startDate;
  endDate = isString(endDate) ? spacetime(endDate, tz_utc) : endDate;
  const between = startDate.every('day', endDate);
  return [startDate, ...between, endDate];
}

export function isWorkDay(date: Spacetime) {
  const dow = date.day();
  // not sunday and not saturday
  return (dow !== 0 && dow !== 6);
}

export function getNumberOfWorkDays(dates: Spacetime[]) {
  return sumBy(dates, date => isWorkDay(date) ? 1 : 0);
}

export function pureSetObject<T extends object>(o: T, p: PropertyPath, v: any): T {
  return setWith(cloneDeep(o), p, v, Object);
}

export function commaSeparated(s: string[]): string {
  if(s.length === 0) {
    return '';
  }
  return s.reduce((a, b) => `${a}, ${b}`);
}

export function spaceSeparated(s: Array<string|undefined>): string {
  const t = compact(s);
  if(t.length === 0) {
    return '';
  }
  return t.reduce((a, b) => `${a} ${b}`);
}

const lazyEmailAddressRegex = /^(\S+)@(\S+)\.(\S+)$/;
export function resemblesEmail(s: string): boolean {
  if(s.match(lazyEmailAddressRegex)) {
    return true;
  } else {
    return false;
  }
}

export const base64toBlob = (base64: string, mimetype: string = 'application/octet-stream') =>
  fetch(`data:${mimetype};base64,${base64}`).then(res => res.blob())

export const lookup = <K extends string|number|symbol, V>(o: Record<K,V>) => (k: K): V => o[k];

export const isNotNil = <T>(x: T): x is NonNullable<T> => !isNil(x) ;
export const mapi = <T,U>(f: ((t: T, i: number) => U)) => (values: T[]) => map(values, f);
export const isNotEmpty = (x: any) => !isEmpty(x);
export const createNaturalSequence = (length: number) => Array.from({ length }, (_, i)=> i);

export class Path {
  static default = '/';
  static matchDemoConditions = '/demo/conditions';

  static matchAccountProfile = '/account/profile';
  static toAccountProfile = Path.matchAccountProfile;
  static matchAccountOrganization = '/account/organization';
  static toAccountOrganization = Path.matchAccountOrganization;
  static matchAccountUsage = '/account/usage';
  static toAccountUsage = Path.matchAccountUsage;
  static matchAccountEditInvoiceAddress = '/account/usage/invoice-address';
  static toAccountEditInvoiceAddress = Path.matchAccountEditInvoiceAddress;
  static matchAccountTopUp = '/account/topup';
  static toAccountTopUp = Path.matchAccountTopUp;
  static matchAccountTopUpSuccess = '/account/topup/success';
  static toAccountTopUpSuccess = Path.matchAccountTopUpSuccess;

  static matchScheduleChooser = '/schedule';
  static toScheduleChooser = '/schedule';

  static matchNewSchedule = '/team/:teamId/create-schedule';
  static toNewSchedule = (teamId: TeamId) => `/team/${teamId}/create-schedule`;

  static matchNewScheduleFromExample = '/team/:teamId/create-example';
  static toNewScheduleFromExample = (teamId: TeamId) => `/team/${teamId}/create-example`;

  static matchSchedule = '/schedule/:scheduleId';

  static matchAvailabilityTable = '/schedule/:scheduleId/availability';
  static toAvailabilityTable = (scheduleId: string) => `/schedule/${scheduleId}/availability`;

  static matchTeam = '/schedule/:scheduleId/team';
  static toTeam = (scheduleId: string) => `/schedule/${scheduleId}/team`;

  static matchPersonReorder = '/schedule/:scheduleId/person/reorder';
  static toPersonReorder = (scheduleId: string) => `/schedule/${scheduleId}/person/reorder`;

  static matchPersonGroupReorder = '/schedule/:scheduleId/team/personGroup/reorder';
  static toPersonGroupReorder = (scheduleId: string) => `/schedule/${scheduleId}/team/personGroup/reorder`;

  static matchUnspecificShift = '/schedule/:scheduleId/shift';
  static toUnspecificShift = (scheduleId: string) => `/schedule/${scheduleId}/shift`;
  static matchShiftReorder = '/schedule/:scheduleId/shift/reorder';
  static toShiftReorder = (scheduleId: string) => `/schedule/${scheduleId}/shift/reorder`;

  static matchUnspecificCondition = '/schedule/:scheduleId/condition';
  static toUnspecificCondition = (scheduleId: string) => `/schedule/${scheduleId}/condition`;
  static matchNewCondition = '/schedule/:scheduleId/condition/create';
  static toNewCondition = (scheduleId: string, type: string) => `/schedule/${scheduleId}/condition/create?type=${type}`;
  static matchSpecificCondition = '/schedule/:scheduleId/condition/:conditionPos';
  static toSpecificCondition = (scheduleId: string, conditionPos: number) => `/schedule/${scheduleId}/condition/${conditionPos}`;

  static matchUnspecificStaffDemand = '/schedule/:scheduleId/demand';
  static toUnspecificStaffDemand = (scheduleId: string) => `/schedule/${scheduleId}/demand`;
  static matchNewStaffDemand = '/schedule/:scheduleId/demand/create';
  static toNewStaffDemand = (scheduleId: string) => `/schedule/${scheduleId}/demand/create`;
  static matchSpecificStaffDemand = '/schedule/:scheduleId/demand/:staffDemandIdx';
  static toSpecificStaffDemand = (scheduleId: string, staffDemandIdx: number) => `/schedule/${scheduleId}/demand/${staffDemandIdx}`;

  static matchScheduleImport = '/schedule/:scheduleId/import';
  static toScheduleImport = (scheduleId: string) => `/schedule/${scheduleId}/import`;
  static matchScheduleConfig = '/schedule/:scheduleId/config';
  static toScheduleConfig = (scheduleId: string) => `/schedule/${scheduleId}/config`;
  static matchComputation = '/schedule/:scheduleId/computation';
  static toComputation = (scheduleId: string) => `/schedule/${scheduleId}/computation`;
  static toComputationStart = (scheduleId: string) => `/schedule/${scheduleId}/computation?start`;
  static matchSolverMonitor = '/schedule/:scheduleId/computation/monitor/:action?';
  static toSolverMonitor = (scheduleId: string) => `/schedule/${scheduleId}/computation/monitor`;
  static toSolverMonitorLive = (scheduleId: string) => `/schedule/${scheduleId}/computation/monitor/live`;

  static matchTenantSwitcher = '/siteadm/tenant-switcher';
  static toTenantSwitcher = Path.matchTenantSwitcher;
  static matchDataTransferScheduleImport = '/transfer/schedule';
  static toDataTransferScheduleImport = Path.matchDataTransferScheduleImport;

  // Testing
  static matchTestComputationCheckout = (testCase: string) => `/test/computation/checkout/${testCase}`;
  static toTestComputationCheckout = '/test/computation/checkout/:testCase'
}

export class StorageKey {
  static lastUsedTeamId = 'lastUsedTeamId';
  static mainMenuExpanded = 'MainMenu:expanded';
  static personListDetails = 'PersonList:details';
  static conditionListDetails = 'ConditionList:details';
  static availabilityTableShowChooser = 'AvailabilityTable:showChooser';
  static computationShowDrilldown = 'Computation:ShowDrilldown';
  static scheduleListShowFullDate = 'ScheduleList:ShowFullDate';
  static accountTopUpData = 'AccountTopUp:Data';
  static showInfo = 'showInfo';
}

export class ExternalLinks {
  static baseUrl = 'https://taso.app';
  static toDocs = `${ExternalLinks.baseUrl}/app-link/docs`;
  static toUsageDocs = `${ExternalLinks.baseUrl}/app-link/usage`;
  static toTeamDocs = `${ExternalLinks.baseUrl}/app-link/team`;
  static toShiftDocs = `${ExternalLinks.baseUrl}/app-link/shift`;
  static toDemandDocs = `${ExternalLinks.baseUrl}/app-link/demand`;
  static toAvailabilityDocs = `${ExternalLinks.baseUrl}/app-link/availability`;
  static toConditionDocs = `${ExternalLinks.baseUrl}/app-link/condition`;
  static toComputationDocs = `${ExternalLinks.baseUrl}/app-link/computation`;
}

export function getLocalStorageValue<T>(key: string, defaultValue: T) {
  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(key);
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : defaultValue;
  } catch (error) {
    // If error also return initialValue
    console.log(error);
    return defaultValue;
  }
}

export function setLocalStorageValue<T>(key: string, value: T) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.log(error);
  }
}

import React, { useState } from 'react';
import { Alert, Button, Table } from 'react-bootstrap';
import { ConfirmationModalButton } from '../Components/ConfirmationModalButton';
import { TeamDto } from '../Generated/BackendTypes';
import { IconEdit2, IconX } from '../icons';
import { usePolyglot } from '../Localization/Localization';
import { TeamId } from '../Types';
import { useUserProfile } from '../UserProfileContext';
import { TeamEditorModal } from './TeamEditorModal';

export interface TeamManagementProps {
  teams: TeamDto[];
  loading: boolean;
  error?: string;
  onDeleteTeam: (teamId: TeamId) => Promise<void>;
  onUpdateTeam: (teamId: TeamId, name: string) => Promise<void>;
  onCreateTeam: (name: string) => Promise<void>;
}

export function TeamManagement(props: TeamManagementProps) {
  const pg = usePolyglot();
  const userProfile = useUserProfile();
  const [showModal, setShowModal] = useState(false);
  const [teamForEditing, setTeamForEditing] = useState<TeamDto|undefined>(undefined);

  function handleModalOpenForCreate() {
    setTeamForEditing(undefined);
    setShowModal(true);
  }

  function handleModalOpenForEdit(team: TeamDto) {
    setTeamForEditing(team);
    setShowModal(true);
  }

  function handleModalCancel() {
    setShowModal(false);
  }

  function handleModalSubmit(name: string) {
    setShowModal(false);
    if(teamForEditing) {
      return props.onUpdateTeam(teamForEditing.teamId, name);
    } else {
      return props.onCreateTeam(name);
    }
  }

  return (
    <>
      <h1 className="mt-5">{pg.t('general.teams')}</h1>
      {userProfile.isAdmin &&
        <Button onClick={handleModalOpenForCreate} className="my-3">{pg.t('general.add')}</Button>
      }
      <TeamEditorModal
        show={showModal}
        name={teamForEditing?.name}
        onCancel={handleModalCancel}
        onSubmit={handleModalSubmit}
      />
      {props.error &&
        <Alert variant='danger'>
          {pg.t('general.errorMessageIntro')}: {props.error}
        </Alert>
      }
      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={{ width: '100%' }}>{pg.t('general.name')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.teams.map(team =>
            <tr key={team.teamId}>
              <td>{team.name}</td>
              <td className="py-2 text-right text-nowrap">
                {userProfile.isAdmin &&
                  <>
                    <Button
                      variant="outline-primary"
                      title={pg.t('general.edit')}
                      onClick={() => handleModalOpenForEdit(team)}
                      className="mr-3"
                      size="sm"
                    >
                      <IconEdit2 />
                    </Button>
                    <ConfirmationModalButton
                      variant="outline-danger"
                      type="button"
                      size="sm"
                      disabled={team.numSchedules > 0 || props.loading}
                      title={team.numSchedules > 0 ?
                        pg.t('account.hintDeleteTeamNotPossible', { numSchedules: team.numSchedules }) :
                        pg.t('account.hintDeleteTeam')}
                      onPositive={() => props.onDeleteTeam(team.teamId)}
                      text={pg.t('account.confirmDeleteTeam', { name: team.name })}
                    >
                      <IconX />
                    </ConfirmationModalButton>
                  </>
                }
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  )
}

import React from 'react';
import { usePolyglot } from '../Localization/Localization';
import { ContainerLeft } from '../Components/UtilityComponents';
import { TeamManagementContainer } from './TeamManagementContainer';
import { MemberManagementContainer } from './MemberManagementContainer';
import { TenantSettingsContainer } from './TenantSettingsContainer';
import { Title } from '../TopBar/Title';

export function AccountOrganization() {
  const pg = usePolyglot();
  return (
    <ContainerLeft>
      <Title>{pg.t('general.organization')}</Title>
      <TeamManagementContainer />
      <MemberManagementContainer />
      <TenantSettingsContainer />
    </ContainerLeft>
  );
}

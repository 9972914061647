import { FormikErrors } from 'formik';
import { defaultTo, isNumber } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuthFetch } from '../AuthService';
import { personCreate, personDelete, personFetchList, personUpdate } from '../BackendService';
import { LoadingSpinner } from '../Components/UtilityComponents';
import { PersonListItemDto, PersonListDto, PersonUpsertDto } from '../Generated/BackendTypes';
import { usePolyglot } from '../Localization/Localization';
import { PersonId, ScheduleId } from '../Types';
import { parseMinMaxCount } from '../Utils/range';
import { PersonEntry, PersonTable } from './PersonTable';

function fromPersonListItemDto(d: PersonListItemDto): PersonEntry {
  return {
    personId: d.personId,
    name: d.name,
    targetWorkloadMode: d.targetWorkload.mode,
    pensum: (isNumber(d.targetWorkload.pensum) ? d.targetWorkload.pensum.toString() : '100'),
    shiftCountRange: `${defaultTo(d.targetWorkload.shiftCountMin, 0)}-${defaultTo(d.targetWorkload.shiftCountMax, 5)}`
  };
}

function toPersonUpsertDto(d: PersonEntry, documentRevision: number): PersonUpsertDto {
  const range = parseMinMaxCount(d.shiftCountRange);
  return {
    documentRevision,
    personId: d.personId,
    name: d.name,
    targetWorkload: {
      mode: d.targetWorkloadMode,
      pensum: parseInt(d.pensum, 10),
      shiftCountMin: range?.minCount,
      shiftCountMax: range?.maxCount
    }
  };
}

export interface PersonTableContainerProps {
  scheduleId: ScheduleId;
}

export function PersonTableContainer(props: PersonTableContainerProps) {
  const authFetch = useAuthFetch();
  const pg = usePolyglot();
  const [persons, setPersons] = useState<PersonEntry[]>([]);
  const [rev, setRev] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);
  const scheduleId = props.scheduleId;

  const processResponse = useCallback((response: PersonListDto) => {
    setPersons(response.persons.map(fromPersonListItemDto));
    setRev(response.documentRevision);
  }, []);

  useEffect(() => {
    setLoading(true);
    setError(false);
    personFetchList(authFetch, scheduleId)
    .then(processResponse)
    .catch(error => {
      console.error(error);
      setError(true);
    })
    .finally(() => setLoading(false));
  }, [authFetch, processResponse, scheduleId]);

  function validate(d: PersonEntry) {
    const errors: FormikErrors<PersonEntry> = {};
    if (!d.name) {
      errors.name = pg.t('validation.notEmpty');
    } else if(d.name.trim().length === 0) {
      errors.name = pg.t('validation.notEmpty');
    } else if(d.name.trim().length > 64) {
      errors.name = pg.t('validation.notLongerThanNCharacters', { number: 64 });
    }

    if(d.targetWorkloadMode === 'Pensum') {
      if (!d.pensum) {
        errors.pensum = pg.t('validation.notEmpty');
      } else if(d.pensum.trim().length === 0) {
        errors.pensum = pg.t('validation.notEmpty');
      } else {
        const value = parseInt(d.pensum, 10);
        if(!isFinite(value)) {
          errors.pensum = pg.t('validation.positiveInteger');
        } else if(value < 0) {
          errors.pensum = pg.t('validation.notNegative');
        } else if(value > 100) {
          errors.pensum = pg.t('validation.notLargerThan', { number: 100 });
        }
      }
    } else {
      if(!d.shiftCountRange) {
        errors.shiftCountRange = pg.t('validation.notEmpty');
      } else if(d.shiftCountRange.trim().length === 0) {
        errors.shiftCountRange = pg.t('validation.notEmpty');
      } else if(parseMinMaxCount(d.shiftCountRange) === undefined) {
        errors.shiftCountRange = pg.t('validation.mustBePositiveIntegerValueOrRange');
      }
    }

    return errors;
  }

  async function handleSave(d: PersonEntry) {
    setSaving(true);
    setError(false);
    try {
      let response;
      if(d.personId === undefined) {
        response = await personCreate(authFetch, props.scheduleId, toPersonUpsertDto(d, rev));
      } else {
        response = await personUpdate(authFetch, props.scheduleId, d.personId, toPersonUpsertDto(d, rev));
      }
      processResponse(response);
    } catch(error) {
      console.error(error);
      setError(true);
      throw error;
    }
    setSaving(false);
  }

  async function handleDelete(personId: PersonId) {
    setSaving(true);
    setError(false);
    try {
      const response = await personDelete(authFetch, props.scheduleId, personId);
      processResponse(response);
    } catch(error) {
      console.error(error);
      setError(true);
    }
    setSaving(false);
  }

  if(loading) {
    return (
      <LoadingSpinner />
    )
  } else {
    return (
      <PersonTable
        scheduleId={scheduleId}
        persons={persons}
        saving={saving}
        error={error}
        validate={validate}
        onSave={handleSave}
        onDelete={handleDelete}
      />
    )
  }
}

import React, {  } from 'react';
import ReactDataSheet from 'react-datasheet';
import { toString } from 'lodash';
import { StaffDemandCellViewData } from './StaffDemandTable';
import { CellEditor } from '../Table/CellEditor';
import { minMaxPattern } from '../Utils/range';

function highlight(value: string): React.ReactElement {
  const matches = value.match(minMaxPattern);
  if(!matches) {
    return (
      <span style={{color: 'red'}}>{value}</span>
    );
  } else {
    if(matches.length === 3) {
      // min is bigger than max is not allowed
      if(parseInt(matches[1], 10) > parseInt(matches[2], 10)) {
        return <span style={{color: 'red'}}>{value}</span>;
      }
    }
    return (
      <span>{value}</span>
    )
  }
}



export function StaffDemandCellEditor(props: ReactDataSheet.DataEditorProps<StaffDemandCellViewData>) {
  const value = toString(props.value);
  return (
    <CellEditor
      {...props}
      enhancedValue={highlight(value)}
    />
  )
}

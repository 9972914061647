import React, { createContext, useContext, useState } from "react";

export interface GlobalState {
  title: string;
  setTitle: (title: string) => void;
}

const emptyGlobalState: GlobalState = {
  title: '',
  setTitle: () => {}
}

export const GlobalStateContext = createContext(emptyGlobalState);

export function useGlobalState() {
  return useContext(GlobalStateContext);
}

export function GlobalStateProvider(props: React.PropsWithChildren<{}>) {
  function setTitle(title: string) {
    setGlobalState(globalState => ({ ...globalState, title: title }));
  }

  const [globalState, setGlobalState] = useState<GlobalState>({
    title: '',
    setTitle
  });

  return (
    <GlobalStateContext.Provider value={globalState}>
      {props.children}
    </GlobalStateContext.Provider>
  );
}

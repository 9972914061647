import { mapValues } from "lodash";
import { PersonGroupId, PersonId, ShiftId } from "../Types";

export interface NamesLookup {
  shifts: Record<ShiftId, string>;
  persons: Record<PersonId, string>;
  personGroups: Record<PersonGroupId, string>;
}

interface NamesLookupInput {
  shifts: Record<ShiftId, { name: string }>;
  personGroups: Record<PersonGroupId, { name: string }>;
  persons: Record<PersonId, { name: string }>;
}

export function toNamesLookup(d: NamesLookupInput) {
  return {
    shifts: mapValues(d.shifts, s => s.name),
    personGroups: mapValues(d.personGroups, s => s.name),
    persons: mapValues(d.persons, s => s.name),
  }
}

import React, { useEffect, useState } from 'react';
import { TenantInviteListItemDto, TenantMemberListItemDto } from '../Generated/BackendTypes';
import { useAuthFetch } from '../AuthService';
import { tenantCreateInvite, tenantDeleteInvite, tenantListMembersAndInvites, tenantRemoveMember, tenantUpdateMember } from '../BackendService';
import { MemberManagement } from './MemberManagement';
import { UserId } from '../Types';

export function MemberManagementContainer() {
  const authFetch = useAuthFetch();
  const [members, setMembers] = useState<TenantMemberListItemDto[]>([]);
  const [invites, setInvites] = useState<TenantInviteListItemDto[]>([]);
  const [error, setError] = useState<string|undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    tenantListMembersAndInvites(authFetch)
    .then(d => {
      setMembers(d.members);
      setInvites(d.invites);
    })
    .catch(e => setError(e.message))
    .finally(() => setLoading(false));
  }, [authFetch]);

  async function handleRemoveMember(userId: UserId) {
    setLoading(true);
    try {
      const d = await tenantRemoveMember(authFetch, userId);
      setMembers(d.members);
    } catch(error) {
      setError(error.message);
    }
    setLoading(false);
  }

  async function handleUpdateMember(userId: UserId, isAdmin: boolean) {
    setLoading(true);
    try {
      const d = await tenantUpdateMember(authFetch, userId, isAdmin);
      setMembers(d.members);
    } catch(error) {
      setError(error.message);
    }
    setLoading(false);
  }

  async function handleCreateInvite(name: string, emailAddress: string) {
    setLoading(true);
    try {
      const d = await tenantCreateInvite(authFetch, name, emailAddress);
      setInvites(d.invites);
    } catch(error) {
      setError(error.message);
    }
    setLoading(false);
  }

  async function handleDeleteInvite(emailAddress: string) {
    setLoading(true);
    try {
      const d = await tenantDeleteInvite(authFetch, emailAddress);
      setInvites(d.invites);
    } catch(error) {
      setError(error.message);
    }
    setLoading(false);
  }

  const checkInviteAlreadyExists = (emailAddress: string) =>
    invites.findIndex(m => m.emailAddress === emailAddress) !== -1;

  const checkMemberAlreadyAdded = (emailAddress: string) =>
    members.findIndex(m => m.emailAddress === emailAddress) !== -1;

  return (
    <MemberManagement
      members={members}
      invites={invites}
      error={error}
      loading={loading}
      onRemoveMember={handleRemoveMember}
      onUpdateMember={handleUpdateMember}
      onCreateInvite={handleCreateInvite}
      onDeleteInvite={handleDeleteInvite}
      inviteAlreadyExist={checkInviteAlreadyExists}
      memberAlreadyAdded={checkMemberAlreadyAdded}
    />
  )
}

import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { memberof, minutesToDayHourMinute, oneof, Path } from '../Utils';
import { usePolyglot } from '../Localization/Localization';
import { ScheduleId, ShiftId } from '../Types';
import { ErrorDto, ShiftKind0 } from '../Generated/BackendTypes';
import { Formik, Form as FormikForm, FormikErrors } from 'formik';
import { FoboField } from '../Components/FoboField';
import { FoboCheck } from '../Components/FoboCheck';
import { Dropdown, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ConfirmationModalButton } from '../Components/ConfirmationModalButton';
import { Link, Prompt } from 'react-router-dom';
import { FormTable, FormTableAutoshow, FormTableCell, FormTableHeaderCell, FormTableHeaderRow, FormTableRow } from '../Components/FormTable';
import { useEventListener } from '../Hooks';
import { ErrorPopup } from '../Components/ErrorPopup';
import { IconAlertTriangle, IconAsterisk, IconEdit, IconHelpCircle, IconMoreVertical, IconPlus, IconRepeat, IconSave, IconUndo } from '../icons';

export interface ShiftEntry {
  shiftId?: ShiftId;
  name: string;
  label: string;
  shiftKind: ShiftKind0;
  duration: number;
  durationScaledByPensum: boolean;
}

const defaultShiftEntry: ShiftEntry = {
  name: '',
  label: '',
  shiftKind: 'Work',
  duration: 480,
  durationScaledByPensum: false
};

function ShiftTableHeader() {
  const pg = usePolyglot();
  return (
    <FormTableHeaderRow>
      <FormTableHeaderCell style={{width: '40%'}}>{pg.t('general.name')}</FormTableHeaderCell>
      <FormTableHeaderCell style={{width: '15%'}}>{pg.t('shift.label')}</FormTableHeaderCell>
      <FormTableHeaderCell style={{width: '15%'}}>{pg.t('shift.duration')}</FormTableHeaderCell>
      <FormTableHeaderCell style={{width: '20%'}}>{pg.t('shift.kind')}</FormTableHeaderCell>
      <FormTableHeaderCell center style={{width: '15%'}}>
        {pg.t('shift.scaled')}
        <OverlayTrigger overlay={<Tooltip id={`scaledTooltip`}>{pg.t('shift.scaledExplanation')}</Tooltip>}>
          <IconHelpCircle className="p-1" />
        </OverlayTrigger>
      </FormTableHeaderCell>
      <FormTableHeaderCell style={{width: 0}}></FormTableHeaderCell>
    </FormTableHeaderRow>
  );
}

interface ShiftRowEditProps {
  shift: ShiftEntry;
  error: boolean;
  validate: (d: ShiftEntry) => FormikErrors<ShiftEntry>;
  onSave: (d: ShiftEntry) => Promise<void>;
  onCancel: () => void;
}

function ShiftRowEditor(props: ShiftRowEditProps) {
  const pg = usePolyglot();
  useEventListener("keydown", e => {
    const event = e as KeyboardEvent;
    if(event.code === 'Escape') {
      props.onCancel();
    }
  });
  return (
    <Formik
      initialValues={props.shift}
      validate={props.validate}
      onSubmit={props.onSave}
    >
      {({ isSubmitting, dirty }) => (
        <FormTableRow as={FormikForm}>
          {(props.shift.shiftId === undefined || dirty) && <Prompt message={pg.t('general.unsavedChangesPrompt')} />}
          <FormTableCell editing relative>
            <FoboField name={memberof<ShiftEntry>('name')} feedbackAsTooltip placeholder={pg.t('general.name')} />
          </FormTableCell>
          <FormTableCell editing relative>
            <FoboField name={memberof<ShiftEntry>('label')} feedbackAsTooltip placeholder={pg.t('shift.label')} />
          </FormTableCell>
          <FormTableCell editing relative>
            <InputGroup>
              <FoboField name={memberof<ShiftEntry>('duration')} feedbackAsTooltip placeholder={pg.t('shift.duration')} />
              <InputGroup.Append>
                <InputGroup.Text>min</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </FormTableCell>
          <FormTableCell editing relative>
            <FoboField as="select" custom name={memberof<ShiftEntry>('shiftKind')}>
              <option value={oneof<ShiftKind0>('Work')}>{pg.t('shift.kindWork')}</option>
              <option value={oneof<ShiftKind0>('Rest')}>{pg.t('shift.kindRest')}</option>
            </FoboField>
          </FormTableCell>
          <FormTableCell editing relative center>
            <FoboCheck name={memberof<ShiftEntry>('durationScaledByPensum')} />
          </FormTableCell>
          <FormTableCell editing relative>
            <div className="d-flex flex-row">
              <Button type="submit" variant="primary" disabled={isSubmitting}>
                {props.error ? <IconAlertTriangle /> : <IconSave />}
              </Button>
              <Button variant="outline-secondary" className="ml-3" onClick={props.onCancel} disabled={isSubmitting}>
                <IconUndo />
              </Button>
            </div>
          </FormTableCell>
        </FormTableRow>
      )}
    </Formik>
  );
}

interface ShiftRowProps {
  shift: ShiftEntry;
  isDefaultShift?: boolean;
  editingAnother?: boolean;
  highlighted: boolean;
  onEdit: () => void;
  onMouseDown: () => void;
  onDelete: () => void;
  onSetDefault: () => void;
}

function ShiftRow(props: ShiftRowProps) {
  const pg = usePolyglot();
  const cellProps = {
    editingAnother: props.editingAnother,
    highlighted: props.highlighted
  };
  return (
    <FormTableRow
      onMouseDown={props.onMouseDown}
      onDoubleClick={props.onEdit}
    >
      <FormTableCell {...cellProps}>
        {props.shift.name}
        {props.isDefaultShift &&
          <OverlayTrigger overlay={<Tooltip id={`defaultShiftTooltip`}>{pg.t('shift.defaultShift')}</Tooltip>}>
            <IconAsterisk className="p-1" />
          </OverlayTrigger>
        }
      </FormTableCell>
      <FormTableCell {...cellProps}>{props.shift.label}</FormTableCell>
      <FormTableCell {...cellProps}>{minutesToDayHourMinute(props.shift.duration)}</FormTableCell>
      <FormTableCell {...cellProps}>{props.shift.shiftKind === 'Work' ? pg.t('shift.kindWork') : pg.t('shift.kindRest')}</FormTableCell>
      <FormTableCell {...cellProps} center>{props.shift.durationScaledByPensum ? pg.t('general.yes') : ''}</FormTableCell>
      <FormTableCell {...cellProps}>
        <FormTableAutoshow highlighted={props.highlighted} editingAnother={props.editingAnother} className="d-flex flex-row">
          <Button variant="link" onClick={props.onEdit} className="ml-3" disabled={props.editingAnother}>
            <IconEdit />
          </Button>

          {props.shift.shiftId !== undefined &&
            <Dropdown>
              <Dropdown.Toggle variant="link" className="hide-dropdown-arrow" disabled={props.editingAnother}>
                <IconMoreVertical />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  as={ConfirmationModalButton}
                  disabled={props.isDefaultShift}
                  variant="link"
                  onPositive={props.onSetDefault}
                  text={pg.t('shift.confirmSetDefault', { shiftName: props.shift.name })}
                >
                  {pg.t('shift.setDefault')}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  as={ConfirmationModalButton}
                  variant="link"
                  className="text-danger"
                  onPositive={props.onDelete}
                  text={pg.t('shift.confirmDelete', { shiftName: props.shift.name })}
                >
                  {pg.t('general.remove')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          }
        </FormTableAutoshow>
      </FormTableCell>
    </FormTableRow>
  );
}

export interface ShiftTableProps {
  scheduleId: ScheduleId;
  shifts: ShiftEntry[];
  defaultShiftId?: ShiftId;
  saving: boolean;
  error?: ErrorDto;
  validate: (d: ShiftEntry) => FormikErrors<ShiftEntry>;
  onSave: (d: ShiftEntry) => Promise<void>;
  onDelete: (shiftId: ShiftId) => void;
  onSetDefault: (shiftId: ShiftId) => void;
}

export function ShiftTable(props: ShiftTableProps) {
  const pg = usePolyglot();
  const [editedPos, setEditedPos] = useState<number|undefined>(undefined);
  const [highlightedPos, setHighlightedPos] = useState<number|undefined>(undefined);
  const editing = editedPos !== undefined;

  async function handleSave(d: ShiftEntry) {
    await props.onSave(d);
    setEditedPos(undefined);
    setHighlightedPos(undefined);
  }
  function handleCancel() {
    setEditedPos(undefined);
    setHighlightedPos(undefined);
  }
  function handleEdit(pos: number) {
    if(editedPos === undefined) {
      setEditedPos(pos);
      setHighlightedPos(undefined);
    }
  }
  function handleSelect(pos: number) {
    if(editedPos === undefined) {
      setHighlightedPos(pos);
    }
  }
  async function handleDelete(pos: number) {
    const shiftId = props.shifts[pos].shiftId;
    if(shiftId) {
      props.onDelete(shiftId);
      setEditedPos(undefined);
      setHighlightedPos(undefined);
    }
  }

  function handleSetDefault(pos: number) {
    const shiftId = props.shifts[pos].shiftId;
    if(shiftId) {
      props.onSetDefault(shiftId);
    }
  }

  return (
    <div>
      <Button
        variant="secondary"
        disabled={editing || props.saving}
        as={Link}
        to={Path.toShiftReorder(props.scheduleId)}
      >
        <IconRepeat transform="rotate(90)" /> {pg.t('general.changeOrder')}
      </Button>
      <ErrorPopup error={props.error} />
      <FormTable className="mt-3">
        <ShiftTableHeader />
        {props.shifts.map((shift, pos) => {
          if(pos === editedPos) {
            return (
              <ShiftRowEditor
                key={shift.shiftId || pos}
                shift={shift}
                error={props.error !== undefined}
                validate={props.validate}
                onSave={handleSave}
                onCancel={handleCancel}
              />
            );
          } else {
            return (
              <ShiftRow
                key={shift.shiftId || pos}
                shift={shift}
                onEdit={() => handleEdit(pos)}
                onMouseDown={() => handleSelect(pos)}
                onDelete={() => handleDelete(pos)}
                onSetDefault={() => handleSetDefault(pos)}
                editingAnother={editing}
                highlighted={highlightedPos === pos}
                isDefaultShift={shift.shiftId === props.defaultShiftId}
              />
            );
          }
        })}
        {editedPos === -1 &&
          <ShiftRowEditor
            key={-1}
            error={props.error !== undefined}
            shift={defaultShiftEntry}
            validate={props.validate}
            onSave={handleSave}
            onCancel={handleCancel}
          />
        }
      </FormTable>
      <Button className="ml-1 mt-3" disabled={editing || props.saving} onClick={() => handleEdit(-1)}>
        <IconPlus /> {pg.t('shift.newShift')}
      </Button>
    </div>
  )
}

import React, { useCallback, useRef, useState } from 'react';
import { ShiftId, ShiftLookup } from '../Types';
import Button from 'react-bootstrap/Button';
import { mapi, StorageKey } from '../Utils';
import ReactDataSheet from 'react-datasheet';
import { findIndex, trimEnd, toString } from 'lodash';
import { CellEditor } from '../Table/CellEditor';
import fp from 'lodash/fp';
import { Popover, ListGroup, Badge, Nav } from 'react-bootstrap';
import { usePopper } from 'react-popper';
import { AvailabilityCellData, AvailabilityCellViewData } from './AvailabilityTable';
import { useLocalStorage } from '../Hooks';

type EditorMode = 'assign'|'select';

function highlight(data: AvailabilityCellData, mode: EditorMode, parts: ParseResultPart[]): React.ReactElement {
  if(mode === 'assign') {
    const firstValid = findIndex(parts, part => part.shiftId !== undefined);
    return fp.flow(
      mapi((part: ParseResultPart, i: number) => {
        if(part.shiftId) {
          if(i === firstValid) {
            return <b key={i}>{part.value}</b>;
          } else {
            return <i key={i} style={{color: 'grey'}}>{part.value}</i>;
          }
        } else {
          if(part.value === '!') {
            return <b key={i}>!</b>;
          } else {
            return <i key={i} style={{color: 'red'}}>{part.value}</i>;
          }
        }
      })
    )(parts);
  } else {
    return fp.flow(
      mapi((part: ParseResultPart, i: number) => {
        if(part.shiftId) {
          if(data.options.includes(part.shiftId)) {
            return part.value;
          } else {
            return <i key={i} style={{color: 'grey'}}>{part.value}</i>;
          }
        } else {
          return <i key={i} style={{color: 'red'}}>{part.value}</i>;
        }
      })
    )(parts);
  }
}

const determineShiftId = (shifts: ShiftLookup[]) => (label: string): ShiftId|undefined => {
  return shifts.find(s => s.label === label)?.shiftId;
}

export interface ParseResultPart {
  shiftId?: ShiftId;
  value: string;
}

export interface ParseResult {
  mode: EditorMode;
  parts: ParseResultPart[];
}

export function parseInputValue(shifts: ShiftLookup[], defaultShiftId: ShiftId, input: string): ParseResult {
  const trimmed = (input || '').trim();

  if(trimmed === '') {
    return {
      mode: 'select',
      parts: []
    };
  } else {
    const parse = determineShiftId(shifts);

    const parts = fp.flow(
      fp.split(/(\s)/),
      fp.map((x: string) => ({
        shiftId: parse(x.trim().replaceAll('!', '')),
        value: x
      }))
    )(input);

    const shiftIds = fp.flow(
      fp.map((p: ParseResultPart) => p.shiftId),
      fp.compact
    )(parts);

    let mode: EditorMode;
    if(shiftIds.length === 1 && shiftIds[0] === defaultShiftId) {
      mode = 'assign';
    } else if(trimmed.includes('!')) {
      mode = 'assign';
    } else {
      mode = 'select';
    }

    return {
      mode,
      parts
    };
  }
}

interface ShiftChooserProps {
  mode: EditorMode;
  parts: ParseResultPart[];
  data: AvailabilityCellData;
  target: React.RefObject<HTMLElement>;
  onSelect?: (shiftId: ShiftId|null) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
  onChangeMode: (mode: EditorMode) => void;
}

function ShiftChooser(props: ShiftChooserProps) {
  const [popperRef, setPopperRef] = useState<HTMLElement|null>(null);
  const [arrowRef, setArrowRef] = useState<HTMLElement|null>(null);
  const { styles, attributes } = usePopper(props.target.current, popperRef, {
    modifiers: [{ name: 'arrow', options: { element: arrowRef } }],
    placement: 'bottom-start'
  });

  let shifts: ShiftLookup[] = props.data.shifts;
  if(props.mode === 'select') {
    // only show options that can be chosen by solver
    shifts = shifts.filter(shift => props.data.options.includes(shift.shiftId) && shift.shiftId !== props.data.defaultShiftId);
  }

  const selection = fp.flow(fp.map((p: ParseResultPart) => p.shiftId), fp.compact)(props.parts);

  return (
    <Popover
      id='AvailabilityCellEditor__ShiftChooser'
      className="AvailabilityCellEditor__ShiftChooser"
      placement='bottom'
      ref={setPopperRef}
      arrowProps={{ref: setArrowRef, style: styles.arrow}}
      style={styles.popper}
      {...attributes.popper}
    >
      <Nav
        activeKey={props.mode}
        onSelect={(mode) => props.onChangeMode(mode as any)}
        variant="tabs"
      >
        <Nav.Item onKeyDown={props.onKeyDown}>
          <Nav.Link eventKey="select">Automatik</Nav.Link>
        </Nav.Item>
        <Nav.Item onKeyDown={props.onKeyDown}>
          <Nav.Link eventKey="assign">Manuell</Nav.Link>
        </Nav.Item>
      </Nav>
      <ListGroup variant="flush">
        {shifts.map(shift => (
          <Button
            className="text-left"
            style={{whiteSpace: 'pre'}}
            key={shift.shiftId}
            variant={selection.includes(shift.shiftId) ? 'primary' : 'white' }
            onClick={() => props.onSelect && props.onSelect(shift.shiftId)}
            onKeyDown={props.onKeyDown}
          >
            <Badge>{shift.label}</Badge> {shift.name}
          </Button>
        ))}
        {shifts.length === 0 &&
          <ListGroup.Item>Für diese Person gibt es keinen Personalbedarf an diesem Tag.</ListGroup.Item>
        }
      </ListGroup>
    </Popover>
  );
}

export function AvailabilityCellEditor(props: ReactDataSheet.DataEditorProps<AvailabilityCellViewData>) {
  const data = props.cell.data;
  const value = toString(props.value);
  const onChange = props.onChange;

  const overlayTarget = useRef<HTMLDivElement|null>(null);

  const [expanded, setExpanded] = useLocalStorage(StorageKey.availabilityTableShowChooser, false);

  const handleSelect = useCallback((shiftId: string|null) => {
    if(shiftId && data) {
      const { mode, parts } = parseInputValue(data.shifts, data.defaultShiftId, value);

      if(mode === 'assign') {
        const shift = data.shifts.find(shift => shift.shiftId === shiftId);
        if(shift) {
          if(shiftId === data.defaultShiftId) {
            // shortcut if default shift id, ! is not necessary
            onChange(shift.label);
          } else {
            onChange(`!${shift.label}`);
          }
        }
      } else {
        if(parts.some(p => p.shiftId === shiftId)) {
          // remove
          const newValue = fp.flow(
            fp.filter((p: ParseResultPart) => p.shiftId !== shiftId),
            fp.map(p => p.value.replaceAll('!', '')),
            fp.filter(s => s !== ' '),  // skip whitespace parts and..
            fp.join(' '), // ..join non-whitespace parts with exactly one whitespace
            fp.trim,
          )(parts);
          onChange(newValue);
        } else {
          // add
          const label = data.shifts.find(shift => shift.shiftId === shiftId)?.label;
          if(label) {
            const newValue = trimEnd(value) + ' ' + label;
            onChange(newValue);
          }
        }
      }
    }
  }, [data, value, onChange]);

  const handleChangeMode = useCallback((mode: EditorMode) => {
    if(mode === 'assign') {
      onChange('!');
    } else {
      onChange('');
    }
  }, [onChange]);

  let enhancedValue: React.ReactElement|string;
  let shiftChooser: React.ReactElement|undefined;
  if(data) {
    const { mode, parts } = parseInputValue(data.shifts, data.defaultShiftId, value);
    enhancedValue = highlight(data, mode, parts);
    if(expanded) {
      shiftChooser = (
        <ShiftChooser
          data={data}
          mode={mode}
          parts={parts}
          target={overlayTarget}
          onKeyDown={props.onKeyDown}
          onSelect={handleSelect}
          onChangeMode={handleChangeMode}
        />
      );
    }
  } else {
    enhancedValue = value;
  }

  return (
    <>
      <CellEditor
        {...props}
        innerRef={overlayTarget}
        enhancedValue={enhancedValue}
        onExpandedToggle={() => setExpanded(!expanded)}
        expanded={expanded}
      />
      {shiftChooser}
    </>
  )
}

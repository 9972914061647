import React from 'react';
import { Form } from 'react-bootstrap';
import { ChipSelectItem, ChipSelectOption } from '../Components/ChipSelect';
import { ChipSelectField } from '../Components/ChipSelectField';
import { PersonSelectorDto } from '../Generated/BackendTypes';
import { PersonGroupId, PersonId } from '../Types';

export type PersonSelectorFormData = Array<PersonId|PersonGroupId>;

export interface PersonSelectorProps {
  name: string;
  personGroups: ChipSelectOption<PersonGroupId>[];
  persons: ChipSelectOption<PersonId>[];
}

export function toPersonSelectorFormData(personSelector: PersonSelectorDto): PersonSelectorFormData {
  return [...personSelector.personIds, ...personSelector.groupIds]
}

export function fromPersonSelectorFormData(personIds: PersonId[], personGroupIds: PersonGroupId[],
  d: PersonSelectorFormData): PersonSelectorDto {
  return {
    _type: 'PersonSelector1',
    personIds: d.filter(id => personIds.includes(id)),
    groupIds: d.filter(id => personGroupIds.includes(id))
  };
}

export function PersonSelector(props: PersonSelectorProps) {
  const items: ChipSelectItem<PersonId|PersonGroupId>[] = [
    {
      label: 'Gruppen',
      items: props.personGroups.map(item => ({ ...item, variant: 'composite' }))
    },
    {
      label: 'Personen',
      items: props.persons
    }
  ];

  return (
    <ChipSelectField
      id={`personSelectorChipSelect_{props.name}`}
      items={items}
      name={props.name}
      placeholder="Alle Personen"
    />
  );
}

export function PersonSelectorSubform(props: PersonSelectorProps) {
  return (
    <Form.Group controlId="formPersonSelector">
      <Form.Label>Betrifft die Personen</Form.Label>
      <PersonSelector
        name={props.name}
        persons={props.persons}
        personGroups={props.personGroups}
      />
    </Form.Group>
  );
}

import React from 'react';
import { Button } from 'react-bootstrap';
import { ContainerLeft } from '../Components/UtilityComponents';
import { usePolyglot } from '../Localization/Localization';
import { Title } from '../TopBar/Title';
import { Link } from 'react-router-dom';
import { Path } from '../Utils';
import { IconArrowLeft } from '../icons';

export function AccountTopUpSuccess() {
  const pg = usePolyglot();
  return (
    <ContainerLeft>
      <Title>{pg.t('usage.topUpCredit')}</Title>
      <h1 className="mt-3">{pg.t('usage.paymentSuccessfulHeading')}</h1>
      <p>
        {pg.t('usage.paymentSuccessfulMessage')}
      </p>
      <Button variant="secondary" as={Link} to={Path.toAccountUsage}>
        <IconArrowLeft /> {pg.t('general.back')}
      </Button>
    </ContainerLeft>
  );
}

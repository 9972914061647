import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { ExternalLinks, Path } from '../Utils';
import { ScheduleId } from '../Types';
import { MenuItem } from './MenuItem';
import classNames from 'classnames';
import { usePolyglot } from '../Localization/Localization';
import { IconHelpCircle, IconExternalLink, IconChevronsLeft, IconChevronsRight, IconSliders, IconBriefcase, IconClock,
  IconFilter, IconLibrary, IconUserCheck, IconUsers, IconZap } from '../icons';

export interface MainMenuProps {
  scheduleId?: ScheduleId;
  expanded: boolean;
  onExpand: (expand: boolean) => void;
}

function Divider() {
  return <div style={{ borderTop: '1px solid #cfcfcf'}} />
}

export function MainMenu({ scheduleId, expanded, onExpand }: MainMenuProps) {
  const pg = usePolyglot();
  const match = {
    chooser: useRouteMatch({ path: Path.matchScheduleChooser, exact: true }) !== null,
    team: useRouteMatch({ path: Path.matchTeam }) !== null,
    shift: useRouteMatch({ path: Path.matchUnspecificShift }) !== null,
    demand: useRouteMatch(Path.matchUnspecificStaffDemand) !== null,
    condition: useRouteMatch(Path.matchUnspecificCondition) !== null,
    availability: useRouteMatch({ path: Path.matchAvailabilityTable, exact: true }) !== null,
    computation: useRouteMatch(Path.matchComputation) !== null,
    adjust: false,
    config: useRouteMatch({ path: Path.matchScheduleConfig }) !== null,
    agenda: false,
  };

  return (
    <div>
      <div className={classNames('MainMenu', { 'MainMenu--Expanded' : expanded })}>
        <MenuItem
          link={Path.toScheduleChooser}
          active={match.chooser}
          icon={<IconLibrary />}
        >
          {pg.t('general.schedules')}
        </MenuItem>
        {scheduleId &&
          <>
            <Divider />
            <MenuItem
              link={scheduleId && Path.toTeam(scheduleId)}
              active={match.team}
              icon={<IconUsers />}
            >
              {pg.t('general.team')}
            </MenuItem>
            <MenuItem
              link={scheduleId && Path.toUnspecificShift(scheduleId)}
              active={match.shift}
              icon={<IconClock />}
            >
              {pg.t('general.shifts')}
            </MenuItem>
            <MenuItem
              link={scheduleId && Path.toUnspecificStaffDemand(scheduleId)}
              active={match.demand}
              icon={<IconBriefcase />}
            >
              {pg.t('general.staffDemand')}
            </MenuItem>
            <MenuItem
              link={scheduleId && Path.toAvailabilityTable(scheduleId)}
              active={match.availability}
              icon={<IconUserCheck />}
            >
              {pg.t('general.availabilities')}
            </MenuItem>
            <MenuItem
              link={scheduleId && Path.toUnspecificCondition(scheduleId)}
              active={match.condition}
              icon={<IconFilter />}
            >
              {pg.t('general.conditions')}
            </MenuItem>
            <MenuItem
              link={scheduleId && Path.toComputation(scheduleId)}
              active={match.computation}
              icon={<IconZap />}
            >
              {pg.t('general.computation')}
            </MenuItem>
            {/*<MenuItem
              active={match.adjust}
              icon={<Icon.RotateCw />}
            >
              {pg.t('general.refinement')}
            </MenuItem>
            <MenuItem
              active={match.adjust}
              icon={<Icon.Repeat transform="rotate(90)" />}
            >
              {pg.t('general.postprocessing')}
            </MenuItem>*/}
            <Divider />
            {/*<MenuItem
              icon={<Icon.Calendar />}
              variant='secondary'
            >
              Agenda
            </MenuItem>*/}
            <MenuItem
              link={scheduleId && Path.toScheduleConfig(scheduleId)}
              active={match.config}
              icon={<IconSliders />}
            >
              {pg.t('general.settings')}
            </MenuItem>
          </>
        }
      </div>
      <div className={classNames('MainMenu__Footer', { 'MainMenu__Footer--Expanded': expanded })}>
        <MenuItem
          icon={<IconHelpCircle />}
          href={ExternalLinks.toDocs}
        >
          {pg.t('general.help')}
          <IconExternalLink className="align-self-center ml-5" size={16} />
        </MenuItem>
        <MenuItem
          icon={expanded ? <IconChevronsLeft /> : <IconChevronsRight />}
          onClick={() => onExpand(!expanded)}
        >
          {pg.t('general.collapse')}
        </MenuItem>
      </div>
    </div>
  );
}

import React from 'react';
import { isFinite } from 'lodash';
import { ComputationObjectiveValueDto } from '../Generated/BackendTypes';
import { Container } from 'react-bootstrap';
import { PersonGroupId, PersonId } from '../Types';

export interface ComputationDrilldownProps {
  objectiveValues: ComputationObjectiveValueDto[];
  totalObjectiveValue: number;
  personGroups: Record<PersonGroupId,string>
  persons: Record<PersonId,string>
}

export function ComputationDrilldown(props: ComputationDrilldownProps) {
  return (
    <Container>
      <table className="table">
        <thead>
          <tr>
            <th>Bedingung</th>
            <th>Kontext</th>
            <th>Wert</th>
          </tr>
        </thead>
        <tbody>
          {props.objectiveValues.map(objectiveValue =>
            <React.Fragment key={objectiveValue.conditionPos}>
              <tr >
                <td><b>{objectiveValue.conditionPos}</b></td>
                <td><b>{objectiveValue.name}</b></td>
                <td><b>{objectiveValue.value}</b></td>
              </tr>
              {objectiveValue.subresults.map((subresult, index) =>
                <tr key={`${objectiveValue.conditionPos}-${index}`}>
                  <td></td>
                  <td>
                    {subresult.personGroupId && <span>Gruppe {props.personGroups[subresult.personGroupId]}<br /></span>}
                    {subresult.personId && <span>{props.persons[subresult.personId]}<br /></span>}
                    {subresult.name}
                  </td>
                  <td>{isFinite(subresult.value) && subresult.value}</td>
                </tr>
              )}
            </React.Fragment>
          )}
          <tr>
            <td><b>Total</b></td>
            <td></td>
            <td><b>{props.totalObjectiveValue}</b></td>
          </tr>
        </tbody>
      </table>
    </Container>
  )
}

import React, { useState, useRef } from 'react';
import { dataTransferScheduleImport, teamFetchList } from '../BackendService';
import { Link, Redirect } from 'react-router-dom';
import { Path } from '../Utils';
import Button from 'react-bootstrap/Button';
import { useAuthFetch } from '../AuthService';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { FetchedResource } from '../FetchedResource';
import { TeamDto } from '../Generated/BackendTypes';
import { Dropdown } from 'react-bootstrap';

export function DataTransferScheduleImport() {
  const authFetch = useAuthFetch();
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);

  const [team, setTeam] = useState<TeamDto|undefined>(undefined);
  const [withNewScheduleId, setWithNewScheduleId] = useState(false);
  const [error, setError] = useState<string|undefined>(undefined);

  const fileInputRef = useRef<HTMLInputElement>(null);

  function performImport(blob: Blob) {
    if(team) {
      dataTransferScheduleImport(authFetch, blob, team.teamId, withNewScheduleId).then(
        () => {
          setLoading(false);
          setComplete(true);
        },
        error => {
          setLoading(false);
          setError(error.message);
        }
      );
    }
  }

  function handlePerformImport() {
    if(fileInputRef.current && fileInputRef.current.files && fileInputRef.current.files.length > 0) {
      performImport(fileInputRef.current.files[0]);
    }
  }

  return (
    <div className="m-3">
      {complete && <Redirect to={Path.toScheduleChooser} />}
      <h1>Raw Schedule Import</h1>
      <p>
        Developer-Tool to import a schedule as-is.
      </p>
      <div>
        <input
          ref={fileInputRef}
          type="file" accept="application/json"
        />
      </div>
      <div>
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check type="checkbox"
            checked={withNewScheduleId}
            label="Import with new ScheduleId"
            onChange={() => setWithNewScheduleId(!withNewScheduleId)}
          />
        </Form.Group>
      </div>
      <div>
      <Dropdown className="my-3">
        <Dropdown.Toggle variant="primary" id="team-selector-dropdown">
          {team ? team.name : 'Team auswählen'}
        </Dropdown.Toggle>

        <FetchedResource fetch={() => teamFetchList(authFetch)}>
          {d =>
            <Dropdown.Menu>
              {d.teams.map(team =>
                <Dropdown.Item onClick={() => setTeam(team)}>{team.name}</Dropdown.Item>
              )}
            </Dropdown.Menu>
          }
        </FetchedResource>
      </Dropdown>
      </div>
      <div>
        <Button disabled={loading} onClick={handlePerformImport} className="mr-3">Perform Import</Button>
        <Button as={Link} variant='secondary' to={Path.toScheduleChooser}>Cancel</Button>
      </div>
      {error &&
        <Alert variant='danger'>{error}</Alert>
      }
    </div>
  );
}

import React, { useCallback, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { DOMContainer } from 'react-overlays/useWaitForDOMRef';
import { CellViewData, parsePasteMultipleCells } from '../Table/Utils';
import ReactDataSheet from 'react-datasheet';
import { SaveButton } from '../Components/SaveButton';
import { StaffDemandCellEditor } from './StaffDemandCellEditor';
import { Spacetime } from 'spacetime';
import { Link } from 'react-router-dom';
import { Path } from '../Utils';
import { ScheduleId } from '../Types';

export type CellSelection = Record<number, Record<number, DOMContainer>>;

export interface StaffDemandCellData {
  staffDemandIdx: number;
  index: number;
  date: Spacetime;

  // possibly adjusted values
  adjustedMinCount?: number;
  adjustedMaxCount?: number;

  // unadjusted values
  patternMinCount: number;
  patternMaxCount: number;
}

export interface StaffDemandCellViewData extends CellViewData<StaffDemandCellData> { }

class StaffDemandDataSheet extends ReactDataSheet<StaffDemandCellViewData> { }

function valueRenderer(cell: StaffDemandCellViewData) {
  return cell.label;
}

function dataRenderer(cell: StaffDemandCellViewData) {
  return cell.label;
}

export interface StaffDemandTableProps {
  scheduleId: ScheduleId;
  table: StaffDemandCellViewData[][];
  loading: boolean;
  dirty: boolean;
  error?: string;
  onCellsChanged?: ReactDataSheet.CellsChangedHandler<StaffDemandCellViewData>;
  onSaveClick: () => void;
}

export function StaffDemandTable(props: StaffDemandTableProps) {
  // remember selection for multi-row/multi-column paste
  const selections = useRef<ReactDataSheet.Selection>();

  function handleSelect(s: ReactDataSheet.Selection) {
    selections.current = s;
  }
  const parsePaste = useCallback(
    (value: string) => parsePasteMultipleCells(value, selections.current),
    [selections]
  );

  return (
    <>
      <div className="d-flex flex-row m-3 mt-5">
        <SaveButton
          className="mr-3"
          dirty={props.dirty}
          saving={props.loading}
          error={props.error !== undefined}
          onClick={props.onSaveClick}
        />
        <Button
          as={Link}
          to={Path.toNewStaffDemand(props.scheduleId)}
        >
          Personalbedarf hinzufügen
        </Button>
      </div>
      <StaffDemandDataSheet
        className="StaffDemandTable ValueTable"
        data={props.table}
        valueRenderer={valueRenderer}
        onCellsChanged={props.onCellsChanged}
        onSelect={handleSelect}
        parsePaste={parsePaste}
        dataRenderer={dataRenderer}
        dataEditor={StaffDemandCellEditor}
      />
    </>
  );
}

import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useAuthTokenService } from '../AuthService';
import { usePolyglot } from '../Localization/Localization';
import { FetchedResource } from '../FetchedResource';
import { Link } from 'react-router-dom';
import { Path } from '../Utils';
import { useUserProfile } from '../UserProfileContext';
import { IconAperture, IconBuilding, IconLogOut, IconUser, IconWallet } from '../icons';

export function AccountMenuButton() {
  const userProfile = useUserProfile();
  const authTokenService = useAuthTokenService();
  const pg = usePolyglot();

  return (
    <Dropdown>
      <Dropdown.Toggle id="profile-dropdown" variant="light" className="TopBar_AccountMenuToggle">
        <IconUser />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Header className="TopBar_AccountMenuHeader">
          <h1>{userProfile.userName}</h1>
          <h2>{userProfile.tenantName}</h2>
        </Dropdown.Header>
        <Dropdown.Item as={Link} to={Path.toAccountProfile}><IconUser />&nbsp;{pg.t('account.yourProfile')}</Dropdown.Item>
        <Dropdown.Item as={Link} to={Path.toAccountOrganization}><IconBuilding />&nbsp;{pg.t('general.organization')}</Dropdown.Item>
        <Dropdown.Item as={Link} to={Path.toAccountUsage}><IconWallet />&nbsp;{pg.t('general.usage')}</Dropdown.Item>
        {userProfile.isRoot &&
          <Dropdown.Item as={Link} to={Path.toTenantSwitcher}><IconAperture />&nbsp;Tenant Switch</Dropdown.Item>
        }
        {authTokenService &&
          <FetchedResource fetch={() => authTokenService.getUserAuthLogoutUrl()}>
            {logoutUrl =>
              <>
                <Dropdown.Divider />
                <Dropdown.Item href={logoutUrl}><IconLogOut />&nbsp;{pg.t('general.logout')}</Dropdown.Item>
              </>
            }
          </FetchedResource>
        }
      </Dropdown.Menu>
    </Dropdown>
  );
}

import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { usePolyglot } from '../Localization/Localization';
import { ConfirmationModalButton } from '../Components/ConfirmationModalButton';
import { TenantInviteListItemDto } from '../Generated/BackendTypes';
import { useUserProfile } from '../UserProfileContext';
import { IconX } from '../icons';

export interface InviteListProps {
  loading: boolean;
  invites: TenantInviteListItemDto[];
  onDeleteInvite: (emailAddress: string) => void;
}

export function InviteList(props: InviteListProps) {
  const pg = usePolyglot();
  const userProfile = useUserProfile();
  return (
    <ListGroup>
      {props.invites.map(m =>
        <ListGroup.Item key={m.emailAddress}>
          {m.emailAddress}
          {userProfile.isAdmin &&
            <ConfirmationModalButton
              variant="outline-danger"
              type="button"
              size="sm"
              disabled={props.loading}
              onPositive={() => props.onDeleteInvite(m.emailAddress)}
              text={pg.t('account.confirmDeleteInvite', { emailAddress: m.emailAddress })}
              className="float-right"
            >
              <IconX />
            </ConfirmationModalButton>
          }
        </ListGroup.Item>
      )}
    </ListGroup>
  );
}

import React from 'react';
import { Formik, Form as FormikForm, FormikErrors } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { memberof } from '../Utils';
import { Spacetime } from 'spacetime';
import { ShiftId } from '../Types';
import { DateSelector, DateSelectorFormData } from '../Condition/DateSelector';
import { DropdownSingleSelectItem } from '../Components/DropdownSingleSelect';
import Table from 'react-bootstrap/Table';
import { FoboCheck } from '../Components/FoboCheck';
import { DropdownSingleSelectField } from '../Components/DropdownSingleSelectField';
import { ConfirmationModalButton } from '../Components/ConfirmationModalButton';
import { StaffDemandPatternMode } from '../Generated/BackendTypes';
import { DropdownMultiSelectItem } from '../Components/DropdownMultiSelect';
import { ContainerLeft } from '../Components/UtilityComponents';
import { FoboField } from '../Components/FoboField';
import { isNil, isString } from 'lodash';
import { parseMinMaxCount } from '../Utils/range';
import { AffectedPersonsSelector, AffectedPersonsSelectorFormData } from './AffectedPersonsSelector';
import { SaveButton } from '../Components/SaveButton';
import { Prompt } from 'react-router-dom';
import { IconArrowLeft, IconTrash2 } from '../icons';

export interface StaffDemandEditorValues {
  enabled: boolean;
  shiftId: ShiftId;
  affectedPersonsSelector: AffectedPersonsSelectorFormData;
  startDateSelector: DateSelectorFormData;
  endDateSelector: DateSelectorFormData;
  patternMode: StaffDemandPatternMode;
  daily: string;
  mon: string;
  tue: string;
  wed: string;
  thu: string;
  fri: string;
  sat: string;
  sun: string;
}

export interface StaffDemandEditorProps {
  startDate: Spacetime;
  endDate: Spacetime;
  initialValues: StaffDemandEditorValues;
  shifts: DropdownSingleSelectItem<string>[];
  personGroups: DropdownMultiSelectItem<string>[];
  persons: DropdownMultiSelectItem<string>[];
  error?: string;
  onSave: (values: StaffDemandEditorValues) => void|Promise<void>;
  onDelete?: () => void;
  onCancel?: () => void;
}

const patternModeItems: DropdownSingleSelectItem<StaffDemandPatternMode>[] = [
  { itemKey: 'Daily', label: 'täglich' },
  { itemKey: 'WeekDays', label: 'wöchentlich' },
];

export const defaultStaffDemandEditorValues: StaffDemandEditorValues = {
  enabled: true,
  shiftId: '',
  affectedPersonsSelector: [],
  startDateSelector: { mode: 'StartOfPeriod' },
  endDateSelector: { mode: 'EndOfPeriod' },
  patternMode: 'WeekDays',
  daily: '1',
  mon: '1',
  tue: '1',
  wed: '1',
  thu: '1',
  fri: '1',
  sat: '1',
  sun: '1',
}

const minMaxCountErrorMessage = 'Einzelne Zahl grösser gleich 0 oder Zahlenbereich z.B. 1-3 erwartet';

export function StaffDemandEditor(props: StaffDemandEditorProps) {
  return (
    <Formik
      initialValues={props.initialValues}
      validate={values => {
        const errors: FormikErrors<StaffDemandEditorValues> = {};
        if(values.patternMode === 'Daily') {
          if(isNil(parseMinMaxCount(values.daily))) {
            errors.daily = minMaxCountErrorMessage;
          }
        } else {
          if(isNil(parseMinMaxCount(values.mon))) {
            errors.mon = minMaxCountErrorMessage;
          }
          if(isNil(parseMinMaxCount(values.tue))) {
            errors.tue = minMaxCountErrorMessage;
          }
          if(isNil(parseMinMaxCount(values.wed))) {
            errors.wed = minMaxCountErrorMessage;
          }
          if(isNil(parseMinMaxCount(values.thu))) {
            errors.thu = minMaxCountErrorMessage;
          }
          if(isNil(parseMinMaxCount(values.fri))) {
            errors.fri = minMaxCountErrorMessage;
          }
          if(isNil(parseMinMaxCount(values.sat))) {
            errors.sat = minMaxCountErrorMessage;
          }
          if(isNil(parseMinMaxCount(values.sun))) {
            errors.sun = minMaxCountErrorMessage;
          }
        }
        return errors;
      }}
      onSubmit={props.onSave}
    >
      {({ isSubmitting, values, errors, dirty }) => (
        <ContainerLeft>
          <Prompt when={!isSubmitting && dirty} message="Du hast ungespeicherte Änderungen. Möchtest du den Editor wirklich verlassen?" />
          {props.onDelete && <h1 className="mb-3 mt-3">Personalbedarf bearbeiten</h1>}
          {!props.onDelete && <h1 className="mb-3 mt-3">Personalbedarf hinzufügen</h1>}
          <Form as={FormikForm}>
            <div className="mb-3">
              {!(dirty || props.onDelete === undefined) &&
                <Button variant="secondary" onClick={props.onCancel}>
                  <IconArrowLeft />Zurück
                </Button>
              }
              {(dirty || props.onDelete === undefined) &&
                <>
                  <SaveButton type="submit" dirty={true} saving={isSubmitting} error={isString(props.error)} />
                  <Button disabled={isSubmitting} variant="secondary" onClick={props.onCancel} className="ml-3">
                    Abbrechen
                  </Button>
                </>
              }
            </div>

            <Form.Group controlId="enabled">
              <FoboCheck
                type="checkbox"
                id={`enabledCheckbox`}
                label="Aktiv"
                name={memberof<StaffDemandEditorValues>('enabled')}
              />
            </Form.Group>
            <fieldset disabled={!values.enabled}>
              <Form.Group controlId="shiftSelectorControl">
                <Form.Label>Schicht</Form.Label>
                <DropdownSingleSelectField
                  id="shiftSelectorDropdown"
                  items={props.shifts}
                  name={memberof<StaffDemandEditorValues>('shiftId')}
                />
              </Form.Group>
              <Form.Group controlId="personGroupsSelectorControl">
                <Form.Label>Einsetzbare Personen</Form.Label>
                <AffectedPersonsSelector
                  name={memberof<StaffDemandEditorValues>('affectedPersonsSelector')}
                  persons={props.persons}
                  personGroups={props.personGroups}
                />
              </Form.Group>

              <Form.Group controlId="patternModeControl">
                <Form.Label>Muster</Form.Label>
                <DropdownSingleSelectField
                  id="patternModelDropdown"
                  items={patternModeItems}
                  name={memberof<StaffDemandEditorValues>('patternMode')} />
              </Form.Group>

              <Table className="StaffDemandEditor__Table">
                {values.patternMode === 'Daily' &&
                <tbody>
                  <tr>
                    <td>Täglich</td>
                    <td><FoboField name={memberof<StaffDemandEditorValues>('daily')} aria-label="Anzahl Personen" /></td>
                  </tr>
                </tbody>
                }
                {values.patternMode === 'WeekDays' &&
                <>
                <thead>
                  <tr>
                    <th>Wochentag</th>
                    <th>Bedarf</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Montag</td>
                    <td><FoboField name={memberof<StaffDemandEditorValues>('mon')} aria-label="Anzahl Personen" /></td>
                  </tr>
                  <tr>
                    <td>Dienstag</td>
                    <td><FoboField name={memberof<StaffDemandEditorValues>('tue')} aria-label="Anzahl Personen" /></td>
                  </tr>
                  <tr>
                    <td>Mittwoch</td>
                    <td><FoboField name={memberof<StaffDemandEditorValues>('wed')} aria-label="Anzahl Personen" /></td>
                  </tr>
                  <tr>
                    <td>Donnerstag</td>
                    <td><FoboField name={memberof<StaffDemandEditorValues>('thu')} aria-label="Anzahl Personen" /></td>
                  </tr>
                  <tr>
                    <td>Freitag</td>
                    <td><FoboField name={memberof<StaffDemandEditorValues>('fri')} aria-label="Anzahl Personen" /></td>
                  </tr>
                  <tr>
                    <td>Samstag</td>
                    <td><FoboField name={memberof<StaffDemandEditorValues>('sat')} aria-label="Anzahl Personen" /></td>
                  </tr>
                  <tr>
                    <td>Sonntag</td>
                    <td><FoboField name={memberof<StaffDemandEditorValues>('sun')} aria-label="Anzahl Personen" /></td>
                  </tr>
                </tbody>
                </>
                }
              </Table>

              <div className='d-flex flex-row align-items-center'>
                Gültig
                <DateSelector
                  name={memberof<StaffDemandEditorValues>("startDateSelector")}
                  startDate={props.startDate}
                  endDate={props.endDate}
                  disableEndOfPeriod />
                bis
                <DateSelector
                  name={memberof<StaffDemandEditorValues>("endDateSelector")}
                  startDate={props.startDate}
                  endDate={props.endDate}
                  disableStartOfPeriod />
                des Dienstplans.
              </div>
            </fieldset>
            {props.error && <Alert variant='danger'>{props.error}</Alert>}
            {props.onDelete &&
            <ConfirmationModalButton
              text="Möchten Sie diesen Bedarf wirklich löschen?"
              onPositive={props.onDelete}
              variant="outline-danger"
              className="mt-5"
            >
              <IconTrash2 /> Löschen
            </ConfirmationModalButton>
            }
          </Form>
        </ContainerLeft>
      )}
    </Formik>
  );
}

import { isNumber, sumBy } from "lodash";
import { ComputationMonitorDto, ComputationResultDto, ComputationShiftDto, TargetWorkload0 } from "../Generated/BackendTypes";
import { getDatesBetween, getNumberOfWorkDays } from "../Utils";

type Minutes = number;
type Percentage = number;

class TargetWorkload {
  private static pensumToCounts(numWorkDays: number, pensum: Percentage) {
    const count = (pensum / 100.0) * numWorkDays;
    return [count, count];
  }

  private static pensumToMinutes(durationPerDayFullTime: Minutes, numWorkDays: number, pensum: Percentage) {
    let total = (pensum / 100.0) * numWorkDays * durationPerDayFullTime;
    return [total, total];
  }

  private static shiftCountRangeToMinutes(durationPerShift: Minutes, minCount: number, maxCount: number) {
    return [(durationPerShift * minCount), (durationPerShift * maxCount)];
  }

  static toMinutes(durationPerDayFullTime: Minutes, durationPerShift: Minutes, numWorkDays: number, targetWorkload: TargetWorkload0) {
    if (targetWorkload.mode === 'Pensum') {
      return this.pensumToMinutes(durationPerDayFullTime, numWorkDays, targetWorkload.pensum!);
    } else {
      return this.shiftCountRangeToMinutes(durationPerShift, targetWorkload.shiftCountMin!, targetWorkload.shiftCountMax!);
    }
  }

  static toCounts(numWorkDays: number, targetWorkload: TargetWorkload0) {
    if (targetWorkload.mode === 'Pensum') {
      return this.pensumToCounts(numWorkDays, targetWorkload.pensum!);
    } else {
      return [targetWorkload.shiftCountMin!, targetWorkload.shiftCountMax!];
    }
  }
}

function computePlannedDuration(shifts: ComputationShiftDto[], targetWorkload: TargetWorkload0, entries: number[]): Minutes {
  return sumBy(entries, entry => {
    const shift = shifts[entry];
    if(shift.durationScaledByPensum && targetWorkload.mode === 'Pensum') {
      return shift.duration * (targetWorkload.pensum! / 100);
    } else {
      return shift.duration;
    }
  });
}

function computePlannedCount(shifts: ComputationShiftDto[], entries: number[]): number {
  return sumBy(entries, entry => shifts[entry].duration > 0 ? 1 : 0);
}

export interface PlanningTimes {
  // Anticipated minimum total working duration in minutes
  targetDurationMin: Minutes;
  // Anticipated maximum total working duration in minutes
  targetDurationMax: Minutes;
  // Actual planned total working duration in minutes
  plannedDuration: Minutes;
  diffDuration: Minutes;
  // Anticipated minimum shift count
  targetCountMin: number;
  // Anticipated maximum shift count
  targetCountMax: number;
  // Actual planned shift count
  plannedCount: number;
  diffCount: number;
}

export function computePlanningTimes(durationPerDayFullTime: Minutes, durationPerShift: Minutes, numWorkDays: number, shifts: ComputationShiftDto[], targetWorkload: TargetWorkload0, entries: number[]): PlanningTimes {
  const [targetDurationMin, targetDurationMax] = TargetWorkload.toMinutes(durationPerDayFullTime, durationPerShift, numWorkDays, targetWorkload);
  const [targetCountMin, targetCountMax] = TargetWorkload.toCounts(numWorkDays, targetWorkload);
  const plannedDuration = computePlannedDuration(shifts, targetWorkload, entries);
  const plannedCount = computePlannedCount(shifts, entries);
  const diffDuration = Math.min(plannedDuration - targetDurationMin, Math.max(plannedDuration - targetDurationMax, 0));
  const diffCount = Math.min(plannedCount - targetCountMin, Math.max(plannedCount - targetCountMax, 0));
  return {
    targetDurationMin,
    targetDurationMax,
    plannedDuration,
    diffDuration,
    targetCountMin,
    targetCountMax,
    plannedCount,
    diffCount
  };
}

export function computePlanningTimesFromMonitor(monitor: ComputationMonitorDto, solution: number[][]): PlanningTimes[] {
  const dates = getDatesBetween(monitor.scheduleStartDate, monitor.scheduleEndDate);
  const numWorkDays = getNumberOfWorkDays(dates);

  return monitor.persons.map((person, personIdx) =>
    computePlanningTimes(monitor.standardDurationPerDay, monitor.standardDurationPerShift, numWorkDays,
      monitor.shifts, person.targetWorkload, solution[personIdx]));
}

export function computePlanningTimesFromResult(result: ComputationResultDto): PlanningTimes[] {
  const dates = getDatesBetween(result.scheduleStartDate, result.scheduleEndDate);
  const numWorkDays = getNumberOfWorkDays(dates);

  return result.persons.map((person, personIdx) =>
    computePlanningTimes(
      result.standardDurationPerDay,
      result.standardDurationPerShift,
      numWorkDays,
      result.shifts,
      person.targetWorkload,
      result.entries[personIdx].map(entry => isNumber(entry.a) ? entry.a : entry.c))
  );
}

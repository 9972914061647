import React, { useCallback, useRef } from 'react';
import { Spacetime } from 'spacetime';
import { CellViewData, parsePasteMultipleCells } from '../Table/Utils';
import ReactDataSheet from 'react-datasheet';
import { PersonId, ShiftLookup } from '../Types';
import { isString } from 'lodash';
import { ComputationCellEditor } from './ComputationCellEditor';

export interface ComputationCellData {
  personId: PersonId;
  index: number;
  date: Spacetime;
  computedShiftIdx: number;
  adjustedShiftIdx?: number;
  shifts: ShiftLookup[];
}

export interface ComputationCellViewData extends CellViewData<ComputationCellData> { }

class ComputationDataSheet extends ReactDataSheet<ComputationCellViewData> { }

function valueRenderer(cell: ComputationCellViewData) {
  return cell.label;
}

function dataRenderer(cell: ComputationCellViewData) {
  return cell.label;
}

function attributesRenderer(cell: ComputationCellViewData) {
  if(isString(cell.title)) {
    return { 'title': cell.title };
  }
  return {};
}

export interface ComputationTableProps {
  table: ComputationCellViewData[][];
  onCellsChanged?: ReactDataSheet.CellsChangedHandler<ComputationCellViewData>;
}

export function ComputationTable(props: ComputationTableProps) {
  // remember selection for multi-row/multi-column paste
  const selections = useRef<ReactDataSheet.Selection>();

  function handleSelect(s: ReactDataSheet.Selection) {
    selections.current = s;
  }
  const parsePaste = useCallback(
    (value: string) => parsePasteMultipleCells(value, selections.current),
    [selections]
  );

  return (
    <ComputationDataSheet
      className="ComputationTable ValueTable"
      data={props.table}
      valueRenderer={valueRenderer}
      attributesRenderer={attributesRenderer}
      onCellsChanged={props.onCellsChanged}
      onSelect={handleSelect}
      parsePaste={parsePaste}
      dataRenderer={dataRenderer}
      dataEditor={ComputationCellEditor}
    />
  );
}

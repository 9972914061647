import React, { ChangeEvent } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { usePolyglot } from '../Localization/Localization';
import { ScheduleId } from '../Types';
import { Path } from '../Utils';
import { SolverTaskProgressProps, SolverTaskProgress } from './SolverTaskProgress';


export interface SolverMonitorToolbarProps extends SolverTaskProgressProps {
  scheduleId: ScheduleId;
  onHandleCancel: () => void;

  resultsCount: number;
  resultsIndex: number;
  onSelectResult: (index: number) => void;
}

export function SolverMonitorToolbar(props: SolverMonitorToolbarProps) {
  const pg = usePolyglot();

  function handleSelectResult(e: ChangeEvent<HTMLInputElement>) {
    props.onSelectResult(parseInt(e.target.value));
  }

  const isRunning = props.status === 'Queued' || props.status === 'Running';

  return (
    <>
      <h1 className="ml-3 mt-3 mb-5">{pg.t('general.computationProcess')}</h1>
      <SolverTaskProgress {...props} />
      <div className="mt-3">
        <Button as={Link} to={Path.toComputation(props.scheduleId)} className="ml-3">
          Zurück
        </Button>
        {isRunning &&
          <Button variant="warning" title="Berechnung abbrechen" onClick={props.onHandleCancel} className="ml-3">
            Stop
          </Button>
        }
        <span className="pl-3">{props.status}</span>
      </div>
      {props.resultsCount > 0 &&
        <Form.Group controlId="selectResultInput" className="mt-5">
          <Form.Label>Zwischenresultat {props.resultsIndex+1} von {props.resultsCount}</Form.Label>
          <Form.Control
            type="range"
            min={0}
            max={props.resultsCount-1}
            step={1}
            value={props.resultsIndex}
            onChange={handleSelectResult}
          />
        </Form.Group>
      }
    </>
  );
}


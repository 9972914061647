import React, { useState } from 'react';
import { Button, ButtonProps, Collapse, Dropdown, Jumbotron, Table } from 'react-bootstrap';
import { ContainerLeft } from '../Components/UtilityComponents';
import { usePolyglot } from '../Localization/Localization';
import { Title } from '../TopBar/Title';
import { FetchedResource } from '../FetchedResource';
import { fetchInvoicePdf, usageFetchList, usageFetchReport } from '../BackendService';
import { UsageCreditStatus } from '../Generated/BackendTypes';
import spacetime from 'spacetime';
import { useAuthFetch } from '../AuthService';
import { deriveScheduleCaption } from '../Domain';
import Polyglot from 'node-polyglot';
import { base64toBlob, Path, spaceSeparated, tz_utc } from '../Utils';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Explainer } from './Explainer';
import { IconAlertTriangle, IconPaperclip, IconPlus } from '../icons';


type HeadingToggleProps = {
  children?: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {};
};

const HeadingToggle = React.forwardRef(
  (props: HeadingToggleProps, ref: React.Ref<HTMLDivElement>) => (
    <h1
      className="AccountUsage__HeadingDropdown"
      ref={ref}
      onClick={e => {
        e.preventDefault();
        props.onClick(e);
      }}
    >
      <span className="AccountUsage__HeadingDropdownText">{props.children}</span>
      <span className="AccountUsage__HeadingDropdownArrow">&#x25bc;</span>
    </h1>
  )
);

const formatDMY = (d: string) => spacetime(d).goto('').format('{date-pad}.{iso-month}.{iso-year}') as string;
const formatDateTime = (d: string) => spacetime(d, tz_utc).goto('').format('{date-pad}.{iso-month}.{iso-year} {hour-24-pad}:{minute-pad}') as string;

function formatStatus(status: UsageCreditStatus, pg: Polyglot) {
  switch(status) {
    case 'Carryover': return '';
    case 'Active': return pg.t('usage.active');
    case 'Pending': return pg.t('usage.pending');
    case 'Void': return pg.t('usage.void');
  }
}

function formatDescription(description: string, status: UsageCreditStatus, pg: Polyglot) {
  if(status === 'Carryover') {
    return spaceSeparated([pg.t('usage.descriptionCarryover'), description]);
  } else {
    return description;
  }
}

interface DownloadInvoiceButtonProps extends ButtonProps {
  usagePeriodId: number;
  usageCreditId: number;
}

function DownloadInvoiceButton({ usagePeriodId, usageCreditId, ...buttonProps }: DownloadInvoiceButtonProps) {
  const authFetch = useAuthFetch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  async function handleClick() {
    setLoading(true);

    try {
      const invoicePdf = await fetchInvoicePdf(authFetch, usagePeriodId, usageCreditId);
      const content = await base64toBlob(invoicePdf.content, invoicePdf.mimeType);
      saveAs(content, invoicePdf.fileName);
    } catch(e) {
      console.error(`Failed to download file: ${e.message}`)
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Button {...buttonProps} onClick={handleClick} disabled={loading}>
      {error ? <IconAlertTriangle /> : <IconPaperclip />}
    </Button>
  )
}

interface UsageReportProps {
  usagePeriodId: number;
}

function UsageReport(props: UsageReportProps) {
  const authFetch = useAuthFetch();
  const pg = usePolyglot();
  return (
    <FetchedResource fetch={() => usageFetchReport(authFetch, props.usagePeriodId)}>
      {d =>
        <>
          <p>
            {pg.t('usage.updatesConsideredUntil', { time: formatDateTime(d.queryTime)})}
          </p>
          <h2 className="mt-3">{pg.t('usage.usageHeading')}</h2>
          <Table bordered>
            <thead>
              <tr>
                <th>{pg.t('general.team')}</th>
                <th>{pg.t('general.schedule')}</th>
                <th>{pg.t('usage.computationPointInTime')}</th>
                <th>{pg.t('general.user')}</th>
                <th className="text-right">{pg.t('usage.countOfRows')}</th>
              </tr>
            </thead>
            <tbody>
              {d.usageRecords.map(r =>
                  <tr key={r.scheduleId}>
                    <td>
                      {r.teamName}
                    </td>
                    <td>
                      <Link to={Path.toComputation(r.scheduleId)}>
                        {deriveScheduleCaption(r.scheduleStartDate, r.scheduleEndDate)}
                      </Link>
                    </td>
                    <td>{formatDateTime(r.computationTime)}</td>
                    <td title={r.emailAddress}>{r.userName}</td>
                    <td className="text-right">{r.amount}</td>
                  </tr>
              )}
              <tr className="font-weight-bold table-primary">
                <td>{pg.t('usage.rowConsumption')}</td>
                <td></td>
                <td></td>
                <td></td>
                <td className="text-right">{d.totalUsage}</td>
              </tr>
            </tbody>
          </Table>
          <h2 className="mt-5">{pg.t('usage.creditHeading')}</h2>
          <Table bordered>
            <thead>
              <tr>
                <th>{pg.t('general.description')}</th>
                <th>{pg.t('general.time')}</th>
                <th>{pg.t('general.status')}</th>
                <th className="text-right">{pg.t('usage.countOfRows')}</th>
              </tr>
            </thead>
            <tbody>
              {d.creditRecords.map((r, i) =>
                <tr key={i} className={classNames({ 'bg-light text-muted': r.status === 'Void' })}>
                  <td>
                    {formatDescription(r.description, r.status, pg)}
                    {r.hasInvoice &&
                      <DownloadInvoiceButton
                        variant="link"
                        usagePeriodId={props.usagePeriodId}
                        usageCreditId={r.usageCreditId}
                      />
                    }
                  </td>
                  <td>{r.status === 'Carryover' ? '' : formatDateTime(r.updateTime)}</td>
                  <td>{formatStatus(r.status, pg)}</td>
                  <td className="text-right">{r.amount}</td>
                </tr>
              )}
              <tr className="font-weight-bold">
                <td>{pg.t('usage.credit')}</td>
                <td></td>
                <td></td>
                <td className="text-right">{d.totalCredit}</td>
              </tr>
              <tr className="font-weight-bold table-primary">
                <td>{pg.t('usage.rowConsumption')}</td>
                <td></td>
                <td></td>
                <td className="text-right">{-d.totalUsage}</td>
              </tr>
              <tr className="font-weight-bold table-success">
                <td>{pg.t('usage.creditRemaining')}</td>
                <td></td>
                <td></td>
                <td className="text-right">{d.balance}</td>
              </tr>
            </tbody>
          </Table>
        </>
      }
    </FetchedResource>
  );
}

export function AccountUsage() {
  const pg = usePolyglot();
  const authFetch = useAuthFetch();
  const [selectedUsagePeriodId, setSelectedUsagePeriodId] = useState<number|undefined>();
  const [showTopUpDetails, setShowTopUpDetails] = useState(false);

  return(
    <ContainerLeft>
      <Title>{pg.t('general.usage')}</Title>


      <FetchedResource fetch={() => usageFetchList(authFetch)}>
        {d => {
          const usagePeriodId = selectedUsagePeriodId || d.periods[0].usagePeriodId;
          const period = d.periods.find(p => p.usagePeriodId === usagePeriodId)!;
          return (
            <>
              <Jumbotron className="mt-5 d-flex flex-column-reverse flex-md-row">
                <div className="flex-grow-1">
                  <h1>Guthaben aufladen</h1>
                  <p>
                    Lade das Guthaben deiner Organisation mit einer Kreditkarte auf.
                    <Button variant="link" onClick={() => setShowTopUpDetails(!showTopUpDetails)} className="px-1">
                      Wie funktioniert das Guthabensystem?
                    </Button>
                  </p>
                  <Collapse in={showTopUpDetails}>
                    <div id="topUpDetailsText">
                      <Explainer />
                    </div>
                  </Collapse>
                  <Button as={Link} to={Path.toAccountTopUp} type="submit" variant="warning" size="lg">
                    <IconPlus />&nbsp; Guthaben jetzt aufladen
                  </Button>
                </div>
                <div className="text-center mx-5 my-auto">
                  Aktuelles&nbsp;Guthaben
                  <h1 className="rounded-lg px-3 pb-0 mb-0 mt-1 font-weight-bold text-success">{d.currentBalance}</h1>
                  Zeilen
                </div>
              </Jumbotron>
              <div className="d-flex flex-row mt-3">
                <h1>
                  {pg.t('usage.reportHeading')}
                </h1>
                <Dropdown>
                  <Dropdown.Toggle as={HeadingToggle} id="dropdown-month">
                    {formatDMY(period.startDate)} – {formatDMY(period.endDate)}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {d.periods.map(p =>
                      <Dropdown.Item key={p.usagePeriodId} onClick={() => setSelectedUsagePeriodId(p.usagePeriodId)}>
                        {formatDMY(p.startDate)} – {formatDMY(p.endDate)}
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <UsageReport
                usagePeriodId={usagePeriodId}
              />
            </>
          );
        }}
      </FetchedResource>
    </ContainerLeft>
  )
}

import classNames from 'classnames';
import { last, uniq, toString, isArray, flatten, isString, tail, escapeRegExp } from 'lodash';
import fp from 'lodash/fp';
import React from 'react';
import { useMemo, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { usePopper } from 'react-popper';
import { useEventListener } from '../Hooks';
import { IconX } from '../icons';

type ChipVariant = 'default'|'composite';

export interface ChipSelectOption<K> {
  itemKey: K;
  label: string;
  variant?: ChipVariant;
}

export interface ChipSelectGroup<K> {
  label: string;
  items: ChipSelectOption<K>[];
}

export type ChipSelectItem<K> = ChipSelectOption<K>|ChipSelectGroup<K>;

function isChipSelectOption<K>(item: ChipSelectItem<K>): item is ChipSelectOption<K> {
  return (item as any).itemKey !== undefined;
}

function isChipSelectGroup<K>(item: ChipSelectItem<K>): item is ChipSelectGroup<K> {
  return isArray((item as any).items);
}

interface ChipSelectMenuItemData<K> {
  itemKey: K;
  header?: string;
  label: string;
}

const isIncludedInMenu = <K,>(exclude: K[], pattern: RegExp) => (item: ChipSelectItem<K>) => {
  if(isChipSelectOption(item)) {
    if(exclude.includes(item.itemKey)) {
      return false;
    }
    if(!item.label.match(pattern)) {
      return false;
    }
  }
  return true;
}

function createMenu<K>(items: ChipSelectItem<K>[], exclude: K[], pattern: RegExp): ChipSelectMenuItemData<K>[] {
  const filterFun = isIncludedInMenu(exclude, pattern);
  return fp.flow(
    fp.map((item: ChipSelectItem<K>) => {
      if(isChipSelectGroup(item)) {
        const filteredGroupItems = item.items.filter(filterFun);
        if(filteredGroupItems.length === 0) {
          return undefined;
        } else {
          return [
            {
              ...filteredGroupItems[0],
              header: item.label
            },
            ...tail(filteredGroupItems)
          ];
        }
      } else {
        if(filterFun(item)) {
          return item;
        } else {
          return undefined;
        }
      }
    }),
    fp.flatten,
    fp.compact
  )(items);
}

interface ChipProps {
  children: string;
  active?: boolean;
  variant?: ChipVariant;
  onSelect?: () => void;
  onBlur?: React.FocusEventHandler;
  onRemove?: () => void;
}

function Chip(props: ChipProps) {
  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if(props.onRemove && (e.key === "Backspace" || e.key === "Delete")) {
      props.onRemove();
    }
  }

  return (
    <div
      role="option"
      aria-selected
      aria-label={props.children}
      className={classNames("ChipSelect__Chip", {
        "ChipSelect__Chip--Composite": props.variant === 'composite',
        "ChipSelect__Chip--Active": props.active
      })}
      onClick={props.onSelect}
      onFocus={props.onSelect}
      onBlur={props.onBlur}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      {props.children}
      <IconX className="ChipSelect__ChipRemove" strokeWidth={3} size={18} onClick={props.onRemove} />
    </div>
  )
}

interface ChipSelectMenuItemProps {
  children: string;
  header?: string;
  active?: boolean;
  onSelect?: () => void;
}

function ChipSelectMenuItem(props: ChipSelectMenuItemProps) {
  return (
    <>
      {isString(props.header) &&
        <div className="ChipSelect__MenuHeader">
          {props.header}
        </div>
      }
      <div
        className={classNames("ChipSelect__MenuItem", { "ChipSelect__MenuItem--Active": props.active })}
        onClick={props.onSelect}
        onKeyDown={e => {
          if(props.onSelect && e.key === "Enter") {
            props.onSelect();
            e.preventDefault();
          }
        }}
      >
        {props.children}
      </div>
    </>
  );
}

interface ChipSelectMenuProps {
  children?: any;
}

function ChipSelectMenu(props: ChipSelectMenuProps) {
  return (
    <div className="ChipSelect__Menu">
      {props.children}
    </div>
  );
}

export interface ChipSelectProps<K> {
  id?: string;
  placeholder?: string;
  items: ChipSelectItem<K>[];
  selected: K[];
  onSelect: (selected: K[]) => void;
}

export function ChipSelect<K>(props: ChipSelectProps<K>) {
  const [referenceElement, setReferenceElement] = useState<HTMLElement|null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement|null>(null);
  const [textElement, setTextElement] = useState<HTMLElement|null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
  });

  const [activeChip, setActiveChip] = useState<K|undefined>(); // position in `selected` array
  const [activeSuggestion, setActiveSuggestion] = useState<K|undefined>(); // position in `remaining` array
  const [text, setText] = useState('');
  const [show, setShow] = useState(false);

  const chipList = useMemo(() =>
    flatten(props.items.map(item => isChipSelectGroup(item) ? item.items : item)),
    [props.items]
  );

  const chipMap = useMemo(() => {
    return new Map(chipList.map(chip => [chip.itemKey, chip]));
  }, [chipList]);

  const selected = useMemo(() =>
    fp.flow(
      fp.map((itemKey: K) => chipMap.get(itemKey)),
      fp.compact,
      fp.sortBy(chip => chip.variant)
    )(props.selected),
    [chipMap, props.selected]
  );

  const chipMenu = useMemo(() => {
    const pattern = new RegExp(escapeRegExp(text), 'i')
    return createMenu(props.items, selected.map(option => option.itemKey), pattern);
  }, [props.items, selected, text]);

  function handleSelect(key: K) {
    setText('');
    setActiveChip(undefined);
    props.onSelect(uniq([...props.selected, key]));
    textElement?.focus();
  }

  function handleRemove(key: K) {
    const index = props.selected.indexOf(key);
    const newSelected = [...props.selected.slice(0, index), ...props.selected.slice(index+1)];
    props.onSelect(newSelected);
    setActiveChip(undefined);
    textElement?.focus();
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setText(e.target.value);
    setActiveChip(undefined);
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if(e.key === "Backspace") {
      // press backspace to delete last chip. user needs to press it twice because at the first time it selects the
      // chip and the second time it actually deletes the chip
      if(text.length === 0 && props.selected.length > 0) {
        e.preventDefault();
        if(activeChip === undefined) {
          setActiveChip(last(props.selected)!);
        } else {
          handleRemove(last(props.selected)!);
        }
      }
      setActiveSuggestion(undefined);
    } else if(e.shiftKey === false && e.key === "Enter") {
      // select active suggestion from chip menu list
      // if there is only one suggestion in the list, take it
      e.preventDefault();
      if(activeSuggestion !== undefined) {
        handleSelect(activeSuggestion);
      } else if(chipMenu.length === 1) {
        handleSelect(chipMenu[0].itemKey);
      }
      setActiveSuggestion(undefined);
      setActiveChip(undefined);
    } else if(e.key === 'ArrowDown') {
      // highlight the next suggestion in the menu
      if(chipMenu.length > 0) {
        const currentIndex = chipMenu.findIndex(chip => chip.itemKey === activeSuggestion);
        let nextIndex;
        if(currentIndex === -1) {
          nextIndex = 0;
        } else {
          nextIndex = (currentIndex + 1) % chipMenu.length;
        }
        setActiveSuggestion(chipMenu[nextIndex].itemKey);
        textElement?.focus();
      }
      setActiveChip(undefined);
    } else if(e.key === 'ArrowUp') {
      // highlight the previous suggestion in the menu
      if(chipMenu.length > 0) {
        const currentIndex = chipMenu.findIndex(chip => chip.itemKey === activeSuggestion);
        let nextIndex;
        if(currentIndex === -1) {
          nextIndex = chipMenu.length - 1;
        } else {
          nextIndex = (chipMenu.length + currentIndex - 1) % chipMenu.length;
        }
        setActiveSuggestion(chipMenu[nextIndex].itemKey);
        textElement?.focus();
      }
      setActiveChip(undefined);
    } else if(e.key === 'Tab') {
      // pressing tab means user leaves input field
      setShow(false);
    }
  }

  useEventListener('mousedown', (e: Event) => {
    if(referenceElement?.contains(e.target as Node) === false) {
      setShow(false);
    }
  })

  return (
    <div className="ChipSelect" ref={setReferenceElement} id={props.id}>
      {selected.map((chip, index) => {
        return (
          <Chip
            key={`${index}-${toString(chip.itemKey)}`}
            active={chip.itemKey === activeChip}
            variant={chip.variant}
            onSelect={() => setActiveChip(chip.itemKey)}
            onRemove={() => handleRemove(chip.itemKey)}
            onBlur={() => chip.itemKey === activeChip && setActiveChip(undefined)}
          >
            {chip.label}
          </Chip>
        );
      })}
      <FormControl
        className="ChipSelect__Input"
        value={text}
        onFocus={() => setShow(true)}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={selected.length === 0 ? props.placeholder : undefined}
        ref={setTextElement}
        formNoValidate
      />
      <div
        className={classNames({'invisible': !show})}
        ref={setPopperElement}
        style={{...styles.popper, zIndex: 100}}
        {...attributes.popper}
      >
        <ChipSelectMenu>
          {chipMenu.map(chip =>
            <ChipSelectMenuItem
              key={toString(chip.itemKey)}
              onSelect={() => handleSelect(chip.itemKey)}
              active={chip.itemKey === activeSuggestion || chipMenu.length === 1}
              header={chip.header}
            >
              {chip.label}
            </ChipSelectMenuItem>
          )}
        </ChipSelectMenu>
      </div>
    </div>
  );
}

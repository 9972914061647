import { useField } from 'formik';
import React from 'react';
import { DropdownSingleSelect, DropdownSingleSelectItem } from './DropdownSingleSelect';

export interface DropdownSingleSelectFieldProps<T> {
  id: string;
  name: string;
  items: DropdownSingleSelectItem<T>[];
  variant?: any;
  placeholder?: string;
  className?: string;
}

export function DropdownSingleSelectField<T extends string>({id, name, items, variant,
  placeholder, className}: DropdownSingleSelectFieldProps<T>) {
  const [field,, helpers] = useField(name);
  return (
    <DropdownSingleSelect
      id={id}
      items={items}
      variant={variant}
      placeholder={placeholder}
      selected={field.value}
      onSelect={helpers.setValue}
      className={className}
    />
  );
}

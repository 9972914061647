import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { usePolyglot } from '../Localization/Localization';
import { Formik, Form as FormikForm, FormikErrors } from 'formik';
import { FoboField } from '../Components/FoboField';
import { memberof } from '../Utils';
import { trim } from 'lodash';

export interface TeamEditorModalProps {
  show: boolean;
  isEdit?: boolean;
  name?: string;
  onCancel: () => void;
  onSubmit: (name: string) => Promise<void>;
}

interface TeamEditorModalData {
  name: string;
}

export function TeamEditorModal(props: TeamEditorModalProps) {
  const pg = usePolyglot();
  return (
    <Modal show={props.show} onHide={props.onCancel}>
      <Formik
        initialValues={{ name: props.name || '' }}
        validate={values => {
          let errors: FormikErrors<TeamEditorModalData> = {}
          if(trim(values.name) === '') {
            errors.name = pg.t('validation.notEmpty');
          }

          return errors;
        }}
        onSubmit={values => props.onSubmit(values.name)}
      >
        {({ isSubmitting, values, setSubmitting, dirty }) => (
          <Form as={FormikForm}>
            <Modal.Header closeButton>
              <Modal.Title>{props.isEdit ? pg.t('account.editTeam') : pg.t('account.createTeam')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="formName">
                <Form.Label>{pg.t('general.name')}</Form.Label>
                <FoboField
                  type="text"
                  name={memberof<TeamEditorModalData>('name')}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={props.onCancel} disabled={isSubmitting}>
                {pg.t('general.cancel')}
              </Button>
              <Button type="submit" variant="primary" disabled={isSubmitting}>
                {pg.t('general.create')}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

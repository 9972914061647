
import React, { useEffect } from 'react';
import { useGlobalState } from '../GlobalStateContext';


export interface TitleProps {
  children?: string;
}

/** Helper component to set the title */
export function Title(props: TitleProps) {
  const globalState = useGlobalState();
  const newTitle = props.children || '';

  useEffect(() => {
    if(globalState.title !== newTitle) {
      globalState.setTitle(newTitle);
    }
  }, [globalState, newTitle]);
  return <></>;
}

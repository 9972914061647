import React, { useCallback, useEffect, useState } from 'react';
import { UsageInvoiceAddressDto } from '../Generated/BackendTypes';
import { useAuthFetch } from '../AuthService';
import { getCountryNames, useLanguage, usePolyglot } from '../Localization/Localization';
import { LoadingSpinner } from '../Components/UtilityComponents';
import { Alert } from 'react-bootstrap';
import { EditInvoiceAddress } from './EditInvoiceAddress';
import { ShowInvoiceAddress } from './ShowInvoiceAddress';
import { FormikErrors } from 'formik';
import { usageFetchInvoiceAddress, usageUpdateInvoiceAddress } from '../BackendService';
import { resemblesEmail } from '../Utils';

export type InvoiceAddressMode = 'company'|'private';

export interface InvoiceAddressData {
  emailAddress: string;
  mode: InvoiceAddressMode;
  firstName: string;
  lastName: string;
  companyName: string;
  companyAddition: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
}

function toInvoiceAddressData(d: UsageInvoiceAddressDto): InvoiceAddressData {
  return {
    ...d,
    mode: d.isCompanyAddress ? 'company' : 'private'
  }
}

function fromInvoiceAddressData(d: InvoiceAddressData): UsageInvoiceAddressDto {
  return {
    ...d,
    isCompanyAddress: d.mode === 'company'
  }
}

export interface InvoiceAddressContainerProps {
  editing?: (isEditing: boolean) => void;
}

export function InvoiceAddressContainer(props: InvoiceAddressContainerProps) {
  const authFetch = useAuthFetch();
  const pg = usePolyglot();
  const language = useLanguage();
  const [address, setAddress] = useState<InvoiceAddressData | undefined>(undefined);
  const [supportedCountryCodes, setSupportedCountryCodes] = useState<string[]>([]);
  const [editRequired, setEditRequired] = useState(false);
  const [editing, setEditingState] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const [countryNames, setCountryNames] = useState<Map<string, string>>(new Map());
  useEffect(() => {
    getCountryNames(language).then(countryNames => setCountryNames(countryNames));
  }, [language]);

  const reportEditing = props.editing;
  const setEditing = useCallback((v: boolean) => {
    if(reportEditing) {
      reportEditing(v);
    }
    setEditingState(v);
  }, [reportEditing,  setEditingState]);

  useEffect(() => {
    setLoading(true);
    usageFetchInvoiceAddress(authFetch)
    .then(response => {
      setAddress(toInvoiceAddressData(response.invoiceAddress));
      setSupportedCountryCodes(response.supportedCountryCodes);
      if(response.editRequired) {
        setEditRequired(true);
        setEditing(true);
      }
    })
    .catch(error => setError(error.message))
    .finally(() => setLoading(false));
  }, [authFetch, setEditing]);

  async function handleUpdate(address: InvoiceAddressData) {
    setLoading(true);
    try {
      setAddress(toInvoiceAddressData(await usageUpdateInvoiceAddress(authFetch, fromInvoiceAddressData(address))));
      setEditing(false);
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  }

  function handleCancel() {
    setEditing(false);
  }

  function handleValidate(d: InvoiceAddressData) {
    const errors: FormikErrors<InvoiceAddressData> = {};

    if(!resemblesEmail(d.emailAddress)) {
      errors.emailAddress = pg.t('validation.enterEmailAddress');
    }

    if(d.mode === 'private') {
      const firstNameLength = d.firstName.trim().length;
      if(firstNameLength < 2) {
        errors.firstName = pg.t('validation.atLeastTwoCharacters');
      } else if(firstNameLength > 50) {
        errors.firstName = pg.t('validation.tooLong');
      }
      const lastNameLength = d.lastName.trim().length;
      if(lastNameLength < 2) {
        errors.lastName = pg.t('validation.atLeastTwoCharacters');
      } else if(lastNameLength > 50) {
        errors.lastName = pg.t('validation.tooLong');
      }
    } else if(d.mode === 'company') {
      const companyNameLength = d.companyName.trim().length;
      if(companyNameLength < 2) {
        errors.companyName = pg.t('validation.atLeastTwoCharacters');
      } else if(companyNameLength > 50) {
        errors.companyName = pg.t('validation.tooLong');
      }
      const companyAdditionLength = d.companyAddition.trim().length;
      if(companyAdditionLength < 2) {
        errors.companyAddition = pg.t('validation.atLeastTwoCharacters');
      } else if(companyAdditionLength > 50) {
        errors.companyAddition = pg.t('validation.tooLong');
      }
    }

    const postalCodeLength = d.postalCode.trim().length;
    if(postalCodeLength < 2) {
      errors.postalCode = pg.t('validation.atLeastTwoCharacters');
    } else if(postalCodeLength > 20) {
      errors.postalCode = pg.t('validation.tooLong');
    }

    const addressLength = d.address.trim().length;
    if(addressLength < 2) {
      errors.address = pg.t('validation.atLeastTwoCharacters');
    } else if(addressLength > 300) {
      errors.address = pg.t('validation.tooLong');
    }

    const cityLength = d.city.trim().length;
    if(cityLength < 2) {
      errors.city = pg.t('validation.atLeastTwoCharacters');
    } else if(cityLength > 50) {
      errors.city = pg.t('validation.tooLong');
    }

    return errors;
  }

  if (address === undefined) {
    if (loading) {
      return (
        <LoadingSpinner />
      );
    } else {
      return (
        <Alert variant="danger">{pg.t('general.errorMessageIntro')}: {error}</Alert>
      );
    }
  } else {
    if (editing) {
      return (
        <EditInvoiceAddress
          address={address}
          supportedCountryCodes={supportedCountryCodes}
          countryNames={countryNames}
          error={error}
          onSubmit={handleUpdate}
          onCancel={handleCancel}
          validate={handleValidate}
          hideCancel={editRequired}
        />
      );
    } else {
      return (
        <ShowInvoiceAddress
          address={address}
          onEdit={() => setEditing(true)}
        />
      )
    }
  }
}

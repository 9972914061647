import { backendBaseUrl } from './config';
import { createFetch, createFetchWithoutId, createCreate, createDelete, performUpdate, performFetch, performCreate, performDelete, performCreateNoContent, performDeleteWithContent } from './CrudService';
import { ScheduleId, PersonGroupId, TenantId, UserId, TeamId } from './Types';
import { AuthFetch } from './AuthService';
import {
  AppConfDto, ConditionListDto,
  PersonListDto, PersonGroupUpsertDto, PersonGroupListDto, ScheduleCloneRequestDto, ScheduleCloneResponseDto,
  ScheduleCreateRequestDto, ScheduleCreateResponseDto, ScheduleImportResponseDto, ScheduleInputDto,
  ScheduleInputUpdateDto, ScheduleListDto, ScheduleMetadataResponseDto,
  ShiftListDto, SiteAdminTenantListDto, StaffDemandAdjustmentsDto, ConditionDto_Cases,
  ConditionReadDto, ConditionCreateDto, ConditionUpdateDto, ConditionAutocompleteDto, ConditionReadTemplateDto,
  StaffDemandTableDto, StaffDemandEditDto, StaffDemandReadDto, ScheduleSettingsDto, SolverTaskCreateDto,
  ComputationAdjustmentsDto, ComputationResultDto, ComputationMonitorDto, ComputationMonitorResultsDto,
  SiteAdminSwitchTenantRequestDto, TenantDto, TenantUpdateMemberRequestDto, TenantCreateInviteRequestDto, UserDto,
  TenantUpdateDto, UserUpdateDto, TeamListDto, TeamUpdateDto, TeamCreateDto, TenantMemberAndInviteListDto,
  UsagePeriodListDto, UsageReportDto, UsageInvoiceAddressDto, UsageCreateCheckoutSessionRequestDto, UsageInvoicePdfDto,
  UsageInvoiceAddressEditDto, ComputationCheckoutInfoDto, ShiftUpsertDto, ShiftSetDefaultDto, ShiftOrderingReadDto,
  ShiftOrderingUpdateDto,
  PersonUpsertDto,
  PersonOrderingReadDto,
  PersonOrderingUpdateDto,
  PersonGroupOrderingReadDto,
  PersonGroupOrderingUpdateDto,
  ScheduleExampleListDto,
  ScheduleExampleCreateRequestDto,
  ScheduleExampleCreateResponseDto,
} from './Generated/BackendTypes';
import { isNumber } from 'lodash';

// generic request function
export async function request<T>(authFetch: AuthFetch, endpoint: string, method: 'PUT'|'POST', obj: object) {
  const params: RequestInit = {
    method: method,
    body: JSON.stringify(obj),
    headers: {
      'Content-Type': 'text/json'
    },
    cache: 'no-cache'
  };

  const response = await authFetch(endpoint, params);

  if(!response.ok) {
    throw new Error("got a " + response.status + ": '" + response.statusText + "' during update");
  }

  const data = await response.json();
  return data as T;
}

//
// App config
export const fetchAppConf = () => createFetchWithoutId<AppConfDto>(backendBaseUrl+'/appconf')(fetch);

//
// Site Administration
const siteadmUrlTenants = `${backendBaseUrl}/siteadm/tenants`;
export const siteadmFetchTenants = (authFetch: AuthFetch) =>
  performFetch<SiteAdminTenantListDto>(authFetch, siteadmUrlTenants);
export const siteadmSwitchTenant = (authFetch: AuthFetch, tenantId: TenantId) =>
  performCreateNoContent<SiteAdminSwitchTenantRequestDto>(authFetch, siteadmUrlTenants, { tenantId });

//
// User
const userUrl = `${backendBaseUrl}/user`;
export const userFetch = (authFetch: AuthFetch) =>
  performFetch<UserDto>(authFetch, userUrl);
export const userUpdate = (authFetch: AuthFetch, d: UserUpdateDto) =>
  performUpdate<UserDto, UserUpdateDto>(authFetch, userUrl, d);

//
// Tenant
const tenantUrl = `${backendBaseUrl}/tenant`
const tenantMemberUrlUnspecific = `${backendBaseUrl}/tenant/member`;
const tenantMemberUrlSpecific = (userId: UserId) => `${backendBaseUrl}/tenant/member/${userId}`;
const tenantInviteUrl = `${backendBaseUrl}/tenant/invite`;

export const tenantFetch = (authFetch: AuthFetch) =>
  performFetch<TenantDto>(authFetch, tenantUrl);
export const tenantUpdate = (authFetch: AuthFetch, d: TenantUpdateDto) =>
  performUpdate<TenantDto, TenantUpdateDto>(authFetch, tenantUrl, d);

export const tenantListMembersAndInvites = (authFetch: AuthFetch) =>
  performFetch<TenantMemberAndInviteListDto>(authFetch, tenantMemberUrlUnspecific);
export const tenantRemoveMember = (authFetch: AuthFetch, userId: UserId) =>
  performDeleteWithContent<TenantMemberAndInviteListDto>(authFetch, tenantMemberUrlSpecific(userId));
export const tenantUpdateMember = (authFetch: AuthFetch, userId: UserId, isAdmin: boolean) =>
  performUpdate<TenantMemberAndInviteListDto, TenantUpdateMemberRequestDto>(authFetch, tenantMemberUrlSpecific(userId), { isAdmin });
export const tenantCreateInvite = (authFetch: AuthFetch, name: string, emailAddress: string) =>
  performCreate<TenantMemberAndInviteListDto, TenantCreateInviteRequestDto>(authFetch, tenantInviteUrl, { name, emailAddress });
export const tenantDeleteInvite = (authFetch: AuthFetch, emailAddress: string) =>
  performDeleteWithContent<TenantMemberAndInviteListDto>(authFetch, `${tenantInviteUrl}?emailAddress=${encodeURIComponent(emailAddress)}`);

//
// Usage
export const usageFetchList = (authFetch: AuthFetch) =>
  performFetch<UsagePeriodListDto>(authFetch, `${backendBaseUrl}/usage`);
export const usageFetchReport = (authFetch: AuthFetch, usagePeriodId: number) =>
  performFetch<UsageReportDto>(authFetch, `${backendBaseUrl}/usage/${usagePeriodId}`);
export const usageCreateCheckoutSession = (authFetch: AuthFetch, d: UsageCreateCheckoutSessionRequestDto) =>
  performCreate<string, UsageCreateCheckoutSessionRequestDto>(authFetch, `${backendBaseUrl}/usage/create-checkout-session`, d);
export const usageFetchInvoiceAddress = (authFetch: AuthFetch) =>
  performFetch<UsageInvoiceAddressEditDto>(authFetch, `${backendBaseUrl}/usage/invoice-address`);
export const usageUpdateInvoiceAddress = (authFetch: AuthFetch, d: UsageInvoiceAddressDto) =>
  performUpdate<UsageInvoiceAddressDto>(authFetch, `${backendBaseUrl}/usage/invoice-address`, d);

export const fetchInvoicePdf = (authFetch: AuthFetch, usagePeriodId: number, usageCreditId: number) =>
  performFetch<UsageInvoicePdfDto>(authFetch, `${backendBaseUrl}/usage/${usagePeriodId}/credit/${usageCreditId}/pdf`);

//
// Team
const teamUrlUnspecific = `${backendBaseUrl}/team`;
const teamUrlSpecific = (teamId: TeamId) => `${backendBaseUrl}/team/${teamId}`;
export const teamFetchList = (authFetch: AuthFetch) =>
  performFetch<TeamListDto>(authFetch, teamUrlUnspecific);
export const teamCreate = (authFetch: AuthFetch, team: TeamCreateDto) =>
  performCreate<TeamListDto, TeamCreateDto>(authFetch, teamUrlUnspecific, team);
export const teamUpdate = (authFetch: AuthFetch, teamId: TeamId, team: TeamUpdateDto) =>
  performUpdate<TeamListDto, TeamUpdateDto>(authFetch, teamUrlSpecific(teamId), team);
export const teamDelete = (authFetch: AuthFetch, teamId: TeamId) =>
  performDeleteWithContent<TeamListDto>(authFetch, teamUrlSpecific(teamId));

//
// Person
const personUrlUnspecific = (scheduleId: string) => `${backendBaseUrl}/schedule/${scheduleId}/person`;
const personUrlSpecific = (scheduleId: string, personId: string) => `${backendBaseUrl}/schedule/${scheduleId}/person/${personId}`;
const personUrlOrdering = (scheduleId: string) => `${backendBaseUrl}/schedule/${scheduleId}/person/ordering`;

export const personFetchList = (authFetch: AuthFetch, scheduleId: string) =>
  performFetch<PersonListDto>(authFetch, personUrlUnspecific(scheduleId));
export const personCreate = (authFetch: AuthFetch, scheduleId: string, person: PersonUpsertDto) =>
  performCreate<PersonListDto, PersonUpsertDto>(authFetch, personUrlUnspecific(scheduleId), person);
export const personUpdate = (authFetch: AuthFetch, scheduleId: string, personId: string, person: PersonUpsertDto) =>
  performUpdate<PersonListDto, PersonUpsertDto>(authFetch, personUrlSpecific(scheduleId, personId), person);
export const personDelete = (authFetch: AuthFetch, scheduleId: string, personId: string) =>
  performDeleteWithContent<PersonListDto>(authFetch, personUrlSpecific(scheduleId, personId));
export const personFetchOrdering = (authFetch: AuthFetch, scheduleId: string) =>
  performFetch<PersonOrderingReadDto>(authFetch, personUrlOrdering(scheduleId));
export const personUpdateOrdering = (authFetch: AuthFetch, scheduleId: string, personIds: string[]) =>
  performUpdate<PersonOrderingReadDto, PersonOrderingUpdateDto>(authFetch, personUrlOrdering(scheduleId), { personIds });

//
// Shift
const shiftUrlUnspecific = (scheduleId: string) => `${backendBaseUrl}/schedule/${scheduleId}/shift`;
const shiftUrlSpecific = (scheduleId: string, shiftId: string) => `${backendBaseUrl}/schedule/${scheduleId}/shift/${shiftId}`;
const shiftUrlOrdering = (scheduleId: string) => `${backendBaseUrl}/schedule/${scheduleId}/shift/ordering`;

export const shiftFetchList = (authFetch: AuthFetch, scheduleId: string) =>
  performFetch<ShiftListDto>(authFetch, shiftUrlUnspecific(scheduleId));
export const shiftCreate = (authFetch: AuthFetch, scheduleId: string, shift: ShiftUpsertDto) =>
  performCreate<ShiftListDto, ShiftUpsertDto>(authFetch, shiftUrlUnspecific(scheduleId), shift);
export const shiftUpdate = (authFetch: AuthFetch, scheduleId: string, shiftId: string, shift: ShiftUpsertDto) =>
  performUpdate<ShiftListDto, ShiftUpsertDto>(authFetch, shiftUrlSpecific(scheduleId, shiftId), shift);
export const shiftDelete = (authFetch: AuthFetch, scheduleId: string, shiftId: string) =>
  performDeleteWithContent<ShiftListDto>(authFetch, shiftUrlSpecific(scheduleId, shiftId));
export const shiftSetDefault = (authFetch: AuthFetch, scheduleId: string, shiftId: string) =>
  performUpdate<ShiftListDto, ShiftSetDefaultDto>(authFetch, shiftUrlUnspecific(scheduleId), { shiftId });
export const shiftFetchOrdering = (authFetch: AuthFetch, scheduleId: string) =>
  performFetch<ShiftOrderingReadDto>(authFetch, shiftUrlOrdering(scheduleId));
export const shiftUpdateOrdering = (authFetch: AuthFetch, scheduleId: string, shiftIds: string[]) =>
  performUpdate<ShiftOrderingReadDto, ShiftOrderingUpdateDto>(authFetch, shiftUrlOrdering(scheduleId), { shiftIds });

// PersonGroup
const personGroupUrlUnspecific = (scheduleId: ScheduleId) => `${backendBaseUrl}/schedule/${scheduleId}/personGroup`;
const personGroupUrlSpecific = (scheduleId: ScheduleId, personGroupId: PersonGroupId) => `${backendBaseUrl}/schedule/${scheduleId}/personGroup/${personGroupId}`;
const personGroupUrlOrdering = (scheduleId: string) => `${backendBaseUrl}/schedule/${scheduleId}/personGroup/ordering`;

export const personGroupFetchList = (authFetch: AuthFetch, scheduleId: string) =>
  performFetch<PersonGroupListDto>(authFetch, personGroupUrlUnspecific(scheduleId));
export const personGroupCreate = (authFetch: AuthFetch, scheduleId: string, personGroup: PersonGroupUpsertDto) =>
  performCreate<PersonGroupListDto, PersonGroupUpsertDto>(authFetch, personGroupUrlUnspecific(scheduleId), personGroup);
export const personGroupUpdate = (authFetch: AuthFetch, scheduleId: string, personGroupId: string, personGroup: PersonGroupUpsertDto) =>
  performUpdate<PersonGroupListDto, PersonGroupUpsertDto>(authFetch, personGroupUrlSpecific(scheduleId, personGroupId), personGroup);
export const personGroupDelete = (authFetch: AuthFetch, scheduleId: string, personGroupId: string) =>
  performDeleteWithContent<PersonGroupListDto>(authFetch, personGroupUrlSpecific(scheduleId, personGroupId));
export const personGroupFetchOrdering = (authFetch: AuthFetch, scheduleId: string) =>
  performFetch<PersonGroupOrderingReadDto>(authFetch, personGroupUrlOrdering(scheduleId));
export const personGroupUpdateOrdering = (authFetch: AuthFetch, scheduleId: string, personGroupIds: string[]) =>
  performUpdate<PersonGroupOrderingReadDto, PersonGroupOrderingUpdateDto>(authFetch, personGroupUrlOrdering(scheduleId), { personGroupIds });

// Condition
const conditionUrlUnspecific = (scheduleId: string) => `${backendBaseUrl}/schedule/${scheduleId}/condition`;
const conditionUrlSpecific = (scheduleId: string, conditionPos: number) => `${backendBaseUrl}/schedule/${scheduleId}/condition/${conditionPos}`;
const conditionUrlAutocomplete = (scheduleId: string) => `${backendBaseUrl}/schedule/${scheduleId}/condition/autocomplete`;
const conditionUrlTemplate = (scheduleId: string, typeName: string) => `${backendBaseUrl}/schedule/${scheduleId}/condition/template?type=${typeName}`;

export const conditionFetchList = (authFetch: AuthFetch, scheduleId: string) =>
  performFetch<ConditionListDto>(authFetch, conditionUrlUnspecific(scheduleId));
export const conditionCreate = (authFetch: AuthFetch, scheduleId: string, condition: ConditionCreateDto) =>
  performCreate<ConditionReadDto, ConditionCreateDto>(authFetch, conditionUrlUnspecific(scheduleId), condition);
export const conditionRead = (authFetch: AuthFetch, scheduleId: string, conditionPos: number) =>
  performFetch<ConditionReadDto>(authFetch, conditionUrlSpecific(scheduleId, conditionPos));
export const conditionUpdate = (authFetch: AuthFetch, scheduleId: string, conditionPos: number, condition: ConditionUpdateDto) =>
  performUpdate<ConditionReadDto, ConditionUpdateDto>(authFetch, conditionUrlSpecific(scheduleId, conditionPos), condition);
export const conditionDelete = (authFetch: AuthFetch, scheduleId: string, conditionPos: number) =>
  performDelete(authFetch, conditionUrlSpecific(scheduleId, conditionPos));
export const conditionFetchAutocomplete = (authFetch: AuthFetch, scheduleId: string) =>
  performFetch<ConditionAutocompleteDto>(authFetch, conditionUrlAutocomplete(scheduleId));
export const conditionReadTemplate = (authFetch: AuthFetch, scheduleId: string, typeName: ConditionDto_Cases) =>
  performFetch<ConditionReadTemplateDto>(authFetch, conditionUrlTemplate(scheduleId, typeName));

// StaffDemand
const staffDemandUrlUnspecific = (scheduleId: string) => `${backendBaseUrl}/schedule/${scheduleId}/demand`;
const staffDemandUrlUpdateAdjustments = (scheduleId: string) => `${backendBaseUrl}/schedule/${scheduleId}/demand/adjustments`;
const staffDemandUrlSpecific = (scheduleId: string, staffDemandIdx: number) => `${backendBaseUrl}/schedule/${scheduleId}/demand/${staffDemandIdx}`;
const staffDemandUrlTemplate = (scheduleId: string) => `${backendBaseUrl}/schedule/${scheduleId}/demand/template`;

export const staffDemandFetchTable = (authFetch: AuthFetch, scheduleId: string) =>
  performFetch<StaffDemandTableDto>(authFetch, staffDemandUrlUnspecific(scheduleId));
export const staffDemandCreate = (authFetch: AuthFetch, scheduleId: string, staffDemand: StaffDemandEditDto) =>
  performCreate<StaffDemandReadDto, StaffDemandEditDto>(authFetch, staffDemandUrlUnspecific(scheduleId), staffDemand);
export const staffDemandRead = (authFetch: AuthFetch, scheduleId: string, staffDemandIdx: number) =>
  performFetch<StaffDemandReadDto>(authFetch, staffDemandUrlSpecific(scheduleId, staffDemandIdx));
export const staffDemandReadTemplate = (authFetch: AuthFetch, scheduleId: string) =>
  performFetch<StaffDemandReadDto>(authFetch, staffDemandUrlTemplate(scheduleId));
export const staffDemandUpdate = (authFetch: AuthFetch, scheduleId: string, staffDemandIdx: number, staffDemand: StaffDemandEditDto) =>
  performUpdate<StaffDemandReadDto, StaffDemandEditDto>(authFetch, staffDemandUrlSpecific(scheduleId, staffDemandIdx), staffDemand);
export const staffDemandDelete = (authFetch: AuthFetch, scheduleId: string, staffDemandIdx: number) =>
  performDelete(authFetch, staffDemandUrlSpecific(scheduleId, staffDemandIdx));
export const staffDemandUpdateAdjustments = (authFetch: AuthFetch, scheduleId: string, d: StaffDemandAdjustmentsDto) =>
  performUpdate<StaffDemandTableDto, StaffDemandAdjustmentsDto>(authFetch, staffDemandUrlUpdateAdjustments(scheduleId), d);

// Computation
const computationUrlUnspecific = (scheduleId: string) => `${backendBaseUrl}/schedule/${scheduleId}/computation`;
const computationUrlUpdateAdjustments = (scheduleId: string) => `${backendBaseUrl}/schedule/${scheduleId}/computation/adjustments`;
const computationUrlMonitor = (scheduleId: string) =>
  `${backendBaseUrl}/schedule/${scheduleId}/computation/monitor`;
const computationUrlMonitorResults = (scheduleId: string, solverTaskId: number, solverResultId?: number) =>
  `${backendBaseUrl}/schedule/${scheduleId}/computation/monitor/${solverTaskId}` + (isNumber(solverResultId) ? `?resultId=${solverResultId}` : '');
const computationUrlCheckoutInfo = (scheduleId: string) =>
  `${backendBaseUrl}/schedule/${scheduleId}/computation-checkout`;

export const computationFetchResult = (authFetch: AuthFetch, scheduleId: string) =>
  performFetch<ComputationResultDto>(authFetch, computationUrlUnspecific(scheduleId));
export const computationUpdateAdjustments = (authFetch: AuthFetch, scheduleId: ScheduleId, d: ComputationAdjustmentsDto) =>
  performUpdate<ComputationResultDto, ComputationAdjustmentsDto>(authFetch, computationUrlUpdateAdjustments(scheduleId), d);
export const computationFetchMonitor = (authFetch: AuthFetch, scheduleId: ScheduleId) =>
  performFetch<ComputationMonitorDto>(authFetch, computationUrlMonitor(scheduleId));
export const computationFetchMonitorResults = (authFetch: AuthFetch, scheduleId: ScheduleId, solverTaskId: number, solverResultId?: number) =>
  performFetch<ComputationMonitorResultsDto>(authFetch, computationUrlMonitorResults(scheduleId, solverTaskId, solverResultId));
export const computationFetchCheckoutInfo = (authFetch: AuthFetch, scheduleId: ScheduleId) =>
  performFetch<ComputationCheckoutInfoDto>(authFetch, computationUrlCheckoutInfo(scheduleId));

// Schedule
const scheduleUrlUnspecific = backendBaseUrl+'/schedule';
const scheduleUrlSpecific = (scheduleId: string) => `${backendBaseUrl}/schedule/${scheduleId}`;
const scheduleUrlSettings = (scheduleId: string) => `${backendBaseUrl}/schedule/${scheduleId}/settings`;
const scheduleUrlExample = `${backendBaseUrl}/schedule/create-example`;

export const fetchSchedule = createFetch<ScheduleInputDto>(scheduleUrlUnspecific);
export const fetchScheduleList = createFetchWithoutId<ScheduleListDto>(scheduleUrlUnspecific);
export const createSchedule = createCreate<ScheduleCreateRequestDto, ScheduleCreateResponseDto>(scheduleUrlUnspecific);
export const fetchScheduleMetadata = (authFetch: AuthFetch, scheduleId: ScheduleId) =>
  performFetch<ScheduleMetadataResponseDto>(authFetch, `${backendBaseUrl}/schedule/${scheduleId}/meta`);

export const fetchExampleList = createFetchWithoutId<ScheduleExampleListDto>(scheduleUrlExample);
export const createScheduleFromExample = createCreate<ScheduleExampleCreateRequestDto, ScheduleExampleCreateResponseDto>(scheduleUrlExample);

// update schedule
export const updateScheduleInputEntries = (authFetch: AuthFetch, scheduleId: string, d: ScheduleInputUpdateDto) =>
  performUpdate<ScheduleInputDto, ScheduleInputUpdateDto>(authFetch, scheduleUrlSpecific(scheduleId), d);

export const scheduleFetchSettings = (authFetch: AuthFetch, scheduleId: string) =>
  performFetch<ScheduleSettingsDto>(authFetch, scheduleUrlSettings(scheduleId));
export const scheduleUpdateSettings = (authFetch: AuthFetch, scheduleId: ScheduleId, d: ScheduleSettingsDto) =>
  performUpdate<void, ScheduleSettingsDto>(authFetch, scheduleUrlSettings(scheduleId), d);

export function scheduleClearComputedShifts(authFetch: AuthFetch, scheduleId: string) {
  let endpointUrl = `${backendBaseUrl}/schedule/${scheduleId}/clearComputed`;
  return request<ScheduleInputDto>(authFetch, endpointUrl, 'POST', {});
}

export const scheduleClone = (authFetch: AuthFetch, scheduleId: string, d: ScheduleCloneRequestDto) =>
  performCreate<ScheduleCloneResponseDto, ScheduleCloneRequestDto>(authFetch, `${backendBaseUrl}/schedule/${scheduleId}/clone`, d);

export async function importSchedule(authFetch: AuthFetch, scheduleId: string, xlsxData: Blob, dryRun: boolean) {
  let endpointUrl = `${backendBaseUrl}/schedule/${scheduleId}/import?dryRun=${dryRun}`;
  const params: RequestInit = {
    method: 'POST',
    body: xlsxData
  };

  const response = await authFetch(endpointUrl, params);

  if(!response.ok) {
    throw new Error("got a " + response.status + ": '" + response.statusText + "' during update");
  }

  const data = await response.json();
  return data as ScheduleImportResponseDto;
}

export const deleteSchedule = createDelete(backendBaseUrl+'/schedule');

// SolverTask
const solverTaskUrl = (scheduleId: string) => `${backendBaseUrl}/schedule/${scheduleId}/solverTask`;

export const solverTaskCreate = (authFetch: AuthFetch, scheduleId: string, parameters: SolverTaskCreateDto) =>
  performCreate<{}, SolverTaskCreateDto>(authFetch, solverTaskUrl(scheduleId), parameters);

export const solverTaskCancel = (authFetch: AuthFetch, scheduleId: string) =>
  performDelete(authFetch, solverTaskUrl(scheduleId));

// import / export
export function linkToScheduleExcel(scheduleId: string, mode?: 'input'|'result') {
  let suffix = (mode === 'input') ? '?mode=input' : '';
  return `${backendBaseUrl}/schedule/${scheduleId}.xlsx${suffix}`;
}

// data transfer
export const linkToDataTransferScheduleExport = (scheduleId: string) =>
  `${backendBaseUrl}/transfer/schedule/${scheduleId}`;

export async function dataTransferScheduleImport(authFetch: AuthFetch, requestData: Blob, teamId: TeamId, withNewScheduleId: boolean) {
  let endpointUrl = `${backendBaseUrl}/transfer/schedule?teamId=${teamId}&withNewScheduleId=${withNewScheduleId ? 'true' : 'false'}`;
  const params: RequestInit = {
    method: 'POST',
    body: requestData
  };

  const response = await authFetch(endpointUrl, params);

  if(!response.ok) {
    throw new Error("got a " + response.status + ": '" + response.statusText + "' during update");
  }
}

import React from 'react';
import Form from 'react-bootstrap/Form';
import { Formik, Form as FormikForm } from 'formik';
import { fromPersonSelectorFormData, PersonSelectorFormData, PersonSelectorSubform, toPersonSelectorFormData } from '../PersonSelector';
import { ConditionDto_RestrictChangeFromShiftConditionDto, ConfineOrPrevent0 } from '../../Generated/BackendTypes';
import { memberof, oneof } from '../../Utils';
import { fromShiftSelectorFormData, ShiftSelector, ShiftSelectorFormData, toShiftSelectorFormData } from '../ShiftSelector';
import { FoboField } from '../../Components/FoboField';
import { renderMarkup } from '../../SimpleMarkup';
import { explainDE } from '../ExplainDE';
import { ConditionEditorCard } from '../ConditionEditorCard';
import { ConditionEditorProps } from '../ConditionEditorContainer';
import { FoboCheck } from '../../Components/FoboCheck';
import { usePolyglot } from '../../Localization/Localization';

type C = ConditionDto_RestrictChangeFromShiftConditionDto;

interface FormValues {
  enabled: boolean;
  personSelector: PersonSelectorFormData;
  fromShiftSelector: ShiftSelectorFormData;
  toShiftSelector: ShiftSelectorFormData;
  length: number;
  restriction: ConfineOrPrevent0;
}

function toFormValues(d: C): FormValues {
  return {
    enabled: d.enabled,
    personSelector: toPersonSelectorFormData(d.personSelector),
    fromShiftSelector: toShiftSelectorFormData(d.fromShiftSelector),
    toShiftSelector: toShiftSelectorFormData(d.toShiftSelector),
    length: d.length,
    restriction: d.restriction
  };
}

function fromFormValues(props: ConditionEditorProps<C>, d: FormValues): C {
  return {
    _type: props.condition._type,
    enabled: d.enabled,
    personSelector: fromPersonSelectorFormData(props.identifiers.personIds, props.identifiers.personGroupIds, d.personSelector),
    fromShiftSelector: fromShiftSelectorFormData(props.identifiers.shiftIds, d.fromShiftSelector),
    toShiftSelector: fromShiftSelectorFormData(props.identifiers.shiftIds, d.toShiftSelector),
    length: d.length,
    restriction: d.restriction,
  };
}

export function RestrictChangeFromShiftConditionEditor(props: ConditionEditorProps<C>) {
  const pg = usePolyglot();
  const initialValues = toFormValues(props.condition);

  async function handleSave(values: FormValues) {
    props.onSave && await props.onSave(fromFormValues(props, values));
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSave}
    >
      {({ isSubmitting, values, errors, isValid, setFieldValue, dirty }) => (
        <Form as={FormikForm}>
          <ConditionEditorCard
            enabled={values.enabled}
            onEnableClick={() => setFieldValue(memberof<FormValues>('enabled'), true)}
            onDisableClick={() => setFieldValue(memberof<FormValues>('enabled'), false)}
            error={props.error}
            title={renderMarkup(explainDE(fromFormValues(props, values), props.lookup))}
            dirty={dirty}
            saving={isSubmitting}
            isSubmitting={isSubmitting}
            onCancelClick={props.onCancel}
            onDeleteClick={props.onDelete}
          >
            <PersonSelectorSubform
              name={memberof<FormValues>('personSelector')}
              persons={props.dropdown.persons}
              personGroups={props.dropdown.personGroups}
            />

            <FoboCheck
              type="radio"
              id="formRestrictionConfine"
              name={memberof<FormValues>('restriction')}
              value={oneof<ConfineOrPrevent0>('Confine')}
              label={pg.t('condition.confine')}
            />

            <FoboCheck
              type="radio"
              id="formRestrictionPrevent"
              name={memberof<FormValues>('restriction')}
              value={oneof<ConfineOrPrevent0>('Prevent')}
              label={pg.t('condition.prevent')}
            />

            <Form.Group controlId="formFromShiftSelector">
              <Form.Label>Wechsel von</Form.Label>
              <ShiftSelector
                name={memberof<FormValues>('fromShiftSelector')}
                shifts={props.dropdown.shifts}
              />
            </Form.Group>

            <Form.Group controlId="formToShiftSelector">
              <Form.Label>zu</Form.Label>
              <ShiftSelector
                name={memberof<FormValues>('toShiftSelector')}
                shifts={props.dropdown.shifts}
              />
            </Form.Group>

            <Form.Group controlId="formLength">
              <Form.Label>Mindestanzahl</Form.Label>
              <FoboField
                name={memberof<FormValues>('length')}
                type="number"
                required
                min="1"
                max="30"
              />
            </Form.Group>
          </ConditionEditorCard>
        </Form>
      )}
    </Formik>
  );
}

import React, { useState, useEffect, useCallback } from 'react';
import { ScheduleId } from '../Types';
import { useAuthFetch } from '../AuthService';
import { deleteSchedule, scheduleClone, scheduleFetchSettings, scheduleUpdateSettings } from '../BackendService';
import { ScheduleConfig } from './ScheduleConfig';
import Alert from 'react-bootstrap/Alert';
import { ContainerLeft, LoadingSpinner } from '../Components/UtilityComponents';
import { ScheduleSettingsDto } from '../Generated/BackendTypes';
import { SchedulePropertiesValues } from './ScheduleProperties';
import { Path } from '../Utils';
import { useHistory } from 'react-router-dom';
import { usePolyglot } from '../Localization/Localization';
import { useUserProfile } from '../UserProfileContext';

export interface ScheduleConfigContainerProps {
  scheduleId: ScheduleId;
}

export function ScheduleConfigContainer({ scheduleId }: ScheduleConfigContainerProps) {
  const [settings, setSettings] = useState<ScheduleSettingsDto|undefined>(undefined);
  const history = useHistory();
  const authFetch = useAuthFetch();
  const pg = usePolyglot();
  const userProfile = useUserProfile();

  const [error, setError] = useState<string|undefined>();

  const reloadSchedule = useCallback(() => {
    scheduleFetchSettings(authFetch, scheduleId).then(
      settings => setSettings(settings),
      error => setError(error.message || error)
    );
  }, [authFetch, scheduleId]);

  async function handleSaveScheduleProperties(values: SchedulePropertiesValues) {
    if(settings) {
      await scheduleUpdateSettings(authFetch, scheduleId, {
        scheduleId,
        documentRevision: settings.documentRevision,
        comment: values.comment,
        standardDurationPerDay: values.standardDurationPerDay,
        standardDurationPerShift: values.standardDurationPerShift
      });

      reloadSchedule();
    }
  }

  function handleCloneSchedule() {
    if(settings) {
      scheduleClone(authFetch, scheduleId, { comment: settings.comment + ' Kopie'}).then(
        d => history.push(Path.toAvailabilityTable(d.scheduleId)),
        error => setError(error.message)
      );
    }
  }

  function handleDeleteSchedule() {
    if(settings) {
      deleteSchedule(authFetch, scheduleId).then(
        () => {
          history.push(Path.toScheduleChooser);
          window.location.reload();
        },
        error => { setError(error.message); }
      );
    }
  }

  useEffect(() => {
    reloadSchedule();
  }, [reloadSchedule]);

  return (
    <ContainerLeft>
      <h1 className="mt-3 mb-4">{pg.t('general.settings')}</h1>
      {settings &&
        <ScheduleConfig
          scheduleId={scheduleId}
          scheduleProperties={{
            comment: settings.comment,
            standardDurationPerDay: settings.standardDurationPerDay,
            standardDurationPerShift: settings.standardDurationPerShift
          }}
          showDuplicateButton={userProfile.isRoot}
          showExportButton={userProfile.isRoot}
          onSaveScheduleProperties={handleSaveScheduleProperties}
          onCloneSchedule={handleCloneSchedule}
          onDeleteSchedule={handleDeleteSchedule}
        />
      }
      {error &&
        <Alert variant='danger'>{error}</Alert>
      }
      {!settings && !error &&
        <LoadingSpinner />
      }
    </ContainerLeft>
  );
}

import { Formik, Form as FormikForm, FormikErrors, Field } from 'formik';
import { isString } from 'lodash';
import React from 'react';
import { Card, Col, Form } from 'react-bootstrap';
import { SaveButton } from '../Components/SaveButton';
import { usePolyglot } from '../Localization/Localization';
import { memberof, oneof } from '../Utils';
import { InvoiceAddressData, InvoiceAddressMode } from './InvoiceAddressContainer';
import { ConfirmationModalButton } from '../Components/ConfirmationModalButton';
import { FoboField } from '../Components/FoboField';
import { IconAlertCircle, IconX } from '../icons';

export interface EditInvoiceAddressProps {
  address: InvoiceAddressData;
  supportedCountryCodes: string[];
  countryNames: Map<string, string>;
  onSubmit: (address: InvoiceAddressData) => void|Promise<void>;
  onCancel: () => void;
  validate: (address: InvoiceAddressData) => void|FormikErrors<InvoiceAddressData>;
  error?: string;
  hideCancel?: boolean;
}

export function EditInvoiceAddress(props: EditInvoiceAddressProps) {
  const pg = usePolyglot();
  return (
    <Formik
      initialValues={props.address}
      validate={props.validate}
      onSubmit={props.onSubmit}
    >
      {({ isSubmitting, setSubmitting, values, dirty }) => (
        <Card>
          <Card.Body>
            <Card.Title>{pg.t('usage.invoiceAddress')}</Card.Title>
            <Card.Text><IconAlertCircle /> {pg.t('usage.reasonForAddressCollection')}</Card.Text>
            <Form as={FormikForm}>
              <Form.Group controlId="formEmailAddress">
                <Form.Label>{pg.t('general.emailAddress')}</Form.Label>
                <FoboField name={memberof<InvoiceAddressData>('emailAddress')} />
              </Form.Group>
              <Form.Group>
                <label className="d-block">
                  <Field
                    type="radio"
                    name={memberof<InvoiceAddressData>('mode')}
                    value={oneof<InvoiceAddressMode>('company')}
                  />
                  &nbsp;{pg.t('usage.invoiceCompany')}
                </label>
                <label className="d-block">
                  <Field
                    type="radio"
                    name={memberof<InvoiceAddressData>('mode')}
                    value={oneof<InvoiceAddressMode>('private')}
                  />
                  &nbsp;{pg.t('usage.invoicePrivate')}
                </label>
              </Form.Group>
              {values.mode === 'private' &&
                <Form.Row>
                  <Form.Group as={Col} controlId="formFirstName">
                    <Form.Label>{pg.t('usage.firstName')}</Form.Label>
                    <FoboField name={memberof<InvoiceAddressData>('firstName')} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formLastName">
                    <Form.Label>{pg.t('usage.lastName')}</Form.Label>
                    <FoboField name={memberof<InvoiceAddressData>('lastName')} />
                  </Form.Group>
                </Form.Row>
              }
              {values.mode === 'company' &&
                <Form.Row>
                  <Form.Group as={Col} controlId="formCompany">
                    <Form.Label>{pg.t('usage.company')}</Form.Label>
                    <FoboField name={memberof<InvoiceAddressData>('companyName')} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formCompanyAddition">
                    <Form.Label>{pg.t('usage.companyAddition')}</Form.Label>
                    <FoboField name={memberof<InvoiceAddressData>('companyAddition')} />
                  </Form.Group>
                </Form.Row>
              }
              <Form.Group controlId="formAddress">
                <Form.Label>{pg.t('usage.address')}</Form.Label>
                <FoboField as="textarea" rows={3} name={memberof<InvoiceAddressData>('address')} />
              </Form.Group>
              <Form.Row>
                <Form.Group as={Col} controlId="formPostalCode">
                  <Form.Label>{pg.t('usage.postalCode')}</Form.Label>
                  <FoboField name={memberof<InvoiceAddressData>('postalCode')} />
                </Form.Group>
                <Form.Group as={Col} controlId="formCity">
                  <Form.Label>{pg.t('usage.city')}</Form.Label>
                  <FoboField name={memberof<InvoiceAddressData>('city')} />
                </Form.Group>
                <Form.Group controlId="formCountry">
                  <Form.Label>{pg.t('usage.country')}</Form.Label>
                  <FoboField as="select" custom name={memberof<InvoiceAddressData>('country')}>
                    {props.supportedCountryCodes.map(code =>
                      <option key={code} value={code}>{props.countryNames.get(code) || code}</option>
                    )}
                  </FoboField>
                </Form.Group>
              </Form.Row>
              <div className="d-flex flex-row justify-content-between">
                <SaveButton
                  type="submit"
                  variant="warning"
                  dirty={true}
                  saving={isSubmitting}
                  error={isString(props.error)}
                />
                {props.hideCancel === false &&
                  <ConfirmationModalButton
                    variant="secondary"
                    onPositive={props.onCancel}
                    text={pg.t('general.unsavedChangesPrompt')}
                    passthrough={!dirty}
                  >
                    <IconX />&nbsp;{pg.t('general.cancel')}
                  </ConfirmationModalButton>
                }
              </div>
            </Form>
          </Card.Body>
        </Card>
      )}
    </Formik>
  )
}

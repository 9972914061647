import React, { useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import spacetime, { Spacetime } from 'spacetime';
import { ComputationMonitorStatus } from '../Generated/BackendTypes';
import { useInterval } from '../Hooks';

export interface SolverTaskProgressProps {
  status: ComputationMonitorStatus;
  creationTime: Spacetime;
  startTime?: Spacetime;
  maxQueueTimeSeconds: number;
  maxSolveTimeSeconds: number;
  showErrorHint?: boolean;
}

export function SolverTaskProgress(props: SolverTaskProgressProps) {
  const [currentTime, setCurrentTime] = useState<Spacetime>(spacetime.now('utc'));

  useInterval(() => {
    setCurrentTime(spacetime.now('utc'));
  }, 500);


  if(props.status === 'Running') {
    const value = (props.startTime || currentTime).diff(currentTime).seconds;
    return (
      <ProgressBar variant="success" max={props.maxSolveTimeSeconds} now={value} animated={props.showErrorHint} />
    );
  } else if(props.status === 'Queued') {
    const value = props.creationTime.diff(currentTime).seconds;
    return (
      <ProgressBar variant="info" max={props.maxQueueTimeSeconds} now={value} animated={props.showErrorHint} />
    );
  } else if(props.status === 'Completed') {
    return (
      <ProgressBar variant="success" max={100} now={100} animated={props.showErrorHint} />
    );
  } else if(props.status === 'Cancelled' || props.status === 'QueueTimeout') {
    return (
      <ProgressBar variant="warning" max={100} now={100} animated={props.showErrorHint} />
    );
  } else if(props.status === 'Failed') {
    return (
      <ProgressBar variant="danger" max={100} now={100} animated={props.showErrorHint} />
    );
  }
  return <></>;
}

import { FormikErrors } from 'formik';
import { isNil } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuthFetch } from '../AuthService';
import { shiftCreate, shiftDelete, shiftFetchList, shiftSetDefault, shiftUpdate } from '../BackendService';
import { LoadingSpinner } from '../Components/UtilityComponents';
import { ErrorDto, ShiftListDto, ShiftListItemDto, ShiftUpsertDto } from '../Generated/BackendTypes';
import { usePolyglot } from '../Localization/Localization';
import { ScheduleId, ShiftId } from '../Types';
import { ShiftEntry, ShiftTable } from './ShiftTable';
import { ContainerLeft } from '../Components/UtilityComponents';
import { IntroPanel } from '../Components/IntroPanel';
import imageShift from '../images/shift.svg';
import { ExternalLinks } from '../Utils';

const shiftLabelPattern = /^[a-zA-Z0-9.-]{1,4}$/;

function fromShiftListItemDto(d: ShiftListItemDto): ShiftEntry {
  return d;
}

function toShiftUpsertDto(d: ShiftEntry, documentRevision: number): ShiftUpsertDto {
  return {
    ...d,
    documentRevision
  };
}

export interface ShiftTableContainerProps {
  scheduleId: ScheduleId;
}

export function ShiftTableContainer(props: ShiftTableContainerProps) {
  const authFetch = useAuthFetch();
  const pg = usePolyglot();
  const [shifts, setShifts] = useState<ShiftEntry[]>([]);
  const [defaultShiftId, setDefaultShiftId] = useState<ShiftId|undefined>(undefined);
  const [rev, setRev] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<ErrorDto>();
  const scheduleId = props.scheduleId;

  const processResponse = useCallback((response: ShiftListDto) => {
    setShifts(response.shifts.map(fromShiftListItemDto));
    setDefaultShiftId(response.defaultShiftId);
    setRev(response.documentRevision);
  }, []);

  useEffect(() => {
    setLoading(true);
    setError(undefined);
    shiftFetchList(authFetch, scheduleId)
    .then(processResponse)
    .catch(error => {
      console.error(error);
      setError(error);
    })
    .finally(() => setLoading(false));
  }, [authFetch, processResponse, scheduleId]);

  function validate(d: ShiftEntry) {
    const errors: FormikErrors<ShiftEntry> = {};
    if (!d.name) {
      errors.name = pg.t('validation.notEmpty');
    } else if(d.name.trim().length === 0) {
      errors.name = pg.t('validation.notEmpty');
    } else if(d.name.trim().length > 64) {
      errors.name = pg.t('validation.notLongerThanNCharacters', { number: 64 });
    }

    if (!d.label) {
      errors.label = pg.t('validation.notEmpty');
    } else if(d.label.trim().length === 0) {
      errors.label = pg.t('validation.notEmpty');
    } else if(d.label.trim().length > 4) {
      errors.label =  pg.t('validation.atMostFourCharacters');
    } else if(isNil(d.label.match(shiftLabelPattern))) {
      errors.label = pg.t('shift.invalidCharsetForLabel');
    }

    if(!isFinite(d.duration) || d.duration < 0) {
      errors.duration = pg.t('validation.positiveInteger');
    } else if(d.duration > 1440) {
      errors.duration = pg.t('validation.notLargerThan', { number: 1440 });
    }

    return errors;
  }

  async function handleSave(d: ShiftEntry) {
    setSaving(true);
    setError(undefined);
    try {
      let response;
      if(d.shiftId === undefined) {
        response = await shiftCreate(authFetch, props.scheduleId, toShiftUpsertDto(d, rev));
      } else {
        response = await shiftUpdate(authFetch, props.scheduleId, d.shiftId, toShiftUpsertDto(d, rev));
      }
      processResponse(response);
    } catch(error) {
      console.error(error);
      setError(error);
      // throw for formik
      throw error;
    }
    setSaving(false);
  }

  async function handleDelete(shiftId: ShiftId) {
    setSaving(true);
    setError(undefined);
    try {
      const response = await shiftDelete(authFetch, props.scheduleId, shiftId);
      processResponse(response);
    } catch(error) {
      console.error(error);
      setError(error);
    }
    setSaving(false);
  }

  async function handleSetDefault(shiftId: ShiftId) {
    setSaving(true);
    setError(undefined);
    try {
      const response = await shiftSetDefault(authFetch, props.scheduleId, shiftId);
      processResponse(response);
    } catch(error) {
      console.error(error);
      setError(error);
    }
    setSaving(false);
  }

  return (

    <ContainerLeft>
      <IntroPanel imageSource={imageShift} href={ExternalLinks.toShiftDocs}>
        {pg.t('introtext.shift')}
      </IntroPanel>
      <h1 className="pt-3 mb-4">{pg.t('general.shifts')}</h1>
      {loading && <LoadingSpinner />}
      {!loading &&
        <ShiftTable
          scheduleId={scheduleId}
          shifts={shifts}
          defaultShiftId={defaultShiftId}
          saving={saving}
          error={error}
          validate={validate}
          onSave={handleSave}
          onDelete={handleDelete}
          onSetDefault={handleSetDefault}
        />
      }
    </ContainerLeft>
  );
}

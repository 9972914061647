import React from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { ExternalLinks, Path, StorageKey } from '../Utils';
import { FetchedResource } from '../FetchedResource';
import { useAuthFetch } from '../AuthService';
import { ScheduleId } from '../Types';
import { conditionFetchList } from '../BackendService';
import { ContainerLeft } from '../Components/UtilityComponents';
import { usePolyglot } from '../Localization/Localization';
import { toNamesLookup } from './Types';
import { useLocalStorage } from '../Hooks';
import { ConditionCard } from './ConditionCard';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { ConditionDto_CaseNames } from "../Generated/BackendTypes";
import { IntroPanel } from '../Components/IntroPanel';
import imageCondition from '../images/condition.svg';
import { IconEye, IconEyeOff } from '../icons';

export interface ConditionListProps {
  scheduleId: ScheduleId;
}

export function ConditionList({ scheduleId }: ConditionListProps) {
  const authFetch = useAuthFetch();
  const pg = usePolyglot();
  const [showDetails, setShowDetails] = useLocalStorage(StorageKey.conditionListDetails, true);

  return (
    <ContainerLeft>
      <IntroPanel imageSource={imageCondition} href={ExternalLinks.toConditionDocs}>
        {pg.t('introtext.condition')}
      </IntroPanel>
      <h1 className="pt-3 mb-4">{pg.t('general.conditions')}</h1>

      <div className="my-3 d-flex flex-row">
        <DropdownButton id="add-condition-dropdown" title={pg.t('condition.addNew')}>
          {ConditionDto_CaseNames.map(type =>
            <Dropdown.Item as={Link} key={type} to={Path.toNewCondition(scheduleId, type)}>{type.replace(/Dto$/, '')}</Dropdown.Item>
          )}
        </DropdownButton>
        <Button
          active={showDetails}
          variant="light"
          onClick={() => setShowDetails(!showDetails)}
          className="ml-3"
        >
          {showDetails ? <IconEye /> : <IconEyeOff />} Details
        </Button>
      </div>
      <FetchedResource fetch={() => conditionFetchList(authFetch, scheduleId)}>
        {data => {
          const namesLookup = toNamesLookup(data);
          return (
            <div className="ConditionList">
              {data.conditions.map((item, index) =>
                <ConditionCard
                  key={index}
                  scheduleId={scheduleId}
                  conditionPos={item.conditionPos}
                  condition={item.condition}
                  namesLookup={namesLookup}
                  showDetails={showDetails}
                />
              )}
            </div>
          );
        }}
      </FetchedResource>
    </ContainerLeft>
  )
}

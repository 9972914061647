import React, { useState } from 'react';
import { createSchedule } from '../BackendService';
import spacetime from 'spacetime';
import { tz_utc, Path, spacetimeFromDateOnly } from '../Utils';
import { FormikErrors } from 'formik';
import { useAuthFetch } from '../AuthService';
import { useHistory } from 'react-router-dom';
import { ScheduleCreateRequestDto, ScheduleListItemDto } from '../Generated/BackendTypes';
import { TeamId } from '../Types';
import { FormValues, DateMode, CreateSchedule } from './CreateSchedule';
import { usePolyglot } from '../Localization/Localization';

export interface CreateScheduleContainerProps {
  teamId: TeamId;
}

export function CreateScheduleContainer(props: CreateScheduleContainerProps) {
  const authFetch = useAuthFetch();
  const history = useHistory();
  const pg = usePolyglot();
  const [error, setError] = useState<string|undefined>(undefined);
  const [overlaps, setOverlaps] = useState<ScheduleListItemDto[]>([]);

  async function handleSubmit(values: FormValues) {
    setError(undefined);
    setOverlaps([]);
    let startDate;
    let endDate;
    if(values.dateMode === DateMode.DateRange) {
      startDate = spacetimeFromDateOnly(values.dateRange![0]);
      endDate = spacetimeFromDateOnly(values.dateRange![1]);
    } else {
      startDate = spacetime([parseInt(values.year, 10), parseInt(values.month, 10), 1]);
      endDate = startDate.endOf('month');
    }

    try {
      const request: ScheduleCreateRequestDto = {
        teamId: props.teamId,
        startDate: startDate.format('iso-short') as string,
        endDate: endDate.format('iso-short') as string,
      }
      const response = await createSchedule(authFetch, request);
      if(response.overlaps.length === 0) {
        history.push(Path.toUnspecificStaffDemand(response.scheduleId));
      } else {
        setError(pg.t('schedule.overlapsError'));
        setOverlaps(response.overlaps);
      }
    } catch(e) {
      setError(e.message);
    }
  }

  function handleValidate(values: FormValues): FormikErrors<FormValues> {
    const errors: FormikErrors<FormValues> = {};
    if(values.dateMode === DateMode.DateRange) {
      if(!values.dateRange) {
        errors.dateRange = 'Bitte Datumsbereich auswählen';
      } else {
        const startDate = spacetime(values.dateRange[0], tz_utc);
        const endDate = spacetime(values.dateRange[1], tz_utc);

        if(startDate > endDate) {
          errors.dateRange = 'Enddatum muss später als Startdatum sein.';
        }
      }
    }
    return errors;
  }

  return (
    <CreateSchedule
      validate={handleValidate}
      onSubmit={handleSubmit}
      error={error}
      overlaps={overlaps}
    />
  )
}

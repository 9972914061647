import React from 'react';
import DropdownItem from 'react-bootstrap/DropdownItem';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { isString } from 'lodash';

export interface DropdownSingleSelectItem<T> {
  label: string|React.ReactNode;
  itemKey: T;
  disabled?: boolean; // useful for placeholder
}

export interface DropdownSingleSelectProps<T> {
  id: string;
  items: DropdownSingleSelectItem<T>[];
  selected?: T;
  onSelect: (selected: T) => void;
  variant?: any;
  placeholder?: string;
  className?: string;
}

export function DropdownSingleSelect<T extends string>({id, onSelect, items, selected, variant,
  placeholder, className}: DropdownSingleSelectProps<T>) {
  let title;
  if(selected === undefined) {
    title = placeholder;
  } else {
    title = items.find(item => item.itemKey === selected)?.label || `<${selected}>`;
  }

  return (
    <DropdownButton
      onSelect={(s: string|null) => isString(s) && onSelect(s as T)}
      id={id}
      title={title}
      variant={variant}
      className={className}
    >
      {items.map(item =>
        <DropdownItem
          key={item.itemKey}
          eventKey={item.itemKey}
          active={selected === item.itemKey}
          disabled={item.disabled}
        >
          {item.label}
        </DropdownItem>
      )}
    </DropdownButton>
  );
}

import React from 'react';
import { Table, Dropdown } from 'react-bootstrap';
import { TenantMemberListItemDto } from '../Generated/BackendTypes';
import { UserId } from '../Types';
import { usePolyglot } from '../Localization/Localization';
import { ConfirmationModalButton } from '../Components/ConfirmationModalButton';
import { useUserProfile } from '../UserProfileContext';
import { IconX } from '../icons';

export interface MemberListProps {
  loading: boolean;
  members: TenantMemberListItemDto[];
  onRemoveMember: (userId: UserId) => void;
  onUpdateMember: (userId: UserId, adminStatus: boolean) => void;
}

export function MemberList(props: MemberListProps) {
  const pg = usePolyglot();
  const userProfile = useUserProfile();
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email-Adresse</th>
          <th>Rolle</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.members.filter(m => !m.isHidden).map(m =>
          <tr key={m.userId}>
            <td>{m.name}</td>
            <td>{m.emailAddress}</td>
            <td>
              {userProfile.isAdmin &&
                <Dropdown>
                  <Dropdown.Toggle
                    variant="link"
                    id="dropdown-basic"
                    disabled={m.userId === userProfile.userId}
                  >
                    {m.isAdmin ? pg.t('account.adminUser') : pg.t('account.normalUser')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => props.onUpdateMember(m.userId, false)}>{pg.t('account.normalUser')}</Dropdown.Item>
                    <Dropdown.Item onClick={() => props.onUpdateMember(m.userId, true)}>{pg.t('account.adminUser')}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              }
              {!userProfile.isAdmin &&
                (m.isAdmin ? pg.t('account.adminUser') : pg.t('account.normalUser'))
              }
            </td>
            <td className="py-2">
              {userProfile.isAdmin &&
                <ConfirmationModalButton
                  variant="outline-danger"
                  type="button"
                  size="sm"
                  disabled={m.userId === userProfile.userId || props.loading}
                  title={m.userId === userProfile.userId ? pg.t('account.hintCannotDeleteOneself') : pg.t('general.remove')}
                  onPositive={() => props.onRemoveMember(m.userId)}
                  text={pg.t('account.confirmRemoveMember', { emailAddress: m.emailAddress })}
                  className="float-right"
                >
                  <IconX />
                </ConfirmationModalButton>
              }
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

import React, { useEffect, useRef } from 'react';
import ReactDataSheet from 'react-datasheet';
import { toString, truncate, isFinite } from 'lodash';
import classNames from 'classnames';
import { CellViewData } from './Utils';
import { IconChevronLeft } from '../icons';

export interface CellEditorProps<T> extends ReactDataSheet.DataEditorProps<CellViewData<T>> {
  /** The value expressed using react components to achieve formatting and highlighting*/
  enhancedValue?: React.ReactElement|string;
  /** Truncate input value after this many characters */
  maxLength?: number;
  /** When supplied a callback, will show a trigger button on the right side */
  onExpandedToggle?: () => void;
  expanded?: boolean;
  innerRef?: React.Ref<HTMLDivElement>;
}

export function CellEditor<T>(props: CellEditorProps<T>) {
  const targetRef = useRef<HTMLInputElement|null>(null);

  const value = toString(props.value);
  const data = props.cell.data;

  useEffect(() => {
    targetRef.current?.focus();
  }, []);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    let value: string;
    if(isFinite(props.maxLength)) {
      value = truncate(event.target.value, { length: props.maxLength, omission: '' })
    } else {
      value = event.target.value;
    }
    props.onChange(value);
  }

  function handleExpandedToggle() {
    if(props.expanded) {
      // if currently expanded but will be collapsed -> focus text input
      targetRef.current?.focus();
    }
    if(props.onExpandedToggle) {
      props.onExpandedToggle();
    }
  }

  if(data) {
    return (
      <div className="CellEditor" ref={props.innerRef}>
        <div className={classNames('CellEditor__InputLayer', { 'CellEditor__InputLayer--WithToggle': props.onExpandedToggle })}>
          <input
            className="CellEditor__InputText"
            ref={targetRef}
            value={value}
            onChange={handleChange}
            onKeyDown={props.onKeyDown}
          />
          <div className="CellEditor__InputDecoy">{value}</div>
        </div>
        <div className="CellEditor__OutputLayer">
          <div className="CellEditor__OutputText">
            {props.enhancedValue}
          </div>
          {props.onExpandedToggle &&
            <div
              className={classNames('CellEditor__Toggle', {'CellEditor__Toggle--Show': props.expanded})}
              onClick={handleExpandedToggle}
            >
              <IconChevronLeft size={16} />
            </div>
          }
        </div>
      </div>
    );
  } else {
    return <span>No data available</span>;
  }
};

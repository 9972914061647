import classNames from 'classnames';
import { isNil, isFinite, toPairs, includes, upperFirst, isString, isBoolean } from 'lodash';
import Polyglot from 'node-polyglot';
import React from 'react';
import { ConditionDto, PersonSelectorDto, ShiftKind0, ShiftSelector1Dto } from '../Generated/BackendTypes';
import { usePolyglot } from '../Localization/Localization';
import { commaSeparated, isNotNil, lookup, memberof } from '../Utils';
import { NamesLookup } from './Types';

interface Property {
  member?: string;
  label: any;
  value: any;
}

interface PropsOfPersonSelectorOpts {
  member?: string;
}

function propsOfPersonSelector(s: PersonSelectorDto, namesLookup: NamesLookup, pg: Polyglot, opts?: PropsOfPersonSelectorOpts): Property[] {
  const memberProp = opts?.member ? { member: opts.member } : {};
  const props: Property[] = [];
  if(s.groupIds.length === 0 && s.personIds.length === 0 && isNil(s.pensumAbove) && isNil(s.pensumBelow)) {
    props.push({
      label: pg.t('general.persons'),
      value: pg.t('general.all'),
      ...memberProp
    });
  } else {
    const personGroupNames = s.groupIds.map(lookup(namesLookup.personGroups));
    const personNames = s.personIds.map(lookup(namesLookup.persons));
    if(personGroupNames.length > 0) {
      props.push({
        label: pg.t('general.personGroups'),
        value: commaSeparated(personGroupNames),
        ...memberProp
      });
    }
    if(personNames.length > 0) {
      props.push({
        label: pg.t('general.persons'),
        value: commaSeparated(personNames),
        ...memberProp
      });
    }
    if(isFinite(s.pensumAbove)) {
      props.push({
        label: pg.t('condition.personsWithPensumAbove'),
        value: `${s.pensumAbove} %`,
        ...memberProp
      });
    }
    if(isFinite(s.pensumBelow)) {
      props.push({
        label: pg.t('condition.personsWithPensumBelow'),
        value: `${s.pensumBelow} %`,
        ...memberProp
      });
    }
  }
  return props;
}

const translateShiftKind = (pg: Polyglot) => (w: ShiftKind0) => {
  switch(w) {
    case "Work": return pg.t('condition.shiftKindWork');
    case "Rest": return pg.t('condition.shiftKindRest');
  }
}

interface PropsOfShiftSelectorOpts {
  member?: string;
  labelPrefix?: string
}

function propsOfShiftSelector(s: ShiftSelector1Dto, namesLookup: NamesLookup, pg: Polyglot, opts?: PropsOfShiftSelectorOpts): Property[] {
  const labelPrefix = opts?.labelPrefix ? opts.labelPrefix : '';
  const memberProp = opts?.member ? { member: opts.member } : {};
  const props: Property[] = [];
  if(s.shiftIds.length === 0 && isNil(s.shiftKind)) {
    props.push({
      label: labelPrefix + pg.t('general.shifts'),
      value: pg.t('general.all'),
      ...memberProp
    });
  } else {
    const shiftNames = s.shiftIds.map(lookup(namesLookup.shifts));
    if(shiftNames.length > 0) {
      props.push({
        label: labelPrefix + pg.t('general.shifts'),
        value: commaSeparated(shiftNames),
        ...memberProp
      });
    }
    if(isNotNil(s.shiftKind)) {
      props.push({
        label: labelPrefix + pg.t('condition.shiftKind'),
        value: translateShiftKind(pg)(s.shiftKind),
        ...memberProp
      });
    }
  }
  return props;
}

function specialPropsOf(c: ConditionDto, l: NamesLookup, pg: Polyglot): Property[] {
  switch(c._type) {
    case "RestrictChangeFromShiftConditionDto":
    case "RestrictChangeToShiftConditionDto":
      return [
        { label: pg.t('condition.repetitionCount'), value: c.length, member: memberof<typeof c>('length') },
        ...propsOfShiftSelector(c.fromShiftSelector, l, pg, {
          labelPrefix: pg.t('condition.fromShiftSelectorPrefix'),
          member: memberof<typeof c>('fromShiftSelector')
        }),
        ...propsOfShiftSelector(c.toShiftSelector, l, pg, {
          labelPrefix: pg.t('condition.toShiftSelectorPrefix'),
          member: memberof<typeof c>('toShiftSelector')
        })
      ];
    case "MaxShiftsInARowConstraintDto":
      return [
        { label: pg.t('condition.maxDaysCount'), value: `${c.maxCount}`, member: memberof<typeof c>('maxCount') }
      ];
    case "TargetMinutesObjectiveDto":
      let alignment;
      if(c.alignment === 'None') {
        alignment = pg.t('condition.targetMinutesAlignmentNone');
      } else {
        alignment = pg.t('condition.targetMinutesAlignmentMiddle');
      }
      return [
        { label: pg.t('condition.targetMinutesAlignment'), value: alignment, member: memberof<typeof c>('alignment') }
      ];
    case "MinCountWeekendsHavingShiftObjectiveDto":
      return [
        { label: pg.t('condition.penalty'), value: c.penalty, member: memberof<typeof c>('penalty') },
        { label: pg.t('condition.minWeekendsCount'), value: c.minCount, member: memberof<typeof c>('minCount') }
      ];
    case "EvenlyDistributeOverWeeksObjectiveDto":
    case "WeekendEitherFreeOrWorkObjectiveDto":
    case "AvoidSingleWorkdaysObjectiveDto":
    case "AvoidSingleFreedaysObjectiveDto":
    case "PreferMaxFiveWorkdaysInARowObjectiveDto":
    case "DistributeWeekendLoadObjectiveDto":
    case "MaxShiftsPerWeekObjectiveDto":
    case "ShiftSelectionConditionDto":
      return [];
  }
}

function fallbackPropsOf(c: ConditionDto, pg: Polyglot, r: Property[]): Property[] {
  const existing = [
    memberof<ConditionDto>('_type'),
    'enabled',
    ...r.map(p => p.member).filter(m => m)
  ];
  return toPairs(c)
    .filter(([member, _]) => !includes(existing, member))
    .map(([member, value]): Property => {
      let displayValue;
      if(isString(value)) {
        displayValue = value;
      } else if(isFinite(value)) {
        displayValue = value.toString();
      } else if(isBoolean(value)) {
        displayValue = value ? pg.t('general.yes') : pg.t('general.no');
      } else {
        displayValue = (<pre>{JSON.stringify(value)}</pre>);
      }
      return {
        label: upperFirst(member),
        value: displayValue,
        member
      };
    });
}

function propsOf(c: ConditionDto, l: NamesLookup, pg: Polyglot) {
  const r: Property[] = [];

  // get standard props
  if('personSelector' in c) {
    r.push(...propsOfPersonSelector(c.personSelector, l, pg, { member: memberof<typeof c>('personSelector')}));
  }
  if('shiftSelector' in c) {
    r.push(...propsOfShiftSelector(c.shiftSelector, l, pg, { member: memberof<typeof c>('shiftSelector')}));
  }
  // TODO DatePattern and DateSelector

  // get special props
  r.push(...specialPropsOf(c, l, pg));

  // get all remaining props that haven't been mapped
  r.push(...fallbackPropsOf(c, pg, r));

  return r;
}

export interface PropertiesTableProps {
  enabled: boolean; // TODO: should be available directly from condition
  condition: ConditionDto;
  lookup: NamesLookup;
}

export function PropertiesTable(props: PropertiesTableProps) {
  const pg = usePolyglot();
  const properties = propsOf(props.condition, props.lookup, pg);
  return (
    <table className={classNames('ConditionList__Props', {'ConditionList__Props--Muted': !props.enabled })}>
      <tbody>
      {properties.map(({ label, value }) =>
        <tr key={`${label}-${value}`}>
          <td>{label}</td>
          <td>{value}</td>
        </tr>
      )}
      </tbody>
    </table>
  );
}

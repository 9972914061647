import React, { useRef, useState } from 'react';
import { useAuthFetch } from '../AuthService';
import { computationFetchMonitor, computationFetchMonitorResults, solverTaskCancel } from '../BackendService';
import { SolverMonitorSolutionTable } from './SolverMonitorSolutionTable';
import { useInterval } from '../Hooks';
import { ComputationMonitorDto, ComputationMonitorResultDto, ComputationMonitorResultsDto, SolverResultUnsatSubset0 } from '../Generated/BackendTypes';
import { Path, tz_utc } from '../Utils';
import spacetime, { Spacetime } from 'spacetime';
import { fromPairs, isString, some } from 'lodash';
import { FetchedResource } from '../FetchedResource';
import { ScheduleId } from '../Types';
import { Redirect } from 'react-router-dom';
import { SolverMonitorToolbar } from './SolverMonitorToolbar';
import fp from 'lodash/fp';
import { UnsatSubsets } from './UnsatSubsets';
import { computePlanningTimesFromMonitor } from '../Domain/PlanningTimes';


interface SolverMonitorResultsContainerProps {
  scheduleId: ScheduleId;
  redirectToComputationOnFinish: boolean;
  monitor: ComputationMonitorDto;
}

function SolverMonitorResultsContainer(props: SolverMonitorResultsContainerProps) {
  const authFetch = useAuthFetch();

  const [results, setResults] = useState<ComputationMonitorResultDto[]>([]);
  const [selectedResult, setSelectedResult] = useState(0);
  const [state, setState] = useState<ComputationMonitorResultsDto>();
  const isWaitingForResults = useRef(false);
  const hasMoreResults = useRef(true);
  const solverResultId = useRef<number|undefined>(undefined);
  const [healthCounter, setHealthCounter] = useState(0);
  const [cooldown, setCooldown] = useState(0);

  useInterval(() => {
    async function req() {
      try {
        const response = await computationFetchMonitorResults(authFetch, props.scheduleId, props.monitor.solverTaskId,
          solverResultId.current);
        setResults(results => [...results, ...response.results]);
        setSelectedResult(selectedResult => {
          // auto scroll
          if(results.length === 0) {
            return response.results.length - 1;
          } else if(selectedResult === results.length - 1) {
            return results.length + response.results.length - 1;
          } else {
            return selectedResult;
          }
        });
        setState(response);
        setHealthCounter(0);
        solverResultId.current = response.lastSolverResultId;
        hasMoreResults.current = response.hasMoreResults;
      } catch {
        setHealthCounter(healthCounter + 1);
        setCooldown(healthCounter + 1);
      } finally {
        isWaitingForResults.current = false;
      }
    }

    if(cooldown > 0) {
      setCooldown(cooldown - 1);
    } else if(hasMoreResults.current && !isWaitingForResults.current) {
      isWaitingForResults.current = true;
      req();
    }
  }, 1000, true);

  let startTime: Spacetime|undefined;
  if(state && isString(state?.startTime)) {
    startTime = spacetime(state.startTime, tz_utc);
  } else if(isString(props.monitor.startTime)) {
    startTime = spacetime(props.monitor.startTime, tz_utc)
  }

  const solution = results[selectedResult]?.solution;
  const planningTimes = solution && computePlanningTimesFromMonitor(props.monitor, solution);
  let unsatSubsetsComponent: React.ReactElement|undefined;
  if(some(results, result => result.unsatSubset)) {
    const unsatSubsetsWithRound = fp.flow(
      fp.map((result: ComputationMonitorResultDto) => result.unsatSubset),
      fp.compact
    )(results);
    const lastRound = fp.flow(
      fp.map((subset: SolverResultUnsatSubset0) => subset.round),
      fp.max
    )(unsatSubsetsWithRound);
    const unsatSubsets = fp.flow(
      fp.filter((subset: SolverResultUnsatSubset0) => subset.round === lastRound),
      fp.map(subset => subset.unsatSubset)
    )(unsatSubsetsWithRound);

    const persons = fromPairs(props.monitor.persons.map(person => [person.personId, person.name]));
    const personGroups = fromPairs(props.monitor.personGroups.map(personGroup => [personGroup.personGroupId, personGroup.name]));
    const shifts = fromPairs(props.monitor.shifts.map(shift => [shift.shiftId, shift.name]));
    unsatSubsetsComponent = (
      <UnsatSubsets
        scheduleId={props.scheduleId}
        unsatSubsets={unsatSubsets}
        persons={persons}
        personGroups={personGroups}
        shifts={shifts}
      />
    );
  }

  function handleCancel() {
    solverTaskCancel(authFetch, props.scheduleId);
  }

  return (
    <>
      {state?.status === 'Completed' && props.redirectToComputationOnFinish &&
        <Redirect to={Path.toComputation(props.scheduleId)} />
      }
      <SolverMonitorToolbar
        scheduleId={props.scheduleId}
        resultsCount={solution ? results.length : 0}
        resultsIndex={selectedResult}
        onSelectResult={setSelectedResult}
        onHandleCancel={handleCancel}
        creationTime={spacetime(props.monitor.creationTime, tz_utc)}
        startTime={startTime}
        maxQueueTimeSeconds={props.monitor.maxQueueTimeSeconds}
        maxSolveTimeSeconds={props.monitor.maxSolveTimeSeconds}
        status={state ? state.status : props.monitor.status}
        showErrorHint={healthCounter > 0}
      />
      {solution && planningTimes &&
        <SolverMonitorSolutionTable
          startDate={props.monitor.scheduleStartDate}
          endDate={props.monitor.scheduleEndDate}
          persons={props.monitor.persons}
          shifts={props.monitor.shifts}
          entries={solution}
          planningTimes={planningTimes}
        />
      }
      <div className="mt-5">
        {unsatSubsetsComponent}
      </div>
    </>
  );
}

export interface SolverMonitorContainerProps {
  scheduleId: ScheduleId;
  redirectToComputationOnFinish: boolean;
}

export function SolverMonitorContainer(props: SolverMonitorContainerProps) {
  const authFetch = useAuthFetch();
  return (
    <FetchedResource fetch={() => computationFetchMonitor(authFetch, props.scheduleId)}>
      {monitor =>
        <SolverMonitorResultsContainer
          scheduleId={props.scheduleId}
          redirectToComputationOnFinish={props.redirectToComputationOnFinish}
          monitor={monitor}
        />
      }
    </FetchedResource>
  )
}

import { FormikErrors } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuthFetch } from '../AuthService';
import { personGroupCreate, personGroupDelete, personGroupFetchList, personGroupUpdate } from '../BackendService';
import { LoadingSpinner } from '../Components/UtilityComponents';
import { PersonGroupListDto, PersonGroupUpsertDto } from '../Generated/BackendTypes';
import { usePolyglot } from '../Localization/Localization';
import { PersonGroupId, ScheduleId } from '../Types';
import { PersonGroupEntry, PersonGroupTable, PersonLookup } from './PersonGroupTable';

function toPersonGroupUpsertDto(d: PersonGroupEntry, documentRevision: number): PersonGroupUpsertDto {
  return {
    documentRevision,
    name: d.name,
    personIds: d.personIds
  };
}

export interface PersonGroupTableContainerProps {
  scheduleId: ScheduleId;
}

export function PersonGroupTableContainer(props: PersonGroupTableContainerProps) {
  const authFetch = useAuthFetch();
  const pg = usePolyglot();
  const [personGroups, setPersonGroups] = useState<PersonGroupEntry[]>([]);
  const [persons, setPersons] = useState<PersonLookup[]>([]);
  const [rev, setRev] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);
  const scheduleId = props.scheduleId;

  const processResponse = useCallback((response: PersonGroupListDto) => {
    setPersonGroups(response.personGroups);
    setPersons(response.persons);
    setRev(response.documentRevision);
  }, []);

  useEffect(() => {
    setLoading(true);
    setError(false);
    personGroupFetchList(authFetch, scheduleId)
    .then(processResponse)
    .catch(error => {
      console.error(error);
      setError(true);
    })
    .finally(() => setLoading(false));
  }, [authFetch, processResponse, scheduleId]);

  function validate(d: PersonGroupEntry) {
    const errors: FormikErrors<PersonGroupEntry> = {};
    if (!d.name) {
      errors.name = pg.t('validation.notEmpty');
    } else if(d.name.trim().length === 0) {
      errors.name = pg.t('validation.notEmpty');
    } else if(d.name.trim().length > 64) {
      errors.name = pg.t('validation.notLongerThanNCharacters', { number: 64 });
    }

    return errors;
  }

  async function handleSave(d: PersonGroupEntry) {
    setSaving(true);
    setError(false);
    try {
      let response;
      if(d.personGroupId === undefined) {
        response = await personGroupCreate(authFetch, props.scheduleId, toPersonGroupUpsertDto(d, rev));
      } else {
        response = await personGroupUpdate(authFetch, props.scheduleId, d.personGroupId, toPersonGroupUpsertDto(d, rev));
      }
      processResponse(response);
    } catch(error) {
      console.error(error);
      setError(true);
      throw error;
    }
    setSaving(false);
  }

  async function handleDelete(personGroupId: PersonGroupId) {
    setSaving(true);
    setError(false);
    try {
      const response = await personGroupDelete(authFetch, props.scheduleId, personGroupId);
      processResponse(response);
    } catch(error) {
      console.error(error);
      setError(true);
    }
    setSaving(false);
  }

  if(loading) {
    return (
      <LoadingSpinner />
    )
  } else {
    return (
      <PersonGroupTable
        scheduleId={scheduleId}
        personGroups={personGroups}
        persons={persons}
        saving={saving}
        error={error}
        validate={validate}
        onSave={handleSave}
        onDelete={handleDelete}
      />
    )
  }
}

import React from 'react';
import { toString, groupBy, last, initial, isNumber } from 'lodash';
import { Alert, OverlayTrigger, Popover } from 'react-bootstrap';
import spacetime, { Spacetime } from 'spacetime';
import { AuthFetch } from '../AuthService';
import { staffDemandFetchTable, staffDemandUpdateAdjustments } from '../BackendService';
import { ContainerLeft, LoadingSpinner } from '../Components/UtilityComponents';
import { StaffDemandAdjustmentsChangeDto, StaffDemandAdjustmentsDto, StaffDemandRangeDto, StaffDemandTableCellDto, StaffDemandTableDto } from '../Generated/BackendTypes';
import { ScheduleId } from '../Types';
import { StaffDemandCellData, StaffDemandCellViewData, StaffDemandTable } from './StaffDemandTable';
import { History } from 'history';
import ReactDataSheet from 'react-datasheet';
import fp from 'lodash/fp';
import { ExternalLinks, getDatesBetween, mapi, Path, tz_utc } from '../Utils';
import { generateDayOfMonthRow, generateDayOfWeekRow, readOnlyCell } from '../Table/Utils';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { AffectedPersonsCaption } from './AffectedPersonsCaption';
import Polyglot from 'node-polyglot';
import { parseMinMaxCount, stringifyMinMaxCount } from '../Utils/range';
import { IntroPanel } from '../Components/IntroPanel';
import imageDemand from '../images/demand.svg';
import { IconAlertTriangle, IconEdit2 } from '../icons';

export interface Change {
  staffDemandIdx: number;
  index: number;
  date: Spacetime;
  adjustedMinCount?: number;
  adjustedMaxCount?: number;
}

export interface RecordedChange extends Change {
  changeId: number;
}

export interface StaffDemandTableDataState {
  savedData?: StaffDemandTableDto;
  changes: RecordedChange[];
  table: StaffDemandCellViewData[][];
}

const computeCellData = (changes: RecordedChange[], dates: Spacetime[], staffDemandIdx: number) =>
  (d: StaffDemandTableCellDto, index: number): StaffDemandCellData => {

  const lastChange = fp.flow(
    fp.filter((c: Change) => c.staffDemandIdx === staffDemandIdx && c.index === index),
    fp.last
  )(changes);

  if(lastChange) {
    return {
      staffDemandIdx: staffDemandIdx,
      index,
      date: dates[index],
      adjustedMinCount: lastChange.adjustedMinCount,
      adjustedMaxCount: lastChange.adjustedMaxCount,
      patternMinCount: d.pl,
      patternMaxCount: d.pu
    }
  } else {
    return {
      staffDemandIdx: staffDemandIdx,
      index,
      date: dates[index],
      adjustedMinCount: d.al,
      adjustedMaxCount: d.au,
      patternMinCount: d.pl,
      patternMaxCount: d.pu
    }
  }
}

const computeCellViewData = (dates: Spacetime[]) => (data: StaffDemandCellData): StaffDemandCellViewData => {
  let label: string;
  let adjusted: boolean;
  if(isNumber(data.adjustedMinCount) && isNumber(data.adjustedMaxCount)) {
    label = stringifyMinMaxCount(data.adjustedMinCount, data.adjustedMaxCount);
    adjusted = true;
  } else {
    label = stringifyMinMaxCount(data.patternMinCount, data.patternMaxCount);
    adjusted = false;
  }

  const className = classNames({
    'vt-sat': dates[data.index].day() === 6,
    'vt-sun': dates[data.index].day() === 0,
    'vt-hl': adjusted,
  });

  return {
    label,
    data,
    className
  };
}

function recomputeTableDataState(savedData: StaffDemandTableDto, changes: RecordedChange[]): StaffDemandTableDataState {
  const dates = getDatesBetween(savedData.scheduleStartDate, savedData.scheduleEndDate);

  const titleCell: StaffDemandCellViewData = {
    label: '',
    readOnly: true,
    className: 'text-left font-weight-bold cell-no-border-right',
  }

  const identifierHeader = {
    label: '',
    readOnly: true,
    className: 'text-left font-weight-bold'
  }

  const shiftColumnHeader = {
    label: 'Schicht',
    readOnly: true,
    className: 'text-left font-weight-bold',
  };

  const affectedPersonsColumnHeader = {
    label: 'Personen',
    readOnly: true,
    className: 'text-left font-weight-bold cell-no-border-right',
  };

  const warningsByDate = groupBy(savedData.warnings, d => spacetime(d.date, tz_utc).format('iso-short') as string);

  const emptyWarningCell = readOnlyCell({
    disableEvents: true,
    className: 'vt-warning'
  });
  const warningRow = dates.map((date, slotIdx) => {
    const isodate = date.format('iso-short') as string;
    const warnings = warningsByDate[isodate] || [];
    if(warnings.length > 0) {
      const warningPopover = (
        <Popover id="popover-basic">
          <Popover.Title as="h3">Warnung</Popover.Title>
          {warnings.map((warning, index) =>
            {
              if(warning._type === 'MultipleNonZeroOverlappingDemandsWarning') {
                const all =
                  warning.staffDemandIdxs
                  .map(staffDemandIdx => (<span key={staffDemandIdx}>P{staffDemandIdx+1}</span>));
                const considered = last(all);
                const ignored = initial(all);

                return (
                  <Popover.Content key={index}>
                    <p>Ein oder mehrere Personen sind nicht eindeutig zu einem Personalbedarf zuweisbar.</p>
                    <p>
                      Wenn eine Person Mitglied in zwei Personalbedarfen ungleich 0 mit der gleichen Schicht ist,
                      kann nicht eindeutig im Voraus bestimmt werden zu welchem Personalbedarf die Person
                      gezählt werden soll wenn sie diese Schicht erhält.
                    </p>
                    <p>
                      Taso wird folgende Massnahmen treffen, um die effiziente Lösbarkeit des Problems sicherzustellen.
                    </p>
                    <ul>
                      {ignored.length === 1 && <li>Der Personalbedarf {ignored} <b>wird ignoriert</b> an diesem Tag.</li>}
                      {ignored.length > 1 && <li>Die Personalbedarfe {ignored.reduce((a,b) => <>{a}, {b}</>)} <b>werden ignoriert</b> an diesem Tag.</li>}
                      <li>Personalbedarf {considered} <b>wird berücksichtigt an diesem Tag.</b></li>
                    </ul>
                    <p>
                      Um das Problem zu beheben, setzen Sie alle Personalbedarfe ausser einen auf 0.
                    </p>
                  </Popover.Content>
                );
              } else {
                return undefined;
              }
            }
          )}
        </Popover>
      );

      return {
        ...emptyWarningCell,
        forceComponent: true,
        component: (
          <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={warningPopover}>
            <IconAlertTriangle />
          </OverlayTrigger>
        )
      };
    } else {
      return emptyWarningCell;
    }
  });

  const header = [
    [emptyWarningCell, emptyWarningCell, emptyWarningCell, emptyWarningCell, ...warningRow],
    [titleCell, readOnlyCell({ className: 'cell-no-border-right'}), readOnlyCell({ className: 'cell-no-border-right'}), readOnlyCell(), ...generateDayOfMonthRow(dates)],
    [identifierHeader, shiftColumnHeader, affectedPersonsColumnHeader, readOnlyCell(), ...generateDayOfWeekRow(dates)],
  ]

  const content = savedData.staffDemands.map(row => {
    const identifierCell: StaffDemandCellViewData = {
      label: `P${row.staffDemandIdx+1}`, // TODO: let backend make this name
      readOnly: true,
      disableEvents: true,
      className: 'sdt-identifer'
    };

    const shiftCell: StaffDemandCellViewData = {
      readOnly: true,
      label: savedData.shifts[row.shiftId].name,
      className: 'sdt-shift'
    };

    const affectedPersonsCell: StaffDemandCellViewData = {
      readOnly: true,
      forceComponent: true,
      label: '',
      component: (
        <AffectedPersonsCaption
          persons={row.personIds.map(personId => savedData.persons[personId])}
          personGroups={row.personGroupIds.map(personGroupId => savedData.personGroups[personGroupId])}
          affectedPersons={row.affectedPersonIds.map(personId => savedData.persons[personId])}
        />
      ),
      className: 'sdt-affected cell-no-border-right',
    }

    const editCell: StaffDemandCellViewData = {
      forceComponent: true,
      readOnly: true,
      label: '',
      component: (
        <Link
          className="visible-on-row-hover"
          to={Path.toSpecificStaffDemand(savedData.scheduleId, row.staffDemandIdx)}
        >
          <IconEdit2 />
        </Link>
      )
    };

    const relevantChanges = changes.filter(c => c.staffDemandIdx === row.staffDemandIdx);

    const cells: StaffDemandCellViewData[] = fp.flow(
      mapi(computeCellData(relevantChanges, dates, row.staffDemandIdx)),
      fp.map(computeCellViewData(dates))
    )(row.entries);

    return [
      identifierCell,
      shiftCell,
      affectedPersonsCell,
      editCell,
      ...cells
    ];
  });

  const table: StaffDemandCellViewData[][] = [
    ...header,
    ...content
  ];

  return {
    savedData,
    changes,
    table
  }
}

function toAdjustmentsChangeDto(change: RecordedChange): StaffDemandAdjustmentsChangeDto {
  let range: StaffDemandRangeDto|undefined;
  if(isNumber(change.adjustedMinCount) && isNumber(change.adjustedMaxCount)) {
    range = {
      minCount: change.adjustedMinCount,
      maxCount: change.adjustedMaxCount,
    };
  }
  return {
    staffDemandIdx: change.staffDemandIdx,
    date: change.date.format('iso-short') as string,
    range
  }
}

export interface StaffDemandTableContainerProps {
  scheduleId: ScheduleId;
  authFetch: AuthFetch;
  history: History;
  pg: Polyglot;
}

export interface StaffDemandTableContainerState extends StaffDemandTableDataState {
  loading: boolean;
  error?: string;
  nextChangeId: number;
}

export class StaffDemandTableContainer extends React.Component<StaffDemandTableContainerProps, StaffDemandTableContainerState> {
  _intervalHandle: any;

  constructor(props: StaffDemandTableContainerProps) {
    super(props);
    this.state = {
      loading: false,
      changes: [],
      table: [],
      nextChangeId: 0
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    staffDemandFetchTable(this.props.authFetch, this.props.scheduleId)
      .then(
        savedData => {
          this.setState({
            error: undefined,
            loading: false,
            ...recomputeTableDataState(savedData, [])
          });
        },
        error => {
          this.setState({
            error: error.message,
            loading: false
          });
        }
      );

    // start continuous saving
    this._intervalHandle = setInterval(this.handleSaveChanges, 1000);
  }

  componentWillUnmount() {
    clearInterval(this._intervalHandle);
  }

  // Change management
  addChanges(changes: Change[]) {
    this.setState(state => {
      if(state.savedData) {
        let nextChangeId = state.nextChangeId;
        // assign unique change id to each change
        const recordedChanges = [];
        for(let change of changes) {
          recordedChanges.push({
            ...change,
            changeId: nextChangeId
          });
          nextChangeId ++;
        }

        // update table state and nextChangeId
        return {
          ...recomputeTableDataState(state.savedData, [...state.changes, ...recordedChanges]),
          nextChangeId
        };
      } else {
        return {};
      }
    })
  }

  async saveChanges() {
    if(!this.state.loading && this.state.changes.length > 0 && this.state.savedData) {
      console.debug(`Detected ${this.state.changes.length} pending changes.`);
      let changes: StaffDemandAdjustmentsChangeDto[];

      changes = this.state.changes.map(toAdjustmentsChangeDto);

      const payload: StaffDemandAdjustmentsDto = {
        scheduleId: this.props.scheduleId,
        documentRevision: this.state.savedData.documentRevision,
        changes
      }

      const lastSavedChangeId = fp.flow(
        fp.map((change: RecordedChange) => change.changeId),
        fp.max
      )(this.state.changes);

      if(lastSavedChangeId !== undefined) {
        console.debug(`Saving ${changes.length} changes up to changeId ${lastSavedChangeId}.`);
        this.setState({ loading: true });
        try {
          const newSavedData = await staffDemandUpdateAdjustments(this.props.authFetch, this.props.scheduleId, payload);
          this.setState(state => {
            const unsavedChanges = state.changes.filter(change => (change.changeId > lastSavedChangeId));
            console.log(`Saved ${changes.length} changes. Unsaved changes appeared in the meantime ${unsavedChanges.length}`);
            return {
              ...recomputeTableDataState(newSavedData, unsavedChanges),
              error: undefined,
              loading: false
            };
          })
        } catch(error) {
          console.error(`Error during save.`, error)
          this.setState({ error: error.message, loading: false });
        }
      }
    } else {
      console.debug('No pending changes');
    }
  }

  // Handlers
  handleCellsChanged = (cellChanges: ReactDataSheet.CellsChangedArgs<StaffDemandCellViewData>) => {
    if(this.state.savedData) {
      const changes = cellChanges.map(({ cell, value }): Change|undefined => {
        if(cell?.data) {
          const newCounts = parseMinMaxCount(toString(value));
          if(newCounts) {
            return {
              staffDemandIdx: cell.data.staffDemandIdx,
              index: cell.data.index,
              date: cell.data.date,
              adjustedMinCount: newCounts.minCount,
              adjustedMaxCount: newCounts.maxCount,
            }
          } else {
            return {
              staffDemandIdx: cell.data.staffDemandIdx,
              index: cell.data.index,
              date: cell.data.date,
            };
          }
        } else {
          return undefined;
        }
      });
      this.addChanges(fp.compact(changes));
    }
  }

  handleSaveChanges = () => {
    this.saveChanges();
  }

  // Rendering
  render() {
    return (
      <>
        <ContainerLeft>
          <IntroPanel imageSource={imageDemand} href={ExternalLinks.toDemandDocs}>
            {this.props.pg.t('introtext.demand')}
          </IntroPanel>
        </ContainerLeft>
        <h1 className="ml-3 mt-3 mb-5">{this.props.pg.t('general.staffDemand')}</h1>
        {this.state.savedData &&
          <StaffDemandTable
            scheduleId={this.props.scheduleId}
            table={this.state.table}
            onCellsChanged={this.handleCellsChanged}
            loading={this.state.loading}
            dirty={this.state.changes.length > 0}
            error={this.state.error}
            onSaveClick={this.handleSaveChanges}
          />
        }
        {this.state.error &&
          <Alert variant='danger'>{this.state.error}</Alert>
        }
        {this.state.loading &&
          <LoadingSpinner />
        }
      </>
    );
  }
}


// from https://github.com/iansinnott/react-string-replace/blob/master/index.js

import { escapeRegExp, flatten, isRegExp, isString } from "lodash";
import { ReactNode } from "react";

type ReactStringReplaceFunction = (result: string, resultIndex: number, startPosition: number) => any

/**
 * Given a string, replace every substring that is matched by the `match` regex
 * with the result of calling `fn` on matched substring. The result will be an
 * array with all odd indexed elements containing the replacements. The primary
 * use case is similar to using String.prototype.replace except for React.
 *
 * React will happily render an array as children of a react element, which
 * makes this approach very useful for tasks like surrounding certain text
 * within a string with react elements.
 *
 * Example:
 * matchReplace(
 *   'Emphasize all phone numbers like 884-555-4443.',
 *   /([\d|-]+)/g,
 *   (number, i) => <strong key={i}>{number}</strong>
 * );
 * // => ['Emphasize all phone numbers like ', <strong>884-555-4443</strong>, '.'
 *
 * @param {string} s
 * @param {regexp|str} m Must contain a matching group
 * @param {function} f
 * @return {array}
 */
function replaceString(s: string, m: RegExp|string, f: ReactStringReplaceFunction) {
  var curCharStart = 0;
  var curCharLen = 0;

  if (s === '') {
    return s;
  } else if (!s || !isString(s)) {
    throw new TypeError('First argument to react-string-replace#replaceString must be a string');
  }

  var re = m;
  if (!isRegExp(re)) {
    re = new RegExp('(' + escapeRegExp(re) + ')', 'gi');
  }

  var result = s.split(re);

  // Apply fn to all odd elements
  for (var i = 1, length = result.length; i < length; i += 2) {
    curCharLen = result[i].length;
    curCharStart += result[i - 1].length;
    result[i] = f(result[i], i, curCharStart);
    curCharStart += curCharLen;
  }

  return result;
}

export function reactStringReplace(s: string|ReactNode[], m: RegExp|string, f: ReactStringReplaceFunction) {
  let sa;
  if (Array.isArray(s)) {
    sa = s;
  } else {
    sa = [s];
  }

  return flatten(sa.map(function(x) {
    return isString(x) ? replaceString(x, m, f) : x;
  }));
};

import { useField } from 'formik';
import React from 'react';
import { ChipSelectItem, ChipSelect } from './ChipSelect';

export interface ChipSelectFieldProps<K> {
  id: string;
  name: string;
  items: ChipSelectItem<K>[];
  placeholder?: string;
}

export function ChipSelectField<T>(props: ChipSelectFieldProps<T>) {
  const [field,, helpers] = useField<T[]>(props.name);
  return (
    <ChipSelect
      id={props.id}
      items={props.items}
      selected={field.value || []}
      placeholder={props.placeholder}
      onSelect={v => { helpers.setValue(v); }}
    />
  );
}

import React from "react";
import { useField } from "formik";
import FormCheck, { FormCheckProps } from "react-bootstrap/FormCheck";
import { isNil } from "lodash";

/**
 * Wire Formik's touched and errors to bootstrap's Form.Check isValid / isInvalid props and also add a FormCheck.Feedback.
 */
export function FoboCheck(props: FormCheckProps & { name: string }) {
  const [field] = useField(props.name);
  return (
    <FormCheck
      checked={isNil(props.value) ? field.value : field.value === props.value}
      {...field}
      {...props}
    />
  );
}

import React from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { Path } from '../Utils';
import { Badge, Card } from 'react-bootstrap';
import { ScheduleId } from '../Types';
import classNames from 'classnames';
import { NamesLookup } from './Types';
import { PropertiesTable } from './PropertiesTable';
import { explainDE } from './ExplainDE';
import { ConditionDto } from '../Generated/BackendTypes';
import { renderMarkup } from '../SimpleMarkup';
import { IconHelpCircle, IconEdit2 } from '../icons';

export interface ConditionCardProps {
  scheduleId: ScheduleId;
  conditionPos: number;
  condition: ConditionDto;
  namesLookup: NamesLookup;
  showDetails: boolean;
}

export function ConditionCard(props: ConditionCardProps) {
  const enabled = props.condition.enabled;
  const explanation = renderMarkup(explainDE(props.condition, props.namesLookup));
  const conditionType = props.condition._type.replace(/Dto$/, '');
  return (
    <Card border={enabled ? 'success' : 'warning'} className="shadow-sm">
      <Card.Body>
        <Card.Title className={classNames({'text-muted': !enabled}, 'mb-0')}>
          <div className="float-right ml-3">
            <Badge variant="secondary" className="rounded-end-0">
              C{props.conditionPos+1}
            </Badge>
            <Badge variant={enabled ? 'success' : 'warning'} className="rounded-start-0">
              {enabled ? 'Aktiv' : 'Inaktiv' }
            </Badge>
          </div>
          <Link
            className={classNames("text-body", { "stretched-link": !props.showDetails })}
            to={Path.toSpecificCondition(props.scheduleId, props.conditionPos)}>
            {explanation}
          </Link>
        </Card.Title>
        {props.showDetails &&
          <Card.Text as="div" className="mt-5">
            <PropertiesTable
              enabled={enabled}
              condition={props.condition}
              lookup={props.namesLookup}
            />

          </Card.Text>
        }
      </Card.Body>
      {props.showDetails &&
        <Card.Footer className="text-muted">
          <span className="text-monospace">{conditionType}</span>
          <Button
            href={`https://taso.app/docs/conditions#${conditionType}`}
            target="_blank"
            variant="link"
            title={`Hilfe zu ${conditionType} öffnen`}
          >
            <IconHelpCircle size={20} />
          </Button>
          <Button
            as={Link}
            to={Path.toSpecificCondition(props.scheduleId, props.conditionPos)}
            variant="primary"
            className="float-right"
            title={`Bedingung ${conditionType} bearbeiten`}
          >
            <IconEdit2 size={20} />
          </Button>
        </Card.Footer>
      }
    </Card>
  )
}

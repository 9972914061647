import React from 'react';
import Form from 'react-bootstrap/Form';
import { Formik, Form as FormikForm } from 'formik';
import { fromPersonSelectorFormData, PersonSelectorFormData, PersonSelectorSubform, toPersonSelectorFormData } from '../PersonSelector';
import { memberof, oneof } from '../../Utils';
import { fromShiftSelectorFormData, ShiftSelectorFormData, ShiftSelectorSubform, toShiftSelectorFormData } from '../ShiftSelector';
import { FoboField } from '../../Components/FoboField';
import { renderMarkup } from '../../SimpleMarkup';
import { explainDE } from '../ExplainDE';
import { ConditionEditorCardSubform } from '../ConditionEditorCard';
import { ConditionEditorProps } from '../ConditionEditorContainer';
import { ConditionDto_ShiftSelectionConditionDto, ConfineOrPrevent0, Hardness0 } from '../../Generated/BackendTypes';
import { DatePattern, DatePatternFormData, fromDatePatternFormData, toDatePatternFormData } from '../DatePattern';
import { FoboCheck } from '../../Components/FoboCheck';
import { usePolyglot } from '../../Localization/Localization';
import * as Yup from 'yup';

type C = ConditionDto_ShiftSelectionConditionDto;

interface FormValues {
  enabled: boolean;
  personSelector: PersonSelectorFormData;
  shiftSelector: ShiftSelectorFormData;
  datePattern: DatePatternFormData;
  hardness: Hardness0;
  restriction: ConfineOrPrevent0;
  penalty: string;
}

function toFormValues(d: C): FormValues {
  return {
    enabled: d.enabled,
    personSelector: toPersonSelectorFormData(d.personSelector),
    shiftSelector: toShiftSelectorFormData(d.shiftSelector),
    datePattern: toDatePatternFormData(d.datePattern),
    hardness: d.hardness,
    restriction: d.restriction,
    penalty: d.penalty.toString(),
  };
}

function fromFormValues(props: ConditionEditorProps<C>, d: FormValues): C {
  return {
    _type: props.condition._type,
    enabled: d.enabled,
    personSelector: fromPersonSelectorFormData(props.identifiers.personIds, props.identifiers.personGroupIds, d.personSelector),
    shiftSelector: fromShiftSelectorFormData(props.identifiers.shiftIds, d.shiftSelector),
    datePattern: fromDatePatternFormData(d.datePattern),
    hardness: d.hardness,
    restriction: d.restriction,
    penalty: Math.max(parseInt(d.penalty, 10) || 0, 0),
  };
}

export function ShiftSelectionConditionEditor(props: ConditionEditorProps<C>) {
  const pg = usePolyglot();
  return (
    <Formik
      initialValues={toFormValues(props.condition)}
      validationSchema={Yup.object({
        penalty: Yup.number().when(memberof<FormValues>('hardness'), {
          is: oneof<Hardness0>('Soft'), then: Yup.number().min(1, pg.t('validation.positiveInteger'))
        })
      })}
      onSubmit={values => props.onSave && props.onSave(fromFormValues(props, values))}
    >
      {({ values }) => (
        <Form as={FormikForm}>
          <ConditionEditorCardSubform
            {...props}
            name={memberof<FormValues>('enabled')}
            title={renderMarkup(explainDE(fromFormValues(props, values), props.lookup))}
          >
            <PersonSelectorSubform
              name={memberof<FormValues>('personSelector')}
              persons={props.dropdown.persons}
              personGroups={props.dropdown.personGroups}
            />

            <ShiftSelectorSubform
              name={memberof<FormValues>('shiftSelector')}
              shifts={props.dropdown.shifts}
            />

            <DatePattern
              name={memberof<FormValues>('datePattern')}
            />

            <div>
              <FoboCheck
                type="radio"
                id="formRestrictionConfine"
                name={memberof<FormValues>('restriction')}
                value={oneof<ConfineOrPrevent0>('Confine')}
                label={pg.t('condition.confine')}
              />

              <FoboCheck
                type="radio"
                id="formRestrictionPrevent"
                name={memberof<FormValues>('restriction')}
                value={oneof<ConfineOrPrevent0>('Prevent')}
                label={pg.t('condition.prevent')}
              />
            </div>

            <div>
              <FoboCheck
                type="radio"
                id="formHardnessHard"
                name={memberof<FormValues>('hardness')}
                value={oneof<Hardness0>('Hard')}
                label={pg.t('condition.hardConstraint')}
              />

              <FoboCheck
                type="radio"
                id="formHardnessSoft"
                name={memberof<FormValues>('hardness')}
                value={oneof<Hardness0>('Soft')}
                label={pg.t('condition.softConstraint')}
              />
            </div>

            {values.hardness === 'Soft' &&
              <Form.Group controlId="formPenalty">
                <Form.Label>Penalty</Form.Label>
                <FoboField
                  name={memberof<FormValues>('penalty')}
                  type="number"
                />
              </Form.Group>
            }

          </ConditionEditorCardSubform>
        </Form>
      )}
    </Formik>
  );
}

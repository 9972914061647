import React, { useEffect, useState } from 'react';
import { siteadmFetchTenants, siteadmSwitchTenant } from '../BackendService';
import { useAuthFetch, useAuthTokenService } from '../AuthService';
import ListGroup from 'react-bootstrap/ListGroup';
import Alert from 'react-bootstrap/Alert';
import { useHistory } from 'react-router-dom';
import { Path } from '../Utils';
import { SiteAdminTenantListItemDto } from '../Generated/BackendTypes';
import { ContainerLeft } from '../Components/UtilityComponents';
import { Title } from '../TopBar/Title';
import { IconChevronRight } from '../icons';

export function TenantSwitcher() {
  const authFetch = useAuthFetch();
  const authTokenService = useAuthTokenService();
  const history = useHistory();
  const [tenants, setTenants] = useState<SiteAdminTenantListItemDto[]>([]);
  const [error, setError] = useState<string>();
  useEffect(() => {
    siteadmFetchTenants(authFetch).then(
      tenantList => setTenants(tenantList.tenants),
      error => setError(error.message || error)
    )
  }, [authFetch]);

  function handleTenantSwitch(tenantId: string) {
    if(authTokenService && tenantId) {
      siteadmSwitchTenant(authFetch, tenantId).then(() => {
        authTokenService.forget();
        history.push(Path.toAccountOrganization);
      })
    }
  }

  return (
    <ContainerLeft>
      <Title>Tenant Switch</Title>
      <h1 className="mt-5">Tenants</h1>
      { error && <Alert variant='danger'>{error}</Alert>}
      <ListGroup variant="flush" className="mt-3">
        {tenants.map(tenant =>
          <ListGroup.Item key={tenant.tenantId} action onClick={() => handleTenantSwitch(tenant.tenantId)}>
            {tenant.principalUserEmailAddress} {tenant.principalUserName} {tenant.name}  {tenant.tenantId}
            <IconChevronRight className="float-right" />
          </ListGroup.Item>
        )}
      </ListGroup>
    </ContainerLeft>
  );
}

import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { usePolyglot } from '../Localization/Localization';

export interface ConfirmationModalProps {
  show?: boolean;
  onHide?: () => void;
  onNegative?: () => void;
  onPositive?: () => void;
  children: any;
  positiveText?: any;
  negativeText?: any;
}

export function ConfirmationModal(props: ConfirmationModalProps) {
  const pg = usePolyglot();
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{pg.t('general.confirm')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onNegative}>{props.negativeText || pg.t('general.no')}</Button>
        <Button variant="primary" onClick={props.onPositive}>{props.positiveText || pg.t('general.yes')}</Button>
      </Modal.Footer>
    </Modal>
  )
}


import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { uniq, without, isNil } from 'lodash';
import Badge from 'react-bootstrap/Badge';
import { IconX, IconPlus } from '../icons';

export interface DropdownMultiSelectItem<T> {
  label: string;
  itemKey: T;
}

export interface DropdownMultiSelectProps<T> {
  id: string;
  items?: DropdownMultiSelectItem<T>[];
  selected?: T[];
  onSelect: (selected: T[]) => void;
  variant?: any;
  className?: string;
}

export function DropdownMultiSelect<T extends string>(props: DropdownMultiSelectProps<T>) {
  const items = isNil(props.items) ? [] : props.items;
  const selected = isNil(props.selected) ? [] : props.selected;

  function addItem(itemKey: T) {
    props.onSelect(uniq([...selected, itemKey]));
  }
  function removeItem(itemKey: T) {
    props.onSelect(without(selected, itemKey));
  }

  function getItem(itemKey: T) {
    const item = items.find(item => item.itemKey === itemKey);
    if(item) {
      return item;
    } else {
      return { itemKey, label: `?? <${itemKey}>` };
    }
  }

  return (
    <div className="border rounded d-flex align-items-baseline">
      <div className="flex-grow-1">
      {selected.map(getItem).map(item =>
        <Badge className="ml-2" key={item.itemKey} variant="secondary">
          {item.label}
          <IconX className="cursor-pointer" size={16} onClick={() => removeItem(item.itemKey)} />
        </Badge>
      )}
      </div>
      <Dropdown onSelect={(itemKey: any) => addItem(itemKey)}>
        <Dropdown.Toggle
          variant={props.variant}
          id={props.id}
          className={props.className}
        >
          <IconPlus />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {items.filter(item => selected.includes(item.itemKey) === false).map(item =>
            <Dropdown.Item
              key={item.itemKey}
              eventKey={item.itemKey}
            >
              {item.label}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

import React from 'react';
import { DropdownSingleSelect, DropdownSingleSelectItem } from '../Components/DropdownSingleSelect';
import { useField } from 'formik';
import { DayOfWeek } from '../Types';
import { DropdownMultiSelect, DropdownMultiSelectItem } from '../Components/DropdownMultiSelect';
import { memberof } from '../Utils';
import { DatePatternDto, DatePatternMode0 } from '../Generated/BackendTypes';

export interface DatePatternFormData {
  mode: DatePatternMode0;
  daysOfWeek: DayOfWeek[];
}

export function createEmptyDatePatternFormData(mode?: DatePatternMode0): DatePatternFormData {
  return {
    mode: mode !== undefined ? mode : 'Daily',
    daysOfWeek: []
  };
}

export function toDatePatternFormData(d: DatePatternDto): DatePatternFormData {
  return {
    mode: d.mode,
    daysOfWeek: (d.mode === 'DaysOfWeek' && d.daysOfWeek) ? d.daysOfWeek : []
  }
}

export function fromDatePatternFormData(d: DatePatternFormData): DatePatternDto {
  return {
    _type: 'DatePattern0',
    mode: d.mode,
    daysOfWeek: (d.mode === 'DaysOfWeek' && d.daysOfWeek) ? d.daysOfWeek : []
  };
}

const modeItems: DropdownSingleSelectItem<DatePatternMode0>[] = [
  { itemKey: "Daily", label: "Täglich" },
  { itemKey: "DaysOfWeek", label: "Woche" }
];

interface ModeDropdownProps {
  name: string;
}
function ModeDropdown({ name }: ModeDropdownProps) {
  const [field,, helpers] = useField<DatePatternMode0>(name);

  return (
    <DropdownSingleSelect
      id="datePatternModeDropdown"
      variant="link"
      items={modeItems}
      selected={field.value}
      onSelect={helpers.setValue}
    />
  );
}

const daysOfWeekItems: DropdownMultiSelectItem<string>[] = [
  { itemKey: "1", label: "Montag" },
  { itemKey: "2", label: "Dienstag" },
  { itemKey: "3", label: "Mittwoch" },
  { itemKey: "4", label: "Donnerstag" },
  { itemKey: "5", label: "Freitag" },
  { itemKey: "6", label: "Samstag" },
  { itemKey: "7", label: "Sonntag" }
];

interface DaysOfWeekDropdownProps {
  name: string;
}
function DaysOfWeekDropdown({ name }: DaysOfWeekDropdownProps) {
  const [field,, helpers] = useField<DayOfWeek[]>(name);

  function handleSelect(value: string[]) {
    const daysOfWeek = value.map(v => parseInt(v, 10)).sort();
    helpers.setValue(daysOfWeek);
  }

  return (
    <DropdownMultiSelect
      id="datePatternModeDropdown"
      variant="link"
      items={daysOfWeekItems}
      selected={field.value.map(v => v.toString())}
      onSelect={value => handleSelect(value)}
    />
  );
}

export interface DatePatternProps {
  name: string;
}

export function DatePattern({ name: parentName }: DatePatternProps) {
  const modeName = `${parentName}.${memberof<DatePatternFormData>('mode')}`;
  const [{ value: mode }] = useField<DatePatternMode0>(modeName);

  return (
    <div className="d-flex flex-row">
      <ModeDropdown name={modeName} />
      {mode === "DaysOfWeek" &&
        <DaysOfWeekDropdown name={`${parentName}.${memberof<DatePatternFormData>('daysOfWeek')}`} />
      }
    </div>
  );
}

import React from 'react';
import { memberof } from '../Utils';
import { Form, InputGroup } from 'react-bootstrap';
import { Formik, Form as FormikForm, FormikHelpers, FormikErrors } from 'formik';
import { FoboField } from '../Components/FoboField';
import { isFinite } from 'lodash';
import { SaveButton } from '../Components/SaveButton';

export interface SchedulePropertiesValues {
  comment: string;
  standardDurationPerDay: number;
  standardDurationPerShift: number;
}

export interface SchedulePropertiesProps {
  initial: SchedulePropertiesValues;
  onSave: (values: SchedulePropertiesValues) => Promise<void>;
}

export function ScheduleProperties(props: SchedulePropertiesProps) {
  async function handleSubmit(values: SchedulePropertiesValues, { setStatus, resetForm }: FormikHelpers<SchedulePropertiesValues>) {
    try {
      await props.onSave(values);
      setStatus(true);
    } catch {
      setStatus(false);
    }
  }
  return (
    <Formik
      initialStatus={undefined}
      initialValues={props.initial}
      onSubmit={handleSubmit}
      validate={values => {
        const errors: FormikErrors<SchedulePropertiesValues> = {};

        if(!isFinite(values.standardDurationPerDay)) {
          errors.standardDurationPerDay = 'Ganzzahlige positive Zahl erforderlich';
        } else if(values.standardDurationPerDay < 0) {
          errors.standardDurationPerDay = 'Darf nicht kleiner als 0 sein.';
        } else if(values.standardDurationPerDay > 1440) {
          errors.standardDurationPerDay = 'Darf nicht grösser als 1440 sein.';
        }

        if(!values.standardDurationPerShift) {
          errors.standardDurationPerShift = 'Erforderlich';
        } else if(values.standardDurationPerShift < 0) {
          errors.standardDurationPerShift = 'Darf nicht kleiner als 0 sein.';
        } else if(values.standardDurationPerShift > 1440) {
          errors.standardDurationPerShift = 'Darf nicht grösser als 1440 sein.';
        }

        if(values.comment.length > 256) {
          errors.comment = 'Darf nicht länger als 256 Zeichen sein.'
        }

        return errors;
      }}
      enableReinitialize
    >
      {({ isSubmitting, isValid, dirty, status }) => (
        <Form as={FormikForm}>
          <Form.Group controlId="formStandardDurationPerDay">
            <Form.Label>
              Standardmässige Dauer eines Arbeitstages in Minuten.
              Damit wird das Pensum einer Person in eine monatliche Sollzeit umgerechnet.
            </Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>min</InputGroup.Text>
              </InputGroup.Prepend>
              <FoboField
                name={memberof<SchedulePropertiesValues>('standardDurationPerDay')}
                type="number"
                required
                min="0"
                max="1440"
              />
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="formStandardDurationPerShift">
            <Form.Label>
              Standardmässige Dauer einer Schicht.
              Damit wird die gewünschte Anzahl Schichten einer Person in eine monatliche Sollzeit umgerechnet.
            </Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>min</InputGroup.Text>
              </InputGroup.Prepend>
              <FoboField
                name={memberof<SchedulePropertiesValues>('standardDurationPerShift')}
                type="number"
                required
                min="0"
                max="1440" />
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="formComment">
            <Form.Label>Kommentar</Form.Label>
            <FoboField type="text" name={memberof<SchedulePropertiesValues>('comment')} />
          </Form.Group>
          <SaveButton type="submit" dirty={dirty} saving={isSubmitting} error={status === false} />
        </Form>
      )}
    </Formik>
  );
}

import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { ConfirmationModalButton } from '../Components/ConfirmationModalButton';
import { FetchedResource } from '../FetchedResource';
import { conditionFetchAutocomplete } from '../BackendService';
import { ScheduleId } from '../Types';
import Editor from 'react-simple-code-editor';
import { ContainerLeft } from '../Components/UtilityComponents';
import { ConditionEditorProps } from './ConditionEditorContainer';
import { useAuthFetch } from '../AuthService';

interface AutocompleteInfoProps {
  scheduleId: ScheduleId;
}

export function AutocompleteInfo(props: AutocompleteInfoProps) {
  const authFetch = useAuthFetch();
  return (
    <FetchedResource fetch={() => conditionFetchAutocomplete(authFetch, props.scheduleId)}>
      {info =>
      <>
        <table>
          <tbody>
            <tr>
              <th colSpan={2}>Personen</th>
            </tr>
            {info.persons.map(person =>
              <tr key={person.personId}>
                <td>{person.name}</td>
                <td>{person.personId}</td>
              </tr>
            )}
            <tr>
              <th colSpan={2}>Schichten</th>
            </tr>
            {info.shifts.map(shift =>
              <tr key={shift.shiftId}>
                <td>{shift.name} <b>{shift.label}</b></td>
                <td>{shift.shiftId}</td>
              </tr>
            )}
            <tr>
              <th colSpan={2}>Gruppen</th>
            </tr>
            {info.personGroups.map(personGroup =>
              <tr key={personGroup.personGroupId}>
                <td>{personGroup.name}</td>
                <td>{personGroup.personGroupId}</td>
              </tr>
            )}
          </tbody>
        </table>
        <pre>
          {JSON.stringify(info.templates, undefined, 2)}
        </pre>
      </>
      }
    </FetchedResource>
  )
}

export function RawConditionEditor(props: ConditionEditorProps) {
  const [data, setData] = useState<string>(JSON.stringify(props.condition, undefined, 2));
  const [saving, setSaving] = useState(false);

  let isValidJson: boolean;
  try {
    JSON.parse(data);
    isValidJson = true;
  } catch {
    isValidJson = false;
  }

  async function handleSave() {
    if(props.onSave) {
      setSaving(true);
      try {
        await props.onSave(JSON.parse(data));
      } finally {
        setSaving(false);
      }
    }
  }

  return (
    <ContainerLeft>
      <Form>
        <Form.Group controlId="formConditions">
          <Form.Label>Bedingung</Form.Label>
          <Editor
            value={data}
            onValueChange={code => setData(code)}
            highlight={code => code}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
              border: '1px solid grey',
              borderRadius: '3px',
              minHeight: '30em'
            }}
            disabled={saving}
          />
          {isValidJson && <Form.Control.Feedback type="invalid"></Form.Control.Feedback>}
        </Form.Group>
        {props.error && <Alert variant='danger'>{props.error}</Alert>}
        <Button variant="primary" type="button" disabled={saving || isValidJson === false} onClick={handleSave}>Speichern</Button>
        <Button variant="secondary" type="button" disabled={saving} onClick={props.onCancel}>Abbrechen</Button>
        {props.onDelete &&
        <ConfirmationModalButton
          variant="danger"
          type="button"
          disabled={saving}
          onPositive={props.onDelete}
          text={`Wollen Sie diese Bedingung wirklich aus dem Dienstplan entfernen?`}
        >
          Löschen
        </ConfirmationModalButton>
        }
      </Form>
    </ContainerLeft>
  );
}

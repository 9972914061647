/**
 * Demo of condition editor embeddable via iFrame.
 * Uses postMessage() to coordinate interaction with parent.
 */

import { fromPairs } from 'lodash';
import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { ConditionDto_MaxShiftsInARowConstraintDto, ConditionDto_MaxShiftsPerWeekObjectiveDto, ConditionDto_RestrictChangeFromShiftConditionDto } from '../Generated/BackendTypes';
import { useEventListener } from '../Hooks';
import { renderMarkup } from '../SimpleMarkup';
import { ensureOneOf, isNotNil } from '../Utils';
import { MaxShiftsInARowConstraintEditor } from './Editors/MaxShiftsInARowConstraintEditor';
import { MaxShiftsPerWeekObjectiveEditor } from './Editors/MaxShiftsPerWeekObjectiveEditor';
import { RestrictChangeFromShiftConditionEditor } from './Editors/RestrictChangeFromShiftConditionEditor';
import { explainDE } from './ExplainDE';


export const Hardness0_Names = ["Hard", "Soft"] as const;
export type Hardness0 = typeof Hardness0_Names[number];

const DemoConditionId_Names = [
  'demo-condition-0',
  'demo-condition-1',
  'demo-condition-2'
] as const;

type DemoConditionId = typeof DemoConditionId_Names[number];

/**
 * For messages sent from landingpage to us.
 */
interface ParentMessage {
  who: 'taso-landingpage'
  demoConditionId?: DemoConditionId;
}

/**
 * For messages sent from us to the landingapge
 */
interface ChildMessage {
  who: 'taso-shift';
  close?: boolean;
  demoConditionId?: DemoConditionId;
  explanation?: string;
}

const personGroups = [
  { personGroupId: 'pg1', name: 'Assistenz' },
  { personGroupId: 'pg2', name: 'Fach' },
];

const persons = [
  { personId: 'p1', name: 'Maria' },
  { personId: 'p2', name: 'Jonas' },
  { personId: 'p3', name: 'Reto' },
  { personId: 'p4', name: 'Anna' },
];

const shifts = [
  { shiftId: 's1', name: 'Frei' },
  { shiftId: 's2', name: 'Ferien' },
  { shiftId: 's3', name: 'Frühdienst' },
  { shiftId: 's4', name: 'Spätdienst' },
  { shiftId: 's5', name: 'Nachtdienst' },
];

const namesLookup = {
  personGroups: fromPairs(personGroups.map(g => [g.personGroupId, g.name])),
  persons: fromPairs(persons.map(p => [p.personId, p.name])),
  shifts: fromPairs(shifts.map(s => [s.shiftId, s.name]))
};

interface DemoConditionContainerProps {
  demoConditionId?: string;
}

export function DemoConditionContainer(props: DemoConditionContainerProps) {
  const [demoConditionId, setDemoConditionId] = useState<DemoConditionId>(
    ensureOneOf(DemoConditionId_Names, props.demoConditionId, 'demo-condition-0'));
  const [refreshKey, setRefreshKey] = useState(0);

  const [demo0, setDemo0] = useState<ConditionDto_MaxShiftsInARowConstraintDto>({
    _type: 'MaxShiftsInARowConstraintDto',
    enabled: true,
    maxCount: 3,
    personSelector: { _type: 'PersonSelector1', personIds: [], groupIds: [] },
    shiftSelector: { _type: 'ShiftSelector1', shiftIds: ['s5'], shiftKind: undefined },
  });

  const [demo1, setDemo1] = useState<ConditionDto_RestrictChangeFromShiftConditionDto>({
    _type: 'RestrictChangeFromShiftConditionDto',
    enabled: true,
    fromShiftSelector: { _type: 'ShiftSelector1', shiftIds: ['s5'], shiftKind: undefined },
    toShiftSelector: { _type: 'ShiftSelector1', shiftIds: [], shiftKind: 'Rest' },
    personSelector: { _type: 'PersonSelector1', personIds: [], groupIds: ['pg2'] },
    length: 2,
    restriction: 'Confine',
  });

  const [demo2, setDemo2] = useState<ConditionDto_MaxShiftsPerWeekObjectiveDto>({
    _type: 'MaxShiftsPerWeekObjectiveDto',
    enabled: true,
    maxCount: 2,
    penalty: 5,
    personSelector: { _type: 'PersonSelector1', personIds: [], groupIds: ['p2', 'p3'] },
    shiftSelector: { _type: 'ShiftSelector1', shiftIds: ['s3'], shiftKind: undefined },
  });

  function handleCancel() {
    const message: ChildMessage = { who: 'taso-shift', close: true };
    window.parent && window.parent.postMessage(message, '*');
  }

  function handleUpdate(data: any) {
    switch(demoConditionId) {
      case 'demo-condition-0': setDemo0(data); break;
      case 'demo-condition-1': setDemo1(data); break;
      case 'demo-condition-2': setDemo2(data); break;
    }

    const explanation = ReactDOMServer.renderToStaticMarkup(renderMarkup(explainDE(data, namesLookup)));
    const message: ChildMessage = { who: 'taso-shift', demoConditionId: demoConditionId, explanation, close: true };
    window.parent && window.parent.postMessage(message, '*');
  }

  useEventListener('message', e => {
    const event = e as MessageEvent;
    if(event.data.who !== 'taso-landingpage') {
      return;
    }
    const msg = event.data as ParentMessage;
    if(isNotNil(msg.demoConditionId)) {
      setRefreshKey(refreshKey+1);
      setDemoConditionId(msg.demoConditionId);
    }
  });

  const editorProps = {
    key: refreshKey,
    dropdown: {
      personGroups: personGroups.map(g => ({ itemKey: g.personGroupId, label: g.name })),
      persons: persons.map(p => ({ itemKey: p.personId, label: p.name })),
      shifts: shifts.map(s => ({ itemKey: s.shiftId, label: s.name })),
    },
    identifiers: {
      personGroupIds: personGroups.map(g => g.personGroupId),
      personIds: persons.map(p => p.personId),
      shiftIds: shifts.map(s => s.shiftId)
    },
    lookup: namesLookup,
    onSave: handleUpdate,
    onCancel: handleCancel,
  };

  switch(demoConditionId) {
    case 'demo-condition-0': return (<MaxShiftsInARowConstraintEditor condition={demo0} {...editorProps} />);
    case 'demo-condition-1': return (<RestrictChangeFromShiftConditionEditor condition={demo1} {...editorProps} />);
    case 'demo-condition-2': return (<MaxShiftsPerWeekObjectiveEditor condition={demo2} {...editorProps} /> )
  }
}

import { createContext, useContext } from "react";
import { backendBaseUrl } from './config';
import { AppConfDto } from "./Generated/BackendTypes";

type AuthFailedReason = 'configError'|'notLoggedIn'|'userauthError'|
  'userauthUnreachable'|'backendError'|'backendUnreachable'|'backendDidNotAcceptUserAuthToken'|'dummyAuthFailed';

export type AuthFailedCallback = (reason: AuthFailedReason, loginUrl: string) => void;

const backendTokenEndpoint = `${backendBaseUrl}/enter`;
const backendDummyEndpoint = `${backendBaseUrl}/enter/dummy`;

interface AcquireTokenSuccess {
  kind: 'success';
  token: string;
}

interface AcquireTokenFailed {
  kind: 'failure';
  reason: AuthFailedReason;
}

type AcquireTokenResult = AcquireTokenSuccess | AcquireTokenFailed;

function authFailed(reason: AuthFailedReason): AcquireTokenFailed {
  return { kind: 'failure', reason };
}

function authSuccess(token: string): AcquireTokenSuccess {
  return { kind: 'success', token };
}

async function acquireDummyToken(): Promise<AcquireTokenResult> {
  try {
    const response = await fetch(backendDummyEndpoint, { method: 'POST' });
    if(!response.ok) {
      return authFailed('backendError');
    } else {
      const data = await response.json() as { token: string };
      return authSuccess(data.token);
    }
  } catch {
    // catch connection errors
    return authFailed('backendUnreachable');
  }
}

async function acquireToken(userAuthTokenEndpoint: string): Promise<AcquireTokenResult> {
  if(!userAuthTokenEndpoint) {
    return authFailed('configError');
  }

  const acquireUserAuthToken = async (): Promise<AcquireTokenResult> => {
    try {
      const response = await fetch(userAuthTokenEndpoint, { method: 'POST', credentials: 'include' });

      if(!response.ok) {
        if(response.status === 403) {
          return authFailed('notLoggedIn');
        } else {
          return authFailed('userauthError');
        }
      } else {
        const { token } = await response.json();
        return authSuccess(token);
      }
    } catch {
      // catch connection errors
      return authFailed('userauthUnreachable');
    }
  };

  const acquireTurnsToken = async (userauthToken: string): Promise<AcquireTokenResult> => {
    try {
      const response = await fetch(backendTokenEndpoint, { headers: {'Authorization': `Bearer ${userauthToken}`} });

      if(!response.ok) {
        if(response.status === 403) {
          return authFailed('backendDidNotAcceptUserAuthToken');
        } else {
          return authFailed('backendError');
        }
      } else {
        const { token } = await response.json();
        return authSuccess(token);
      }
    } catch {
      // catch connection errors
      return authFailed('backendUnreachable');
    }
  };

  const userauthResult = await acquireUserAuthToken();
  if(userauthResult.kind === 'failure') {
    return userauthResult;
  } else {
    return await acquireTurnsToken(userauthResult.token);
  }
}

export class AuthTokenService {
  private token: Promise<AcquireTokenResult>|undefined = undefined;
  private appConfPromise: Promise<AppConfDto>;

  private authFailedCallback?: AuthFailedCallback;

  /**
   * @param tokenEndpoint
   * @param authFailedCallback is called when acquiring a bearer token at the userauth server fails
   *        because the user is logged out. The ui should notify the user and redirect him
   *        to userauth for login.
   */
  constructor(appConfPromise: Promise<AppConfDto>, authFailedCallback?: AuthFailedCallback) {
    this.appConfPromise = appConfPromise;
    this.authFailedCallback = authFailedCallback;
  }

  forget() {
    this.token = undefined;
  }

  async getUserAuthLogoutUrl() {
    return (await this.appConfPromise).logoutUrl;
  }

  getToken(): Promise<string> {
    // check if token acquisition is underway
    if(this.token === undefined) {
      // no token is being acquired. start the process
      const perform = async (): Promise<AcquireTokenResult> => {
        const appConf = await this.appConfPromise;
        if(appConf.d) {
          return await acquireDummyToken();
        } else {
          return await acquireToken(appConf.tokenUrl);
        }
      };
      const performPromise = perform();
      this.token = performPromise;

      // as the initiator, we are also responsible for reporting errors
      const watch = async () => {
        const result = await performPromise;
        if(result.kind === 'failure') {
          let loginUrl = await this.getUserAuthLoginUrl();
          if(this.authFailedCallback) {
            this.authFailedCallback(result.reason, loginUrl);
          }
          throw Error(result.reason);
        } else {
          return result.token;
        }
      }
      return watch();
    } else {
      const watch = async () => {
        const result = await this.token;
        if(result === undefined) {
          throw Error('Someone probably called forget() while we waited for the token result.');
        } else if(result.kind === 'success') {
          // we have a token cached
          return result.token;
        } else {
          // there is an error and the auth failed callback has been called already.
          throw Error(result.reason);
        }
      };
      return watch();
    }
  }

  private async getUserAuthLoginUrl() {
    return (await this.appConfPromise).loginUrl;
  }
}

export type AuthFetch = (input: RequestInfo, init?: RequestInit) => Promise<Response>

export function createAuthFetch(authTokenService: AuthTokenService): AuthFetch {
  return async (input: RequestInfo, init?: RequestInit) => {
    // (currently this does not matter, but perhaps it's a timesaver when solving a problem)
    // hint: Request.referrer and Request.mode are reset when using new Request(...) (see below)
    // See https://developer.mozilla.org/en-US/docs/Web/API/Request/Request#Parameters

    const token = await authTokenService.getToken();
    const initWithAuthOptions: RequestInit = {...init, credentials: 'include'};
    const request = new Request(input, initWithAuthOptions);
    request.headers.set('Authorization', "Bearer " + token);
    const response = await fetch(request);

    if(response.status !== 403) {
      // no auth error, so everything is fine
      return response;
    } else {
      // it was an auth error, so we will force a new token
      authTokenService.forget();
      const newToken = await authTokenService.getToken();
      if(newToken !== undefined) {
        const request = new Request(input, initWithAuthOptions);
        request.headers.set('Authorization', "Bearer " + newToken);
        return await fetch(request);
      } else {
        throw Error("failed to fetch secured ressource");
      }
    }
  };
}

export const AuthContext = createContext(fetch);
export const AuthTokenServiceContext = createContext<AuthTokenService|undefined>(undefined);

export function useAuthFetch() {
  return useContext(AuthContext);
}

export function useAuthTokenService() {
  return useContext(AuthTokenServiceContext);
}

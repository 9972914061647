import { TableDto } from '../Types';
export interface AppConfDto {
  loginUrl: string;
  logoutUrl: string;
  tokenUrl: string;
  userLocale: string;
  d: boolean;
}

export interface ComputationAdjustmentsChangeDto {
  personId: string;
  date: string;
  shiftId?: string;
}

export interface ComputationAdjustmentsDto {
  scheduleId: string;
  documentRevision: number;
  changes: ComputationAdjustmentsChangeDto[];
}

export interface ComputationCheckoutInfoDto {
  hasEnoughCredit: boolean;
  isFree: boolean;
  firstTeamUsage: boolean;
  balanceBefore: number;
  balanceAfter: number;
  additionalUsage: number;
  scheduleUsage: number;
}

export interface ComputationMonitorDto {
  solverTaskId: number;
  creationTime: string;
  startTime?: string;
  endTime?: string;
  maxQueueTimeSeconds: number;
  maxSolveTimeSeconds: number;
  status: ComputationMonitorStatus;
  scheduleStartDate: string;
  scheduleEndDate: string;
  standardDurationPerDay: number;
  standardDurationPerShift: number;
  shifts: ComputationShiftDto[];
  persons: PersonLookupDto[];
  personGroups: PersonGroupLookupDto[];
}

export interface ComputationMonitorResultDto {
  solverResultId: number;
  solution?: number[][];
  unsatSubset?: SolverResultUnsatSubset0;
}

export interface ComputationMonitorResultsDto {
  lastSolverResultId: number;
  startTime?: string;
  endTime?: string;
  hasMoreResults: boolean;
  status: ComputationMonitorStatus;
  results: ComputationMonitorResultDto[];
}

export const ComputationMonitorStatus_Names = ["Queued", "Running", "Cancelled", "Completed", "Failed", "QueueTimeout"] as const;
export type ComputationMonitorStatus = typeof ComputationMonitorStatus_Names[number];

export interface ComputationObjectiveValueDto {
  conditionPos: number;
  name: string;
  value: number;
  subresults: ComputationSubresultDto[];
}

export interface ComputationResultDto {
  scheduleId: string;
  documentRevision: number;
  entries: ComputationTableCellDto[][];
  shifts: ComputationShiftDto[];
  personGroups: PersonGroupLookupDto[];
  persons: PersonLookupDto[];
  scheduleStartDate: string;
  scheduleEndDate: string;
  standardDurationPerDay: number;
  standardDurationPerShift: number;
  isRunning: boolean;
  objectiveValues: ComputationObjectiveValueDto[];
  totalObjectiveValue: number;
  unsatSubsets: UnsatLocator0[][];
}

export interface ComputationShiftDto {
  shiftId: string;
  name: string;
  label: string;
  duration: number;
  durationScaledByPensum: boolean;
}

export interface ComputationSubresultDto {
  value: number;
  staffDemandIdx?: number;
  conditionPos?: number;
  personId?: string;
  personGroupId?: string;
  date?: string;
  name?: string;
}

export interface ComputationTableCellDto {
  c: number;
  a?: number;
}

export interface ConditionAutocompleteDto {
  templates: ConditionDto[];
  shifts: ShiftLookupDto[];
  persons: PersonLookupDto[];
  personGroups: PersonGroupLookupDto[];
}

export interface ConditionAvoidSingleFreedaysObjectiveDoc0 {
  enabled: boolean;
  penaltyPerSingleDay: number;
}

export interface ConditionAvoidSingleWorkdaysObjectiveDoc0 {
  enabled: boolean;
  penaltyPerSingleDay: number;
}

export interface ConditionCreateDto {
  scheduleId: string;
  documentRevision: number;
  condition: ConditionDto;
}

export interface ConditionDistributeWeekendLoadObjectiveDoc2 {
  enabled: boolean;
  personSelector: PersonSelectorDto;
  penalty: number;
}

export type ConditionDto_MaxShiftsInARowConstraintDto = { _type: 'MaxShiftsInARowConstraintDto' } & ConditionMaxShiftsInARowConstraintDoc2;
export type ConditionDto_MinCountWeekendsHavingShiftObjectiveDto = { _type: 'MinCountWeekendsHavingShiftObjectiveDto' } & ConditionMinCountWeekendsHavingShiftObjectiveDoc2;
export type ConditionDto_EvenlyDistributeOverWeeksObjectiveDto = { _type: 'EvenlyDistributeOverWeeksObjectiveDto' } & ConditionEvenlyDistributeOverWeeksObjectiveDoc0;
export type ConditionDto_TargetMinutesObjectiveDto = { _type: 'TargetMinutesObjectiveDto' } & ConditionTargetMinutesObjectiveDoc1;
export type ConditionDto_WeekendEitherFreeOrWorkObjectiveDto = { _type: 'WeekendEitherFreeOrWorkObjectiveDto' } & ConditionWeekendEitherFreeOrWorkObjectiveDoc0;
export type ConditionDto_AvoidSingleWorkdaysObjectiveDto = { _type: 'AvoidSingleWorkdaysObjectiveDto' } & ConditionAvoidSingleWorkdaysObjectiveDoc0;
export type ConditionDto_AvoidSingleFreedaysObjectiveDto = { _type: 'AvoidSingleFreedaysObjectiveDto' } & ConditionAvoidSingleFreedaysObjectiveDoc0;
export type ConditionDto_PreferMaxFiveWorkdaysInARowObjectiveDto = { _type: 'PreferMaxFiveWorkdaysInARowObjectiveDto' } & ConditionPreferMaxFiveWorkdaysInARowObjectiveDoc0;
export type ConditionDto_DistributeWeekendLoadObjectiveDto = { _type: 'DistributeWeekendLoadObjectiveDto' } & ConditionDistributeWeekendLoadObjectiveDoc2;
export type ConditionDto_MaxShiftsPerWeekObjectiveDto = { _type: 'MaxShiftsPerWeekObjectiveDto' } & ConditionMaxShiftsPerWeekObjectiveDoc1;
export type ConditionDto_ShiftSelectionConditionDto = { _type: 'ShiftSelectionConditionDto' } & ConditionShiftSelectionConditionDoc0;
export type ConditionDto_RestrictChangeFromShiftConditionDto = { _type: 'RestrictChangeFromShiftConditionDto' } & ConditionRestrictChangeFromShiftConditionDoc0;
export type ConditionDto_RestrictChangeToShiftConditionDto = { _type: 'RestrictChangeToShiftConditionDto' } & ConditionRestrictChangeToShiftConditionDoc0;
export type ConditionDto =
| ConditionDto_MaxShiftsInARowConstraintDto
| ConditionDto_MinCountWeekendsHavingShiftObjectiveDto
| ConditionDto_EvenlyDistributeOverWeeksObjectiveDto
| ConditionDto_TargetMinutesObjectiveDto
| ConditionDto_WeekendEitherFreeOrWorkObjectiveDto
| ConditionDto_AvoidSingleWorkdaysObjectiveDto
| ConditionDto_AvoidSingleFreedaysObjectiveDto
| ConditionDto_PreferMaxFiveWorkdaysInARowObjectiveDto
| ConditionDto_DistributeWeekendLoadObjectiveDto
| ConditionDto_MaxShiftsPerWeekObjectiveDto
| ConditionDto_ShiftSelectionConditionDto
| ConditionDto_RestrictChangeFromShiftConditionDto
| ConditionDto_RestrictChangeToShiftConditionDto;
export type ConditionDto_Cases = ConditionDto['_type'];
export const ConditionDto_CaseNames = ["MaxShiftsInARowConstraintDto", "MinCountWeekendsHavingShiftObjectiveDto", "EvenlyDistributeOverWeeksObjectiveDto", "TargetMinutesObjectiveDto", "WeekendEitherFreeOrWorkObjectiveDto", "AvoidSingleWorkdaysObjectiveDto", "AvoidSingleFreedaysObjectiveDto", "PreferMaxFiveWorkdaysInARowObjectiveDto", "DistributeWeekendLoadObjectiveDto", "MaxShiftsPerWeekObjectiveDto", "ShiftSelectionConditionDto", "RestrictChangeFromShiftConditionDto", "RestrictChangeToShiftConditionDto"];


export interface ConditionEvenlyDistributeOverWeeksObjectiveDoc0 {
  enabled: boolean;
}

export interface ConditionListDto {
  conditions: ConditionListItemDto[];
  shifts: Record<string,ShiftLookupDto>
  personGroups: Record<string,PersonGroupLookupDto>
  persons: Record<string,PersonLookupDto>
}

export interface ConditionListItemDto {
  conditionPos: number;
  condition: ConditionDto;
}

export interface ConditionMaxShiftsInARowConstraintDoc2 {
  enabled: boolean;
  personSelector: PersonSelectorDto;
  shiftSelector: ShiftSelectorDto;
  maxCount: number;
}

export interface ConditionMaxShiftsPerWeekObjectiveDoc1 {
  enabled: boolean;
  personSelector: PersonSelectorDto;
  shiftSelector: ShiftSelectorDto;
  maxCount: number;
  penalty: number;
}

export interface ConditionMinCountWeekendsHavingShiftObjectiveDoc2 {
  enabled: boolean;
  personSelector: PersonSelectorDto;
  shiftSelector: ShiftSelectorDto;
  minCount: number;
  penalty: number;
}

export interface ConditionPreferMaxFiveWorkdaysInARowObjectiveDoc0 {
  enabled: boolean;
  penalty: number;
}

export interface ConditionReadDto {
  shifts: Record<string,ShiftLookupDto>
  personGroups: Record<string,PersonGroupLookupDto>
  persons: Record<string,PersonLookupDto>
  conditionPos: number;
  scheduleId: string;
  documentRevision: number;
  condition: ConditionDto;
}

export interface ConditionReadTemplateDto {
  shifts: Record<string,ShiftLookupDto>
  personGroups: Record<string,PersonGroupLookupDto>
  persons: Record<string,PersonLookupDto>
  scheduleId: string;
  documentRevision: number;
  condition: ConditionDto;
}

export interface ConditionRestrictChangeFromShiftConditionDoc0 {
  enabled: boolean;
  personSelector: PersonSelectorDto;
  fromShiftSelector: ShiftSelectorDto;
  toShiftSelector: ShiftSelectorDto;
  length: number;
  restriction: ConfineOrPrevent0;
}

export interface ConditionRestrictChangeToShiftConditionDoc0 {
  enabled: boolean;
  personSelector: PersonSelectorDto;
  fromShiftSelector: ShiftSelectorDto;
  toShiftSelector: ShiftSelectorDto;
  length: number;
  restriction: ConfineOrPrevent0;
}

export interface ConditionShiftSelectionConditionDoc0 {
  enabled: boolean;
  personSelector: PersonSelectorDto;
  shiftSelector: ShiftSelectorDto;
  datePattern: DatePatternDto;
  hardness: Hardness0;
  restriction: ConfineOrPrevent0;
  penalty: number;
}

export interface ConditionTargetMinutesObjectiveDoc1 {
  enabled: boolean;
  alignment: TargetMinutesAlignment0;
}

export interface ConditionUpdateDto {
  conditionPos: number;
  scheduleId: string;
  documentRevision: number;
  condition: ConditionDto;
}

export interface ConditionWeekendEitherFreeOrWorkObjectiveDoc0 {
  enabled: boolean;
  penaltyPerWeekend: number;
}

export const ConfineOrPrevent0_Names = ["Confine", "Prevent"] as const;
export type ConfineOrPrevent0 = typeof ConfineOrPrevent0_Names[number];

export interface DataTransferScheduleDto {
  scheduleId: string;
  startDate: string;
  endDate: string;
  scheduleDoc: string;
  scheduleFormat: number;
}

export interface DatePattern0Dto {
  mode: DatePatternMode0;
  daysOfWeek: number[];
}

export type DatePatternDto_DatePattern0 = { _type: 'DatePattern0' } & DatePattern0Dto;
export type DatePatternDto =
| DatePatternDto_DatePattern0;
export type DatePatternDto_Cases = DatePatternDto['_type'];
export const DatePatternDto_CaseNames = ["DatePattern0"];


export const DatePatternMode0_Names = ["Daily", "DaysOfWeek"] as const;
export type DatePatternMode0 = typeof DatePatternMode0_Names[number];

export interface DateSelector0Dto {
  mode: DateSelectorMode0;
  date?: string;
}

export type DateSelectorDto_DateSelector0 = { _type: 'DateSelector0' } & DateSelector0Dto;
export type DateSelectorDto =
| DateSelectorDto_DateSelector0;
export type DateSelectorDto_Cases = DateSelectorDto['_type'];
export const DateSelectorDto_CaseNames = ["DateSelector0"];


export const DateSelectorMode0_Names = ["Date", "StartOfPeriod", "EndOfPeriod"] as const;
export type DateSelectorMode0 = typeof DateSelectorMode0_Names[number];

export interface ErrorCannotDeleteShiftIsDefaultShiftDto {
  shiftId: string;
  shiftName: string;
}

export interface ErrorCannotDeleteShiftStillReferencedDto {
  shiftId: string;
  shiftName: string;
  staffDemandIdxs: number[];
}

export type ErrorDto_CannotDeleteShiftStillReferenced = { _type: 'CannotDeleteShiftStillReferenced' } & ErrorCannotDeleteShiftStillReferencedDto;
export type ErrorDto_CannotDeleteShiftIsDefaultShift = { _type: 'CannotDeleteShiftIsDefaultShift' } & ErrorCannotDeleteShiftIsDefaultShiftDto;
export type ErrorDto_Other = { _type: 'Other' } & ErrorOtherDto;
export type ErrorDto =
| ErrorDto_CannotDeleteShiftStillReferenced
| ErrorDto_CannotDeleteShiftIsDefaultShift
| ErrorDto_Other;
export type ErrorDto_Cases = ErrorDto['_type'];
export const ErrorDto_CaseNames = ["CannotDeleteShiftStillReferenced", "CannotDeleteShiftIsDefaultShift", "Other"];


export interface ErrorOtherDto {
  message: string;
}

export const Hardness0_Names = ["Hard", "Soft"] as const;
export type Hardness0 = typeof Hardness0_Names[number];

export interface PersonGroupListDto {
  documentRevision: number;
  personGroups: PersonGroupListItemDto[];
  persons: PersonGroupPersonLookupDto[];
}

export interface PersonGroupListItemDto {
  personGroupId: string;
  name: string;
  personIds: string[];
}

export interface PersonGroupLookupDto {
  personGroupId: string;
  name: string;
}

export interface PersonGroupOrderingReadDto {
  personGroups: PersonGroupOrderingReadItemDto[];
}

export interface PersonGroupOrderingReadItemDto {
  personGroupId: string;
  name: string;
}

export interface PersonGroupOrderingUpdateDto {
  personGroupIds: string[];
}

export interface PersonGroupPersonLookupDto {
  personId: string;
  name: string;
}

export interface PersonGroupUpsertDto {
  documentRevision: number;
  name: string;
  personIds: string[];
}

export interface PersonListDto {
  documentRevision: number;
  persons: PersonListItemDto[];
}

export interface PersonListItemDto {
  personId: string;
  name: string;
  targetWorkload: TargetWorkload0;
}

export interface PersonLookupDto {
  personId: string;
  name: string;
  personGroupNames: string[];
  targetWorkload: TargetWorkload0;
}

export interface PersonOrderingReadDto {
  persons: PersonOrderingReadItemDto[];
}

export interface PersonOrderingReadItemDto {
  personId: string;
  name: string;
}

export interface PersonOrderingUpdateDto {
  personIds: string[];
}

export interface PersonSelector1Dto {
  personIds: string[];
  groupIds: string[];
  pensumAbove?: number;
  pensumBelow?: number;
}

export type PersonSelectorDto_PersonSelector1 = { _type: 'PersonSelector1' } & PersonSelector1Dto;
export type PersonSelectorDto =
| PersonSelectorDto_PersonSelector1;
export type PersonSelectorDto_Cases = PersonSelectorDto['_type'];
export const PersonSelectorDto_CaseNames = ["PersonSelector1"];


export interface PersonUpsertDto {
  documentRevision: number;
  personId?: string;
  name: string;
  targetWorkload: TargetWorkload0;
}

export interface ScheduleCloneRequestDto {
  comment: string;
}

export interface ScheduleCloneResponseDto {
  scheduleId: string;
}

export interface ScheduleCreateRequestDto {
  teamId: string;
  startDate: string;
  endDate: string;
}

export interface ScheduleCreateResponseDto {
  scheduleId: string;
  overlaps: ScheduleListItemDto[];
}

export interface ScheduleExampleCreateRequestDto {
  teamId: string;
  exampleScheduleId: string;
}

export interface ScheduleExampleCreateResponseDto {
  scheduleId: string;
}

export interface ScheduleExampleListDto {
  examples: ScheduleExampleListItemDto[];
}

export interface ScheduleExampleListItemDto {
  exampleScheduleId: string;
  name: string;
  url: string;
}

export interface ScheduleImportResponseCellAddressDto {
  columnNumber: number;
  columnLetter: string;
  rowNumber: number;
}

export interface ScheduleImportResponseDto {
  schedule: ScheduleInputDto;
  warnings: ScheduleImportResponseWarningDto[];
}

export interface ScheduleImportResponseWarningDto {
  address?: ScheduleImportResponseCellAddressDto;
  message: string;
}

export interface ScheduleInputDto {
  scheduleId: string;
  documentRevision: number;
  comment: string;
  startDate: string;
  endDate: string;
  shifts: ScheduleInputShiftDto[];
  defaultShiftId: string;
  standardDurationPerDay: number;
  standardDurationPerShift: number;
  persons: ScheduleInputPersonDto[];
  personGroups: ScheduleInputPersonGroupDto[];
  inputEntries: TableDto<string,string,ScheduleInputInputEntryDto>
  staffDemandWarnings: ScheduleInputStaffDemandWarningDto[];
  unsatSubsets: UnsatLocator0[][];
}

export interface ScheduleInputInputEntryDto {
  o: number[];
  s: number[];
  sl: string[];
}

export interface ScheduleInputPersonDto {
  personId: string;
  name: string;
  pensum: number;
  targetWorkload: TargetWorkload0;
}

export interface ScheduleInputPersonGroupDto {
  personGroupId: string;
  name: string;
}

export interface ScheduleInputShiftDto {
  shiftId: string;
  name: string;
  label: string;
  duration: number;
}

export interface ScheduleInputStaffDemandWarningDto {
  staffDemandIdx: number;
  shiftId: string;
  date: string;
  minCount: number;
  maxCount: number;
  actualLowerBound: number;
  actualUpperBound: number;
}

export interface ScheduleInputUpdateDto {
  scheduleId: string;
  entries: ScheduleInputUpdateEntryDto[];
}

export interface ScheduleInputUpdateEntryDto {
  personId: string;
  index: number;
  selection: string[];
}

export interface ScheduleListDto {
  schedules: ScheduleListItemDto[];
  teams: ScheduleListTeamDto[];
}

export interface ScheduleListItemDto {
  scheduleId: string;
  teamId: string;
  comment: string;
  startDate: string;
  endDate: string;
}

export interface ScheduleListTeamDto {
  teamId: string;
  name: string;
}

export interface ScheduleMetadataResponseDto {
  scheduleId: string;
  comment: string;
  startDate: string;
  endDate: string;
}

export interface ScheduleSettingsDto {
  scheduleId: string;
  documentRevision: number;
  comment: string;
  standardDurationPerDay: number;
  standardDurationPerShift: number;
}

export const ShiftKind0_Names = ["Work", "Rest"] as const;
export type ShiftKind0 = typeof ShiftKind0_Names[number];

export interface ShiftListDto {
  documentRevision: number;
  shifts: ShiftListItemDto[];
  defaultShiftId: string;
}

export interface ShiftListItemDto {
  shiftId: string;
  name: string;
  label: string;
  duration: number;
  shiftKind: ShiftKind0;
  durationScaledByPensum: boolean;
}

export interface ShiftLookupDto {
  shiftId: string;
  name: string;
  label: string;
  shiftKind: ShiftKind0;
  isDefault: boolean;
}

export interface ShiftOrderingReadDto {
  shifts: ShiftOrderingReadItemDto[];
}

export interface ShiftOrderingReadItemDto {
  shiftId: string;
  name: string;
  label: string;
}

export interface ShiftOrderingUpdateDto {
  shiftIds: string[];
}

export interface ShiftSelector1Dto {
  shiftIds: string[];
  shiftKind?: ShiftKind0;
}

export type ShiftSelectorDto_ShiftSelector1 = { _type: 'ShiftSelector1' } & ShiftSelector1Dto;
export type ShiftSelectorDto =
| ShiftSelectorDto_ShiftSelector1;
export type ShiftSelectorDto_Cases = ShiftSelectorDto['_type'];
export const ShiftSelectorDto_CaseNames = ["ShiftSelector1"];


export interface ShiftSetDefaultDto {
  shiftId: string;
}

export interface ShiftUpsertDto {
  documentRevision: number;
  shiftId?: string;
  name: string;
  label: string;
  duration: number;
  shiftKind: ShiftKind0;
  durationScaledByPensum: boolean;
}

export interface SiteAdminSwitchTenantRequestDto {
  tenantId: string;
}

export interface SiteAdminTenantListDto {
  tenants: SiteAdminTenantListItemDto[];
}

export interface SiteAdminTenantListItemDto {
  tenantId: string;
  name: string;
  principalUserName: string;
  principalUserEmailAddress: string;
}

export interface SolverResultUnsatSubset0 {
  unsatSubset: UnsatLocator0[];
  round: number;
}

export interface SolverTaskCreateDto {
  queueTimeout: number;
  solveTimeout: number;
}

export interface SolverTaskStatusDto {
  inProgress: boolean;
}

export interface StaffDemandAdjustmentsChangeDto {
  staffDemandIdx: number;
  date: string;
  range?: StaffDemandRangeDto;
}

export interface StaffDemandAdjustmentsDto {
  scheduleId: string;
  documentRevision: number;
  changes: StaffDemandAdjustmentsChangeDto[];
}

export interface StaffDemandEditDto {
  scheduleId: string;
  documentRevision?: number;
  staffDemandIdx?: number;
  shiftId: string;
  personGroupIds: string[];
  personIds: string[];
  enabled: boolean;
  startDateSelector: DateSelectorDto;
  endDateSelector: DateSelectorDto;
  pattern: StaffDemandPatternDto;
}

export interface StaffDemandMultipleNonZeroOverlappingDemandsWarningDto {
  staffDemandIdxs: number[];
  date: string;
}

export interface StaffDemandPatternDto {
  mode: StaffDemandPatternMode;
  staffDemandRanges: StaffDemandRangeDto[];
}

export const StaffDemandPatternMode_Names = ["Daily", "WeekDays"] as const;
export type StaffDemandPatternMode = typeof StaffDemandPatternMode_Names[number];

export interface StaffDemandRangeDto {
  minCount: number;
  maxCount: number;
}

export interface StaffDemandReadDto {
  shifts: Record<string,ShiftLookupDto>
  personGroups: Record<string,PersonGroupLookupDto>
  persons: Record<string,PersonLookupDto>
  scheduleStartDate: string;
  scheduleEndDate: string;
  scheduleId: string;
  documentRevision?: number;
  staffDemandIdx?: number;
  shiftId: string;
  personGroupIds: string[];
  personIds: string[];
  enabled: boolean;
  startDateSelector: DateSelectorDto;
  endDateSelector: DateSelectorDto;
  pattern: StaffDemandPatternDto;
}

export interface StaffDemandTableCellDto {
  al?: number;
  au?: number;
  pl: number;
  pu: number;
}

export interface StaffDemandTableDto {
  scheduleId: string;
  documentRevision: number;
  staffDemands: StaffDemandTableRowDto[];
  shifts: Record<string,ShiftLookupDto>
  personGroups: Record<string,PersonGroupLookupDto>
  persons: Record<string,PersonLookupDto>
  scheduleStartDate: string;
  scheduleEndDate: string;
  warnings: StaffDemandWarningDto[];
}

export interface StaffDemandTableRowDto {
  staffDemandIdx: number;
  shiftId: string;
  personGroupIds: string[];
  personIds: string[];
  affectedPersonIds: string[];
  enabled: boolean;
  entries: StaffDemandTableCellDto[];
}

export type StaffDemandWarningDto_MultipleNonZeroOverlappingDemandsWarning = { _type: 'MultipleNonZeroOverlappingDemandsWarning' } & StaffDemandMultipleNonZeroOverlappingDemandsWarningDto;
export type StaffDemandWarningDto =
| StaffDemandWarningDto_MultipleNonZeroOverlappingDemandsWarning;
export type StaffDemandWarningDto_Cases = StaffDemandWarningDto['_type'];
export const StaffDemandWarningDto_CaseNames = ["MultipleNonZeroOverlappingDemandsWarning"];


export const TargetMinutesAlignment0_Names = ["None", "Middle"] as const;
export type TargetMinutesAlignment0 = typeof TargetMinutesAlignment0_Names[number];

export interface TargetWorkload0 {
  mode: TargetWorkloadMode0;
  pensum?: number;
  shiftCountMin?: number;
  shiftCountMax?: number;
}

export const TargetWorkloadMode0_Names = ["Pensum", "ShiftCountRange"] as const;
export type TargetWorkloadMode0 = typeof TargetWorkloadMode0_Names[number];

export interface TeamCreateDto {
  name: string;
}

export interface TeamDto {
  teamId: string;
  name: string;
  numSchedules: number;
}

export interface TeamListDto {
  teams: TeamDto[];
}

export interface TeamUpdateDto {
  name: string;
}

export interface TenantCreateInviteRequestDto {
  name: string;
  emailAddress: string;
}

export interface TenantDeleteInviteRequestDto {
  emailAddress: string;
}

export interface TenantDto {
  name: string;
}

export interface TenantInviteListItemDto {
  creationTime: string;
  emailAddress: string;
}

export interface TenantMemberAndInviteListDto {
  members: TenantMemberListItemDto[];
  invites: TenantInviteListItemDto[];
}

export interface TenantMemberListItemDto {
  userId: string;
  name: string;
  emailAddress: string;
  isAdmin: boolean;
  isHidden: boolean;
}

export interface TenantUpdateDto {
  name: string;
}

export interface TenantUpdateMemberRequestDto {
  isAdmin: boolean;
}

export interface UnsatLocator0 {
  availability: boolean;
  staffDemandIdxs: number[];
  conditionPos: number[];
  personIds: string[];
  groupIds: string[];
  dates: string[];
}

export interface UsageCreateCheckoutSessionRequestDto {
  amount: number;
}

export interface UsageCreditRecordDto {
  usageCreditId: number;
  description: string;
  updateTime: string;
  status: UsageCreditStatus;
  amount: number;
  hasInvoice: boolean;
}

export const UsageCreditStatus_Names = ["Carryover", "Pending", "Active", "Void"] as const;
export type UsageCreditStatus = typeof UsageCreditStatus_Names[number];

export interface UsageInvoiceAddressDto {
  emailAddress: string;
  isCompanyAddress: boolean;
  firstName: string;
  lastName: string;
  companyName: string;
  companyAddition: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
}

export interface UsageInvoiceAddressEditDto {
  invoiceAddress: UsageInvoiceAddressDto;
  editRequired: boolean;
  supportedCountryCodes: string[];
}

export interface UsageInvoicePdfDto {
  fileName: string;
  mimeType: string;
  content: string;
}

export interface UsagePeriodListDto {
  currentBalance: number;
  periods: UsagePeriodListItemDto[];
}

export interface UsagePeriodListItemDto {
  usagePeriodId: number;
  startDate: string;
  endDate: string;
}

export interface UsageReportDto {
  queryTime: string;
  creditRecords: UsageCreditRecordDto[];
  usageRecords: UsageUsageRecordDto[];
  totalUsage: number;
  totalCredit: number;
  balance: number;
}

export interface UsageUsageRecordDto {
  teamName: string;
  scheduleId: string;
  scheduleStartDate: string;
  scheduleEndDate: string;
  computationTime: string;
  userName: string;
  emailAddress: string;
  amount: number;
}

export interface UserDto {
  userId: string;
  userName: string;
  emailAddress: string;
  tenantId: string;
  tenantName: string;
  r: boolean;
  a: boolean;
}

export interface UserUpdateDto {
  name: string;
}

import fp from 'lodash/fp';
import React, { ReactNode } from 'react';
import { reactStringReplace } from "./reactStringReplace";

export const italic = (s: string) => `*${s}*`;

const matchItalic = /\*([^*]+)\*/;
const matchBold = /(?:\*\*|__)(.*?)(?:\*\*|__)/;
const replace = (r: RegExp, f: (v: string, i: number) => ReactNode) => (s: string) => reactStringReplace(s, r, f);

export const renderMarkup = (s: string) => fp.flow(
  replace(matchBold, (v, i) => <b key={i}>{v}</b>),
  replace(matchItalic, (v, i) => <i key={i}>{v}</i>)
)([s]);

import React from 'react';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import { usePolyglot } from '../Localization/Localization';
import { Formik, Form as FormikForm, FormikErrors } from 'formik';
import { FoboField } from '../Components/FoboField';
import { memberof, resemblesEmail } from '../Utils';
import { trim } from 'lodash';
import { renderMarkup } from '../SimpleMarkup';
import { IconInfo } from '../icons';

export interface CreateInviteModalProps {
  show: boolean;
  onCancel: () => void;
  onSubmit: (name: string, emailAddress: string) => Promise<void>;
  inviteAlreadyExist: (emailAddress: string) => boolean;
  memberAlreadyAdded: (emailAddress: string) => boolean;
}

interface CreateInviteModalData {
  name: string;
  emailAddress: string;
}

export function CreateInviteModal(props: CreateInviteModalProps) {
  const pg = usePolyglot();
  return (
    <Modal show={props.show} onHide={props.onCancel}>
      <Formik
        initialValues={{ name: '', emailAddress: '' }}
        validate={values => {
          let errors: FormikErrors<CreateInviteModalData> = {}
          if(trim(values.name) === '') {
            errors.name = pg.t('validation.notEmpty');
          }
          if(!resemblesEmail(values.emailAddress)) {
            errors.emailAddress = pg.t('validation.enterEmailAddress');
          } else if(props.inviteAlreadyExist(values.emailAddress.trim())) {
            errors.emailAddress = pg.t('account.inviteAlreadyExists');
          } else if(props.memberAlreadyAdded(values.emailAddress.trim())) {
            errors.emailAddress = pg.t('account.memberAlreadyAdded');
          }

          return errors;
        }}
        onSubmit={values => props.onSubmit(values.name, values.emailAddress)}
      >
        {({ isSubmitting, values, setSubmitting, dirty }) => (
          <Form as={FormikForm}>
            <Modal.Header closeButton>
              <Modal.Title>{pg.t('account.addMember')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Alert variant="info">
                <IconInfo /> {renderMarkup(pg.t('account.createInviteIntro'))}
              </Alert>
              <Form.Group controlId="formName">
                <Form.Label>{pg.t('general.name')}</Form.Label>
                <FoboField
                  type="text"
                  name={memberof<CreateInviteModalData>('name')}
                />
              </Form.Group>
              <Form.Group controlId="formEmailAddress">
                <Form.Label>{pg.t('general.emailAddress')}</Form.Label>
                <FoboField
                  type="text"
                  name={memberof<CreateInviteModalData>('emailAddress')}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={props.onCancel} disabled={isSubmitting}>
                {pg.t('general.cancel')}
              </Button>
              <Button type="submit" variant="primary" disabled={isSubmitting}>
                {pg.t('account.sendInvite')}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

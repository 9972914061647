import React from 'react';
import { Jumbotron, Button, ButtonProps } from 'react-bootstrap';
import { useLocalStorage } from '../Hooks';
import { StorageKey } from '../Utils';
import { usePolyglot } from '../Localization/Localization';
import { IconBook, IconInfo, IconXCircle } from '../icons';

export interface IntroPanelProps extends ButtonProps {
  imageSource: any;
  href?: string;
  children: React.ReactNode;
}

export function IntroPanel({ imageSource, children, href, ...rest}: IntroPanelProps) {
  const pg = usePolyglot();
  const [showInfo, setShowInfo] = useLocalStorage(StorageKey.showInfo, true);
  return (
    <>
    {showInfo &&
      <Jumbotron className="mt-3 p-2 d-flex flex-row">
        <img src={imageSource} alt="" className="p-4" style={{ minWidth: '360px' }} />
        <div>
          <Button variant="link" className="float-right px-1 py-0" onClick={() => setShowInfo(!showInfo)}><IconXCircle /></Button>
          <div className="px-5 pt-5">
            {children}
          </div>
          {href &&
            <div className="px-5 pt-3 pb-5">
              <a href={href} target="_blank" rel="noreferrer">
                {pg.t('introtext.toDocs')} <IconBook size={18} />
              </a>
            </div>
          }
        </div>
      </Jumbotron>
    }
    {!showInfo &&
      <Button variant="link" onClick={() => setShowInfo(!showInfo)} className="float-right px-1 py-0 mt-4 mr-2" {...rest}>
        <IconInfo />
      </Button>
    }
    </>
  )
}

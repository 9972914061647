import React, { useState } from 'react';
import { Alert, Button, Table } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useAuthFetch } from '../AuthService';
import { createScheduleFromExample, fetchExampleList } from '../BackendService';
import { ContainerLeft } from '../Components/UtilityComponents';
import { FetchedResource } from '../FetchedResource';
import { IconArrowLeft } from '../icons';
import { usePolyglot } from '../Localization/Localization';
import { Title } from '../TopBar/Title';
import { ScheduleId, TeamId } from '../Types';
import { Path } from '../Utils';

export interface CreateScheduleFromExampleProps {
  teamId: TeamId;
}

export function CreateScheduleFromExample(props: CreateScheduleFromExampleProps) {
  const authFetch = useAuthFetch();
  const pg = usePolyglot();
  const history = useHistory();
  const [error, setError] = useState<string|undefined>(undefined);
  const [loading, setLoading] = useState(false);

  async function handleCreateClick(exampleScheduleId: ScheduleId) {
    setLoading(true);
    setError(undefined);
    try {
      const d = await createScheduleFromExample(authFetch, { teamId: props.teamId, exampleScheduleId });
      history.push(Path.toComputation(d.scheduleId));
    } catch(e) {
      setError(e.message);
    }
    setLoading(false);
  }

  return (
    <ContainerLeft>
      <Title>{pg.t('general.examples')}</Title>
      <h1 className="mb-3 mt-3">{pg.t('schedule.createScheduleFromExampleHeading')}</h1>
      <Button variant="secondary" className="mb-3" as={Link} to={Path.toScheduleChooser}>
        <IconArrowLeft /> {pg.t('general.back')}
      </Button>
      {error &&
        <Alert variant="danger">{pg.t('general.errorMessageIntro')}: {error}</Alert>
      }
      <FetchedResource fetch={() => fetchExampleList(authFetch)}>
        {d => (
          <Table>
            <tbody>
              {d.examples.map(e => (
                <tr key={e.exampleScheduleId}>
                  <td>
                    {e.name}
                  </td>
                  <td>
                    <Button variant="secondary" href={e.url} target="_blank">
                      {pg.t('general.documentation')}
                    </Button>
                    <Button variant="primary" className="ml-3" onClick={() => handleCreateClick(e.exampleScheduleId)} disabled={loading}>
                      {pg.t('general.create')}
                    </Button>
                  </td>
                </tr>
              ))}
              {d.examples.length === 0 &&
                <tr>
                  <td>{pg.t('general.listIsEmpty')}</td>
                </tr>
              }
            </tbody>
          </Table>
        )}
      </FetchedResource>
    </ContainerLeft>
  );
}

import React from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { ContainerLeft } from '../Components/UtilityComponents';
import { Card } from 'react-bootstrap';
import { ConfirmationModalButton } from '../Components/ConfirmationModalButton';
import { usePolyglot } from '../Localization/Localization';
import { Prompt } from 'react-router-dom';
import { SaveButton } from '../Components/SaveButton';
import { isString } from 'lodash';
import { useField, useFormikContext } from 'formik';
import { IconArrowLeft, IconTrash2 } from '../icons';

export interface ConditionEditorCardProps {
  enabled: boolean;
  dirty?: boolean;
  saving?: boolean;
  error?: string;
  title: any;
  children: any;
  isSubmitting?: boolean;
  onCancelClick?: () => void;
  onDeleteClick?: () => void;
  onEnableClick?: () => void;
  onDisableClick?: () => void;
}

export function ConditionEditorCard(props: ConditionEditorCardProps) {
  const pg = usePolyglot();
  const showSave = props.dirty || props.onDeleteClick === undefined;
  return (
    <>
      {props.onCancelClick &&
        <div className="mb-3">
          <Prompt when={props.isSubmitting === false && props.dirty} message="Du hast ungespeicherte Änderungen. Möchtest du den Editor wirklich verlassen?" />
          {!showSave &&
            <Button variant="secondary" onClick={props.onCancelClick}>
              <IconArrowLeft />Zurück
            </Button>
          }
          {showSave &&
            <>
              <SaveButton type="submit" dirty={true} saving={props.saving || false} error={isString(props.error)} />
              <Button disabled={props.isSubmitting} variant="secondary" onClick={props.onCancelClick} className="ml-3">
                Abbrechen
              </Button>
            </>
          }
        </div>
      }
      {props.error && <Alert variant='danger'>{props.error}</Alert>}
      <Card border={props.enabled ? 'success' : 'warning'} className="shadow-sm">
        <Card.Body>
          <Card.Title>
            <div className="float-right">
              <Button
                id="enableConditionButton"
                type="button"
                onClick={props.onEnableClick}
                variant={props.enabled ? 'success' : 'light'}
              >
                {pg.t('general.active')}
              </Button>
              <Button
                id="disableConditionButton"
                type="button"
                onClick={props.onDisableClick}
                variant={props.enabled ? 'light' : 'warning'}
              >
                {pg.t('general.inactive')}
              </Button>
            </div>
            {props.title}
          </Card.Title>
          <Card.Text as="div" className="mt-5">
            {props.children}
          </Card.Text>
        </Card.Body>
      </Card>
      <div className="mt-5">
        {props.onDeleteClick &&
          <ConfirmationModalButton
            variant="outline-danger"
            type="button"
            disabled={props.isSubmitting}
            onPositive={props.onDeleteClick}
            text={`Wollen Sie diese Bedingung wirklich aus dem Dienstplan entfernen?`}
          >
            <IconTrash2 /> Löschen
          </ConfirmationModalButton>
        }
      </div>
    </>
  );
}

export interface ConditionEditorCardSubformProps {
  name: string;
  error?: string;
  title: any;
  children: any;
  onCancel?: () => void;
  onDelete?: () => void;
}

export function ConditionEditorCardSubform(props: ConditionEditorCardSubformProps) {
  const [, { value }, { setValue }] = useField<boolean>(props.name);
  const { dirty, isSubmitting } = useFormikContext();
  return (
    <ConditionEditorCard
      enabled={value}
      onEnableClick={() => setValue(true)}
      onDisableClick={() => setValue(false)}
      error={props.error}
      title={props.title}
      dirty={dirty}
      saving={isSubmitting}
      isSubmitting={isSubmitting}
      onCancelClick={props.onCancel}
      onDeleteClick={props.onDelete}
    >
      {props.children}
    </ConditionEditorCard>
  );
}

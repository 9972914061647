import React, {  } from 'react';
import ReactDataSheet from 'react-datasheet';
import { toString, trim } from 'lodash';
import { ComputationCellViewData } from './ComputationTable';
import { CellEditor } from '../Table/CellEditor';

function highlight(shiftLabels: string[], value: string): React.ReactElement {
  if(shiftLabels.includes(trim(value))) {
    return (
      <span>{value}</span>
    );
  } else {
    return (
      <span style={{ color: 'red' }}>{value}</span>
    );
  }
}


export function ComputationCellEditor(props: ReactDataSheet.DataEditorProps<ComputationCellViewData>) {
  const value = toString(props.value);
  const shiftLabels = props.cell.data!.shifts.map(shift => shift.label);
  return (
    <CellEditor
      {...props}
      enhancedValue={highlight(shiftLabels, value)}
    />
  )
}

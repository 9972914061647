import React, { ReactChild, ReactNode, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Alert, Table } from 'react-bootstrap';
import { Path } from '../Utils';
import { Link } from 'react-router-dom';
import { ComputationCheckoutInfoDto } from '../Generated/BackendTypes';
import { usePolyglot } from '../Localization/Localization';
import { LoadingSpinner } from '../Components/UtilityComponents';
import { computationFetchCheckoutInfo } from '../BackendService';
import { useAuthFetch } from '../AuthService';
import { ScheduleId } from '../Types';
import { renderMarkup } from '../SimpleMarkup';
import { IconAlertCircle, IconCheckCircle2, IconPlus } from '../icons';

interface CheckoutFooterProps {
  loading?: boolean;
  insufficient?: boolean;
  sufficient?: boolean;
  error?: boolean;
  onCancel: () => void;
  onStart?: () => void;
}

function CheckoutFooter(props: CheckoutFooterProps) {
  const pg = usePolyglot();
  return (
    <Modal.Footer>
      <Button variant="secondary" onClick={props.onCancel}>
        {pg.t('general.cancel')}
      </Button>
      {(props.loading || props.error) &&
        <Button variant="primary" disabled>
          {pg.t('general.start')}
        </Button>
      }
      {props.sufficient &&
        <Button variant="primary" onClick={props.onStart}>
          {pg.t('general.start')}
        </Button>
      }
      {props.insufficient &&
        <Button variant="warning" as={Link} to={Path.toAccountUsage}>
          <IconPlus /> {pg.t('computation.topUpCredit')}
        </Button>
      }
    </Modal.Footer>
  )
}

function CheckoutHeader() {
  const pg = usePolyglot();
  return (
    <Modal.Header closeButton>
      <Modal.Title>
        {pg.t('computation.startComputation')}
      </Modal.Title>
    </Modal.Header>
  );
}

interface SkeletonProps {
  show: boolean;
  onHide: () => void;
  children: ReactNode;
}

function Skeleton(props: SkeletonProps) {
  return (
    <Modal show={props.show} onHide={props.onHide} centered>
      {props.children}
    </Modal>
  )
}

interface LoadingContentProps {
  onCancel: () => void;
}

function LoadingContent(props: LoadingContentProps) {
  return (
    <>
      <CheckoutHeader />
      <Modal.Body className="text-center">
        <LoadingSpinner immediate />
      </Modal.Body>
      <CheckoutFooter onCancel={props.onCancel} loading />
    </>
  );
}

interface ErrorContentProps {
  onCancel: () => void;
  children: ReactNode;
}

function ErrorContent(props: ErrorContentProps) {
  return (
    <>
      <CheckoutHeader />
      <Modal.Body className="text-center">
        <Alert variant="danger">
          {props.children}
        </Alert>
      </Modal.Body>
      <CheckoutFooter onCancel={props.onCancel} error />
    </>
  );
}

interface CheckoutTableProps {
  checkoutInfo: ComputationCheckoutInfoDto;
}

function CheckoutTable(props: CheckoutTableProps) {
  const pg = usePolyglot();
  return (
    <Table>
      <tbody>
        <tr className="font-weight-bold">
          <td>{pg.t('computation.rowCaptionAvailableCredit')}</td>
          <td className="text-right">{props.checkoutInfo.balanceBefore}</td>
          <td>{pg.t('general.rows')}</td>
        </tr>
        <tr className="font-weight-bold text-primary">
          <td>
            {props.checkoutInfo.firstTeamUsage ?
              pg.t('computation.rowCaptionFirstUsage') :
              pg.t('computation.rowCaptionAdditionalUsage')}
          </td>
          <td className="text-right">-{props.checkoutInfo.additionalUsage}</td>
          <td>{pg.t('general.rows')}</td>
        </tr>
        {props.checkoutInfo.hasEnoughCredit &&
          <tr className="font-weight-bold text-success">
            <td>{pg.t('computation.rowCaptionRemainingCredit')}</td>
            <td className="text-right">{props.checkoutInfo.balanceAfter}</td>
            <td>{pg.t('general.rows')}</td>
          </tr>
        }
        {!props.checkoutInfo.hasEnoughCredit &&
          <tr className="font-weight-bold text-danger">
            <td>{pg.t('computation.rowCaptionMissingCredit')}</td>
            <td className="text-right">{-props.checkoutInfo.balanceAfter}</td>
            <td>{pg.t('general.rows')}</td>
          </tr>
        }

      </tbody>
    </Table>
  )
}

interface CheckoutContentProps {
  onCancel: () => void;
  onStart: () => void;
  checkoutInfo: ComputationCheckoutInfoDto;
}

function CheckoutContent(props: CheckoutContentProps) {
  const pg = usePolyglot();
  const isBalanceUnchanged = props.checkoutInfo.balanceAfter === props.checkoutInfo.balanceBefore;
  let message: ReactChild;
  if(props.checkoutInfo.isFree) {
    message = (
      <Alert variant="success" className="d-flex flex-row">
        <div className="mr-3"><IconCheckCircle2 /></div>
        <div>
          {renderMarkup(pg.t('computation.freeUsageMessage', {
            scheduleUsage: props.checkoutInfo.scheduleUsage
          }))}
        </div>
      </Alert>
    );
  } else {
    if(props.checkoutInfo.hasEnoughCredit) {
      if(isBalanceUnchanged) {
        message = (
          <Alert variant="success" className="d-flex flex-row">
            <div className="mr-3"><IconCheckCircle2 /></div>
            <div>
              {pg.t('computation.balanceUnchangedMessage')}
            </div>
          </Alert>
        );
      } else {
        if(props.checkoutInfo.firstTeamUsage) {
          message = (
            <>
            <Alert variant="success" className="d-flex flex-row">
              <div className="mr-3"><IconCheckCircle2 /></div>
              <div>
                {renderMarkup(pg.t('computation.sufficientFirstUsageMessage', {
                  scheduleUsage: props.checkoutInfo.scheduleUsage,
                  additionalUsage: props.checkoutInfo.additionalUsage
                }))}
              </div>
            </Alert>
            <CheckoutTable checkoutInfo={props.checkoutInfo} />
            </>
          );
        } else {
          message = (
            <>
            <Alert variant="success" className="d-flex flex-row">
              <div className="mr-3"><IconCheckCircle2 /></div>
              <div>
                {renderMarkup(pg.t('computation.sufficientAdditionalUsageMessage', {
                  scheduleUsage: props.checkoutInfo.scheduleUsage,
                  additionalUsage: props.checkoutInfo.additionalUsage
                }))}
              </div>
            </Alert>
            <CheckoutTable checkoutInfo={props.checkoutInfo} />
            </>
          )
        }
      }
    } else {
      const insufficientCreditAlert = (
        <Alert variant="warning" className="d-flex flex-row">
          <div className="mr-3"><IconAlertCircle /></div>
          <div>
            {pg.t('computation.insufficientCreditMessage')}
          </div>
        </Alert>
      );
      if(props.checkoutInfo.firstTeamUsage) {
        message = (
          <>
          {insufficientCreditAlert}
          <CheckoutTable checkoutInfo={props.checkoutInfo} />
          <p>
            {renderMarkup(pg.t('computation.topUpHintFirstUsage', {
              scheduleUsage: props.checkoutInfo.scheduleUsage,
              minimumTopUp: -props.checkoutInfo.balanceAfter
            }))}
          </p>
          <p>
            {renderMarkup(pg.t('computation.freeUsageHint'))}
          </p>
          </>
        );
      } else {
        message = (
          <>
          {insufficientCreditAlert}
          <CheckoutTable checkoutInfo={props.checkoutInfo} />
          <p>
            {renderMarkup(pg.t('computation.topUpHintAdditionalUsage', {
              scheduleUsage: props.checkoutInfo.scheduleUsage,
              additionalUsage: props.checkoutInfo.additionalUsage,
              minimumTopUp: -props.checkoutInfo.balanceAfter
            }))}
          </p>
          <p>
            {renderMarkup(pg.t('computation.freeUsageHint'))}
          </p>
          </>
        );
      }
    }
  }

  return (
    <>
      <CheckoutHeader />
      <Modal.Body>
        {message}
      </Modal.Body>
      {props.checkoutInfo.hasEnoughCredit === true &&
        <CheckoutFooter onCancel={props.onCancel} onStart={props.onStart} sufficient />
      }
      {props.checkoutInfo.hasEnoughCredit === false &&
        <CheckoutFooter onCancel={props.onCancel} insufficient />
      }
    </>
  )
}

export interface ComputationCheckoutContainerProps {
  scheduleId: ScheduleId;
  show: boolean;
  onCancel: () => void;
  onStart: () => void;
}

export function ComputationCheckoutContainer(props: ComputationCheckoutContainerProps) {
  const authFetch = useAuthFetch();
  const [checkoutInfo, setCheckoutInfo] = useState<ComputationCheckoutInfoDto|undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string|undefined>();

  useEffect(() => {
    if(props.show) {
      setLoading(true);
      setError(undefined);
      computationFetchCheckoutInfo(authFetch, props.scheduleId)
      .then(d => setCheckoutInfo(d))
      .catch(e => setError(e.message))
      .finally(() => setLoading(false));
    }
  }, [authFetch, props.show, props.scheduleId]);

  let content: ReactNode;
  if(loading) {
    content = (
      <LoadingContent onCancel={props.onCancel} />
    );
  } else if(error) {
    content = (
      <ErrorContent onCancel={props.onCancel}>{error}</ErrorContent>
    );
  } else if(checkoutInfo) {
    content = (
      <CheckoutContent checkoutInfo={checkoutInfo} onCancel={props.onCancel} onStart={props.onStart} />
    );
  }

  return (
    <Skeleton show={props.show} onHide={props.onCancel}>
      {content}
    </Skeleton>
  )
}

//
// mock data for each case
//

const checkoutInfoMocks: ComputationCheckoutInfoDto[] = [
  // sufficient, balance unchanged
  {
    additionalUsage: 0,
    scheduleUsage: 12,
    balanceBefore: 22,
    balanceAfter: 22,
    firstTeamUsage: false,
    hasEnoughCredit: true,
    isFree: false
  },

  // sufficient, balance changes, first usage
  {
    additionalUsage: 16,
    scheduleUsage: 16,
    balanceBefore: 22,
    balanceAfter: 6,
    firstTeamUsage: true,
    hasEnoughCredit: true,
    isFree: false
  },

  // sufficient, balance changes, not first usage
  {
    additionalUsage: 4,
    scheduleUsage: 16,
    balanceBefore: 22,
    balanceAfter: 18,
    firstTeamUsage: false,
    hasEnoughCredit: true,
    isFree: false
  },

  // insufficient, zero credit
  {
    additionalUsage: 16,
    scheduleUsage: 16,
    balanceBefore: 0,
    balanceAfter: -16,
    firstTeamUsage: true,
    hasEnoughCredit: false,
    isFree: false
  },

  // insufficient, first usage
  {
    additionalUsage: 16,
    scheduleUsage: 16,
    balanceBefore: 4,
    balanceAfter: -12,
    firstTeamUsage: true,
    hasEnoughCredit: false,
    isFree: false
  },

  // insufficient, not first usage
  {
    additionalUsage: 4,
    scheduleUsage: 16,
    balanceBefore: 2,
    balanceAfter: -2,
    firstTeamUsage: false,
    hasEnoughCredit: false,
    isFree: false
  },

  // free
  {
    additionalUsage: 0,
    scheduleUsage: 4,
    balanceBefore: 0,
    balanceAfter: 0,
    firstTeamUsage: true,
    hasEnoughCredit: true,
    isFree: true
  },
];

export interface TestComputationCheckoutProps {
  testCase: string;
}

export function TestComputationCheckout(props: TestComputationCheckoutProps) {
  let testCase = parseInt(props.testCase, 10);
  const dummy = () => {};
  return (
    <Skeleton show={true} onHide={dummy}>
      <CheckoutContent checkoutInfo={checkoutInfoMocks[testCase]} onCancel={dummy} onStart={dummy} />
    </Skeleton>
  );
}

import React, { useState } from 'react';
import { useTimeout } from '../Hooks';
import { Container, ContainerProps } from 'react-bootstrap';
import classNames from 'classnames';
import { IconLoader2, IconCheck, IconX } from '../icons';

export interface LoadingSpinnerProps {
  immediate?: boolean;
}

export function LoadingSpinner(props: LoadingSpinnerProps) {
  const [show, setShow] = useState<boolean>(false);
  useTimeout(() => setShow(true), props.immediate ? 0 : 500);

  if(show) {
    return (
      <IconLoader2 className="loading-spinner" role="status" aria-label="Loading..." />
    );
  } else {
    return (<></>);
  }
}

export interface WorkingIndicatorProps {
  isWorking?: boolean;
  isFailed?: boolean;
  isSuccess?: boolean;
}

export function WorkingIndicator(props: WorkingIndicatorProps) {
  if(props.isSuccess) {
    return (
      <IconCheck />
    );
  } else if(props.isFailed) {
    return (
      <IconX />
    );
  } else if(props.isWorking) {
    return (
      <IconLoader2 className="loading-spinner" role="status" aria-label="Working..." />
    );
  } else {
    return (<></>);
  }
}

export function ContainerLeft(props: ContainerProps) {
  return (
    <Container {...props} className={classNames('ml-0', props.className)}>{props.children}</Container>
  );
}

export interface TimeoutContainerProps {
  delay: number;
  cb: () => void;
}

export function TimeoutContainer(props: TimeoutContainerProps) {
  useTimeout(props.cb, props.delay);
  return <></>;
}

import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { LoadingSpinner } from './Components/UtilityComponents';

export interface FetchedResourceProps<T> {
  fetch: () => Promise<T>;
  children?: (resource: T) => JSX.Element;
}

export function FetchedResource<T>({fetch, children}: FetchedResourceProps<T>) {
  const [resource, setResource] = useState<T|undefined>();
  const [error, setError] = useState<string|undefined>();

  useEffect(() => {
    fetch().then(
      resource => setResource(resource),
      error => setError(error.message)
    );
  }, [fetch]);

  if(resource) {
    if(children) {
      return children(resource);
    } else {
      return null;
    }
  } else if(error) {
    return <Alert variant='danger'>{error}</Alert>
  } else {
    return <LoadingSpinner />
  }
}

export interface FetchedResource2Props<T1, T2> {
  fetch1: () => Promise<T1>;
  fetch2: () => Promise<T2>;
  children?: (fetched1: T1, fetched: T2) => JSX.Element;
}

export function FetchedResource2<T1, T2>({fetch1, fetch2, children}: FetchedResource2Props<T1, T2>) {
  const [fetched1, setFetched1] = useState<T1|undefined>();
  const [fetched2, setFetched2] = useState<T2|undefined>();
  const [error1, setError1] = useState<string|undefined>();
  const [error2, setError2] = useState<string|undefined>();

  useEffect(() => {
    fetch1().then(
      resource => setFetched1(resource),
      error => setError1(error.message)
    );
  }, [fetch1]);

  useEffect(() => {
    fetch2().then(
      resource => setFetched2(resource),
      error => setError2(error.message)
    );
  }, [fetch2]);

  if(fetched1 && fetched2) {
    if(children) {
      return children(fetched1, fetched2);
    } else {
      return null;
    }
  } else if(error1) {
    return <Alert variant='danger'>{error1}</Alert>
  } else if(error2) {
    return <Alert variant='danger'>{error2}</Alert>
  } else {
    return <LoadingSpinner />
  }
}


export interface FetchedResource3Props<T1, T2, T3> {
  fetch1: () => Promise<T1>;
  fetch2: () => Promise<T2>;
  fetch3: () => Promise<T3>;
  children?: (fetched1: T1, fetched2: T2, fetched3: T3) => JSX.Element;
}

export function FetchedResource3<T1, T2, T3>({fetch1, fetch2, fetch3, children}: FetchedResource3Props<T1, T2, T3>) {
  const [fetched1, setFetched1] = useState<T1|undefined>();
  const [fetched2, setFetched2] = useState<T2|undefined>();
  const [fetched3, setFetched3] = useState<T3|undefined>();
  const [error1, setError1] = useState<string|undefined>();
  const [error2, setError2] = useState<string|undefined>();
  const [error3, setError3] = useState<string|undefined>();

  useEffect(() => {
    fetch1().then(
      resource => setFetched1(resource),
      error => setError1(error.message)
    );
  }, [fetch1]);

  useEffect(() => {
    fetch2().then(
      resource => setFetched2(resource),
      error => setError2(error.message)
    );
  }, [fetch2]);

  useEffect(() => {
    fetch3().then(
      resource => setFetched3(resource),
      error => setError3(error.message)
    );
  }, [fetch3]);

  if(fetched1 && fetched2 && fetched3) {
    if(children) {
      return children(fetched1, fetched2, fetched3);
    } else {
      return null;
    }
  } else if(error1) {
    return <Alert variant='danger'>{error1}</Alert>
  } else if(error2) {
    return <Alert variant='danger'>{error2}</Alert>
  } else if(error3) {
    return <Alert variant='danger'>{error3}</Alert>
  } else {
    return <LoadingSpinner />
  }
}


import React from 'react';
import { ContainerLeft } from '../Components/UtilityComponents';
import { usePolyglot } from '../Localization/Localization';
import { ScheduleId } from '../Types';
import imageTeam from '../images/team.svg';
import { IntroPanel } from '../Components/IntroPanel';
import { ExternalLinks } from '../Utils';
import { PersonTableContainer } from './PersonTableContainer';
import { PersonGroupTableContainer } from './PersonGroupTableContainer';

export interface TeamProps {
  scheduleId: ScheduleId;
}

export function Team({ scheduleId }: TeamProps) {
  const pg = usePolyglot();
  return (
    <ContainerLeft>
      <IntroPanel imageSource={imageTeam} href={ExternalLinks.toTeamDocs}>
        {pg.t('introtext.team')}
      </IntroPanel>
      <h1 className="pt-3 mb-4">{pg.t('general.persons')}</h1>
      <PersonTableContainer scheduleId={scheduleId} />
      <h1 className="pt-3 mb-4 mt-5">{pg.t('general.personGroups')}</h1>
      <PersonGroupTableContainer scheduleId={scheduleId} />
    </ContainerLeft>
  );
}

import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import { IconLoader2, IconAlertTriangle, IconPlus } from '../icons';
import { usePolyglot } from '../Localization/Localization';

export interface CreateButtonProps extends ButtonProps {
  creating: boolean;
  error: boolean;
}

export function CreateButton({ creating, error, ...rest}: CreateButtonProps) {
  const pg = usePolyglot();
  if(creating) {
    return (
      <Button {...rest} disabled>
        <IconLoader2 className="loading-spinner" />&nbsp;{pg.t('general.create')}
      </Button>
    );
  } else if(error) {
    return (
      <Button {...rest}>
        <IconAlertTriangle />&nbsp;{pg.t('general.create')}
      </Button>
    );
  } else {
    return (
      <Button {...rest}>
        <IconPlus />&nbsp;{pg.t('general.create')}
      </Button>
    );
  }
}

import React, { useState } from 'react';
import { conditionCreate, conditionDelete, conditionRead, conditionReadTemplate, conditionUpdate } from '../BackendService';
import { useAuthFetch } from '../AuthService';
import { ConditionDto, ConditionDto_Cases, ConditionCreateDto, ConditionReadDto, ConditionReadTemplateDto, ConditionUpdateDto } from '../Generated/BackendTypes';
import { FetchedResource } from '../FetchedResource';
import { isNumber, keys, mapValues, values } from 'lodash';
import { memberof } from '../Utils';
import { AutocompleteInfo, RawConditionEditor } from './RawConditionEditor';
import { DropdownOption, PersonGroupId, PersonId, ScheduleId, ShiftId } from '../Types';
import { NamesLookup } from './Types';
import { MaxShiftsInARowConstraintEditor } from './Editors/MaxShiftsInARowConstraintEditor';
import { AvoidSingleFreedaysObjectiveEditor } from './Editors/AvoidSingleFreedaysObjectiveEditor';
import { AvoidSingleWorkdaysObjectiveEditor } from './Editors/AvoidSingleWorkdaysObjectiveEditor';
import { DistributeWeekendLoadEditor } from './Editors/DistributeWeekendLoadEditor';
import { EvenlyDistributeOverWeeksObjectiveEditor } from './Editors/EvenlyDistributeOverWeeksObjectiveEditor';
import { MaxShiftsPerWeekObjectiveEditor } from './Editors/MaxShiftsPerWeekObjectiveEditor';
import { MinCountWeekendsHavingShiftObjectiveEditor } from './Editors/MinCountWeekendsHavingShiftObjectiveEditor';
import { PreferMaxFiveWorkdaysInARowObjectiveEditor } from './Editors/PreferMaxFiveWorkdaysInARowObjectiveEditor';
import { TargetMinutesObjectiveEditor } from './Editors/TargetMinutesObjectiveEditor';
import { WeekendEitherFreeOrWorkObjectiveEditor } from './Editors/WeekendEitherFreeOrWorkObjectiveEditor';
import { RestrictChangeFromShiftConditionEditor } from './Editors/RestrictChangeFromShiftConditionEditor';
import { ShiftSelectionConditionEditor } from './Editors/ShiftSelectionConditionEditor';
import { RestrictChangeToShiftConditionEditor } from './Editors/RestrictChangeToShiftConditionEditor';
import { ContainerLeft } from '../Components/UtilityComponents';
import { usePolyglot } from '../Localization/Localization';

export interface ConditionEditorContainerProps {
  scheduleId: string;
  conditionPos?: number;
  conditionType?: ConditionDto_Cases;
  onComplete: () => void;
  useRawEditor?: boolean;
}

export interface ConditionEditorProps<T extends ConditionDto = ConditionDto> {
  condition: T;
  error?: string;
  dropdown: {
    persons: DropdownOption<PersonId>[];
    personGroups: DropdownOption<PersonGroupId>[];
    shifts: DropdownOption<ShiftId>[];
  };
  lookup: NamesLookup;
  identifiers: {
    personIds: PersonId[];
    personGroupIds: PersonGroupId[];
    shiftIds: ShiftId[];
  }
  onSave?: (condition: T) => Promise<void>|void;
  onCancel?: () => void;
  onDelete?: () => void;
}

interface ConditionEditorSwitchProps extends ConditionEditorProps {
  scheduleId: ScheduleId;
}

function ConditionEditorSwitch(props: ConditionEditorSwitchProps) {
  switch(props.condition._type) {
    case 'AvoidSingleFreedaysObjectiveDto':
      return <AvoidSingleFreedaysObjectiveEditor {...props} condition={props.condition} />;
    case 'AvoidSingleWorkdaysObjectiveDto':
      return <AvoidSingleWorkdaysObjectiveEditor {...props} condition={props.condition} />;
    case 'DistributeWeekendLoadObjectiveDto':
      return <DistributeWeekendLoadEditor {...props} condition={props.condition} />;
    case 'EvenlyDistributeOverWeeksObjectiveDto':
      return <EvenlyDistributeOverWeeksObjectiveEditor {...props} condition={props.condition} />;
    case 'MaxShiftsInARowConstraintDto':
      return <MaxShiftsInARowConstraintEditor {...props} condition={props.condition} />;
    case 'MaxShiftsPerWeekObjectiveDto':
      return <MaxShiftsPerWeekObjectiveEditor {...props} condition={props.condition} />;
    case 'MinCountWeekendsHavingShiftObjectiveDto':
      return <MinCountWeekendsHavingShiftObjectiveEditor {...props} condition={props.condition} />;
    case 'PreferMaxFiveWorkdaysInARowObjectiveDto':
      return <PreferMaxFiveWorkdaysInARowObjectiveEditor {...props} condition={props.condition} />;
    case 'TargetMinutesObjectiveDto':
      return <TargetMinutesObjectiveEditor {...props} condition={props.condition} />;
    case 'WeekendEitherFreeOrWorkObjectiveDto':
      return <WeekendEitherFreeOrWorkObjectiveEditor {...props} condition={props.condition} />;
    case 'ShiftSelectionConditionDto':
      return <ShiftSelectionConditionEditor {...props} condition={props.condition} />;
    case 'RestrictChangeFromShiftConditionDto':
      return <RestrictChangeFromShiftConditionEditor {...props} condition={props.condition} />;
    case 'RestrictChangeToShiftConditionDto':
      return <RestrictChangeToShiftConditionEditor {...props} condition={props.condition} />;
    default:
      return (
        <>
          <RawConditionEditor {...props} />
          <ContainerLeft>
            <AutocompleteInfo scheduleId={props.scheduleId} />
          </ContainerLeft>
        </>
      );
  }
}

export function ConditionEditorContainer(props: ConditionEditorContainerProps) {
  const pg = usePolyglot();
  const authFetch = useAuthFetch();
  const [error, setError] = useState<string|undefined>();

  let handleFetch: () => Promise<ConditionReadDto|ConditionReadTemplateDto>;
  let handleDelete: (() => void)|undefined;
  let handleSave: (documentRevision: number) => (condition: ConditionDto) => Promise<void>;
  if(isNumber(props.conditionPos)) {
    const conditionPos = props.conditionPos;
    handleFetch = async () => {
      return await conditionRead(authFetch, props.scheduleId, conditionPos);
    }
    handleDelete = async () => {
      await conditionDelete(authFetch, props.scheduleId, conditionPos);
      props.onComplete();
    };
    handleSave = (documentRevision: number) => async (condition) => {
      const updateData: ConditionUpdateDto = {
        scheduleId: props.scheduleId,
        conditionPos,
        documentRevision,
        condition
      }
      try {
        await conditionUpdate(authFetch, props.scheduleId, conditionPos, updateData);
        props.onComplete();
      } catch(e) {
        setError(e.message);
      }
    };
  } else if(props.conditionType !== undefined) {
    const conditionType = props.conditionType;
    handleFetch = () => conditionReadTemplate(authFetch, props.scheduleId, conditionType);
    handleDelete = undefined;
    handleSave = (documentRevision: number) => async (condition) => {
      const createData: ConditionCreateDto = {
        scheduleId: props.scheduleId,
        documentRevision,
        condition
      }
      try {
        await conditionCreate(authFetch, props.scheduleId, createData);
        props.onComplete();
      } catch(e) {
        setError(e.message);
      }
    };
  } else {
    throw Error(`Either ${memberof<ConditionEditorContainerProps>('conditionPos')} or ${memberof<ConditionEditorContainerProps>('conditionType')} must be specified.`);
  }

  function handleCancel() {
    props.onComplete();
  }

  return (
    <ContainerLeft>
      <h1 className="mt-3 mb-4">{pg.t('condition.edit')}</h1>
      <FetchedResource fetch={handleFetch}>
        {(response: ConditionReadDto|ConditionReadTemplateDto) => {
          const dropdown = {
            persons: values(response.persons).map(p => ({ itemKey: p.personId, label: p.name })),
            personGroups: values(response.personGroups).map(p => ({ itemKey: p.personGroupId, label: p.name })),
            shifts: values(response.shifts).map(p => ({ itemKey: p.shiftId, label: p.name })),
          };
          const lookup = {
            persons: mapValues(response.persons, p => p.name),
            personGroups: mapValues(response.personGroups, g => g.name),
            shifts: mapValues(response.shifts, s => s.name),
          };
          const identifiers = {
            personIds: keys(response.persons),
            personGroupIds: keys(response.personGroups),
            shiftIds: keys(response.shifts),
          };
          const editorProps: ConditionEditorSwitchProps = {
            scheduleId: props.scheduleId,
            condition: response.condition,
            error,
            onSave: handleSave(response.documentRevision),
            onCancel: handleCancel,
            onDelete: handleDelete,
            dropdown,
            lookup,
            identifiers,
          };
          if(props.useRawEditor) {
            return <RawConditionEditor {...editorProps} />;
          } else {
            return <ConditionEditorSwitch {...editorProps} />;
          }
        }}
      </FetchedResource>
    </ContainerLeft>
  );
}



import React from 'react';
import { IconBook } from '../icons';
import { ExternalLinks } from '../Utils';

export function Explainer() {
  return (
    <>
      <ul>
        <li>
          Jede Organisation besitzt ein Zeilenguthaben auf Taso. Das Guthaben kannst du auf dieser Seite aufladen.
        </li>
        <li>
          Jeden Monat wird der Zeilenverbrauch deiner Teams vom Guthaben abgezogen.
        </li>
        <li>
          Der Zeilenverbrauch pro Team entspricht der Anzahl Zeilen des grössten Dienstplans,
          der in diesem Monat berechnet wurde.
        </li>
        <li>
          <b>Dienstpläne mit sechs Zeilen und weniger sind gratis.</b> Perfekt geeignet um Taso ohne Kostenfolge auszuprobieren.
        </li>
      </ul>
      <p>
        Weitere Informationen findest du in der
        &nbsp;<a href={ExternalLinks.toUsageDocs} target="_blank" rel="noreferrer">
          Dokumentation <IconBook size={18} />
        </a>.
      </p>
    </>
  )
}

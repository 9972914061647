import React from 'react';
import Form from 'react-bootstrap/Form';
import { Formik, Form as FormikForm } from 'formik';
import { memberof } from '../../Utils';
import { renderMarkup } from '../../SimpleMarkup';
import { explainDE } from '../ExplainDE';
import { ConditionEditorCardSubform } from '../ConditionEditorCard';
import { ConditionEditorProps } from '../ConditionEditorContainer';
import { ConditionDto_TargetMinutesObjectiveDto, TargetMinutesAlignment0 } from '../../Generated/BackendTypes';
import { DropdownSingleSelectField } from '../../Components/DropdownSingleSelectField';

type C = ConditionDto_TargetMinutesObjectiveDto;

interface FormValues {
  enabled: boolean;
  alignment: TargetMinutesAlignment0;
}

function toFormValues(condition: C): FormValues {
  return {
    enabled: condition.enabled,
    alignment: condition.alignment,
  };
}

function fromFormValues(props: ConditionEditorProps<C>, values: FormValues): C {
  return {
    _type: props.condition._type,
    enabled: values.enabled,
    alignment: values.alignment,
  };
}

export function TargetMinutesObjectiveEditor(props: ConditionEditorProps<C>) {
  return (
    <Formik
      initialValues={toFormValues(props.condition)}
      onSubmit={values => props.onSave && props.onSave(fromFormValues(props, values))}
    >
      {({ values }) => (
        <Form as={FormikForm}>
          <ConditionEditorCardSubform
            {...props}
            name={memberof<FormValues>('enabled')}
            title={renderMarkup(explainDE(fromFormValues(props, values), props.lookup))}
          >
            <Form.Group controlId="formAlignment">
              <Form.Label>Optimale Planzeit heisst</Form.Label>
              <DropdownSingleSelectField
                id="formAlignmentDropdown"
                name={memberof<FormValues>('alignment')}
                items={[
                  { itemKey: 'None', label: 'Innerhalb des Sollzeitintervalls' },
                  { itemKey: 'Middle', label: 'Mitte des Sollzeitintervalls' }
                ]}
              />
            </Form.Group>
          </ConditionEditorCardSubform>
        </Form>
      )}
    </Formik>
  );
}

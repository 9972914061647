import React, { useState } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import { ConfirmationModal } from './ConfirmationModal';

export interface ConfirmationModalButtonProps {
  text: any;
  children: any;
  passthrough?: boolean;
  positiveText?: any;
  negativeText?: any;
  onNegative?: () => void;
  onPositive?: () => void;
}

export function ConfirmationModalButton({negativeText, positiveText, text, onPositive, onNegative, children, passthrough, ...rest}: ConfirmationModalButtonProps & ButtonProps) {
  const [show, setShow] = useState<boolean>(false);
  function handlePositive() {
    if(onPositive) {
      onPositive();
    }
    setShow(false);
  }
  function handleNegative() {
    if(onNegative) {
      onNegative();
    }
    setShow(false);
  }
  function handleClick() {
    if(passthrough) {
      handlePositive();
    } else {
      setShow(true);
    }
  }

  return (
    <>
      <Button {...rest}
        onClick={handleClick}>
        {children}
      </Button>
      <ConfirmationModal
        show={show}
        onHide={() => setShow(false)}
        negativeText={negativeText}
        positiveText={positiveText}
        onNegative={handleNegative}
        onPositive={handlePositive}
      >
        {text}
      </ConfirmationModal>
    </>
  )
}


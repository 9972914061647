import classNames from "classnames";
import { range } from "lodash";
import ReactDataSheet from "react-datasheet";
import { Spacetime } from "spacetime";

// TODO: move to polyglot
const dayName = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
const monthName = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

export interface CellViewData<T> extends ReactDataSheet.Cell<CellViewData<T>> {
  label: string;
  title?: string;
  data?: T;
}

export function readOnlyCell(options?: Partial<CellViewData<any>>): CellViewData<any> {
  return {
    label: '',
    readOnly: true,
    ...options
  }
}

export function generateDayOfMonthRow(dates: Spacetime[], opts?: { className?: string }) {
  return dates.map(d => ({
    readOnly: true,
    label: d.date().toString(),
    className: classNames('vt-dates', { 'vt-sat': d.day() === 6, 'vt-sun': d.day() === 0 }, opts?.className),
  }));
}

export function generateDayOfWeekRow(dates: Spacetime[], opts?: { className?: string }) {
  return dates.map(d => ({
    readOnly: true,
    label: dayName[d.day()],
    className: classNames('vt-dates', { 'vt-sat': d.day() === 6, 'vt-sun': d.day() === 0 }, opts?.className),
  }));
}

export function generateYearMonthLabel(dates: Spacetime[]) {
  const month = dates.map((d: Spacetime) => d.month())[Math.floor((dates.length / 2))];
  const year = dates.map((d: Spacetime) => d.year())[Math.floor((dates.length / 2))];
  return `${monthName[month]} ${year}`;
}

// function for pasting into one/multiple selected cells if one/multiple copied (excel-like)
// adapted from https://github.com/nadbm/react-datasheet/issues/171
export function parsePasteMultipleCells(value: string, selections?: ReactDataSheet.Selection) {
  if(selections) {
    const cells = value.split(/\r\n|\n|\r/).map((row: string) => row.split('\t'))
    const selectedNumberOfRows = Math.abs(selections.end.i - selections.start.i) + 1
    const selectedNumberOfCols = Math.abs(selections.end.j - selections.start.j) + 1

    if (cells.length === 0) return []

    const rows = Math.max(selectedNumberOfRows, cells.length)
    const cols = Math.max(selectedNumberOfCols, cells[0].length)

    return range(rows).map(row => range(cols).map(col =>
      cells[row%cells.length][col%cells[0].length]
    ));
  } else {
    return [];
  }
}


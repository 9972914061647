import React, { useContext } from "react";
import Polyglot from 'node-polyglot';

import dePhrases from './de.json';
import { toPairs } from "lodash";

// TODO: add language switch
//import enPhrases from './en.json';

type Language = 'de'|'en';

export interface ILocalizationState {
  pg: Polyglot;
  language: Language;
}

const dummy: ILocalizationState = {
  pg: new Polyglot({ phrases: dePhrases, locale: 'de' }),
  language: 'de',
};

export const LocalizationContext = React.createContext(dummy);

export function injectLocalization<P>(WrappedComponent: React.ComponentType<P & { pg: Polyglot; }>): React.ComponentType<P> {
  return (props: P) => (
    <LocalizationContext.Consumer>
      {l => <WrappedComponent pg={l.pg} {...props} />}
    </LocalizationContext.Consumer>
  );
}

export function usePolyglot() {
  return useContext(LocalizationContext).pg;
}

export function useLanguage() {
  return useContext(LocalizationContext).language;
}

// from https://github.com/umpirsky/country-list
export function getCountryNames(language: Language) {
  function process(countryNames: any) {
    return new Map<string, string>(toPairs(countryNames));
  }
  switch(language) {
    case 'de': return import('./deCountryNames.json').then(process);
    case 'en': return import('./enCountryNames.json').then(process);
  }
}
